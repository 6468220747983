import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import ChangeStatusLeaveForm from "../ChangeStatusLeaveForm";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";

const columns = [
  {
    field: "id",
    headerName: "No",
    headerClassName: "MuiDataGrid-header",
    headerAlign: "center",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 1,
    type: "text",
    hide: true,
  },

  {
    field: "status",
    headerName: "Status",
    headerClassName: "MuiDataGrid-header",
    headerAlign: "center",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    type: "text",
    width: 120,
    renderCell: (params) => {
      var renderCell = "";
      const data = params.row;
      if (params.value === "Approved") {
        renderCell = (
          <ChangeStatusLeaveForm
            isCell={true}
            width={120}
            valueInit={params.value}
            id="selection-status"
            data={data}
          />
        );
      } else if (params.value === "Pending") {
        renderCell = (
          <ChangeStatusLeaveForm
            isCell={true}
            width={130}
            valueInit={params.value}
            id="selection-status"
            data={data}
          />
        );
      } else if (params.value === "Rejected") {
        renderCell = (
          <ChangeStatusLeaveForm
            isCell={true}
            width={120}
            valueInit={params.value}
            id="selection-status"
            data={data}
          />
        );
      }
      return renderCell;
    },
  },
  {
    field: "staffId",
    headerName: "Staff ID",
    headerAlign: "center",
    headerClassName: "MuiDataGrid-header",
    type: "text",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 1,
    minWidth: 80,
  },
  {
    field: "fullName",
    headerName: "Name",
    headerAlign: "center",
    headerClassName: "MuiDataGrid-header",
    type: "text",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 3,
    minWidth: 180,
  },
  {
    field: "type",
    headerName: "Leave Type",
    headerAlign: "center",
    headerClassName: "MuiDataGrid-header",
    type: "text",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 2,
    minWidth: 150,
    renderCell: (params) => {
      var cellRender = "";
      if (params.value === "Wedding Leave" || params.value === "Maternity") {
        cellRender = (
          <div className="status status--approved">{params.value}</div>
        );
      } else if (params.value === "Summer Leave") {
        cellRender = (
          <div className="status status--pending">{params.value}</div>
        );
      } else if (params.value === "Unpaid Leave") {
        cellRender = (
          <div className="status status--rejected">{params.value}</div>
        );
      } else {
        cellRender = params.value;
      }
      return cellRender;
    },
  },
  {
    field: "fromDate",
    headerName: "Start Date",
    headerAlign: "center",
    headerClassName: "MuiDataGrid-header",
    type: "date",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 3,
    renderCell: (params) => {
      let value = params.value.replace("T", " ").substring(0, 11);
      return <div>{value}</div>;
    },
    minWidth: 150,
  },
  {
    field: "toDate",
    headerName: "End Date",
    headerClassName: "MuiDataGrid-header",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    headerAlign: "center",
    type: "date",
    flex: 3,
    renderCell: (params) => {
      let value = params.value.replace("T", " ").substring(0, 11);
      return <div>{value}</div>;
    },
    minWidth: 150,
  },
  {
    field: "fromTime",
    headerName: "Start Time",
    headerAlign: "center",
    headerClassName: "MuiDataGrid-header",
    type: "text",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 3,
    minWidth: 70,
  },
  {
    field: "toTime",
    headerName: "Finish Time",
    headerClassName: "MuiDataGrid-header",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    headerAlign: "center",
    type: "text",
    flex: 3,
    minWidth: 70,
  },
  {
    field: "month",
    headerName: "Month",
    headerClassName: "MuiDataGrid-header",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    headerAlign: "center",
    type: "text",
    flex: 2,
    minWidth: 50,
  },
  {
    field: "hours",
    headerName: "Hours",
    headerClassName: "MuiDataGrid-header",
    headerAlign: "center",
    type: "text",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 2,
    renderCell: (params) => {
      return params.value.toFixed(2);
    },
    minWidth: 50,
  },
  {
    field: "reason",
    headerName: "Reason",
    headerClassName: "MuiDataGrid-header",
    headerAlign: "center",
    type: "text",
    cellClassName: "MuiDataGrid-tablecell",
    flex: 10,
    minWidth: 350,
  },
];

function LeaveFormRequestTable(props) {
  const listLeaveInit = props.listLeaveInit.map((request, index) => ({
    ...request,
    fromTime: request.fromDate.split("T")[1].substring(0, 5),
    toTime: request.toDate.split("T")[1].substring(0, 5),
    month: request.fromDate.substring(5, 7),
  }));
  return (
    <>
      <DataGrid
        rows={listLeaveInit}
        rowHeight={40}
        columns={columns}
        getRowId={(row) => row.leaveformId}
        pageSize={15}
        rowsPerPageOptions={[7]}
      />
      <CustomToastComponent />
    </>
  );
}

export default LeaveFormRequestTable;
