import { useDispatch, useSelector } from "react-redux";
import MenuItem from "../shared/MenuItem";
import { Badge } from "@mui/material";
import React, { useState, useEffect } from "react";
import { getOTandLeavePending } from "E_REDUX_STORE/OTandLeaveCount";

AdminNavigation.propTypes = {};

function AdminNavigation(props) {
  const user = useSelector((state) => state.user.current);

  const OTandLeavePending = useSelector(
    (state) => state.OtLeavePending.current
  );
  const dispatch = useDispatch();

  const { navigation, navigationClick } = props;

  const handleOnClick = (value) => {
    if (navigationClick) navigationClick(value);
  };

  const getOTandLeavePendingHandler = async function () {
    const actions = getOTandLeavePending();
    await dispatch(actions);
  };

  // dispatch(getOTandLeavePending());
  useEffect(() => {
    getOTandLeavePendingHandler();
  }, []);

  const renderNavigation = navigation.map((x) => {
    if (x.name == "OVER TIME") {
      return {
        ...x,
        numberNotice: OTandLeavePending?.OTPending,
      };
    } else if (x.name == "LEAVE FORM") {
      return {
        ...x,
        numberNotice: OTandLeavePending?.LeavePending,
      };
    } else {
      return {
        ...x,
        numberNotice: 0,
      };
    }
  });
  return (
    <>
      {user.role === "Administrator" ? (
        <div className="navigation__admin">
          <p>ADMIN PAGE</p>
          {renderNavigation.map((item, index) => (
            <MenuItem
              key={index}
              navigation={item}
              onClick={handleOnClick}
              numberNotice={item.numberNotice}
            />
          ))}
        </div>
      ) : null}
    </>
  );
}

export default AdminNavigation;
