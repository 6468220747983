import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import TextInputField from "A_SHARED_COMPONENT/TextInputField";
import PropTypes from "prop-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import SaveIcon from "@mui/icons-material/Save";
import DropdownListSelection from "A_SHARED_COMPONENT/DropdownListSelection";
import AddMember from "./AddMember";
import AdminUserAPI from "B_API/AdminUserAPI";
import AdminTeamAPI from "B_API/AdminTeamAPI";
import { useSelector } from "react-redux";

const schema = yup
  .object()
  .shape({
    code: yup.string().required("Please enter the code."),
    name: yup.string().required("Please enter the name."),
  })
  .required();

function GroupManagerPopUp(props) {
  let {
    onClose,
    open,
    groupInfo,
    isNew,
    listAllUsers,
    setShowSuccessPopup,
    setShowErrorPopup,
  } = props;
  const handleClose = () => {
    onClose();
  };

  const listLeader = listAllUsers
    .filter((user) => user.status === "active")
    .map((user) => user.fullName.trim());

  const listUsersActive = listAllUsers.filter(
    (user) => user.status === "active"
  );

  const currentUser = useSelector((state) => state.user.current);

  const [memberList, setMemberList] = React.useState([]);
  const [listMemberAdded, setListMemberAdded] = React.useState([]);

  const listType = ["Group", "Team"];

  const form = useForm({
    defaultValues: {
      code: groupInfo?.teamCode || "",
      isDepartment: listType[groupInfo?.isDepartment] || listType[0],
      name: groupInfo?.teamName || "",
      leader:
        groupInfo?.teamDescription || currentUser?.fullName || listLeader[0],
    },
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    const fetchGetMemberList = async () => {
      if (!isNew) {
        try {
          const response = await AdminUserAPI.getListUserByTeamId(
            groupInfo?.teamId
          );
          let listMemberActive = response.data.filter(
            (user) => user.status === "active"
          );

          setMemberList(listMemberActive);
          setListMemberAdded(listMemberActive);
        } catch (error) {
          console.log("Error get member list", error);
        }
      }
    };
    fetchGetMemberList();
  }, []);

  // let memberList = isNew ? [] : listUsersActive;

  const handleAddMember = (list) => {
    //FINISH ADDED MEMBER

    setListMemberAdded(list);
  };
  var teamId = groupInfo?.teamId;

  const handleSubmitForm = async (values) => {
    const valueChanged = form.getValues();

    const groupInfoChange = {
      ...groupInfo,
      teamCode: valueChanged.code,
      isDepartment: isNew ? 0 : listType.indexOf(valueChanged.isDepartment),
      teamName: valueChanged.name,
      teamDescription: valueChanged.leader,
    };

    let listUserSubmit = listMemberAdded.map((user) => ({
      teamId: user.teamId,
      userId: user.userId,
      role: user.role,
      status: user.status,
    }));

    let leaderUser = listUsersActive.find(
      (user) => user.fullName === groupInfoChange?.teamDescription
    );

    let leader = {
      teamId: leaderUser.teamId,
      userId: leaderUser.userId,
      role: leaderUser.role,
      status: leaderUser.status,
    };

    if (!listUserSubmit.some((user) => user.userId === leader.userId)) {
      listUserSubmit.push(leader);
    }

    if (isNew) {
      const response = await AdminTeamAPI.createTeamOrGroup(groupInfoChange);
      if (response.status !== 200) {
        if (setShowErrorPopup) setShowErrorPopup();
        return;
      }

      const listTeam = (await AdminTeamAPI.getTeam()).data;
      teamId = listTeam.find(
        (item) => item.teamCode === groupInfoChange?.teamCode
      ).teamId;
    } else {
      const response = await AdminTeamAPI.changeTeamOrGroupInfo(
        groupInfoChange
      );
      if (response.status !== 200) {
        if (setShowErrorPopup) setShowErrorPopup();
        return;
      }
    }

    let valueSumbit = {
      teamId: teamId,
      listUser: [...listUserSubmit],
    };

    const reponseInsert = await AdminUserAPI.createTeamByListUser(valueSumbit);
    if (reponseInsert.status !== 200) {
      if (setShowErrorPopup) setShowErrorPopup();
      return;
    }

    onClose();
    if (setShowSuccessPopup) setShowSuccessPopup();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="shared__groupmanager">
        <div className="groupmanager__title">GROUP MANAGER</div>
        <div className="groupmanager__form">
          {!isNew ? (
            <>
              <div className="groupmanager__form-section-02">
                <div className="form__section02-col01">
                  <TextInputField name="code" label="Group Code" form={form} />
                </div>
                <div className="form__section02-col02">
                  <DropdownListSelection
                    name="isDepartment"
                    label="Type"
                    form={form}
                    listSelection={listType}
                    disable={true}
                    initIndex={groupInfo?.isDepartment}
                  />
                </div>
              </div>
              <div className="groupmanager__form-section-02">
                <div className="form__section02-col01">
                  <TextInputField name="name" label="Name" form={form} />
                </div>
                <div className="form__section02-col02">
                  <DropdownListSelection
                    name="leader"
                    label="Leader"
                    form={form}
                    listSelection={listLeader}
                    initIndex={
                      listLeader.indexOf(groupInfo?.teamDescription) === -1
                        ? listLeader.indexOf(currentUser?.fullName)
                        : listLeader.indexOf(groupInfo?.teamDescription)
                    }
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="groupmanager__form-section-01">
                <TextInputField name="name" label="Name" form={form} />
              </div>
              <div className="groupmanager__form-section-02">
                <div className="form__section02-col01">
                  <TextInputField name="code" label="Group Code" form={form} />
                </div>
                <div className="form__section02-col02">
                  <DropdownListSelection
                    name="leader"
                    label="Leader"
                    form={form}
                    listSelection={listLeader}
                    initIndex={
                      listLeader.indexOf(groupInfo?.teamDescription) === -1
                        ? listLeader.indexOf(currentUser?.fullName)
                        : listLeader.indexOf(groupInfo?.teamDescription)
                    }
                  />
                </div>
              </div>
            </>
          )}

          <AddMember
            listUsersActive={listUsersActive}
            memberList={memberList}
            handleAddMember={handleAddMember}
          />

          <div className="groupmanager__form-section-06">
            {/* <div className="btn-delete">
              <Button
                variant="contained"
                style={{ minWidth: "120px" }}
                onClick={handleDeleteGroup}
                startIcon={<DeleteIcon />}
                color="error"
                className={isNew === true ? "btn-delete__hidden" : ""}
              >
                DEACTIVE
              </Button>
            </div> */}
            <div className="btn-save">
              <Button
                className="button--normal"
                variant="contained"
                style={{ width: "100px" }}
                onClick={form.handleSubmit(handleSubmitForm)}
                startIcon={<SaveIcon />}
              >
                SAVE
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

GroupManagerPopUp.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default GroupManagerPopUp;
