import { yupResolver } from "@hookform/resolvers/yup";
import GoogleIcon from "@mui/icons-material/Google";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import TextInputField from "A_SHARED_COMPONENT/TextInputField";
import TokenServices from "B_API/TokenServices";
import { login, loginByGoogleAccount } from "E_REDUX_STORE/userSlice";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import PasswordField from "../PasswordFeild";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";

SignInForm.propTypes = {};

const schema = yup
  .object()
  .shape({
    username: yup
      .string()
      .required("Please enter the email")
      .email("Please enter the valid email"),
    password: yup.string().required("Please enter the password"),
  })
  .required();

function SignInForm(props) {
  let navigate = useNavigate();
  const changeModeReset = props.changeModeReset;
  const [loginFailed, setLoginFailed] = useState(false);
  const [googleLoginFailed, setgoogleLoginFailed] = useState(false);
  const [rememeberMe, setRememberMe] = useState(true);
  const user = useSelector((state) => state.user.current);
  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = async (values) => {
    const { username, password } = values;
    try {
      TokenServices.removeUser();
      TokenServices.setLocalStorageRemember(rememeberMe);
      const actions = login({ username: username, password: password });
      const resultAction = await dispatch(actions);
      const userUn = unwrapResult(resultAction);
      if (userUn.userId) {
        navigate("/dashboard", { replace: true });
        setLoginFailed(false);
      } else {
        setLoginFailed(true);
      }
      return {
        ...user,
        value: userUn,
      };
    } catch (error) {
      console.error(error);
    }
  };
  const hanleClickRememberAccount = (e) => {
    setRememberMe(e?.target?.checked);
  };

  const handleChangeModeReset = () => {
    if (changeModeReset) changeModeReset();
  };

  useEffect(() => {
    return () => {
      setLoginFailed(false);
    };
  }, []);

  const handleFailure = (result) => {
    console.log(result);
  };

  const handleGoogleLogin = async (googleData) => {
    const accessToken = googleData.accessToken;
    const email = googleData.profileObj.email;
    try {
      TokenServices.removeUser();
      const actions = loginByGoogleAccount({
        token: accessToken,
        email: email,
      });
      const resultAction = await dispatch(actions);
      const userUn = unwrapResult(resultAction);
      if (userUn.userId) {
        navigate("/dashboard", { replace: true });
        setgoogleLoginFailed(false);
      } else {
        setgoogleLoginFailed(true);
      }
      return {
        ...user,
        value: userUn,
      };
    } catch (error) {
      console.error(error);
    }
  };

  const clientId =
    "785916691995-m1t1oelk29fq5gat8760i01g9l2ptt94.apps.googleusercontent.com";
  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.auth2.init({ clientId: clientId });
    });
  }, []);

  return (
    <form className="login__sign-form">
      <div className="login__sign-account">
        <TextInputField
          name="username"
          label="Email"
          placeholder="email@ttdesignco.com"
          multiline={true}
          form={form}
        />

        <PasswordField
          type="password"
          name="password"
          label="Password"
          // placeholder="****"
          form={form}
          onEnter={form.handleSubmit(handleOnSubmit)}
        />
        {loginFailed && <h5>Login failed! Incorrect email or password.</h5>}
        <div className="login__sign-forget">
          <FormControlLabel
            control={<Checkbox defaultChecked size="small" />}
            label={<span style={{ fontSize: "14px" }}>Remember me</span>}
            onClick={hanleClickRememberAccount}
          />
          <p className="login__change-mode" onClick={handleChangeModeReset}>
            Forgot password?
          </p>
        </div>
      </div>
      <div className="login__sign-others">
        <Button
          className="button-login"
          fullWidth
          variant="contained"
          color="primary"
          onClick={form.handleSubmit(handleOnSubmit)}
        >
          LOGIN
        </Button>
        <hr />
        <GoogleLogin
          clientId={clientId}
          buttonText="Log in whith Google"
          render={(renderProps) => (
            <Button
              // disabled
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<GoogleIcon />}
              onClick={renderProps.onClick}
            >
              Sign In with Google
            </Button>
          )}
          onSuccess={handleGoogleLogin}
          onFailure={handleFailure}
          cookiePolicy={"single_host_origin"}
        ></GoogleLogin>
        {googleLoginFailed && (
          <h5 style={{ marginTop: "5px", color: "red" }}>
            Login failed! Incorrect email.
          </h5>
        )}
        <p>Don't have account? Please contact admin.</p>
      </div>
    </form>
  );
}

export default SignInForm;
