import classes from "../CalendarService.module.scss";
import { IconButton } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

function CalendarNote(props) {
  const [calendarNote, setCalendarNote] = useState(
    props.calendarNote || {
      calendarId: null,
      date: null,
      userId: null,
      content: null,
      color: "success",
      isPublic: true,
    }
  );
  const [privacy, setPrivacy] = useState(calendarNote.isPublic || true);
  const [user, setUser] = useState(props.user || null);

  const currentUser = useSelector((state) => state.user.current);
  const role = currentUser?.role;

  const onPropsChange = useCallback(() => {
    setPrivacy(calendarNote.isPublic || true);
    setUser(props.user || null);
    setCalendarNote(
      props.calendarNote || {
        calendarId: null,
        date: null,
        userId: null,
        content: null,
        color: "success",
        isPublic: true,
      }
    );
  }, [props, calendarNote]);

  const onClickPrivacyHandle = () => {
    setPrivacy(!calendarNote.isPublic);
    props.onChangePrivacy(calendarNote.calendarId);
  };

  const onOpenNoteHandle = () => {
    props.onOpenNoteForm(calendarNote.calendarId);
  };

  useEffect(() => {
    onPropsChange();
  }, [onPropsChange]);

  return (
    <div
      className={`${classes["calendar-item"]} ${
        classes["calendar-item--" + calendarNote.color]
      }`}
      onDoubleClick={() => {
        return;
      }}
    >
      <div className={classes["calendar-item__text"]}>
        {calendarNote.content}
      </div>

      {user === calendarNote.userId ? (
        <div>
          {currentUser.userId === calendarNote.createdBy ? (
            <IconButton
              className={`${classes["calendar-item__button"]}`}
              onClick={onClickPrivacyHandle}
            >
              {calendarNote.isPublic ? (
                <VisibilityIcon className={`${classes["button__icon"]}`} />
              ) : (
                <VisibilityOffIcon className={`${classes["button__icon"]}`} />
              )}
            </IconButton>
          ) : (
            <IconButton disabled={true}></IconButton>
          )}

          <IconButton
            className={`${classes["calendar-item__button"]}`}
            onClick={onOpenNoteHandle}
          >
            <EditIcon className={`${classes["button__icon"]}`} />
          </IconButton>
        </div>
      ) : (
        <>
          <IconButton
            className={`${classes["calendar-item__button"]}`}
            onClick={onOpenNoteHandle}
          >
            <EditIcon className={`${classes["button__icon"]}`} />
          </IconButton>
        </>
      )}
    </div>
  );
}

export default CalendarNote;
