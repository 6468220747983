// @ts-nocheck
import React from "react";
import logo from "./TT-Logo.png";
import "./ExportPdfOT.scss";

class ExportPdfOT extends React.Component {
  render() {
    const listRequest = this.props.listRequestPrint;
    var totalWeekday = this.props.totalWeekday;
    var totalWeekend = this.props.totalWeekend;
    var totalNightOvertime = this.props.totalNightOvertime;
    var totalPublicHoliday = this.props.totalPublicHoliday;
    var userInfo = this.props.userInfo;
    var userName = userInfo?.fullName;
    var department = userInfo?.teamCode;

    // var userName = JSON.parse(localStorage.getItem("user")).fullName;
    // var department;
    // if (JSON.parse(localStorage.getItem("user")).teamId === 1) {
    //   department = "R&D";
    // } else if (JSON.parse(localStorage.getItem("user")).teamId === 2) {
    //   department = "CIM Team";
    // } else if (JSON.parse(localStorage.getItem("user")).teamId === 3) {
    //   department = "BIM Team";
    // } else if (JSON.parse(localStorage.getItem("user")).teamId === 4) {
    //   department = "HR";
    // }

    if (listRequest.length === 0) {
      return (
        <div className="export printonly">
          <div className="export__info">
            <div className="export__info--logo">
              <img src={logo} alt="Company logo"></img>
            </div>
            <div className="export__info--address">
              <p>
                T&T DESIGN CO., LTD.
                <br />
                167 Trung Kính, Toà nhà văn phòng VPI, Yên Hoà, Cầu Giấy, Hà Nội
                <br />
                Phone: (84) 24 6278 8699
              </p>
            </div>
            <div className="export__info--box"></div>
          </div>

          <div>No data</div>
        </div>
      );
    } else {
      var listCopy = [...listRequest];
      var listSort = listCopy.sort((requestFirst, requestSecond) =>
        requestFirst.overtimeTypeId > requestSecond.overtimeTypeId
          ? 1
          : requestFirst.overtimeTypeId === requestSecond.overtimeTypeId
          ? requestFirst.date > requestSecond.date
            ? 1
            : -1
          : -1
      );
      let countRow = Object.keys(listSort).length;

      var listWeekday = listSort
        .filter(
          (request) =>
            request.overtimeTypeId === 1 && request.statusDefinitionId === 2
        )
        .map((item) => sortObjbyProperties(item));

      var listWeekend = listSort
        .filter(
          (request) =>
            request.overtimeTypeId === 2 && request.statusDefinitionId === 2
        )
        .map((item) => sortObjbyProperties(item));

      var listNightOvertime = listSort
        .filter(
          (request) =>
            request.overtimeTypeId === 3 && request.statusDefinitionId === 2
        )
        .map((item) => sortObjbyProperties(item));

      var listPublicHoliday = listSort
        .filter(
          (request) =>
            request.overtimeTypeId === 4 && request.statusDefinitionId === 2
        )
        .map((item) => sortObjbyProperties(item));

      let countWeekday = listWeekday.length;
      let countWeekend = listWeekend.length;
      let countNightOvertime = listNightOvertime.length;
      let countPublicHoliday = listPublicHoliday.length;

      const renderTableHeaderPrint = () => {
        return (
          <thead>
            <tr align="center">
              <th rowSpan="2" style={{ width: "3%" }}>
                No.
              </th>
              <th rowSpan="2" style={{ width: "6%" }}>
                Dept
              </th>
              <th rowSpan="2" style={{ width: "11%" }}>
                Name of Employee
              </th>
              <th rowSpan="2" style={{ width: "8%" }}>
                Date
              </th>
              <th colSpan="2" style={{ width: "14%" }}>
                Worktime
              </th>
              <th rowSpan="2" style={{ width: "9%" }}>
                OverTime (h)
              </th>
              <th rowSpan="2" style={{ width: "37%" }}>
                Overtime reason
              </th>
              <th rowSpan="2" style={{ width: "7%" }}>
                Total Overtime hours (h)
              </th>
              <th rowSpan="2">Note</th>
            </tr>
            <tr align="center">
              <th style={{ width: "10%" }}>From</th>
              <th style={{ width: "10%" }}>To</th>
            </tr>
          </thead>
        );
      };

      const renderRowPrint = (listRequest, span, totalHours, overtimeType) => {
        let fisrtRow = listRequest.slice(0, 1).map((item) => (
          <tr key={item}>
            {Object.keys(item).map((key) => {
              let value = item[key];
              return <td key={value}>{value}</td>;
            })}
            <td rowSpan={span} key={totalHours}>
              {totalHours}
            </td>
            <td rowSpan={span} key={overtimeType}>
              {overtimeType}
            </td>
          </tr>
        ));

        let nextRow = listRequest
          .slice(1, listRequest.length + 1)
          .map((item) => (
            <tr key={Math.random()}>
              {Object.keys(item).map((key) => {
                let value = item[key];
                return <td key={value}>{value}</td>;
              })}
            </tr>
          ));

        return fisrtRow.concat(nextRow);
      };

      function sortObjbyProperties(item) {
        const columns = ["date", "fromTime", "toTime", "hours", "reason"];
        return columns.reduce((target, key) => {
          if (key === "date") {
            target[key] = item[key].slice(0, 10);
          } else if (key === "fromTime") {
            target[key] = item[key].slice(-8);
          } else if (key === "toTime") {
            target[key] = item[key].slice(-8);
          } else if (key === "hours") {
            target[key] = item[key].toFixed(2);
          } else {
            target[key] = item[key];
          }
          return target;
        }, {});
      }

      const renderTableBodyPrint = () => {
        return (
          <tbody>
            <td id="no" rowSpan={countRow + 1} key={1}>
              1
            </td>
            <td id="dept" rowSpan={countRow + 1} key={2}>
              {department}
            </td>
            <td id="name" rowSpan={countRow + 1} key={3}>
              {userName}
            </td>

            {renderRowPrint(listWeekday, countWeekday, totalWeekday, "Weekday")}
            {renderRowPrint(listWeekend, countWeekend, totalWeekend, "Weekend")}
            {renderRowPrint(
              listNightOvertime,
              countNightOvertime,
              totalNightOvertime,
              "Night OverTime"
            )}
            {renderRowPrint(
              listPublicHoliday,
              countPublicHoliday,
              totalPublicHoliday,
              "Public Holiday"
            )}
          </tbody>
        );
      };
      return (
        <div className="export printonly">
          <div className="export__info">
            <div className="export__info--logo">
              <img src={logo} alt="Company logo"></img>
            </div>
            <div className="export__info--address">
              <p>
                T&T DESIGN CO., LTD.
                <br />
                167 Trung Kính, Toà nhà văn phòng VPI, Yên Hoà, Cầu Giấy, Hà Nội
                City
                <br />
                Phone: (84) 24 6278 8699
              </p>
            </div>
            <div className="export__info--box"></div>
          </div>

          <div className="export__content">
            <div className="export__content--title">
              <b>OverTime Sheet</b>
            </div>
            <div className="export__content--year">
              <b>Month/Year :</b>
            </div>
          </div>

          <div className="export__table">
            <table id="list-request">
              {renderTableHeaderPrint()}
              {renderTableBodyPrint()}
            </table>
          </div>

          <div className="export__signature">
            <div className="export__signature--employee">
              <b>Employee</b>
            </div>
            <div className="export__signature--hr">
              <b>Confirmed by HR</b>
            </div>
            <div className="export__signature--leader">
              <b>Confirmed by Leader</b>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ExportPdfOT;
