import "./AdminLeaveFormService.scss";
import { Tabs } from "@material-ui/core";
import { Tab } from "@mui/material";
import RequestTab from "./components/RequestTab";
import React from "react";
import PublicHolidayTab from "./components/PublicHoliday";
import OverViewLeaveFormTab from "./components/OverViewTab";
AdminLeaveFormService.propTypes = {};

function AdminLeaveFormService(props) {
  const [value, setValue] = React.useState("requestTab");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="admin__leaveform">
      <div className="admin__leaveform--navbar">
        <div className="navbar__column">
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              style: { background: "var(--bs-regal-blue)" },
            }}
          >
            <Tab value="requestTab" label="Request" sx={{ fontWeight: 600 }} />
            <Tab
              value="publicHolidayTab"
              label="Public Holiday"
              sx={{ fontWeight: 600 }}
            />
            <Tab
              value="overViewTab"
              label="Overview"
              sx={{ fontWeight: 600 }}
            />
          </Tabs>
        </div>
      </div>
      <div className="admin__leaveform--content">
        {value === "requestTab" ? (
          <RequestTab />
        ) : value === "publicHolidayTab" ? (
          <PublicHolidayTab />
        ) : value === "overViewTab" ? (
          <OverViewLeaveFormTab />
        ) : null}
      </div>
    </div>
  );
}

export default AdminLeaveFormService;
