import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Switch } from "@mui/material";
import { Box } from "@mui/system";
import ConfirmPopup from "A_SHARED_COMPONENT/ConfirmPopup";
import DropdownListSelection from "A_SHARED_COMPONENT/DropdownListSelection";
import UserSettingAPI from "B_API/UserSettingAPI";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import "../../UserInformationService.scss";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";
import { ToastShowError, ToastShowSuccess } from "F_UTILS/Notify";

AdvancedSetting.propTypes = {};


function AdvancedSetting(props) {
  const {advancedSetting,setAdvancedSetting,listTImeZone,listLanguage  } = props;

  const schema = yup
    .object()
    .shape({})
    .required();

  const form = useForm({
    defaultValues: {
      ...advancedSetting,
    },
    resolver: yupResolver(schema),
  });
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [showConfirm, setShowConfirm] = useState({
    show: false,
    callback: null,
  });

  const handleCloseConfirm = () => {
    setShowConfirm({
      show: false,
      callback: null,
    });
  };

  const handleSubmitForm = async () => {
      try {
        const bodyObject = {
          userId: advancedSetting.userId,
          emailNotification: advancedSetting.emailNotification,
          timezone: advancedSetting.timezone,
          language: advancedSetting.language,
        }
        const response = await UserSettingAPI.UpdateUserSetting(advancedSetting.userSettingId, bodyObject);
        if (response.status === 200) {
          ToastShowSuccess("Updated Advanced Setting Success!");
        }
        else{
          ToastShowError("Updated Advanced Setting Failed!");
        }
      } catch (error) {
        ToastShowError("Failed");
      }
    };

  const changeValueNofitication = (value)=>{
    const checked = value?.target?.checked || false;

    const newObject = {...advancedSetting,
      emailNotification: checked? 1 : 0
    }

    setAdvancedSetting(newObject);
  }

  return (
    <>
      <Box className="advanced">
        <div className="advance__form">
          <div className="advanced__title">Email Notification</div>
          <div className="form__input">
            <Switch {...label} checked={advancedSetting.emailNotification === 1 } onChange={changeValueNofitication}/>
          </div>
          <div className="form__input">
            <DropdownListSelection
              disable ={true}
              name="timezone"
              label="Time zone"
              form={form}
              listSelection={listTImeZone}
              initIndex={
                listTImeZone.indexOf(advancedSetting?.timeZone) === -1
                  ? 0
                  : listTImeZone.indexOf(advancedSetting?.timeZone)
              }
            />
          </div>
          <div className="form__input --width-10">
            <DropdownListSelection
              disable ={true}
              name="language"
              label="Language"
              form={form}
              listSelection={listLanguage}
              initIndex={
                listLanguage.indexOf(advancedSetting?.language) === -1
                  ? 0
                  : listLanguage.indexOf(advancedSetting?.language)
              }
            />
          </div>
        </div>
        <div className="form__button">
          <Button
            className="button"
            variant="contained"
            onClick={form.handleSubmit(handleSubmitForm)}
          >
            Save
          </Button>
        </div>
      </Box>
      <ConfirmPopup
        open={showConfirm.show}
        onClose={handleCloseConfirm}
        onConfirmed={showConfirm.callback}
        title="Are you sure?"
        description="Do you want to save change?"
      />
      <CustomToastComponent />
    </>
  );
}

export default AdvancedSetting;
