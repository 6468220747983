import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { Button, Paper } from "@mui/material";
import SearchField from "A_SHARED_COMPONENT/SearchField";
import React, { createContext, useEffect, useState } from "react";
import AdminTimeSheetAPI from "B_API/AdminTimesheetAPI";
import ProjectInfoPopUp from "../../ProjectInformationPopup";
import ProjectSummary from "../ProjectSummary";
import ProjectAPI from "B_API/ProjectAPI";

ProjectManagement.propTypes = {};

export const ProjectInfoInitContext = createContext();

function ProjectManagement({
  currentTeamId,
  handleChangeActiveProject,
  listInfoProjectInit,
  setListInfoProjectInit,
}) {
  const [newDialog, setNewDialog] = React.useState({
    show: false,
  });

  const [textSearch, setTextSearch] = React.useState("");

  const [listProjectInit, setListProjectInit] = useState([]);

  const [onDelete, setOnDelete] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);

  useEffect(() => {
    const fetchProjectListInit = async () => {
      try {
        var params = {
          id: currentTeamId,
        };
        const response = await AdminTimeSheetAPI.GetProjectListByTeamId(params);
        setListProjectInit(response.data);

        const res = await ProjectAPI.GetInitProjectCode();
        const res1 = await ProjectAPI.GetClients();
        setListInfoProjectInit({
          fiscalAndNumber: res.data,
          listClients: res1.data,
        });

        //Fix bug o day
      } catch (error) {
        console.log("Error get project hours " + error);
      }
    };
    fetchProjectListInit();
  }, [currentTeamId, newDialog, onDelete, onSubmit, setListInfoProjectInit]);

  const textSearchChange = (text) => {
    setTextSearch(text);
  };

  const handleNewDialogClose = () => {
    setNewDialog({ show: false });
  };

  const onChangeActiveProject = () => {
    if (handleChangeActiveProject) handleChangeActiveProject();
  };

  const handleClickNewProject = () => {
    setNewDialog({ show: true });
  };

  const handleDeleteProject = () => {
    var newState = !onDelete;
    setOnDelete(newState);
  };

  const handleOnSubmit = () => {
    var newState = !onSubmit;
    setOnSubmit(newState);
  };

  const searchStyle = {
    backgroundColor: "white",
    backgroundColorHover: "white",
    width: "250px",
    margin: 0,
  };

  let contentWidth = "100%";
  if (window.screen.width < 1200) {
    contentWidth = window.screen.width - 230 + "px";
  }
  if (window.screen.width < 768) {
    contentWidth = window.screen.width - 100 + "px";
  }

  let projectInfoPopUp = null;
  if (newDialog.show === true) {
    projectInfoPopUp = (
      <ProjectInfoPopUp
        currentTeamId={currentTeamId}
        open={newDialog.show}
        onClose={handleNewDialogClose}
        setNewDialog={setNewDialog}
        handleOnSubmit={handleOnSubmit}
        listInfoProjectInit={listInfoProjectInit}
        isNew={true}
      />
    );
  }

  return (
    <Paper
      elevation={3}
      square
      className="overview__projectmanagement"
      component="div"
    >
      <div className="overview-project__title">
        <p>Project Summary</p>
        <div className="project__utils">
          <SearchField
            {...searchStyle}
            textSearchChange={textSearchChange}
            textSearch={textSearch}
          />
          <Button
            className="button--create"
            variant="contained"
            onClick={handleClickNewProject}
            startIcon={<CreateNewFolderIcon />}
            sx={{ width: "150px", marginLeft: "5px" }}
            size="small"
          >
            Create New
          </Button>
        </div>
      </div>

      <div
        className="overview-project__content"
        style={{ width: contentWidth }}
      >
        <ProjectInfoInitContext.Provider
          value={{ listInfoProjectInit, currentTeamId }}
        >
          <ProjectSummary
            textSearch={textSearch}
            listProject={listProjectInit}
            onDeleteProject={handleDeleteProject}
            handleChangeActiveProject={onChangeActiveProject}
            handleOnSubmit={handleOnSubmit}
          />
        </ProjectInfoInitContext.Provider>
      </div>
      {projectInfoPopUp}
    </Paper>
  );
}

export default ProjectManagement;
