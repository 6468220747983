import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MemberItemStatus from "A_SHARED_COMPONENT/MemberItemStatus";
import { List } from "@mui/material";
import SearchField from "A_SHARED_COMPONENT/SearchField";
import "./../shared_component.scss";
import { useDispatch, useSelector } from "react-redux";
import { getListViewUserCombineTeam } from "E_REDUX_STORE/commonSlice";
import { ToastShowError, ToastShowSuccess } from "F_UTILS/Notify";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";
import AdminTeamAPI from "B_API/AdminTeamAPI";
import RoleAPI from "B_API/RolesAPI";

MemberListStatus.propTypes = {};

function MemberListStatus(props) {
  const searchStyle = {
    border: "1px solid",
    borderColor: "gray",
    borderRadius: "4px",
    backgroundColor: "white",
    backgroundColorHover: "white",
    margin: "0 0 10px 0",
    width: "100%",
    height: "40px",
    showFilter: true,
  };

  const filterObject = [
    { name: "All", value: true },
    { name: "BIM Team", value: true, teamId: 3 },
    { name: "CIM1 Team", value: true, teamId: 2 },
    { name: "CIM2 Team", value: true, teamId: 5 },
    { name: "R&D Team", value: true, teamId: 1 },
    { name: "HR Team", value: true, teamId: 4 },
    { name: "SBD Team", value: true, teamId: 6 },
  ];

  //log combineUsersState, sơn còi,
  const combineUsersState = useSelector((state) => state.common.combineUsers);

  //userStatus update => MemberListStatus re-render lại.
  const userStatus = useSelector((state) => state.status);

  const dispatch = useDispatch();

  const [filters, setFilter] = useState(filterObject);
  const [textSearch, setTextSearch] = useState("");
  const handleFilterChange = (filters) => {
    setFilter(filters);
  };

  const textSearchChange = (text) => {
    setTextSearch(text);
  };

  const getListUser = async function () {
    const action = getListViewUserCombineTeam();
    await dispatch(action);
  };

  let renderUser = [...combineUsersState];

  (() => {
    function removeVietnameseTones(str) {
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
      str = str.replace(/Đ/g, "D");
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "");
      str = str.replace(/\u02C6|\u0306|\u031B/g, "");
      str = str.replace(/ + /g, " ");
      str = str.trim();
      str = str.replace(
        /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
        " "
      );
      return str;
    }
    if (filters.find((filter) => filter.name === "All").value !== true) {
      const criteria = [];
      filters.forEach((filter) => {
        if (filter.value === true) criteria.push(filter.teamId);
      });
      renderUser = renderUser.filter((user) => criteria.includes(user.teamId));
    }
    renderUser = renderUser.filter((user) => user.teamId);
    if (textSearch !== "") {
      renderUser = renderUser.filter((user) =>
        removeVietnameseTones(user.fullName.toLowerCase()).includes(
          removeVietnameseTones(textSearch.toLowerCase())
        )
      );
    }
  })();

  if (renderUser.length !== 0) {
    // console.log("UPDATE RENDER STATUS REDUX: ", userStatus);

    var newList = renderUser.map((item) => {
      let updatePerson;
      let findUser = userStatus?.find((user) => user.userId == item.userId);
      if (findUser !== undefined) {
        updatePerson = { ...item, state: findUser?.state };
      } else {
        updatePerson = item;
      }
      return updatePerson;
    });

    renderUser = newList;
  }

  const [onRefresh, setRefresh] = useState(false);

  useEffect(() => {
    getListUser();
    return () => {};
  }, [onRefresh]);

  const setShowSuccessPopup = () => {
    ToastShowSuccess("User info has been updated.");
    setRefresh(!onRefresh);
  };

  const setShowError = (message) => {
    ToastShowError(message || "User info updated failed.");
  };

  const [listTeamInCompany, setListCompanyTeams] = React.useState([]);
  React.useEffect(() => {
    const fetchListTeams = async () => {
      try {
        const response = await AdminTeamAPI.getTeamOrGroup(1);
        if (response.status === 200) setListCompanyTeams(response?.data);
      } catch (error) {
        console.log("Failed to fetch list teams");
      }
    };
    fetchListTeams();
  }, []);

  const [listRole, setListRole] = useState([]);

  React.useEffect(() => {
    const fetchRoles = async () => {
      const response = await RoleAPI.getAllRoles();
      if (response.status === 200) setListRole(response.data);
    };

    fetchRoles();
  }, []);

  return (
    <>
      <div className="memberlist__status">
        {/* here */}
        <SearchField
          {...searchStyle}
          filterChange={handleFilterChange}
          filterObject={filters}
          textSearchChange={textSearchChange}
          textSearch={textSearch}
        />
        <List
          sx={{
            width: "100%",
            position: "relative",
            overflow: "auto",
            height: "100%",
          }}
        >
          {renderUser?.map((user, index) => (
            <MemberItemStatus
              listTeamInCompany={listTeamInCompany}
              listRole={listRole}
              key={index}
              user={user}
              setShowSuccessPopup={setShowSuccessPopup}
              setShowError={setShowError}
            />
          ))}
        </List>
      </div>

      <CustomToastComponent />
    </>
  );
}

export default MemberListStatus;
