import React from "react";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import TimeSheetComponent from "./components/TimeSheetComponent";
import { useNavigate, useParams } from "react-router-dom";
import {} from "E_REDUX_STORE/timesheetSlice";
import UserOverTimeAPI from "B_API/OverTimeAPI";
import TimeSheetAPI from "B_API/TimeSheetAPI";
import { calcTotalFingerPrint } from "F_UTILS/DateTimeUtils";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import PublicHolidayAPI from "B_API/PublicHolidayAPI";

//function
const convertTime = (time) => {
  var mins = time * 60;
  var hours = mins / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);

  rhours = rhours.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  rminutes = rminutes.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  return rhours + ":" + rminutes;
};
const convertDate = (date) => {
  const [day, month, year] = date.split("-");
  return new Date(+year, month - 1, +day);
};

TimeSheetDetailPage.propTypes = {};

function TimeSheetDetailPage() {
  const navigate = useNavigate();
  const { userIdParam, date } = useParams();

  const currentUserState = useSelector((state) => state.user.current);

  const initDate = {
    date: convertDate(date) || new Date(date),
    timeIn: "--:--",
    timeOut: "--:--",
    totalTime: null,
    ot: null,
    note: null,
    lock: 0,
    timesheetId: null,
    listTask: [],
  };
  const [detailObj, setDetailObj] = useState(initDate);
  const [refresh, setRefresh] = useState(false);
  const [initProjectId, setInitProjectId] = useState(null);

  const [pageAvailable, setPageAvailable] = useState(false);

  const [lockMode, setLockMode] = useState(false);
  const [listHolidays, setListHolidays] = useState([]);

  const fetchToInitData = async () => {
    try {
      if (
        currentUserState?.role === "Administrator" ||
        currentUserState?.userId === parseInt(userIdParam)
      ) {
        setPageAvailable(true);
      }

      const currentDate = convertDate(date);

      let params = {
        userId: userIdParam,
        date: format(new Date(currentDate), "yyyy-MM-dd"),
      };

      const dateData = await TimeSheetAPI.GetVwTimesheetByUserIdAndDate(params);

      if (dateData.status === 200) {
        setLockMode(dateData.data.lock === 0 ? true : false);

        let paramTask = {
          userId: userIdParam,
          timesheetId: dateData.data.timesheetId,
        };
        const listTasks =
          await TimeSheetAPI.GetListVwTimesheetTaskSummaryHoursByUserIdAndTimesheetId(
            paramTask
          );

        const newDateObject = {
          ...detailObj,
          date: new Date(currentDate) || new Date(date),
          timeIn: dateData.data.timeIn
            ? format(new Date(dateData.data.timeIn), "HH:mm")
            : "--:--",
          timeOut: dateData.data.timeOut
            ? format(new Date(dateData.data.timeOut), "HH:mm")
            : "--:--",

          totalTime:
            dateData.data.total > 0
              ? convertTime(dateData.data.total)
              : "00:00",
          ot: dateData.data.ot > 0 ? convertTime(dateData.data.ot) : "00:00",

          leaveHours: dateData.data.leaveHours || 0,

          note: dateData.data.note ? dateData.data.note : null,
          timesheetId: dateData.data.timesheetId || null,

          listTask: listTasks.data ? listTasks.data : [],
          totalFingerPrint:
            dateData.data?.timeIn == null || dateData.data?.timeOut == null
              ? null
              : calcTotalFingerPrint(
                  dateData.data?.timeIn,
                  dateData.data?.timeOut
                ),
        };
        setDetailObj(newDateObject);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchToInitData();
  }, [date, refresh, initProjectId]);

  const taskDetailPageToggler = (detail) => {
    navigate("/timesheet");
  };
  const handleUpdateTaskDone = async (value) => {
    fetchToInitData();
    setInitProjectId(value.projectId);
  };

  //Toan start
  const [ToanObject, setToanObject] = useState({
    listOTType: [],
    listProject: [],
  });

  //Get list OT Type
  useEffect(() => {
    const fetchGetListOTType = async () => {
      try {
        const response1 = await UserOverTimeAPI.getListOverTimeType();
        const response2 = await TimeSheetAPI.GetAllTimesheetProjectsByUserId(
          userIdParam
        );
        const toanNewObject = {
          listOTType: response1.data ? response1.data : [],
          listProject: response2.data
            ? response2.data.filter((project) => project.isActive === 1)
            : [],
        };

        var currentYear = new Date().getFullYear();
        const response = await PublicHolidayAPI.GetPublicHolidayRequest();

        setListHolidays(
          response.data.filter((item) => item.year === currentYear)
        );
        setToanObject(toanNewObject);
      } catch (error) {}
    };
    fetchGetListOTType();
  }, [refresh]);

  const onRefreshHandler = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      {pageAvailable ? (
        <>
          <TimeSheetComponent
            userIdParam={userIdParam}
            detailObj={detailObj}
            onBackToTimeSheet={taskDetailPageToggler}
            onUpdateTaskDone={handleUpdateTaskDone}
            initProjectId={initProjectId}
            listOTType={ToanObject.listOTType}
            listProject={ToanObject.listProject}
            onRefresh={onRefreshHandler}
            lockMode={lockMode}
            listHolidays={listHolidays}
            totalFingerPrint={detailObj.totalFingerPrint}
          />
        </>
      ) : (
        <Typography variant="h3" sx={{ m: 3 }}>
          This webpage is not available
        </Typography>
      )}
    </>
  );
}
export default TimeSheetDetailPage;
