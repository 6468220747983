import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";
import { Controller } from "react-hook-form";
import "./style.scss";

const useStyle = makeStyles((theme) => ({
  input__all: {
    margin: "5px 0",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    whiteSpace: "pre-line",
    wordBreak: "break-all",
  },

  input_label: {
    marginBottom: "3px",
    fontWeight: 600,
    fontSize: "16px",
    textTransform: "capitalize",
  },
}));

function AutocompleteSelection(props) {
  const classes = useStyle();

  const { name, form, label, disable, listSelection, initIndex } = props;
  const [selected, setSelected] = React.useState(
    listSelection[initIndex] || listSelection[0]
  );

  const { errors, formState } = form;
  const hasError = errors[name];
  const hasTouched = formState.touched[name];

  const handleChange = (value) => {
    // const value = event.target.value;
    setSelected(value);

    console.log(value, listSelection);

    if (form) form.setValue(name, value, { shouldValidate: true });
  };

  return (
    <div className={classes.input__all}>
      <div className={classes.input_label}>{label}</div>
      <Controller
        control={form.control}
        name={name}
        render={() => (
          <FormControl fullWidth>
            <Autocomplete
              size="small"
              value={selected}
              required
              disabled={disable}
              onChange={(e, value) => handleChange(value)}
              options={listSelection}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={hasError && !selected}
                  helperText={
                    hasError && !selected ? "Please select project name." : null
                  }
                  required
                />
              )}
            />
          </FormControl>
        )}
      />
    </div>
  );
}

export default AutocompleteSelection;
