import React from "react";
import { IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";

import { makeStyles } from "@mui/styles";
import RestoreIcon from "@mui/icons-material/Restore";

const useStyles = makeStyles({
  inactive: {
    backgroundColor: "#eff0f3",
    color: "#001858",
  },
});

function UserTable({
  width,
  height,
  listSelection,
  defaultIndex = 0,
  className,
  popup,
  listAllUsers,
  setListAllUser,
  setUserInfo,
  setShowDeletePopup,
  setIsNewUser,
}) {
  // const [userInfoPopup, setUserInfoPopup] = useState({
  //   show: false,
  // });
  // const handleClose = () => {
  //   setUserInfoPopup({ show: false });
  // };

  const columns = [
    {
      field: "staffId",
      headerName: "STAFF ID",
      headerAlign: "center",
      headerClassName: "MuiDataGrid-header",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      type: "text",
      flex: 1,
      minWidth: 75,
    },
    {
      field: "fullName",
      headerName: "FULL NAME",
      headerAlign: "center",
      headerClassName: "MuiDataGrid-header",
      cellClassName: "MuiDataGrid-tablecell",
      type: "text",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "gender",
      headerName: "GENDER",
      headerAlign: "center",
      headerClassName: "MuiDataGrid-header",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      type: "text",
      minWidth: 80,
    },
    {
      field: "teamCode",
      headerName: "TEAM",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      type: "text",
      minWidth: 110,
    },
    {
      field: "email",
      headerName: "EMAIL",
      headerClassName: "MuiDataGrid-header",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      headerAlign: "center",
      type: "text",
      flex: 2,
      width: 120,
      minWidth: 250,
    },

    {
      field: "idNo",
      headerName: "ID NO",
      headerClassName: "MuiDataGrid-header",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      headerAlign: "center",
      type: "text",
      flex: 2,
      width: 120,
      minWidth: 120,
    },
    {
      field: "issuedTo",
      headerName: "ISSUED TO",
      headerClassName: "MuiDataGrid-header",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      headerAlign: "center",
      type: "text",
      flex: 2,
      width: 120,
      minWidth: 120,
      renderCell: (params) => {
        let value = params.value?.substr(0, 10) || null;
        return <div>{value}</div>;
      },
    },
    {
      field: "issuedBy",
      headerName: "ISSUED BY",
      type: "text",
      headerClassName: "MuiDataGrid-header",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      headerAlign: "center",
      flex: 2,
      width: 120,
      minWidth: 250,
    },
    {
      field: "phoneNumber",
      headerName: "PHONE NUMBER",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      type: "text",
      flex: 1,
      minWidth: 130,
    },
    {
      field: "address",
      headerName: "ADDRESS",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      type: "text",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "dateStartWork",
      headerName: "DATE START WORK",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      type: "date",
      flex: 1,
      minWidth: 110,
      renderCell: (params) => {
        let value = params.value?.substr(0, 10);
        return <div>{value}</div>;
      },
    },

    {
      field: "socialInsuranceBookNo",
      headerName: "SOCIAL INSURANCE BOOK NO",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      type: "text",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "role",
      headerName: "ROLE",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      flex: 1,
      type: "text",
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "STATUS",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      width: 80,
      type: "text",
      renderCell: (params) => {
        var cellRender = "";
        if (params.value === "active") {
          cellRender = (
            <div className="status status--active">{params.value}</div>
          );
        } else if (params.value === "inactive") {
          cellRender = <div className="status">{params.value}</div>;
        }
        return cellRender;
      },
    },
    {
      field: "action",
      headerName: "ACTION",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      cellClassName: "MuiDataGrid-tablecell MuiDataGrid-cell--textCenter ",
      minWidth: 90,
      renderCell: (params) => {
        const data = params.row;

        const onClickEdit = async () => {
          setIsNewUser(false);
          setUserInfo(data);
          popup({ show: true });
        };
        const onClickDelete = async () => {
          setUserInfo(data);
          setShowDeletePopup({ show: true });
        };
        if (data.status === "active") {
          return (
            <div>
              <IconButton onClick={onClickEdit}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={onClickDelete}>
                <ClearIcon />
              </IconButton>
            </div>
          );
        } else {
          return (
            <div>
              <IconButton onClick={onClickEdit}>
                <EditIcon />
              </IconButton>

              <IconButton onClick={onClickDelete}>
                <RestoreIcon />
              </IconButton>
            </div>
          );
        }
      },
      flex: 1,
    },
  ];
  const classes = useStyles();
  return (
    <div className="data-table">
      <DataGrid
        rows={listAllUsers}
        getRowId={(row) => row.userId}
        rowHeight={40}
        columns={columns}
        pageSize={15}
        rowsPerPageOptions={[7]}
        // disableColumnMenu
        // disableColumnFilter
        // disableColumnSelector
        getRowClassName={(params) => {
          return params.row.status === "inactive" ? classes.inactive : "";
        }}
      />
    </div>
  );
}

export default UserTable;
