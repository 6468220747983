import axiosClient from "./axiosClient";

const UserSettingAPI = {
  async uploadAvatar(params) {
    const { formData, userId } = params;

    const url = `/UserSetting/UploadAvatar/${userId}`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: formData,
    });
  },

  async GetUserSetting() {
    const userId = JSON.parse(localStorage.getItem("user")).userId;
    const url = `/UserSetting/${userId}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async UpdateUserSetting(settingId, bodyParams) {
    const url = `/UserSetting/${settingId}`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: bodyParams,
    });
  },
};
export default UserSettingAPI;
