import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function FilterLeaveFormByMonth({ width, height, valueInit, onFilterByMonth }) {
  var currentYear = new Date().getFullYear();
  var monthArr = [
    { key: 0, month: "11", year: currentYear - 1 },
    { key: 1, month: "12", year: currentYear - 1 },
    { key: 2, month: "01", year: currentYear },
    { key: 3, month: "02", year: currentYear },
    { key: 4, month: "03", year: currentYear },
    { key: 5, month: "04", year: currentYear },
    { key: 6, month: "05", year: currentYear },
    { key: 7, month: "06", year: currentYear },
    { key: 8, month: "07", year: currentYear },
    { key: 9, month: "08", year: currentYear },
    { key: 10, month: "09", year: currentYear },
    { key: 11, month: "10", year: currentYear },
    { key: 12, month: "11", year: currentYear },
    { key: 13, month: "12", year: currentYear },
    { key: 14, month: "01", year: currentYear + 1 },
    { key: 15, month: "02", year: currentYear + 1 },
  ];
  const [selected, setSelected] = React.useState(valueInit + 2 || 0);

  const handleChange = async (event) => {
    let valueSelected = event.target.value;
    var selected = monthArr[valueSelected] || monthArr[2];

    let monthSelected = selected?.year.toString().concat("-", selected.month);
    setSelected(valueSelected);
    console.log("onFilterByMonth ", monthSelected);

    if (onFilterByMonth) onFilterByMonth(new Date(monthSelected));
  };

  return (
    <Box sx={{ width: width, paddingLeft: "15px" }}>
      <FormControl fullWidth>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          sx={{
            fontWeight: "600 !important",
          }}
          value={selected}
          onChange={handleChange}
        >
          {monthArr.map((month) => {
            return (
              <MenuItem
                sx={{ fontWeight: 600 }}
                value={month.key}
                key={month.key}
              >
                {month.month.concat(" - ", month.year)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default FilterLeaveFormByMonth;
