import {
  Avatar,
  Box,
  Card,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  MenuList,
  Paper,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import classes from "./TimeSheetService.module.scss";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import CustomizeCalendarPicker from "A_SHARED_COMPONENT/CustomizeCalendarPicker";
import {
  addDays,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  format,
  getDay,
  getMonth,
  getWeek,
  getYear,
  startOfMonth,
  subDays,
} from "date-fns";
import {
  changeUserSelected,
  changeDaySelected,
  getListTaskSummaryHoursByMonthAndYear,
  getListTimesheetsByMonthAndYear,
} from "E_REDUX_STORE/timesheetSlice";
import UrlConstant from "F_UTILS/constants/UrlConstant";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DateTimeSheet from "./components/DateTimeSheet";
import LockClockIcon from "@mui/icons-material/LockClock";
import convertMsToHM, { calcTotalFingerPrint } from "F_UTILS/DateTimeUtils";
import UserMenuItem from "Z_SERVICES/b_CalendarService/components/UserMenuItem";
import AdminUserAPI from "B_API/AdminUserAPI";
import { ToastShowSuccess } from "F_UTILS/Notify";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";
import LockTimesheetPopup from "A_SHARED_COMPONENT/LockTimesheetPopup/LockTimesheetPopup";
import PublicHolidayAPI from "B_API/PublicHolidayAPI";
import SearchField from "A_SHARED_COMPONENT/SearchField";
const moment = require("moment");

TimeSheetService.propTypes = {};
const searchStyle = {
  border: "none",
  borderColor: "gray",
  borderRadius: "4px",
  backgroundColor: "white",
  backgroundColorHover: "white",
  width: "100%",
  height: "40px",
  showFilter: true,
};

const filterObject = [
  { name: "All", value: true },
  { name: "BIM Team", value: true, teamId: 3 },
  { name: "CIM1 Team", value: true, teamId: 2 },
  { name: "CIM2 Team", value: true, teamId: 5 },
  { name: "R&D Team", value: true, teamId: 1 },
  { name: "HR Team", value: true, teamId: 4 },
  { name: "SBD Team", value: true, teamId: 6 },
];
function TimeSheetService(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const timesheetsState = useSelector((state) => state.timesheet.timesheets);
  const combineUsers = useSelector((state) => state.common.combineUsers);
  const taskSummaryHoursState = useSelector(
    (state) => state.timesheet.taskSummaryHours
  );

  const currentUserState = useSelector((state) => state.user.current);

  const userSelected = useSelector((state) => state.timesheet.userSelected);

  const daySelected = useSelector((state) => state.timesheet.daySelected);

  // console.log("daySelected ", daySelected);

  const currentRole = currentUserState?.role;

  const [user, setUser] = useState(userSelected || currentUserState);

  const [userList, setUserList] = useState([]);
  const [openLockPopup, setOpenLockPopup] = useState(false);

  const [userListSelected, setUserListSelected] = useState(userList);
  useEffect(() => {
    const fetchGetAllUsers = async () => {
      try {
        const response = await AdminUserAPI.getListViewUserCombineTeam();
        setUserList(response.data);
        setUserListSelected(response.data);
      } catch (error) {
        console.log("Error get User List ", error);
      }
    };
    fetchGetAllUsers();
  }, []);

  const [publicHolidays, setPulicHolidays] = useState([]);
  useEffect(() => {
    const fetchGetPublicHolidays = async () => {
      try {
        var currentYear = new Date().getFullYear();
        const response = await PublicHolidayAPI.GetPublicHolidayRequest();
        setPulicHolidays(
          response.data.filter((item) => item.year === currentYear)
        );
      } catch (error) {
        console.log("Error get list Holiday", error);
      }
    };
    fetchGetPublicHolidays();
  }, []);

  const selectListBtnRef = useRef();
  const menuUserRef = useRef();

  const [selectListValues, setSelectListValues] = useState({
    left: 0,
    top: 0,
    width: 300,
    isOpen: false,
  });

  //close menu user selection when clicked away
  function clickAwaySelectUser() {
    if (selectListValues.isOpen) {
      setSelectListValues({ ...selectListValues, isOpen: false });
    }
  }

  function toggleUserSelect() {
    const { offsetTop, offsetLeft, offsetHeight, offsetWidth } =
      selectListBtnRef.current;
    const menuUserWidth = menuUserRef.current
      ? menuUserRef.current.offsetWidth
      : 300;
    setSelectListValues({
      ...selectListValues,
      left: offsetLeft,
      top: offsetTop + offsetHeight,
      width:
        menuUserWidth -
        (menuUserWidth > 290
          ? offsetWidth + 32
          : menuUserWidth > 190
          ? offsetWidth / 2 + 10
          : 50),
      isOpen: !selectListValues.isOpen,
    });
  }

  //define date
  const [dayObj, setDay] = useState(
    daySelected ? new Date(daySelected) : new Date()
  );
  // const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const weekDayShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const today = new Date();
  const startDayOfMonth = startOfMonth(dayObj);
  const endDayOfMonth = endOfMonth(dayObj);
  const daysOfMonth = eachDayOfInterval({
    start: startDayOfMonth,
    end: endDayOfMonth,
  });
  const eachSundayOfWeek = eachWeekOfInterval({
    start: startDayOfMonth,
    end: endDayOfMonth,
  });
  const weeks = eachSundayOfWeek.map((w) => {
    return getWeek(w, {
      weekStartsOn: 0,
      firstWeekContainsDate: 6,
    });
  });

  const dateNameWrapRef = useRef();
  const taskDetailPageToggler = (detail) => {
    let userId = user?.userId || currentUserState?.userId;

    //navigate
    navigate(
      `/timesheet/detail/${userId}/${detail.date.getDate()}-${
        detail.date.getMonth() + 1
      }-${detail.date.getFullYear()}`
    );
  };

  //function
  const convertTime = (time) => {
    var mins = time * 60;
    var hours = mins / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    rhours = rhours.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });

    rminutes = rminutes.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return rhours + ":" + rminutes;
  };

  const showEditSuccess = (description) => {
    ToastShowSuccess(description);
    let userFetchData = userSelected?.userId || currentUserState?.userId;
    getListTimesheets(userFetchData, dayObj);
    getListTasks(userFetchData, dayObj);
    setOpenLockPopup(false);
  };

  const handleCalendarNext = async (newDay) => {
    //next month
    setDay(newDay);
    updateDaySelectedState(newDay);
    getListTimesheets(user.userId, newDay);
    getListTasks(user.userId, newDay);
  };

  function handleCalendarPrev(newDay) {
    //previous month
    setDay(newDay);
    updateDaySelectedState(newDay);
    getListTimesheets(user.userId, newDay);
    getListTasks(user.userId, newDay);
  }

  const handleCalendarChange = (newDay) => {
    setDay(newDay);
    updateDaySelectedState(newDay);
    getListTimesheets(user.userId, newDay);
    getListTasks(user.userId, newDay);
  };

  const userSelectHandle = async (user) => {
    setUser({
      userId: user.userId,
      avatar: user.avatar,
      fullName: user.fullName,
      isAdmin: user.isAdmin,
    });

    getListTimesheets(user.userId, dayObj);
    getListTasks(user.userId, dayObj);

    const action = changeUserSelected(user);
    await dispatch(action);
    // navigate(`/timesheet/${user.userId}`);

    setSelectListValues({
      ...selectListValues,
      isOpen: !selectListValues.isOpen,
    });
  };
  const getListTimesheets = async function (userIdParams, day) {
    let params = {
      userId: userIdParams,
      month: getMonth(day) + 1,
      year: getYear(day),
    };
    const action = getListTimesheetsByMonthAndYear(params);
    await dispatch(action);
  };

  const getListTasks = async function (userIdParams, day) {
    let params = {
      userId: userIdParams,
      month: getMonth(day) + 1,
      year: getYear(day),
    };
    const action = getListTaskSummaryHoursByMonthAndYear(params);
    await dispatch(action);

    // const action2 = changeDaySelected(day);
    // await dispatch(action2);
  };

  const updateDaySelectedState = async function (day) {
    const action = changeDaySelected(day.toISOString());
    await dispatch(action);
  };

  const searchUserHandle = (name) => {
    function removeVietnameseTones(str) {
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
      str = str.replace(/Đ/g, "D");
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "");
      str = str.replace(/\u02C6|\u0306|\u031B/g, "");
      str = str.replace(/ + /g, " ");
      str = str.trim();
      str = str.replace(
        /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
        " "
      );
      return str;
    }

    let renderUser = [...userList];
    if (name !== "") {
      renderUser = renderUser.filter((user) =>
        removeVietnameseTones(user.fullName.toLowerCase()).includes(
          removeVietnameseTones(name.toLowerCase())
        )
      );
    } else {
      setUserListSelected(renderUser);
    }
    setUserListSelected(renderUser);
  };

  useEffect(() => {
    let userFetchData = userSelected?.userId || currentUserState?.userId;
    getListTimesheets(userFetchData, dayObj);
    getListTasks(userFetchData, dayObj);
  }, []);

  const handleLockOpen = () => {
    setOpenLockPopup(true);
  };

  let checkIsHoliday = (dayCheck, holiday) => {
    const compareDate = moment(new Date(dayCheck)).format("YYYY-MM-DD");
    const startDate = moment(new Date(holiday?.fromDate)).format("YYYY-MM-DD");
    const endDate = moment(new Date(holiday?.toDate)).format("YYYY-MM-DD");

    return {
      holidayInfo: holiday,
      isHoliday: moment(compareDate).isBetween(startDate, endDate, null, "[]"),
    };
  };

  let lockPopup = null;
  if (openLockPopup === true) {
    lockPopup = (
      <LockTimesheetPopup
        isOpen={openLockPopup}
        setOpenLockPopup={setOpenLockPopup}
        handleLock={showEditSuccess}
      ></LockTimesheetPopup>
    );
  }
  useEffect(() => {
    setUserListSelected(userList);
  }, [selectListValues.isOpen]);

  return (
    <Grid
      className={classes["calendar-wrap"]}
      container
      sx={{
        backgroundColor: "#f8f8fb",
      }}
    >
      <Grid item lg={12} xs={12} sx={{ width: "" }}>
        <Card>
          <CardContent className={classes["calendar"]}>
            <div className={`${classes["calendar__header"]}`}>
              {currentRole === "Administrator" ? (
                <>
                  <div className={classes["user__wrap"]}>
                    <Stack
                      className={classes["user__container"]}
                      direction="row"
                      spacing={1}
                      ref={menuUserRef}
                    >
                      <Avatar
                        className={classes["user__avatar"]}
                        alt={user.fullName}
                        src={`${UrlConstant.REACT_APP_DATABASE_URL}${user?.avatar}`}
                      />
                      <div className={classes["user__text"]}>
                        {user.fullName}
                      </div>
                      <ClickAwayListener onClickAway={clickAwaySelectUser}>
                        <div>
                          <IconButton
                            aria-label="calendar"
                            className={`${classes["user__button"]} ${classes["button"]}`}
                            ref={selectListBtnRef}
                            onClick={toggleUserSelect}
                          >
                            <KeyboardArrowDownIcon
                              className={classes["button__icon"]}
                            />
                          </IconButton>
                          {selectListValues.isOpen ? (
                            <Box
                              className={classes["menu_user_wrap"]}
                              sx={{
                                "& > :not(style)": {
                                  position: "absolute",
                                  left:
                                    selectListValues.left -
                                    selectListValues.width +
                                    5,
                                  top: selectListValues.top + 5,
                                },
                              }}
                            >
                              <Paper
                                elevation={3}
                                className={classes["menu_user"]}
                              >
                                {/* <UserSearch
                                  onSearchInputChange={searchUserHandle}
                                /> */}

                                <Paper className={classes["menu_user__search"]}>
                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    className={classes["search__wrap"]}
                                  >
                                    <SearchIcon
                                      className={classes["search__icon"]}
                                    />
                                    <TextField
                                      className={classes["search__input"]}
                                      placeholder="Search..."
                                      variant="standard"
                                      onChange={
                                        (e) => searchUserHandle(e.target.value)
                                        //here
                                      }
                                    />
                                  </Box>
                                </Paper>

                                <MenuList
                                  className={`${classes["menu_user__container"]} ${classes["scrollable"]}`}
                                >
                                  {userListSelected?.map((user) => (
                                    <UserMenuItem
                                      key={user.userId}
                                      user={user}
                                      onSelectUser={userSelectHandle}
                                    />
                                  ))}
                                </MenuList>
                              </Paper>
                            </Box>
                          ) : null}
                        </div>
                      </ClickAwayListener>
                    </Stack>
                  </div>
                  <CustomizeCalendarPicker
                    dayObj={dayObj}
                    view={["month"]}
                    onCalendarPrevClick={handleCalendarPrev}
                    onCalendarNextClick={handleCalendarNext}
                    onCalendarChange={handleCalendarChange}
                  />
                  <div className={classes["none__wrap"]}>
                    {currentUserState?.teamId === 4 ? (
                      <Tooltip title="Lock timesheet mode" placement="bottom">
                        <LockClockIcon
                          style={{
                            fontSize: "35px",
                            color: "#e4ba02",
                            cursor: "pointer",
                          }}
                          onClick={handleLockOpen}
                        ></LockClockIcon>
                      </Tooltip>
                    ) : null}
                  </div>
                </>
              ) : (
                <CustomizeCalendarPicker
                  dayObj={dayObj}
                  view={["month"]}
                  onCalendarPrevClick={handleCalendarPrev}
                  onCalendarNextClick={handleCalendarNext}
                  onCalendarChange={handleCalendarChange}
                />
              )}
            </div>
            <div className={classes["calendar__body"]}>
              <div className={classes["week__wrap"]}>
                {weeks.map((w) => (
                  <div className={classes["week"]} key={w}>
                    <div className={classes["week__text"]}>{w}</div>
                  </div>
                ))}
              </div>
              <div className={classes["date__wrap"]}>
                <div
                  className={classes["date-name__wrap"]}
                  ref={dateNameWrapRef}
                >
                  {weekDayShort.map((w) => (
                    <div className={classes["date-name"]} key={w}>
                      <div className={classes["date-name__text"]}>{w}</div>
                    </div>
                  ))}
                </div>
                {_.range(getDay(startDayOfMonth)).map((i) => (
                  <DateTimeSheet
                    key={i}
                    date={subDays(startDayOfMonth, getDay(startDayOfMonth) - i)}
                    status="inactive"
                  />
                ))}
                {_.range(daysOfMonth.length).map((i) => {
                  let date = addDays(startDayOfMonth, i);
                  let status =
                    format(addDays(startDayOfMonth, i), "dd/MM/yyyy") ===
                    format(today, "dd/MM/yyyy")
                      ? "active"
                      : "";
                  let dayData = timesheetsState?.find(
                    (x) =>
                      format(new Date(x.date), "yyyy/MM/dd") ===
                      format(addDays(startDayOfMonth, i), "yyyy/MM/dd")
                  );
                  let listTask = dayData
                    ? taskSummaryHoursState.timesheetTasks?.filter(
                        (x) => x.timesheetId === dayData.timesheetId
                      )
                    : [];
                  listTask = listTask?.map((x) => {
                    x = {
                      ...x,
                      hours: convertTime(x.hours),
                    };
                    return x;
                  });

                  let listLeaveForm = dayData
                    ? taskSummaryHoursState.leaveforms?.filter(
                        (x) =>
                          new Date(x.fromDate).toDateString() ===
                          new Date(dayData?.date).toDateString()
                      )
                    : [];
                  listLeaveForm = listLeaveForm?.map((x) => {
                    x = {
                      ...x,
                      hours: convertTime(x.hours),
                    };
                    return x;
                  });

                  let listOvertimes = dayData
                    ? taskSummaryHoursState.overtimes?.filter(
                        (x) => x.timesheetId === dayData.timesheetId
                        // && x.actualHours > 0
                      )
                    : [];

                  const sumActualOt = listOvertimes?.reduce(
                    (accumulator, object) => {
                      return accumulator + object.actualHours;
                    },
                    0
                  );

                  listOvertimes = listOvertimes?.map((x) => {
                    x = {
                      ...x,
                      hours: convertTime(x.actualHours),
                    };
                    return x;
                  });
                  let holidayInfo = publicHolidays
                    .map((holiday) => checkIsHoliday(dayData?.date, holiday))
                    .find((item) => item.isHoliday === true);

                  return (
                    <DateTimeSheet
                      key={i}
                      timesheetId={dayData?.timesheetId}
                      date={date}
                      status={status}
                      timeIn={
                        dayData?.timeIn
                          ? format(new Date(dayData.timeIn), "HH:mm")
                          : null
                      }
                      timeOut={
                        dayData?.timeOut
                          ? format(new Date(dayData.timeOut), "HH:mm")
                          : null
                      }
                      totalFingerPrint={
                        dayData?.timeIn == null || dayData?.timeOut == null
                          ? null
                          : convertMsToHM(
                              calcTotalFingerPrint(
                                dayData?.timeIn,
                                dayData?.timeOut
                              )
                            )
                      }
                      totalTime={
                        dayData?.total > 0 ? convertTime(dayData.total) : null
                      }
                      enough8h={dayData?.total >= 8}
                      failedTime={
                        dayData?.timeIn == null ||
                        dayData?.timeOut == null ||
                        dayData.total == null
                          ? null
                          : new Date(dayData?.timeOut) -
                              new Date(dayData?.timeIn) <=
                            dayData.total * 3600000
                      }
                      // ot={dayData?.ot > 0 ? convertTime(dayData.ot) : null}
                      ot={sumActualOt > 0 ? convertTime(sumActualOt) : null}
                      holidayInfo={holidayInfo}
                      note={dayData ? dayData.note : null}
                      listTask={listTask || []}
                      listOvertimes={listOvertimes || []}
                      listLeaveForm={listLeaveForm || []}
                      user={currentUserState?.userId}
                      currentRole={currentRole}
                      lock={dayData?.lock || 0}
                      showEditSuccess={showEditSuccess}
                      onOpenTaskDetail={taskDetailPageToggler}
                    />
                  );
                })}
                {_.range(6 - getDay(endDayOfMonth)).map((i) => (
                  <DateTimeSheet
                    key={i}
                    date={addDays(endDayOfMonth, i + 1)}
                    status="inactive"
                  />
                ))}
              </div>
            </div>
          </CardContent>
        </Card>
        <CustomToastComponent />
      </Grid>
      {lockPopup}
    </Grid>
  );
}
export default TimeSheetService;
