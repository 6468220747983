import React from "react";

function ListNoticeAbsence({ item, type }) {
  return (
    <div className="dateitem-popover-row">
      {"- "}
      {type === "notice" ? item?.content : item.fullName}
    </div>
  );
}

export default ListNoticeAbsence;
