import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SelectMonth from "./CustomTime/SelectMonth";
import TagContent from "A_SHARED_COMPONENT/TagContent";
import moment from "moment";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ExportAPI from "B_API/ExportAPI";
import { appendSpreadsheet } from "./ExportGoogleAPI/ExportSummaryTimeOfTeam";
import { updateSheet } from "./ExportGoogleAPI/ExportSummaryTimeOfTimeSheet";
import SelectDateFrom from "./CustomTime/SelectDateFrom";
import SelectDateTo from "./CustomTime/SelectDateTo";
import LoadingAnimation from "./Animation";
import fileDownload from "js-file-download";
import SelectPerson from "./SelectPerson";

ReportTab.propTypes = {};

function ReportTab(props) {
  const { control, handleSubmit } = useForm();
  const customTimeList = ["20th to 19th", "Custom date"];
  const arrayALL = [{ fullName: "All", userId: 0 }];
  const [loading, setLoading] = useState(false);
  const [keyGoogleSheet, setKeyGoogleSheets] = useState();
  const [timeSumOfTeam, setTimeSumOfTeam] = React.useState(customTimeList[0]);
  const [timeSumOfTeamAndProject, setTimeSumOfTeamAndProject] = React.useState(
    customTimeList[1]
  );
  const [month, setMonth] = useState(moment());
  const [userIdSelected, setUserIdSelected] = useState(0);

  const [monthOfSummaryPerson, setMonthOfSummaryPerson] = useState(moment());

  const [dateFromTeam, setDateFromTeam] = useState(
    moment().utc().startOf("month").set({ hour: 0, minute: 0 })
  );
  const [dateToTeam, setDateToTeam] = useState(
    moment().utc().endOf("month").set({ hour: 0, minute: 0 })
  );

  const [dateFromTeamAndProject, setDateFromTeamAndProject] = useState(
    moment().utc().startOf("month").set({ hour: 0, minute: 0 })
  );
  const [dateToTeamAndProject, setDateToTeamAndProject] = useState(
    moment().utc().endOf("month").set({ hour: 0, minute: 0 })
  );

  const [showError, setShowError] = useState(false);
  const [errorDescription, setErrorDescription] = useState();

  const handleErrorPopupClose = () => {
    setShowError(false);
  };

  let description =
    "An error occurred while exporting the report, contact the developer for more details.\r\n Error: ";

  useEffect(() => {
    const getKeyGoogleSheet = async () => {
      let response = await ExportAPI.getKeyGoogleSheet();
      setKeyGoogleSheets(response.data);
    };
    getKeyGoogleSheet();
  }, []);

  const exportDataTimesheet = async (valueSubmit) => {
    try {
      setLoading(true);
      // const keyTimeSheet = keyGoogleSheet.find(
      //   (keyInfo) => keyInfo.keyName === "SummaryOfTimesheet"
      // );
      // //Old
      // const response = await ExportAPI.getSummaryOfTimesheet(valueSubmit);
      // await updateSheet(response.data, valueSubmit, keyTimeSheet);

      //New
      const response = await ExportAPI.downloadSummaryOfTimesheet(valueSubmit);

      const outputFilename = `${valueSubmit.dateFrom.toString()}_${valueSubmit.dateTo.toString()}_SummaryOfTimeSheet.xlsx`;

      fileDownload(response.data, outputFilename);

      setLoading(false);
    } catch (error) {
      setShowError(true);
      setLoading(false);
      setErrorDescription(description.concat(error.message));
      console.log("Error export timesheet ", error.message);
    }
  };

  const exportDataTeam = async (valueSubmit, sheetName) => {
    try {
      setLoading(true);
      const response = await ExportAPI.getSummaryOfTeam(valueSubmit);

      const outputFilename = `${valueSubmit.dateFrom.toString()}_${valueSubmit.dateTo.toString()}_SummaryOfTimeSheetByTeam.xlsx`;

      fileDownload(response.data, outputFilename);
      setLoading(false);
    } catch (error) {
      setShowError(true);
      setLoading(false);
      setErrorDescription(description.concat(error.message));
      console.log("Error export timesheet ", error.message);
    }
  };

  // const onSubmit = async (data) => {
  //   var valueSubmit;
  //   var fromDateSelected, toDateSelected;

  //   if (data.customTime === "Last month(from 20th)") {
  //     fromDateSelected = moment()
  //       .utc()
  //       .subtract(1, "months")
  //       .date(20)
  //       .format("YYYY-MM-DD");
  //     toDateSelected = moment().utc().date(19).format("YYYY-MM-DD");

  //     valueSubmit = {
  //       dateFrom: fromDateSelected,
  //       dateTo: toDateSelected,
  //     };
  //   } else if (data.customTime === "Month") {
  //     fromDateSelected = moment(month).startOf("month").format("YYYY-MM-DD");
  //     toDateSelected = moment(month).endOf("month").format("YYYY-MM-DD");
  //     valueSubmit = { dateFrom: fromDateSelected, dateTo: toDateSelected };
  //   } else {
  //     fromDateSelected = moment(dateFrom).format("YYYY-MM-DD");
  //     toDateSelected = moment(dateTo).format("YYYY-MM-DD");
  //     valueSubmit = {
  //       dateFrom: fromDateSelected,
  //       dateTo: toDateSelected,
  //     };
  //   }
  //   var sheetName = fromDateSelected
  //     .replace("-", ".")
  //     .replace("-", ".")
  //     .concat("-", toDateSelected.replace("-", ".").replace("-", "."));

  //   if (data.typeOfTable === "Summary of timesheet") {
  //     exportDataTimesheet(valueSubmit);
  //   } else if (data.typeOfTable === "Summary of team") {
  //     exportDataTeam(valueSubmit, sheetName);
  //   } else if (data.typeOfTable === "Report Working Hours Summary") {
  //     setLoading(true);
  //     const response = await ExportAPI.getReportWorkingHoursSummary(
  //       valueSubmit
  //     );

  //     if (response.status === 200) {
  //       const url = response.data.link;
  //       window.open(url);
  //     }
  //     setLoading(false);
  //   }
  // };

  const exportSummaryOfTimesheet = async (data) => {
    var valueSubmit;
    var fromDateSelected, toDateSelected;

    fromDateSelected = moment(month).startOf("month").format("YYYY-MM-DD");
    toDateSelected = moment(month).endOf("month").format("YYYY-MM-DD");
    valueSubmit = { dateFrom: fromDateSelected, dateTo: toDateSelected };
    exportDataTimesheet(valueSubmit);
  };

  const exportSummaryAsPersonByMonth = async (data) => {
    try {
      setLoading(true);
      let monthSelected = moment(monthOfSummaryPerson).month() + 1;
      if (userIdSelected === 0) {
        props?.listUsers.map(async (user) => {
          const response = await ExportAPI.summaryAsPersonByMonth(
            user.userId,
            monthSelected
          );

          const userName = user.fullName;

          const outputFilename =
            `${userName}_${monthSelected}.xlsx` || "export.xlsx";

          fileDownload(response.data, outputFilename);
        });
      } else {
        const response = await ExportAPI.summaryAsPersonByMonth(
          userIdSelected,
          monthSelected
        );

        const userName = props?.listUsers.find(
          (person) => person.userId === userIdSelected
        ).fullName;

        const outputFilename =
          `${userName}_${monthSelected}.xlsx` || "export.xlsx";

        fileDownload(response.data, outputFilename);
      }

      setLoading(false);
    } catch (error) {
      setShowError(true);
      setLoading(false);
      setErrorDescription(description.concat(error.message));
      console.log("Error export summaryAsPersonByMonth ", error.message);
    }
  };

  const exportSummaryOfTeam = async () => {
    var valueSubmit;
    var fromDateSelected, toDateSelected;

    if (timeSumOfTeam === "20th to 19th") {
      fromDateSelected = moment()
        .utc()
        .subtract(1, "months")
        .date(20)
        .format("YYYY-MM-DD");
      toDateSelected = moment().utc().date(19).format("YYYY-MM-DD");

      valueSubmit = {
        dateFrom: fromDateSelected,
        dateTo: toDateSelected,
      };
    } else {
      fromDateSelected = moment(dateFromTeam).format("YYYY-MM-DD");
      toDateSelected = moment(dateToTeam).format("YYYY-MM-DD");
      valueSubmit = {
        dateFrom: fromDateSelected,
        dateTo: toDateSelected,
      };
    }

    valueSubmit = { dateFrom: fromDateSelected, dateTo: toDateSelected };
    var sheetName = fromDateSelected
      .replace("-", ".")
      .replace("-", ".")
      .concat("-", toDateSelected.replace("-", ".").replace("-", "."));
    exportDataTeam(valueSubmit, sheetName);
  };

  const exportSummaryOfTeamAndProject = async (data) => {
    setLoading(true);
    var valueSubmit;
    var fromDateSelected, toDateSelected;

    if (timeSumOfTeamAndProject === "20th to 19th") {
      fromDateSelected = moment()
        .utc()
        .subtract(1, "months")
        .date(20)
        .format("YYYY-MM-DD");
      toDateSelected = moment().utc().date(19).format("YYYY-MM-DD");

      valueSubmit = {
        dateFrom: fromDateSelected,
        dateTo: toDateSelected,
      };
    } else {
      fromDateSelected = moment(dateFromTeamAndProject).format("YYYY-MM-DD");
      toDateSelected = moment(dateToTeamAndProject).format("YYYY-MM-DD");
      valueSubmit = {
        dateFrom: fromDateSelected,
        dateTo: toDateSelected,
      };
    }

    valueSubmit = { dateFrom: fromDateSelected, dateTo: toDateSelected };

    const response = await ExportAPI.getReportWorkingHoursSummary(valueSubmit);
    setLoading(false);

    if (response.status === 200) {
      const outputFilename = `${fromDateSelected.toString()}_${toDateSelected.toString()}_SummaryOfTimeSheetByTeamAndProject.xlsx`;

      fileDownload(response.data, outputFilename);
    }
  };

  return (
    <>
      <div className="admin__timesheet--reporttab">
        <div className="reporttab__filter">
          <div className="reporttab__filter--condition">
            <TagContent
              width="60% "
              height="430px"
              title="EXPORT REPORT"
              dataFlow="TopToDown"
            >
              {loading ? (
                <div style={{ paddingTop: "8%" }}>
                  <LoadingAnimation textLoading="Exporting data, please wait..." />
                </div>
              ) : (
                <Grid
                  container
                  columnSpacing={3}
                  rowSpacing={1.5}
                  style={{ marginLeft: 1 }}
                >
                  <Grid item xs={12}>
                    <div className="condition__dropdown--title__1">
                      Summary of timesheet
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <SelectMonth name="month" setMonth={setMonth} />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      className="reporttab__filter--button-save"
                      variant="contained"
                      size="small"
                      color="success"
                      startIcon={<ExitToAppIcon />}
                      sx={{
                        height: "40px",
                        lineHeight: "1",
                        width: "150px",
                      }}
                      onClick={exportSummaryOfTimesheet}
                    >
                      Download
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <div className="condition__dropdown--title__1">
                      Summary of team
                    </div>
                  </Grid>

                  <Grid item xs={3}>
                    <Controller
                      name="typeOfTable"
                      control={control}
                      defaultValue={timeSumOfTeam}
                      render={({ onChange }) => (
                        <FormControl
                          fullWidth
                          className="condition__dropdown--box__1"
                        >
                          <Select
                            value={timeSumOfTeam}
                            onChange={(e) => {
                              onChange(e);
                              setTimeSumOfTeam(e.target.value);
                            }}
                          >
                            {customTimeList.map((item, index) => (
                              <MenuItem key={index} value={item}>
                                <p>{item}</p>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>

                  {timeSumOfTeam === "Custom date" ? (
                    <>
                      <Grid item container xs={3}>
                        <Grid
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="select-time__date--title__2">
                            <p>From</p>
                          </div>
                        </Grid>
                        <Grid item xs={10}>
                          <div className="select-time__date-from">
                            <SelectDateFrom setDateFrom={setDateFromTeam} />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid item container xs={3}>
                        <Grid
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="select-time__date--title__2">
                            <p>To</p>
                          </div>
                        </Grid>
                        <Grid item xs={10}>
                          <div className="select-time__date-to">
                            <SelectDateTo setDateTo={setDateToTeam} />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid item xs={3}>
                        <Button
                          className="reporttab__filter--button-save"
                          variant="contained"
                          size="small"
                          color="success"
                          startIcon={<ExitToAppIcon />}
                          sx={{
                            height: "40px",
                            lineHeight: "1",
                            width: "150px",
                          }}
                          onClick={exportSummaryOfTeam}
                        >
                          Download
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={3}>
                      <Button
                        className="reporttab__filter--button-save"
                        variant="contained"
                        size="small"
                        color="success"
                        startIcon={<ExitToAppIcon />}
                        sx={{
                          height: "40px",
                          lineHeight: "1",
                          width: "150px",
                        }}
                        onClick={exportSummaryOfTeam}
                      >
                        Download
                      </Button>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <div className="condition__dropdown--title__1">
                      Summary of team and project
                    </div>
                  </Grid>

                  <Grid item xs={3}>
                    <Controller
                      name="typeOfTable"
                      control={control}
                      defaultValue={timeSumOfTeamAndProject}
                      render={({ onChange }) => (
                        <FormControl
                          fullWidth
                          className="condition__dropdown--box__1"
                        >
                          <Select
                            value={timeSumOfTeamAndProject}
                            onChange={(e) => {
                              onChange(e);
                              setTimeSumOfTeamAndProject(e.target.value);
                            }}
                          >
                            {customTimeList.map((item, index) => (
                              <MenuItem key={index} value={item}>
                                <p>{item}</p>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>

                  {timeSumOfTeamAndProject === "Custom date" ? (
                    <>
                      <Grid item container xs={3}>
                        <Grid
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="select-time__date--title__2">
                            <p>From</p>
                          </div>
                        </Grid>
                        <Grid item xs={10}>
                          <div className="select-time__date-from">
                            <SelectDateFrom
                              setDateFrom={setDateFromTeamAndProject}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid item container xs={3}>
                        <Grid
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            alignItems: "center",
                          }}
                        >
                          <div className="select-time__date--title__2">
                            <p>To</p>
                          </div>
                        </Grid>
                        <Grid item xs={10}>
                          <div className="select-time__date-to">
                            <SelectDateTo setDateTo={setDateToTeamAndProject} />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid item xs={3}>
                        <Button
                          className="reporttab__filter--button-save"
                          variant="contained"
                          size="small"
                          color="success"
                          startIcon={<ExitToAppIcon />}
                          sx={{
                            height: "40px",
                            lineHeight: "1",
                            width: "150px",
                          }}
                          onClick={exportSummaryOfTeamAndProject}
                        >
                          Download
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={3}>
                      <Button
                        className="reporttab__filter--button-save"
                        variant="contained"
                        size="small"
                        color="success"
                        startIcon={<ExitToAppIcon />}
                        sx={{
                          height: "40px",
                          lineHeight: "1",
                          width: "150px",
                        }}
                        onClick={exportSummaryOfTeamAndProject}
                      >
                        Download
                      </Button>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <div className="condition__dropdown--title__1">
                      Summary As Person
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <SelectPerson
                      name="person"
                      listUsers={arrayALL.concat(props?.listUsers)}
                      setUserId={setUserIdSelected}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SelectMonth
                      name="month"
                      setMonth={setMonthOfSummaryPerson}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      className="reporttab__filter--button-save"
                      variant="contained"
                      size="small"
                      color="success"
                      startIcon={<ExitToAppIcon />}
                      sx={{
                        height: "40px",
                        lineHeight: "1",
                        width: "150px",
                      }}
                      onClick={exportSummaryAsPersonByMonth}
                    >
                      Download
                    </Button>
                  </Grid>
                </Grid>
              )}
            </TagContent>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportTab;
