import firebase from "firebase/app";
import "firebase/messaging";
import UrlConstant from "F_UTILS/constants/UrlConstant";

const firebaseConfig = {
  apiKey: "AIzaSyBUflww74hibptamTzB1r5NLuUyTi1A_Mo",
  authDomain: "ttd-notification-firebase.firebaseapp.com",
  projectId: "ttd-notification-firebase",
  storageBucket: "ttd-notification-firebase.appspot.com",
  messagingSenderId: "1020451035938",
  appId: "1:1020451035938:web:9923794fead63a57d6637b",
  measurementId: "G-F8BP4V3CRD",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;
const publicKey = UrlConstant.REACT_APP_VAPID_KEY;

export const getToken = (setTokenFound) => {
  return messaging
    ?.getToken({ vapidKey: publicKey })
    .then((currentToken) => {
      if (currentToken) {
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
