import StorageKeys from "F_UTILS/constants/StorageKeys";

class NotificationServices {
  getPending() {
    return JSON.parse(localStorage.getItem(StorageKeys.objectPending));
  }
  setPending(objectPending) {
    localStorage.setItem(
      StorageKeys.objectPending,
      JSON.stringify(objectPending)
    );
  }
}
export default new NotificationServices();
