import React, { useState, useEffect, createContext } from "react";
import AdminOverTimeAPI from "B_API/AdminOverTimeAPI";
import OTRequestTable from "./RequestTableList/RequestTable";
import SortListOverTime from "./SortListOverTime";
import SelectionTeamField from "Z_SERVICES/g_AdminTimeSheetServices/components/SelectionTeamField";
import AdminTeamAPI from "B_API/AdminTeamAPI";
import FilterLeaveFormByMonth from "Z_SERVICES/h_AdminLeaveFormServices/components/RequestTab/FilterLeaveFormByMonth";
import { Button, Tooltip } from "@mui/material";
import CalculateIcon from "@mui/icons-material/Calculate";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";
import { ToastShowSuccess } from "F_UTILS/Notify";
import ReCalculateOTPopup from "A_SHARED_COMPONENT/ReCalculateOTPopup/ReCalculateOTPopup";
import LoadingAnimation from "Z_SERVICES/g_AdminTimeSheetServices/components/ReportTab/Animation";

export const FetchData = createContext();

function OTRequestTab(props) {
  const initTeamId = JSON.parse(localStorage.getItem("user"))?.teamId;
  let setInitTeam = initTeamId === 4 ? 0 : initTeamId;
  const [currentTeamId, setTeamId] = React.useState(setInitTeam ?? 0);
  const [status, setStatus] = React.useState(0);
  const [listOT, setListOT] = useState([]);
  const [openCalculateOTPopup, setOpenCalculateOTPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dateSelected, setDateSelected] = React.useState(new Date());

  const fetchAllOverTimeRequest = async () => {
    try {
      const response =
        await AdminOverTimeAPI.getListOverTimeAdminByTeamIdAndStatus(
          currentTeamId,
          status
        );
      setListOT(response.data);
    } catch (error) {
      console.log("Error get list Overtime ", error);
    }
  };

  useEffect(() => {
    fetchAllOverTimeRequest();
  }, [currentTeamId, status]);

  const handleOnTeamChange = (teamId) => {
    setTeamId(teamId);
  };

  const handleOnStatusChange = (status) => {
    setStatus(status);
  };

  const handleFilterByMonth = (date) => {
    setDateSelected(date);
  };

  const handleCalculateClick = () => {
    setOpenCalculateOTPopup(true);
  };

  const showEditSuccess = (description) => {
    fetchAllOverTimeRequest();
    ToastShowSuccess(description);
    setOpenCalculateOTPopup(false);
  };

  const [listTeams, setListTeams] = React.useState([]);
  React.useEffect(() => {
    const fetchListTeams = async () => {
      try {
        const response = await AdminTeamAPI.getTeamOrGroup(1);
        if (response.status === 200) setListTeams(response?.data);
      } catch (error) {
        console.log("Failed to fetch list teams");
      }
    };
    fetchListTeams();
  }, []);

  let renderList,
    renderListFilterByMonth = [];

  if (status === 0) {
    renderList = [...listOT].sort((a, b) => {
      if (a.status === "Pending") return -1;
      if (b.status === "Pending") return 1;
      return a?.status > b?.status ? 1 : -1;
    });
    renderListFilterByMonth = [...renderList].filter((ot) => {
      let monthOfLeave = new Date(ot.fromTime);
      if (
        monthOfLeave.getMonth() === dateSelected.getMonth() &&
        monthOfLeave.getFullYear() === dateSelected.getFullYear()
      ) {
        return ot;
      }
    });
  } else {
    renderList = [...listOT].sort((a, b) =>
      a?.fromTime > b?.fromTime ? -1 : 1
    );
    renderListFilterByMonth = [...renderList].filter((ot) => {
      let monthOfLeave = new Date(ot.fromTime);
      if (
        monthOfLeave.getMonth() === dateSelected.getMonth() &&
        monthOfLeave.getFullYear() === dateSelected.getFullYear()
      ) {
        return ot;
      }
    });
  }

  let recalculatePopup = null;
  if (openCalculateOTPopup === true) {
    recalculatePopup = (
      <ReCalculateOTPopup
        isOpen={openCalculateOTPopup}
        setOpenCalculateOTPopup={setOpenCalculateOTPopup}
        handleSuccess={showEditSuccess}
        setIsLoading={setIsLoading}
      ></ReCalculateOTPopup>
    );
  }

  return (
    <div className="admin__overtime--requesttab">
      <div className="requesttab__filter--condition">
        <div className="status-selection">
          <SelectionTeamField
            width={200}
            marginRight={2}
            leftAlign={1}
            showAll
            id="admin__selection-team-id"
            initTeamId={currentTeamId}
            listTeams={listTeams}
            onTeamChange={handleOnTeamChange}
          />
          <SortListOverTime
            width={200}
            id="admin__selection-team-id"
            valueInit={status}
            handleOnStatusChange={handleOnStatusChange}
          />
          <FilterLeaveFormByMonth
            width={200}
            valueInit={dateSelected.getMonth()}
            onFilterByMonth={handleFilterByMonth}
          />
        </div>
        <Tooltip title="CALCULATE ACTUAL OT HOURS" placement="bottom">
          <Button
            className="button--calculate"
            variant="contained"
            style={{ width: "150px" }}
            id="lockButton"
            onClick={handleCalculateClick}
            startIcon={<CalculateIcon />}
          >
            CALCULATE
          </Button>
        </Tooltip>
      </div>
      <div className="requesttab__table">
        {isLoading ? (
          <LoadingAnimation textLoading="Calculating actual overtime hours, please wait..."></LoadingAnimation>
        ) : (
          <FetchData.Provider value={{ fetchAllOverTimeRequest }}>
            <OTRequestTable listOT={renderListFilterByMonth} />
          </FetchData.Provider>
        )}
      </div>
      {recalculatePopup}
      <CustomToastComponent />
    </div>
  );
}

export default OTRequestTab;
