import axiosClient from "./axiosClient";

const PublicHolidayAPI = {
  async GetPublicHolidayRequest() {
    const url = `/PublicHolidayOfYear`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async DeleteHoliday(id) {
    const url = `/PublicHolidayOfYear/DeleteHoliday/${id}`;
    return axiosClient.request({
      method: "DELETE",
      url: url,
    });
  },

  async updateHolidayInfo(params) {
    const url = `/PublicHolidayOfYear/UpdateHoliday`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: params,
    });
  },

  async addHoliday(params) {
    const url = `/PublicHolidayOfYear/AddHoliday`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params,
    });
  },
};

export default PublicHolidayAPI;
