import { yupResolver } from "@hookform/resolvers/yup";
import CalculateIcon from "@mui/icons-material/Calculate";
import { Button, Dialog, Typography } from "@mui/material";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";
import DatePickerInput from "A_SHARED_COMPONENT/DatePickerInput";
import AdminTimeSheetAPI from "B_API/AdminTimesheetAPI";
import { ToastShowError } from "F_UTILS/Notify";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import moment from "moment";
import AdminOverTimeAPI from "B_API/AdminOverTimeAPI";

const schema = yup
  .object()
  .shape({
    fromDate: yup.string().required("Please enter from date."),
    toDate: yup.string().required("Please enter to date."),
  })
  .required();

function ReCalculateOTPopup(props) {
  const closePopupHandle = () => {
    props?.setOpenCalculateOTPopup(false);
  };

  const date = new Date();
  const firstDayCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  const form = useForm({
    defaultValues: {
      fromDate: firstDayCurrentMonth || new Date(),
      toDate: new Date(),
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (id) => {
    props?.setOpenCalculateOTPopup(false);
    props?.setIsLoading(true);
    let values = form.getValues();

    let fromDate = moment(values.fromDate).format("YYYY-MM-DD");
    let toDate = moment(values.toDate).format("YYYY-MM-DD");

    const response = await AdminOverTimeAPI.updateActualHour(fromDate, toDate);
    props?.setIsLoading(false);
    if (
      response.status === 200 &&
      response.data.message === "Update actual hour successfully."
    ) {
      props?.handleSuccess(
        `Overtime has been re-calculate from ${values.fromDate.toLocaleDateString(
          "en-GB"
        )} to ${values.toDate.toLocaleDateString("en-GB")}.`
      );
    } else {
      ToastShowError(
        "Errow while re-calculate overtime, please contact with developer!"
      );
    }
  };

  return (
    <div>
      <Dialog open={props?.isOpen} onClose={closePopupHandle}>
        <div className="shared__userinfomation">
          <div className="userinformation__title">
            <Typography
              variant="h6"
              sx={{ fontWeight: "600", color: "var(--bs-stratos)" }}
            >
              CALCULATE ACTUAL OT HOURS
            </Typography>
          </div>

          <div className="userinformation__form">
            <div className="userinformation__form-section-02">
              <div className="form__section02-col01">
                <DatePickerInput
                  name="fromDate"
                  label="From Date"
                  form={form}
                  dateInit={firstDayCurrentMonth || new Date()}
                />
              </div>
              <div className="form__section02-col02">
                <DatePickerInput
                  name="toDate"
                  label="To Date"
                  form={form}
                  dateInit={new Date()}
                />
              </div>
            </div>

            <div
              className="userinformation__form-section-06"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="button-save"
                variant="contained"
                style={{ width: "150px" }}
                id="lockButton"
                onClick={(e) => form.handleSubmit(onSubmit(e.target.id))}
                startIcon={<CalculateIcon />}
              >
                CALCULATE
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      <CustomToastComponent />
    </div>
  );
}

export default ReCalculateOTPopup;
