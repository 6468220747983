import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AdminUserAPI from "B_API/AdminUserAPI";
import * as React from "react";

function SelectionTeam({
  width,
  height,
  listSelection,
  defaultIndex = 0,
  className,
  setListAllUser,
  id,
  setTeamSelected,
  teamSelected,
  listTeams
}) {
  const handleChange = async (event) => {
    let status = event.target.value;
    setTeamSelected(status);

    const response = await AdminUserAPI.getListUserCredentialByTeamId(status);
    setListAllUser(response.data);
  };
  const renderTeam = listTeams?.sort((a,b)=>a.teamName.localeCompare(b.teamName)) || []
 
  return (
    <Box className={className} sx={{ width: width }}>
      <FormControl fullWidth>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          id={id}
          sx={{
            fontWeight: "600 !important",
            color: "var(--bs-stratos)",
          }}
          value={teamSelected}
          onChange={handleChange}
        >
          <MenuItem sx={{ fontWeight: 600 }} value="0">
            All
          </MenuItem>
          {renderTeam?.map((team) => (
            <MenuItem sx={{ fontWeight: 600 }} value={team.teamId}>
              {team.teamName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default SelectionTeam;
