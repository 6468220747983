import classes from "./MemberTagPopup.module.scss";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { useEffect, useState } from "react";

function MemberTagPopup(props) {
  const [styleCustomize, setStyleCustomize] = useState(
    props.sx || {
      bgcolor: "white",
      width: "100%",
    }
  );
  const [optionsList, setOptionList] = useState(props.optionsList || null);
  const [defaultValue, setDefaultValue] = useState(props.defaultValue || "");

  const userSelectHandle = (event, values) => {
    let userIdArr = [];
    values.forEach((value) => {
      userIdArr.push(
        optionsList.find((x) => x.staffId === value.split("-")[0]).userId
      );
    });

    props.onSelectValue(event, userIdArr);
  };

  const userId = JSON.parse(localStorage.getItem("user")).userId;

  return (
    <Autocomplete
      sx={styleCustomize}
      className={classes["note-form__group"]}
      disabled={props.isDisabled}
      multiple
      options={optionsList.map(
        (option) => `${option.staffId}-${option.fullName}`
      )}
      defaultValue={defaultValue
        .filter((x) => x.userId !== userId)
        .map((option) => `${option.staffId}-${option.fullName}`)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            sx={{
              borderRadius: 1,
              backgroundColor: "#556ee6",
              color: "white",
              "& > .MuiChip-deleteIcon": {
                color: "white",
                "&:hover": {
                  color: "#e1e1e1",
                },
              },
            }}
            variant="outlined"
            label={option ? option : ""}
            {...getTagProps({ index })}
          />
        ))
      }
      onChange={userSelectHandle}
      renderInput={(params) => (
        <TextField
          className={classes["test"]}
          row={3}
          multiple
          disabled={true}
          sx={{
            backgroundColor: "white",
          }}
          {...params}
        />
      )}
    />
  );
}

export default MemberTagPopup;
