import React, { useState } from "react";
import TimePicker from "../TimePicker";
import { Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  input__all: {
    margin: "5px 0",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  input_label: {
    marginBottom: "3px",
    fontWeight: 600,
    fontSize: "16px",
  },
}));

function TimePickerInput(props) {
  const classes = useStyle();

  const { form, name, label, timeInit, onChange } = props;
  const [time, setTime] = useState(timeInit);

  const handleChange = (value) => {
    setTime(value);

    form.setValue(name, value, { shouldValidate: true });
    if (onChange) onChange(value);
  };

  return (
    <div className={classes.input__all}>
      <div className={classes.input_label}>{label}</div>
      <Controller
        control={form.control}
        name={name}
        value={time}
        render={({ field }) => (
          <TimePicker value={time} onChange={handleChange} />
        )}
      />
    </div>
  );
}

export default TimePickerInput;
