import React, { useState, useEffect } from "react";
import "./LeaveFormService.scss";
import TagContent from "A_SHARED_COMPONENT/TagContent";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button } from "@mui/material";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router";
import ExportPdfComponent from "../components/ExportPdfComponent";
import LeaveFormAPI from "B_API/LeaveFormAPI";

const listStatus = [
  {
    id: 1,
    name: "Pending",
  },
  {
    id: 2,
    name: "Approved",
  },
  {
    id: 3,
    name: "Rejected",
  },
];
const columns = [
  {
    field: "no",
    headerName: "No",
    headerClassName: "MuiDataGrid-header",
    headerAlign: "center",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 1,
  },
  {
    field: "leaveType",
    headerName: "Leave Type",
    headerAlign: "center",
    headerClassName: "MuiDataGrid-header",
    type: "text",
    minWidth: 150,
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 2,
    renderCell: (params) => {
      var cellRender = "";
      if (params.value === "Wedding Leave" || params.value === "Maternity") {
        cellRender = (
          <div className="status status--approved">{params.value}</div>
        );
      } else if (params.value === "Summer Leave") {
        cellRender = (
          <div className="status status--pending">{params.value}</div>
        );
      } else if (params.value === "Unpaid Leave") {
        cellRender = (
          <div className="status status--rejected">{params.value}</div>
        );
      } else {
        cellRender = params.value;
      }
      return cellRender;
    },
  },
  {
    field: "fromDate",
    headerName: "Start Date",
    headerAlign: "center",
    headerClassName: "MuiDataGrid-header",
    type: "date",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 2,
    minWidth: 50,
    renderCell: (params) => {
      return params.value.replace("T", " ").substring(0, 11);
    },
  },
  {
    field: "toDate",
    headerName: "Finish Date",
    headerClassName: "MuiDataGrid-header",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    headerAlign: "center",
    type: "date",
    flex: 3,
    minWidth: 150,
    renderCell: (params) => {
      return params.value.replace("T", " ").substring(0, 11);
    },
  },
  {
    field: "fromTime",
    headerName: "Start Time",
    headerAlign: "center",
    headerClassName: "MuiDataGrid-header",
    type: "text",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 2,
    minWidth: 50,
  },
  {
    field: "toTime",
    headerName: "Finish Time",
    headerClassName: "MuiDataGrid-header",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    headerAlign: "center",
    type: "text",
    flex: 2,
    minWidth: 50,
  },
  {
    field: "month",
    headerName: "Month",
    headerClassName: "MuiDataGrid-header",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    headerAlign: "center",
    type: "text",
    flex: 2,
    minWidth: 50,
  },
  {
    field: "hours",
    headerName: "Hours",
    headerClassName: "MuiDataGrid-header",
    headerAlign: "center",
    type: "text",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 2,
    minWidth: 50,
    renderCell: (params) => {
      if (!!params.value) {
        return params.value.toFixed(2);
      }
    },
  },
  {
    field: "status",
    headerName: "Status",
    headerClassName: "MuiDataGrid-header",
    headerAlign: "center",
    type: "text",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 2,
    minWidth: 100,
    renderCell: (params) => {
      var cellRender = "";
      if (params.value === "Approved") {
        cellRender = <div className="status status--approved">Approved</div>;
      } else if (params.value === "Pending") {
        cellRender = <div className="status status--pending">Pending</div>;
      } else if (params.value === "Rejected") {
        cellRender = <div className="status status--rejected">Rejected</div>;
      }
      return cellRender;
    },
  },
  {
    field: "reason",
    headerName: "Reason",
    headerClassName: "MuiDataGrid-header",
    headerAlign: "center",
    type: "text",
    cellClassName: "MuiDataGrid-tablecell",
    flex: 10,
    minWidth: 200,
  },
];

function LeaveFormService() {
  const navigate = useNavigate();
  const hanldeNewLeaveRequest = () => {
    navigate("/leaveform/newrequest", { state: { leaveDays: leaveDays } });
  };

  const [year, setYear] = useState(new Date());

  const handleNextYear = () => {
    handleYearChange(
      new Date(year.getFullYear() + 1, year.getMonth(), year.getDate())
    );
  };
  const handlePreviousYear = () => {
    handleYearChange(
      new Date(year.getFullYear() - 1, year.getMonth(), year.getDate())
    );
  };

  const [leaveDays, setLeaveDays] = useState(0);
  const [annualLeave, setAnnualLeave] = useState(0);
  const [summerLeave, setSummerLeave] = useState(0);
  const [listRequestFilter, setListRequestFilter] = useState([]);
  const [listRequest, setListRequest] = useState([]);

  //Get all leave form info of user
  useEffect(() => {
    const fetchListLeaveRequest = async () => {
      try {
        const response = await LeaveFormAPI.getLeaveForm();
        var listData = response.data.listLeaveforms;
        // var listDataAddLeaveType = listData.map(addLeaveType);
        let listLeaveSorted = listData.sort((first, second) =>
          first.fromDate < second.fromDate ? 1 : -1
        );
        setListRequest(listLeaveSorted);

        // console.log(listDataAddLeaveType);

        var leaveInfo = response.data.viewReportOfLeaveResource;

        setAnnualLeave(leaveInfo.restOfAnnualLeave);
        setLeaveDays(leaveInfo.totalLeaveDays);
        setSummerLeave(leaveInfo.restOfSummerLeave);

        // if (leaveInfo.thisSummerLeft === 0) {
        //   setSummerLeave(0);
        //   setLeaveDays(leaveInfo.totalLeavrestOfSummerLeaveeDays);
        // } else {
        //   setSummerLeave(leaveInfo.thisSummerLeft - leaveInfo.summerUse);
        // }

        //Doan nay se filter data theo tung nam, sau do add them index
        setListRequestFilter(
          listLeaveSorted
            .filter(
              (request) =>
                request.fromDate.slice(0, 4) === year.getFullYear().toString()
            )
            .map((request, index) => ({
              no: index + 1,
              ...request,
            }))
            .map((request, index) => ({
              ...request,
              fromTime: request.fromDate.split("T")[1].substring(0, 5),
              toTime: request.toDate.split("T")[1].substring(0, 5),
              month: request.fromDate.substring(5, 7),
              status: listStatus.filter(
                (st) => st.id === request.statusDefinitionId
              )[0]?.name,
            }))
        );
      } catch (error) {
        console.log("Error get List Request Leave Form", error);
      }
    };
    fetchListLeaveRequest();
  }, []);

  function handleYearChange(newValue) {
    if (newValue != null && newValue.getFullYear().toString().length == 4) {
      setYear(newValue);

      //Loc list request dua theo nam

      var newListRequest = listRequest
        .filter(
          (request) =>
            request.fromDate.slice(0, 4) === newValue.getFullYear().toString()
        )
        .map((request, index) => ({
          no: index + 1,
          ...request,
        }))
        .map((request, index) => ({
          ...request,
          fromTime: request.fromDate.split("T")[1].substring(0, 5),
          toTime: request.toDate.split("T")[1].substring(0, 5),
          month: request.fromDate.substring(5, 7),
          status: listStatus.filter(
            (st) => st.id === request.statusDefinitionId
          )[0]?.name,
        }));

      //Doan nay se filter data theo tung nam, sau do add them index
      setListRequestFilter(newListRequest);
    }
  }

  console.log("Leave list:", listRequestFilter);
  return (
    <div className="leaveform">
      <div className="leaveform__leaveform-card">
        <TagContent title="REST OF LEAVE DAY" width="600px">
          <div className="card__content">
            <div className="card__content-number">
              {leaveDays}
              <div className="card__content-days">DAYS</div>
            </div>

            <div className="card__content-leave-content">
              <div>
                <p>ANNUAL LEAVE : {annualLeave} DAYS</p>
              </div>
              <div>
                <p>SUMMER LEAVE : {summerLeave} DAYS</p>
              </div>
            </div>
          </div>
        </TagContent>
      </div>
      <div className="leaveform__leaveform-selector">
        <div className="button">
          <Button
            className="button__normal-new"
            variant="contained"
            size="small"
            color="success"
            startIcon={<AddCircleIcon />}
            sx={{
              height: "40px",
              lineHeight: "1",
            }}
            onClick={hanldeNewLeaveRequest}
          >
            Create New
          </Button>
        </div>

        <div className="year-selector">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AiFillCaretLeft
              onClick={handlePreviousYear}
              className="arrow-icon"
            />
            <div className="year-selector__label">
              {year.getFullYear()} YEAR
            </div>
            <AiFillCaretRight onClick={handleNextYear} className="arrow-icon" />
          </LocalizationProvider>
        </div>

        <div className="button button__export">
          <ExportPdfComponent
            listRequestPrint={listRequestFilter}
            yearPrint={year.getFullYear()}
          />
        </div>
      </div>

      <div className="leaveform__leaveform-table">
        <DataGrid
          className="table__data"
          rows={listRequestFilter}
          getRowId={(row) => row.leaveformId}
          rowHeight={40}
          columns={columns}
          pageSize={12}
          rowsPerPageOptions={[7]}
        />
      </div>
    </div>
  );
}

export default LeaveFormService;
