import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as echarts from "echarts";
import ColumnTime from "../ColumnTime";

PipeChartProject.propTypes = {};
const dataProject = [
  {
    value: 1048,
    name: "01. 06I11R.TWB-T and T Design Company Website",
  },
  {
    value: 735,
    name: "02. 06I11Rand T Design Company Website",
  },
  { value: 580, name: "03. 06I11R.TWB-T and T Website" },
  { value: 484, name: "04. 06I11R.TWB-T and T Desbsite Ads" },
  { value: 300, name: "05. 06I11R.TWB-T and T Design  Ads" },
];
const timeSumary = {
  annual: 192,
  overtime: 20,
  leaves: 8,
};

let optionChart = {
  legendWidth: 350,
  emFontSize: "16",
  labelFontSize: "14",
  centerLeft: "20%",
  centerTop: "48%",
  showLegend: true,
};
function CheckResponsiveForChart() {
  let width = window.screen.width;
  if (width >= 360 && width < 639) {
    optionChart.legendWidth = 150;
    optionChart.emFontSize = "12";
    optionChart.labelFontSize = "10";
    optionChart.centerLeft = "48%";
    optionChart.showLegend = false;
  }
  if (width >= 640 && width < 767) {
    optionChart.legendWidth = 150;
    optionChart.emFontSize = "12";
    optionChart.labelFontSize = "12";
  }
  if (width >= 768 && width < 1199) {
    optionChart.legendWidth = 200;
    optionChart.emFontSize = "14";
    optionChart.labelFontSize = "14";
  }
  if (width >= 1200 && width < 1399) {
    optionChart.legendWidth = 230;
    optionChart.emFontSize = "14";
    optionChart.labelFontSize = "14";
  }
  if (width >= 1400) {
  }
}

function PipeChartProject(props) {
  const workingTime = props.summaryOfTimesheet?.normalWorkingTime;
  const overTime =
    props.summaryOfTimesheet?.normalOvertimeHours +
    props.summaryOfTimesheet?.nightOvertimeHours +
    props.summaryOfTimesheet?.weekendOvertimeHours +
    props.summaryOfTimesheet?.publicHolidayOvertimeHours;
  const leaveTime =
    props.summaryOfTimesheet?.paidLeaveHours +
    props.summaryOfTimesheet?.unpaidLeaveHours;

  useEffect(() => {
    var option;
    CheckResponsiveForChart();
    option = {
      color: [
        '#1f78b4',
        '#a6cee3',
        '#b2df8a',
        '#33a02c',
        '#fb9a99',
        '#e31a1c',
        '#fdc06f',
        '#ff7f00',
        '#cab2d6',
        '#6a3d9a',
        '#ffff99',
        '#b15928',
        '#a6cee3',
        '#1f78b4'
      ],    
      tooltip: {
        trigger: "item",
      },
      legend: {
        show: optionChart.showLegend,
        top: "5%",
        orient: "vertical",
        right: "2%",
        icon: "rect",

        textStyle: {
          fontSize: "14",
          width: optionChart.legendWidth,
          overflow: "truncate",
        },
        // selectedMode: false,
      },
      series: [
        {
          name: "PROJECT ANALYSIS",
          type: "pie",
          center: [optionChart.centerLeft, optionChart.centerTop],
          radius: ["40%", "90%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: true,
            position: "inner",
            formatter: "{c} (h)\n\n {d}%",
            fontSize: optionChart.labelFontSize.toString(),
            fontWeight: "600",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: optionChart.labelFontSize.toString(),
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: props?.pieChart?.map((item) => ({
            name: item?.timesheetProjectName,
            value: item?.hours,
          })),
        },
      ],
    };

    var chartDom = document.getElementById("dashboard__ProjectPipeID");
    var myChart = echarts.init(chartDom);
    option && myChart.setOption(option);

    myChart.on("legendselectchanged", function (params, e) {
      suppressSelection(myChart, params);
      console.log(e);
      // Add custom functionality here
    });

    function suppressSelection(chart, params) {
      chart.setOption({ animation: false });

      // Re-select what the user unselected
      chart.dispatchAction({
        type: "legendSelect",
        name: params.name,
      });
      chart.setOption({ animation: true });
    }
  });

  return (
    <div className="dashboard__projectlist">
      <div id="dashboard__ProjectPipeID" className="dashboard__pipechart"></div>
      <div className="dashboard__timesummary">
        <div className="dashboard__timesummary-title">
          <div>WORKING</div>
          <div>OVERTIME</div>
          <div>LEAVE</div>
        </div>
        <div className="dashboard__timesummary-content">
          <div className="dashboard__timesummary-time">
            <ColumnTime
              hours={workingTime <= 0 ? "00" : Math.floor(workingTime)}
              minutes={
                workingTime - Math.floor(workingTime) > 0
                  ? Math.round((workingTime - Math.floor(workingTime)) * 60)
                  : "00"
              }
              color="var(--bs-green)"
            />
          </div>
          <div className="dashboard__timesummary-time">
            <ColumnTime
              hours={overTime <= 0 ? "00" : Math.floor(overTime)}
              minutes={
                overTime - Math.floor(overTime) > 0
                  ? Math.round((overTime - Math.floor(overTime)) * 60)
                  : "00"
              }
              color="var(--bs-yellow)"
            />
          </div>
          <div className="dashboard__timesummary-time">
            <ColumnTime
              color="var(-bs-red)"
              hours={leaveTime <= 0 ? "00" : Math.floor(leaveTime)}
              minutes={
                leaveTime - Math.floor(leaveTime) > 0
                  ? Math.round((leaveTime - Math.floor(leaveTime)) * 60)
                  : "00"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PipeChartProject;
