// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import styles from "./OverTimeService.scss";
import classes from "./OverTimeService.module.scss";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  MenuList,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import moment from "moment";
import ExportPdfOTComponent from "./components/ExportPdfComponent";
import { DataGrid } from "@mui/x-data-grid";
import OverTimeAPI from "B_API/OverTimeAPI";
import ProjectAPI from "B_API/ProjectAPI";
import EditRequestSuccessPopup from "./components/SuccessPopup";
import TimeSheetAPI from "B_API/TimeSheetAPI";
import { ToastShowSuccess } from "F_UTILS/Notify";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";
import CreateOTPopup from "Z_SERVICES/e_OverTimeService/components/CreateOTPopup";
import UserMenuItem from "Z_SERVICES/b_CalendarService/components/UserMenuItem";
import { useSelector } from "react-redux";
import UrlConstant from "F_UTILS/constants/UrlConstant";
import CustomizeCalendarPicker from "A_SHARED_COMPONENT/CustomizeCalendarPicker";
import AdminUserAPI from "B_API/AdminUserAPI";

OverTimeService.propTypes = {};

const startOfMonth = moment().utc().startOf("month");
const endOfMonth = moment().utc().endOf("month");
const startTimeDefault = moment()
  .set("hour", 17)
  .set("minutes", 0)
  .set("second", 0);

const endTimeDefault = moment()
  .set("hour", 18)
  .set("minutes", 0)
  .set("second", 0);

function OverTimeService(props) {
  const [newDialog, setNewDialog] = useState({
    show: false,
  });

  const [showSuccessPopup, setShowSuccessPopup] = React.useState({
    show: false,
  });

  const handleCloseSucess = () => {
    setShowSuccessPopup({
      show: false,
    });
    setNewDialog({
      show: false,
    });

    fetchGetOverTimeRequets();
  };

  const handleNewDialogClose = () => {
    setNewDialog({ show: false });
  };

  const [dateFrom, setDateFrom] = useState(startOfMonth);

  const handleChangeDateFrom = (newValue) => {
    if (typeof newValue === "object") {
      setDateFrom(moment(newValue));
    } else {
      setDateFrom(newValue);
    }
  };

  const [dateTo, setDateTo] = useState(endOfMonth);

  const handleChangeDateTo = (newValue) => {
    if (typeof newValue === "object") {
      setDateTo(moment(newValue));
    } else {
      setDateTo(newValue);
    }
  };

  const userName = JSON.parse(localStorage.getItem("user")).fullName;
  const userId = JSON.parse(localStorage.getItem("user")).userId;

  const currentUserState = useSelector((state) => state.user.current);

  const userSelected = useSelector((state) => state.timesheet.userSelected);

  const daySelected = useSelector((state) => state.timesheet.daySelected);

  // console.log("daySelected ", daySelected);

  const currentRole = currentUserState?.role;
  const selectListBtnRef = useRef();
  const menuUserRef = useRef();
  const department = JSON.parse(localStorage.getItem("user")).teamCode;

  const [requestOT, setRequestOT] = useState({});
  const [userList, setUserList] = useState([]);
  const [userListFiltered, setUserListFiltered] = useState(userList);
  const [user, setUser] = useState(userSelected || currentUserState);
  const [selectListValues, setSelectListValues] = useState({
    left: 0,
    top: 0,
    width: 300,
    isOpen: false,
  });

  //close menu user selection when clicked away
  function clickAwaySelectUser() {
    if (selectListValues.isOpen) {
      setSelectListValues({ ...selectListValues, isOpen: false });
    }
  }

  const handleChangeUser = async (user) => {
    setUser(user);

    setSelectListValues({
      ...selectListValues,
      isOpen: !selectListValues.isOpen,
    });
  };

  function toggleUserSelect() {
    const { offsetTop, offsetLeft, offsetHeight, offsetWidth } =
      selectListBtnRef.current;
    const menuUserWidth = menuUserRef.current
      ? menuUserRef.current.offsetWidth
      : 300;
    setSelectListValues({
      ...selectListValues,
      left: offsetLeft,
      top: offsetTop + offsetHeight,
      width:
        menuUserWidth -
        (menuUserWidth > 290
          ? offsetWidth + 32
          : menuUserWidth > 190
          ? offsetWidth / 2 + 10
          : 50),
      isOpen: !selectListValues.isOpen,
    });
    searchUserHandle("");
  }

  const searchUserHandle = (name) => {
    function removeVietnameseTones(str) {
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
      str = str.replace(/Đ/g, "D");
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "");
      str = str.replace(/\u02C6|\u0306|\u031B/g, "");
      str = str.replace(/ + /g, " ");
      str = str.trim();
      str = str.replace(
        /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
        " "
      );
      return str;
    }

    let renderUser = [...userList];
    if (name !== "") {
      console.log("name : ", name);
      renderUser = renderUser.filter((user) =>
        removeVietnameseTones(user.fullName.toLowerCase()).includes(
          removeVietnameseTones(name.toLowerCase())
        )
      );
    } else {
      setUserListFiltered(renderUser);
    }
    setUserListFiltered(renderUser);
  };

  const listStatus = [
    {
      id: 1,
      name: "Pending",
    },
    {
      id: 2,
      name: "Approved",
    },
    {
      id: 3,
      name: "Rejected",
    },
  ];
  const listOTObjects = [
    {
      id: 1,
      name: "Weekday",
    },
    {
      id: 2,
      name: "Weekend",
    },
    {
      id: 3,
      name: "Night Overtime",
    },
    {
      id: 4,
      name: "Public Holiday",
    },
  ];
  const columns = [
    {
      field: "status",
      headerName: "Status",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      type: "text",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      flex: 2,
      minWidth: 90,
      renderCell: (params) => {
        var cellRender = "";
        if (params.value === "Approved") {
          cellRender = <div className="status status--approved">Approved</div>;
        } else if (params.value === "Pending") {
          cellRender = <div className="status status--pending">Pending</div>;
        } else if (params.value === "Rejected") {
          cellRender = <div className="status status--rejected">Rejected</div>;
        }
        return cellRender;
      },
    },

    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      headerClassName: "MuiDataGrid-header",
      type: "date",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      flex: 1,
      minWidth: 110,
      renderCell: (params) => {
        let value = params.value?.substr(0, 10);
        return <div>{value}</div>;
      },
    },
    {
      field: "month",
      headerName: "Month",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      type: "text",

      cellClassName: "MuiDataGrid-tablecell MuiDataGrid-cell--textCenter ",
      flex: 2,
      minWidth: 50,
    },
    {
      field: "fromTime",
      headerName: "Start Time",
      headerAlign: "center",
      type: "text",
      headerClassName: "MuiDataGrid-header",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      flex: 2,
      minWidth: 90,
      renderCell: (params) => {
        let value = params.value?.substr(11, 5);
        return <div>{value}</div>;
      },
    },
    {
      field: "toTime",
      headerName: "End Time",
      headerClassName: "MuiDataGrid-header",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      headerAlign: "center",
      type: "text",
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        let value = params.value?.substr(11, 5);
        return <div>{value}</div>;
      },
    },
    {
      field: "hours",
      headerName: "Hours",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      type: "text",
      renderCell: (params) => {
        return params.value?.toFixed(2);
      },
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      flex: 2,
      minWidth: 50,
    },
    {
      field: "actualHours",
      headerName: "Actual Hours",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      type: "text",
      renderCell: (params) => {
        return params.value?.toFixed(2);
      },
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      flex: 2,
      minWidth: 50,
    },
    {
      field: "projectName",
      headerName: "Project",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      type: "text",
      cellClassName: "MuiDataGrid-tablecell MuiDataGrid-cell--textCenter ",
      flex: 7,
      minWidth: 250,
      renderCell: (params) => {
        return <div>{params.value}</div>;
      },
    },
    {
      field: "reason",
      headerName: "Reason",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      type: "text",
      cellClassName: "MuiDataGrid-tablecell MuiDataGrid-cell--textCenter ",
      flex: 7,
      minWidth: 250,
    },
    {
      field: "ottype",
      headerName: "OT Type",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      type: "text",
      cellClassName: "MuiDataGrid-tablecell MuiDataGrid-cell--textCenter ",
      minWidth: 120,
      flex: 2,
      renderCell: (params) => {
        var cellRender = "";
        if (params.value === "Weekday") {
          cellRender = <div className="weekday">Weekday</div>;
        } else if (params.value === "Weekend") {
          cellRender = <div className="weekend">Weekend</div>;
        } else if (params.value === "Night Overtime") {
          cellRender = <div className="night-overtime">Night Overtime</div>;
        } else if (params.value === "Public Holiday") {
          cellRender = <div className="public-holiday">Public Holiday</div>;
        }
        return cellRender;
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      minWidth: 100,
      sortable: false,
      cellClassName:
        "MuiDataGrid-tablecell MuiDataGrid-cell--textCenter list-ot__edit",
      flex: 1,
      renderCell: (params) => {
        const onClickEdit = async () => {
          const data = params.row;
          const dataInput = {
            ...data,
            date: new Date(data.date),
            fromTime: moment(data.fromTime),
            toTime: moment(data.toTime),
            overtimeId: data.overtimeId,
            overtimeTypeId: data.overtimeTypeId,
          };
          setRequestOT(dataInput);
          setNewDialog({ show: true });
        };
        return (
          <div
            onClick={onClickEdit}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <EditIcon sx={{ color: "var(--bs-regal-blue)" }} />
          </div>
        );
      },
    },
  ];

  const [filterRequest, setFilterRequest] = useState([]);
  const [totalWeekday, setTotalWeekday] = useState(0);
  const [totalWeekend, setTotalWeekend] = useState(0);
  const [totalNightOvertime, setTotalNightOvertime] = useState(0);
  const [totalPublicHoliday, setTotalPublicHoliday] = useState(0);
  const [listProject, setListProject] = useState([]);
  const [listOTType, setOTType] = useState([]);

  const fetchGetAllUsers = async () => {
    try {
      const response = await AdminUserAPI.getListViewUserCombineTeam();
      setUserList(response.data);
      setUserListFiltered(response.data);
    } catch (error) {
      console.log("Error get User List ", error);
    }
  };
  const fetchListProject = async (userParams) => {
    // console.log(userIdParams);
    try {
      const response2 = await TimeSheetAPI.GetAllTimesheetProjectsByUserId(
        user?.userId
      );
      const data = response2.data
        ? response2.data.filter((project) => project.isActive === 1)
        : [];
      setListProject(data);
    } catch (error) {}
  };

  //Get list Project
  useEffect(() => {
    fetchListProject(user);
    fetchGetAllUsers();
    fetchGetOverTimeRequets();
  }, []);

  //Get list OT Type
  useEffect(() => {
    const fetchGetListOTType = async () => {
      try {
        const response = await OverTimeAPI.getListOverTimeType();
        setOTType(response.data);
      } catch (error) {
        console.log("Error get list OT Type", error);
      }
    };
    fetchGetListOTType();
  }, []);

  function setTotal(listOT) {
    let totalWeekdayCalculate = 0;
    let totalWeekendCalculate = 0;
    let totalNightOvertimeCalculate = 0;
    let totalPublicHolidayCalculate = 0;
    for (let i = 0; i < listOT.length; i++) {
      var request = listOT[i];
      if (request.statusDefinitionId === 2) {
        if (request.overtimeTypeId === 1) {
          totalWeekdayCalculate += request.actualHours;
        } else if (request.overtimeTypeId === 2) {
          totalWeekendCalculate += request.actualHours;
        } else if (request.overtimeTypeId === 3) {
          totalNightOvertimeCalculate += request.actualHours;
        } else if (request.overtimeTypeId === 4) {
          totalPublicHolidayCalculate += request.actualHours;
        }
      }
    }

    setTotalWeekday(totalWeekdayCalculate.toFixed(2));
    setTotalWeekend(totalWeekendCalculate.toFixed(2));
    setTotalNightOvertime(totalNightOvertimeCalculate.toFixed(2));
    setTotalPublicHoliday(totalPublicHolidayCalculate.toFixed(2));
  }
  //Get list OverTime Request
  const fetchGetOverTimeRequets = async () => {
    try {
      var params = {
        dateFrom: dateFrom.format("YYYY-MM-DD"),
        dateTo: dateTo.format("YYYY-MM-DD"),
        userId: user?.userId ?? userId,
      };
      const response = await OverTimeAPI.getListOverTime(params);

      const data = response.data.map((ovt) =>
        Object.assign(ovt, { date: ovt.fromTime })
      );
      setFilterRequest(
        data.map((request, index) => ({
          ...request,
          month: request.date.substring(5, 7),
          ottype: listOTObjects.filter(
            (ot) => ot.id === request.overtimeTypeId
          )[0]?.name,
          status: listStatus.filter(
            (st) => st.id === request.statusDefinitionId
          )[0]?.name,
        }))
      );
      setTotal(data);
    } catch (error) {
      console.log("Error get All OverTime Request", error);
    }
  };

  const [onRefresh, setRefresh] = useState(false);

  useEffect(() => {
    fetchGetOverTimeRequets();
  }, [onRefresh]);

  const handleCreateSuccess = () => {
    setNewDialog({ show: false });
    setRefresh(!onRefresh);
    ToastShowSuccess("OT request has been sent to admin successfully.");
  };

  const handleCreateNewOT = () => {
    setRequestOT({
      date: new Date(),
      fromTime: startTimeDefault,
      toTime: endTimeDefault,
      timesheetProjectId: 1,
      reason: "",
      overtimeTypeId: 1,
      overtimeType: "Weekday",
      statusDefinitionId: 1,
    });
    setNewDialog({ show: true });
  };

  let OTpopup = null;
  if (newDialog.show === true) {
    OTpopup = (
      <CreateOTPopup
        open={newDialog.show}
        onClose={handleNewDialogClose}
        listProjectInfo={listProject}
        listOTType={listOTType}
        requestOT={requestOT}
        setShowSuccessPopup={setShowSuccessPopup}
        createSuccess={handleCreateSuccess}
      />
    );
  }

  let SuccessPopup = null;
  if (showSuccessPopup.show === true) {
    SuccessPopup = (
      <EditRequestSuccessPopup
        open={showSuccessPopup.show}
        onClose={handleCloseSucess}
      />
    );
  }

  return (
    <div className="container">
      <div className="overtime">
        <div className="overtime__info">
          <div className="info__label">
            <h1>OVERTIME</h1>
          </div>

          <div className="info__grid">
            <div className="employee__name">
              {/* <p>Name: {userName}</p> */}
              {currentRole === "Administrator" ? (
                <>
                  <div className={classes["user__wrap"]}>
                    <Stack
                      className={classes["user__container"]}
                      direction="row"
                      spacing={1}
                      ref={menuUserRef}
                    >
                      <Avatar
                        className={classes["user__avatar"]}
                        alt={user.fullName}
                        src={`${UrlConstant.REACT_APP_DATABASE_URL}${user?.avatar}`}
                      />
                      <div className={classes["user__text"]}>
                        {user.fullName}
                      </div>
                      <ClickAwayListener onClickAway={clickAwaySelectUser}>
                        <div>
                          <IconButton
                            aria-label="calendar"
                            className={`${classes["user__button"]} ${classes["button"]}`}
                            ref={selectListBtnRef}
                            onClick={toggleUserSelect}
                          >
                            <KeyboardArrowDownIcon
                              className={classes["button__icon"]}
                            />
                          </IconButton>
                          {selectListValues.isOpen ? (
                            <Box
                              className={classes["menu_user_wrap"]}
                              sx={{
                                "& > :not(style)": {
                                  position: "absolute",
                                  left:
                                    selectListValues.left -
                                    selectListValues.width +
                                    5,
                                  top: selectListValues.top + 5,
                                },
                              }}
                            >
                              <Paper
                                elevation={3}
                                className={classes["menu_user"]}
                              >
                                {/* <UserSearch
                                  onSearchInputChange={searchUserHandle}
                                /> */}

                                <Paper className={classes["menu_user__search"]}>
                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    className={classes["search__wrap"]}
                                  >
                                    <SearchIcon
                                      className={classes["search__icon"]}
                                    />
                                    <TextField
                                      className={classes["search__input"]}
                                      placeholder="Search..."
                                      variant="standard"
                                      onChange={
                                        (e) => searchUserHandle(e.target.value)
                                        //here
                                      }
                                    />
                                  </Box>
                                </Paper>

                                <MenuList
                                  className={`${classes["menu_user__container"]} ${classes["scrollable"]}`}
                                >
                                  {userListFiltered?.map((user) => (
                                    <UserMenuItem
                                      key={user.userId}
                                      user={user}
                                      onSelectUser={handleChangeUser}
                                    />
                                  ))}
                                </MenuList>
                              </Paper>
                            </Box>
                          ) : null}
                        </div>
                      </ClickAwayListener>
                    </Stack>
                  </div>
                </>
              ) : (
                <p>Name: {userName}</p>
              )}
            </div>

            <div className="employee__department">
              <p>Department: {user?.teamCode}</p>
            </div>

            <div className="date-from">
              <div className="date-title">
                <p>From</p>
              </div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  openTo="day"
                  views={["day"]}
                  inputFormat="dd/MM/yyyy"
                  value={dateFrom}
                  onChange={handleChangeDateFrom}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </div>

            <div className="date-to">
              <div className="date-title">
                <p>To</p>
              </div>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  openTo="day"
                  views={["day"]}
                  inputFormat="dd/MM/yyyy"
                  value={dateTo}
                  onChange={handleChangeDateTo}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>

        <div className="overtime__totaltime">
          <div className="text-title">
            <div className="text-label text-center">
              <p>
                Weekday <br />
                (hours)
              </p>
            </div>
            <div className=" text-label text-center">
              <p>
                Weekend <br />
                (hours)
              </p>
            </div>
            <div className=" text-label text-center">
              <p>
                Night overtime <br />
                (hours)
              </p>
            </div>
            <div className="text-label text-center">
              <p>
                Public holiday <br />
                (hours)
              </p>
            </div>
          </div>
          <div className="text-number">
            <div className="overtime__totaltime--hours text-center">
              <p>{totalWeekday}</p>
              <div className="unit-label">
                <p>H</p>
              </div>
            </div>
            <div className="overtime__totaltime--hours weekend text-center">
              <p>{totalWeekend}</p>
              <div className="unit-label">
                <p>H</p>
              </div>
            </div>
            <div className="overtime__totaltime--hours night-overtime text-center">
              <p>{totalNightOvertime}</p>
              <div className="unit-label">
                <p>H</p>
              </div>
            </div>
            <div className="overtime__totaltime--hours public-holiday text-center">
              <p>{totalPublicHoliday}</p>
              <div className="unit-label">
                <p>H</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overtime__btn-group">
        <Button
          className="overtime__btn-group-view"
          variant="contained"
          size="small"
          color="success"
          startIcon={<SearchIcon />}
          sx={{
            height: "40px",
            lineHeight: "1",
            width: "100px",
          }}
          onClick={fetchGetOverTimeRequets}
        >
          VIEW
        </Button>

        <div className="button button__export">
          <ExportPdfOTComponent
            listRequestPrint={filterRequest}
            totalWeekday={totalWeekday}
            totalWeekend={totalWeekend}
            totalNightOvertime={totalNightOvertime}
            totalPublicHoliday={totalPublicHoliday}
            userInfo={user}
          ></ExportPdfOTComponent>
        </div>
      </div>
      <div className="export__table">
        <DataGrid
          rows={filterRequest}
          getRowId={(row) => row.overtimeId}
          rowHeight={40}
          columns={columns}
          pageSize={9}
          rowsPerPageOptions={[7]}
          // disableColumnMenu
          // disableColumnFilter
          // disableColumnSelector
        />
      </div>

      <div className="overtime__create">
        <Button
          className="overtime__create-new"
          variant="contained"
          size="small"
          color="success"
          startIcon={<AddCircleIcon />}
          sx={{
            height: "40px",
            lineHeight: "1",
          }}
          onClick={handleCreateNewOT}
        >
          NEW REQUEST
        </Button>
      </div>
      {OTpopup}
      {SuccessPopup}
      <CustomToastComponent />
    </div>
  );
}

export default OverTimeService;
