import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as echarts from "echarts";

import AdminTimeSheetAPI from "B_API/AdminTimesheetAPI";

ProjectTimeBar.propTypes = {};

function ProjectTimeBar({ currentTeamId, changeProjectActive }) {
  var dataHoursInit = {
    CodeProjects: [],
    NormalHours: [],
    OTHours: [],
  };

  var [dataHours, setDataHours] = React.useState(dataHoursInit);

  //Get project hours chart Init
  useEffect(() => {
    const fetchProjectHoursInit = async () => {
      try {
        var params = {
          id: currentTeamId,
        };

        const response = await AdminTimeSheetAPI.getProjectHourInit(params);
        var codeProjects = response.data.map((pro) => pro.projectCode);
        var normalHours = response.data.map((pro) => pro.normalHours);
        var otHours = response.data.map((pro) => pro.otHours);

        setDataHours({
          CodeProjects: codeProjects,
          NormalHours: normalHours,
          OTHours: otHours,
          data: response.data,
        });
      } catch (error) {
        console.log("Error get project hours " + error);
      }
    };
    fetchProjectHoursInit();
  }, [currentTeamId, changeProjectActive]);

  useEffect(() => {
    var option = {
      title: {
        text: "Project Hours",
      },
      color: [
        '#1f78b4',
        '#a6cee3',
        '#b2df8a',
        '#33a02c',
        '#fb9a99',
        '#e31a1c',
        '#fdc06f',
        '#ff7f00',
        '#cab2d6',
        '#6a3d9a',
        '#ffff99',
        '#b15928',
        '#a6cee3',
        '#1f78b4'
      ],   
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: function (value) {
          const proName = dataHours.data.find(
            (x) => x.projectCode === value[0].name
          )?.projectName;

          return `
          <strong >${proName || value[0].name}</strong>  
          <br /> 
          <p> - Normal: ${value[0].value} (h)</p>
          <p> - OT: ${value[1].value} (h)</p>`;
        },
      },
      legend: {
        icon: "circle",
        right: 0,
      },
      grid: {
        left: "1%",
        right: "1%",
        bottom: "0%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: dataHours.CodeProjects,
        axisLabel: {
          fontWeight: "bold",
        },
      },
      yAxis: {
        type: "value",
      },

      series: [
        {
          name: "Normal",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          data: dataHours.NormalHours,
          barMaxWidth: 50,
        },
        {
          name: "Over Time",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },

          data: dataHours.OTHours,
        },
      ],
    };
    var chartDom = document.getElementById("admin__projectTimeChart");
    var myChart = echarts.init(chartDom);
    option && myChart.setOption(option);
  }, [dataHours]);
  return (
    <div
      id="admin__projectTimeChart"
      className="overview__projectTimeChart"
    ></div>
  );
}

export default ProjectTimeBar;
