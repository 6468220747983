import React, { useState } from "react";
// import { DatePicker, LocalizationProvider } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import moment from "moment";

function SelectDateFrom(props) {
  const startOfMonth = moment()
    .utc()
    .startOf("month")
    .set({ hour: 0, minute: 0 });

  const [dateFrom, setDateFrom] = useState(startOfMonth);

  const handleChangeDateFrom = (newValue) => {
    setDateFrom(newValue);
    props.setDateFrom(newValue);
  };

  return (
    <>
      <div className="date-from">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            openTo="day"
            views={["day"]}
            inputFormat="dd/MM/yyyy"
            value={dateFrom}
            onChange={handleChangeDateFrom}
            renderInput={(params) => (
              <TextField {...params} helperText={null} />
            )}
          />
        </LocalizationProvider>
      </div>
    </>
  );
}

export default SelectDateFrom;
