import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ReportsAPI from "B_API/ReportsAPI";

// createAsyncThunk cái này sử dụng cho login và register
export const getReportsOfLeaveByUserId = createAsyncThunk(
  "Timesheet/getReportsOfLeaveByUserId",
  async (payload) => {
    try {
      const response = await ReportsAPI.getReportsOfLeaveByUserId(payload);
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

export const getSummaryOfTimesheetByDateRange = createAsyncThunk(
  "Timesheet/GetSummaryOfTimesheetByDateRange",
  async (payload) => {
    try {
      const response = await ReportsAPI.getSummaryOfTimesheetByDateRange(
        payload.month,
        payload.year
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

export const getProjectHoursByUserIdAndDateRange = createAsyncThunk(
  "Reports/GetProjectHoursByUserIdAndDateRange",
  async (payload) => {
    try {
      const response = await ReportsAPI.getProjectHoursByUserIdAndDateRange(
        payload
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

const reportSlice = createSlice({
  name: "reports",
  initialState: {
    reportOfLeaves: {},
    summaryOfTimesheets: [],
    projectHours: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: {
    [getReportsOfLeaveByUserId.pending]: (state, action) => {
      state.status = "loading";
    },
    [getReportsOfLeaveByUserId.fulfilled]: (state, action) => {
      state.reportOfLeaves = action.payload;
      state.status = "succeeded";
    },
    [getReportsOfLeaveByUserId.rejected]: (state, action) => {
      state.status = action.error.message;
    },
    [getSummaryOfTimesheetByDateRange.pending]: (state, action) => {
      state.status = "loading";
    },
    [getSummaryOfTimesheetByDateRange.fulfilled]: (state, action) => {
      state.summaryOfTimesheets = action.payload;
      state.status = "succeeded";
    },
    [getSummaryOfTimesheetByDateRange.rejected]: (state, action) => {
      state.status = action.error.message;
    },
    [getProjectHoursByUserIdAndDateRange.pending]: (state, action) => {
      state.status = "loading";
    },
    [getProjectHoursByUserIdAndDateRange.fulfilled]: (state, action) => {
      state.projectHours = action.payload;
      state.status = "succeeded";
    },
    [getProjectHoursByUserIdAndDateRange.rejected]: (state, action) => {
      state.status = action.error.message;
    },
  },
});

const { actions, reducer } = reportSlice;
export const {} = actions;
export default reducer;
