import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Controller } from "react-hook-form";

TextInputField.propTypes = {};
const useStyle = makeStyles((theme) => ({
  input__all: {
    margin: "5px 0",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  input_label: {
    marginBottom: "3px",
    fontWeight: 600,
    fontSize: "16px",
  },
}));

function TextInputField(props) {
  const classes = useStyle();
  const {
    name,
    form,
    label,
    placeholder,
    rows,
    type,
    disable,
    defaultValue,
    multiline,
  } = props;

  const { errors, formState } = form;
  const hasError = errors[name];
  const hasTouched = formState.touched[name];

  return (
    <div className={classes.input__all}>
      <div className={classes.input_label}>{label}</div>
      <Controller
        name={name}
        control={form.control}
        // render={() => <TextField variant="outlined" />}
        as={TextField}
        // TRANSFER
        InputProps={{
          style: {
            padding: "0",
          },
        }}
        defaultValue={defaultValue}
        type={type}
        size="small"
        variant="outlined"
        placeholder={placeholder}
        fullWidth={true}
        disabled={disable}
        minRows={rows}
        multiline={!!multiline ? false : true}
        // OTHER

        error={!!hasError}
        helperText={errors[name]?.message}
      />
    </div>
  );
}

export default TextInputField;
