import { yupResolver } from "@hookform/resolvers/yup";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Button, Dialog, Typography } from "@mui/material";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";
import DatePickerInput from "A_SHARED_COMPONENT/DatePickerInput";
import AdminTimeSheetAPI from "B_API/AdminTimesheetAPI";
import { ToastShowError } from "F_UTILS/Notify";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as yup from "yup";
import moment from "moment";

const schema = yup
  .object()
  .shape({
    fromDate: yup.string().required("Please enter from date."),
    toDate: yup.string().required("Please enter to date."),
  })
  .required();

function LockTimesheetPopup(props) {
  const adminId = useSelector((state) => state.user.current).userId;

  const closePopupHandle = () => {
    props?.setOpenLockPopup(false);
  };

  const date = new Date();
  const firstDayCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  const form = useForm({
    defaultValues: {
      fromDate: firstDayCurrentMonth || new Date(),
      toDate: new Date(),
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (id) => {
    let values = form.getValues();

    let dataSubmit = {
      lockBy: adminId,
      lock: id === "unlockButton" ? 1 : 0,
      fromDate: moment(values.fromDate).format("YYYY-MM-DD"),
      toDate: moment(values.toDate).format("YYYY-MM-DD"),
    };

    const response = await AdminTimeSheetAPI.lockTimesheet(dataSubmit);
    if (response.status === 200) {
      if (id === "unlockButton") {
        props?.handleLock(
          `Timesheet has been unlocked from ${values.fromDate.toLocaleDateString(
            "en-GB"
          )} to ${values.toDate.toLocaleDateString("en-GB")}`
        );
      } else {
        props?.handleLock(
          `Timesheet has been locked from ${values.fromDate.toLocaleDateString(
            "en-GB"
          )} to ${values.toDate.toLocaleDateString("en-GB")}`
        );
      }
    } else {
      ToastShowError(
        "Errow while lock timesheet, please contact with developer!"
      );
    }
  };

  return (
    <div>
      <Dialog open={props?.isOpen} onClose={closePopupHandle}>
        <div className="shared__userinfomation">
          <div className="userinformation__title">
            <Typography
              variant="h5"
              sx={{ fontWeight: "600", color: "var(--bs-stratos)" }}
            >
              LOCK TIMESHEET
            </Typography>
          </div>

          <div className="userinformation__form">
            <div className="userinformation__form-section-02">
              <div className="form__section02-col01">
                <DatePickerInput
                  name="fromDate"
                  label="From Date"
                  form={form}
                  dateInit={firstDayCurrentMonth || new Date()}
                />
              </div>
              <div className="form__section02-col02">
                <DatePickerInput
                  name="toDate"
                  label="To Date"
                  form={form}
                  dateInit={new Date()}
                />
              </div>
            </div>

            <div
              className="userinformation__form-section-06"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                className="button-save"
                variant="contained"
                id="unlockButton"
                style={{ width: "100px" }}
                onClick={(e) => form.handleSubmit(onSubmit(e.target.id))}
                startIcon={<LockOpenIcon />}
              >
                UNLOCK
              </Button>
              <Button
                className="button-save"
                variant="contained"
                style={{ width: "100px" }}
                id="lockButton"
                onClick={(e) => form.handleSubmit(onSubmit(e.target.id))}
                startIcon={<LockIcon />}
              >
                LOCK
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      <CustomToastComponent />
    </div>
  );
}

export default LockTimesheetPopup;
