import SearchIcon from "@mui/icons-material/Search";
import { InputBase, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckboxesGroup from "A_SHARED_COMPONENT/CheckBoxGroup";
import React, { useEffect, useRef } from "react";
import "./../shared_component.scss";

SearchField.propTypes = {};
SearchField.defaultProps = {
  border: "1px solid",
  borderColor: "black",
  borderRadius: "4px",
  backgroundColor: "#d9d9d9",
  backgroundColorHover: "#BFBFBF",
  margin: "5px",
  padding: "0px",
  width: "100%",
  height: "40px",
  showFilter: false,
  objectFilter: {},
};

function SearchField(props) {
  const {
    border,
    borderColor,
    borderRadius,
    backgroundColor,
    backgroundColorHover,
    width,
    height,
    margin,
    padding,
    showFilter,
    searchId,
  } = props;

  const AutoPointToSearch = () => {
    var search = document
      .getElementById(`shared__searchfield-${searchId || "id"}`)
      .focus();
  };

  const { filterObject, filterChange, textSearch, textSearchChange } = props;
  const preText = useRef("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleFilterOpen = (event) => {
    const target = event.currentTarget;
    setAnchorEl(target);
  };

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: borderRadius,
    backgroundColor: backgroundColor,
    "&:hover": {
      backgroundColor: backgroundColorHover,
    },
    //responsive
    [theme.breakpoints.up("sm")]: {
      marginLeft: "0",
      // width: "width",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),

      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "100%",
        "font-size": "14px",
      },
    },
  }));

  const handleChangeText = (e) => {
    e.preventDefault();
    const text = e.target.value;
    if (textSearchChange) textSearchChange(text);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeFilter = (filters) => {
    if (filterChange) filterChange(filters);
  };

  useEffect(() => {
    if (textSearch !== "") AutoPointToSearch();
    if (textSearch === "" && preText.current !== "") AutoPointToSearch();
    preText.current = textSearch;
  });

  const filterMenu = (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 0.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <div className="shared__searchfield-popup">
        <div className="shared__searchfield-popupClose" onClick={handleClose}>
          <i className="fas fa-times"></i>
        </div>
        <div className="shared__searchfield-popupContent">
          <CheckboxesGroup
            initObject={filterObject}
            changeFilters={handleChangeFilter}
          />
        </div>
      </div>
    </Menu>
  );

  return (
    <div
      className="shared__searchfield"
      style={{
        border: border,
        borderColor: borderColor,
        borderRadius: borderRadius,
        width: width,
        height: height,
        margin: margin,
        padding: padding,
      }}
    >
      <Search className="shared__searchfield-main">
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          id={`shared__searchfield-${searchId || "id"}`}
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          onChange={handleChangeText}
          value={textSearch}
          style={{ height: "100%" }}
        />
      </Search>

      {showFilter && (
        <div
          className="shared__searchfield-filter"
          style={{
            borderColor: borderColor,
          }}
          onClick={handleFilterOpen}
        >
          <i className="fas fa-sort-down"></i>
        </div>
      )}
      {filterMenu}
    </div>
  );
}

export default SearchField;
