import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import moment from "moment";
import TelegramIcon from "@mui/icons-material/Telegram";
import DatePickerInput from "A_SHARED_COMPONENT/DatePickerInput";
import DropdownListSelection from "A_SHARED_COMPONENT/DropdownListSelection";
import TextInputField from "A_SHARED_COMPONENT/TextInputField";
import ConfirmPopup from "A_SHARED_COMPONENT/ConfirmPopup";
import TimePickerInput from "A_SHARED_COMPONENT/TimePickerInput/TimeSelectForm";
import UserOverTimeAPI from "B_API/OverTimeAPI";
import AdminTimeSheetAPI from "B_API/AdminTimesheetAPI";
import ErrorPopup from "A_SHARED_COMPONENT/ErrorPopup";
import { useDispatch } from "react-redux";
import { getOTandLeavePending } from "E_REDUX_STORE/OTandLeaveCount";
import { ToastShowError, ToastShowSuccess } from "F_UTILS/Notify";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";
import DropdownListSelectionNoForm from "A_SHARED_COMPONENT/DropdownListSelectionNoForm";
import PublicHolidayAPI from "B_API/PublicHolidayAPI";
import AutocompleteSelection from "A_SHARED_COMPONENT/AutoCompleteSelection";

const schema = yup
  .object()
  .shape({
    reason: yup.string().required("Please enter the reason."),
    projectName: yup.string().required("Please enter the project name."),
  })
  .required();

function CreateOTPopup(props) {
  let { onClose, open, requestOT, listProjectInfo, listOTType, createSuccess } =
    props;

  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  };
  const [initDate, setInitDate] = React.useState([]);
  React.useEffect(() => {
    const fetchGetPublicHoliday = async () => {
      try {
        var currentTime = new Date();
        const response = await PublicHolidayAPI.GetPublicHolidayRequest(
          currentTime.getFullYear()
        );
        setInitDate(response.data);
      } catch (error) {
        console.log("Error get list Holiday", error);
      }
    };
    fetchGetPublicHoliday();
  }, []);

  //Tach tu list project 2 thong tin name va Id
  let listProject = listProjectInfo?.map((project) => ({
    name: project.name,
    timesheetProjectId: project.timesheetProjectId,
  }));

  //Chi lay moi ten

  const listOTTypeSelect = listOTType?.map((ot) => ot.type);
  const listProjectSelect = listProject?.map((project) => project.name);

  const projectIndex = listProjectSelect?.indexOf(
    listProjectInfo.find(
      (project) => project.timesheetProjectId === requestOT.timesheetProjectId
    )?.name
  );

  requestOT = {
    ...requestOT,
    projectName: requestOT?.projectName || listProject[projectIndex]?.name,
  };

  const formOT = useForm({
    defaultValues: {
      ...requestOT,
    },
    resolver: yupResolver(schema),
  });

  const [showConfirm, setShowConfirm] = React.useState({
    show: false,
    callback: null,
  });

  const handleCloseConfirm = () => {
    setShowConfirm({
      show: false,
      callback: null,
    });
    onClose();
  };

  const oTTypeIndex = listOTTypeSelect?.indexOf(
    listOTType.find((ot) => ot.overtimeTypeId === requestOT.overtimeTypeId)
      ?.type
  );

  const [OTTypeIndex, setOTTypeIndex] = React.useState(oTTypeIndex);
  const [date, setDate] = React.useState(requestOT.fromTime);

  const dateToYMD = (date) => {
    var d = formatString(date.getDate());
    var m = formatString(date.getMonth() + 1);
    var y = date.getFullYear();
    return y + "-" + m + "-" + d;
  };

  const formatString = (d) => {
    return d < 10 ? "0" + d.toString() : d.toString();
  };

  const fetchSubmitOT = async () => {
    let data = formOT.getValues();
    let userId = JSON.parse(localStorage.getItem("user")).userId;

    //format date before update
    let dateSelected;
    if (typeof data.date === "object") {
      dateSelected = dateToYMD(data.date);
    } else {
      dateSelected = dateToYMD(new Date(data.date));
    }

    const response = await AdminTimeSheetAPI.getTimesheetInfo(dateSelected);
    let fromTimeSubmit = dateSelected.concat(
      "T",
      data.fromTime.format("HH:mm:ss")
    );
    let toTimeSubmit = dateSelected.concat("T", data.toTime.format("HH:mm:ss"));

    let timesheetId = response.data.timesheetId;

    if (data.overtimeType === null || data.overtimeType === undefined) {
      data = { ...data, overtimeTypeId: requestOT.overtimeTypeId };
    } else {
      let overtimeTypeIdSelected = listOTType.find(
        (ot) => ot.type === data.overtimeType
      ).overtimeTypeId;
      data = { ...data, overtimeTypeId: overtimeTypeIdSelected };
    }

    if (data.projectName === undefined) {
      data = { ...data, projectName: listProjectSelect[0] };
    }
    let hoursCalculate = moment
      .duration(data.toTime.diff(data.fromTime))
      .asHours()
      .toFixed(2);

    if (
      data.fromTime.format("HH:mm:ss") < "12:00:00" &&
      data.toTime.format("HH:mm:ss") > "13:00:00"
    ) {
      hoursCalculate = hoursCalculate - 1;
    }
    const valueSubmit = {
      overtimeId: requestOT.overtimeId,
      overtimeTypeId: OTTypeIndex + 1,

      timesheetProjectId: listProjectInfo.find(
        (project) => project.name === data.projectName
      ).timesheetProjectId,

      fromTime: fromTimeSubmit,
      toTime: toTimeSubmit,
      hours: hoursCalculate,
      reason: data.reason,
      userId: userId,
      timesheetId: timesheetId,
      statusDefinitionId: 1,
    };
    // Check time
    if (!data.fromTime.isBefore(data.toTime)) {
      ToastShowError("Start time must be less than end time.");
    } else {
      const callBackFunction = async () => {
        setShowConfirm({
          show: false,
          callback: null,
        });

        try {
          if (requestOT.overtimeId === undefined) {
            await UserOverTimeAPI.postOverTimeRequest(valueSubmit);
          } else {
            await UserOverTimeAPI.putOverTimeRequest(valueSubmit);
          }
          if (createSuccess) createSuccess();
          onClose();
        } catch (error) {
          console.log("Error POST OverTIME API ", error);

          if (error.response.status === 400) {
            if (
              error.response.data ===
              "There are Overtime with same information in the database. Please check again!"
            ) {
              ToastShowError(error.response.data);
            } else {
              ToastShowError(error.response.data);
            }
          } else {
            ToastShowError(error.response.data);
          }
        }

        const actions = getOTandLeavePending();
        await dispatch(actions);
      };

      setShowConfirm({ show: true, callback: callBackFunction });
    }
  };

  let confirmPopup;
  if (showConfirm.show === true) {
    confirmPopup = (
      <ConfirmPopup
        open={showConfirm.show}
        onClose={handleCloseConfirm}
        onConfirmed={showConfirm.callback}
        title="Are you sure?"
        fromPage="OverTime"
        description="Do you want to submit the request to leader?"
      />
    );
  }
  const [validate, setValidate] = React.useState(true);

  const onDateChange = (date) => {
    setDate(date);
    let data = formOT.getValues();
    const index = CheckTime(
      date,
      data.fromTime._d.getHours(),
      data.toTime._d.getMinutes(),
      data.toTime._d.getHours()
    );
    setOTTypeIndex(index);
  };

  const handleFromTimeChange = () => {
    let data = formOT.getValues();
    const index = CheckTime(
      date,
      data.fromTime._d.getHours(),
      data.toTime._d.getMinutes(),
      data.toTime._d.getHours()
    );
    setOTTypeIndex(index);
  };

  const handleToTimeChange = () => {
    let data = formOT.getValues();
    const index = CheckTime(
      date,
      data.fromTime._d.getHours(),
      data.toTime._d.getMinutes(),
      data.toTime._d.getHours()
    );
    setOTTypeIndex(index);
  };

  const GetListDate = () => {
    const dates = [];
    const getDate = (item) => {
      const startDate = new Date(item.fromDate);
      const endDate = new Date(item.toDate);
      const date = new Date(startDate.getTime());
      while (date <= endDate) {
        date.setDate(date.getDate() + 1);
        dates.push(date.toISOString().substring(0, 10));
      }
    };
    initDate.forEach(getDate);
    return dates;
  };

  const CheckTime = (dateUI, fromTimeHour, toTimeMinutes, toTimeHour) => {
    console.log(dateUI);
    let typeOfOT = "Weekday";
    if (dateUI !== null) {
      const dates = GetListDate();
      if (dates.includes(dateUI.toISOString().substring(0, 10))) {
        if (fromTimeHour > toTimeHour) {
          setValidate(false);
          ToastShowError("Invalid input.");
          return null;
        }

        if (toTimeHour === 22) {
          if (toTimeMinutes === 0 && fromTimeHour < 22) {
            setValidate(true);

            return listOTTypeSelect?.indexOf("Public Holiday");
          }
          if (toTimeMinutes > 0 && fromTimeHour < 22) {
            setValidate(false);
            ToastShowError("Please create two 2 request!");
            return null;
          }
        }

        if (toTimeHour > 22 && fromTimeHour < 22) {
          setValidate(false);
          ToastShowError("Please create two 2 request!");
          return null;
        }

        if (toTimeHour >= 22 && fromTimeHour >= 22) {
          setValidate(true);
          return listOTTypeSelect?.indexOf("Night Overtime");
        }
        setValidate(true);
        return listOTTypeSelect?.indexOf("Public Holiday");
      } else {
        var date = new Date(dateUI);
        if (date.getDay() === 0 || date.getDay() === 6) {
          typeOfOT = "Weekend";
        } else {
          typeOfOT = "Weekday";
        }
      }
    }

    if (fromTimeHour > toTimeHour) {
      setValidate(false);
      ToastShowError("Invalid input.");
      return null;
    }

    if (typeOfOT === "Weekday") {
      console.log("typeOfOT");
      if (fromTimeHour < 17) {
        setValidate(false);
        ToastShowError("Invalid input.");
        return null;
      }
      if (fromTimeHour > toTimeHour) {
        setValidate(false);
        ToastShowError("Invalid input.");
        return null;
      }

      if (toTimeHour > 22 && fromTimeHour < 22) {
        setValidate(false);
        ToastShowError("Please create two 2 request!");
        return null;
      }

      if (toTimeHour === 22) {
        if (toTimeMinutes === 0 && fromTimeHour < 22) {
          setValidate(true);
          return listOTTypeSelect?.indexOf("Weekday");
        }
        if (toTimeMinutes > 0 && fromTimeHour < 22) {
          setValidate(false);
          ToastShowError("Please create two 2 request!");
          return null;
        }
      }

      if (toTimeHour >= 22 && fromTimeHour >= 22) {
        setValidate(true);
        return listOTTypeSelect?.indexOf("Night Overtime");
      }
      setValidate(true);
      return listOTTypeSelect?.indexOf("Weekday");
    } else if (typeOfOT === "Weekend") {
      if (fromTimeHour > toTimeHour) {
        setValidate(false);
        ToastShowError("Invalid input.");
        return null;
      }

      if (toTimeHour > 22 && fromTimeHour < 22) {
        setValidate(false);
        ToastShowError("Please create two 2 request!");
        return null;
      }

      if (toTimeHour === 22) {
        if (toTimeMinutes === 0 && fromTimeHour < 22) {
          setValidate(true);
          return listOTTypeSelect?.indexOf("Weekend");
        }
        if (toTimeMinutes > 0 && fromTimeHour < 22) {
          setValidate(false);
          ToastShowError("Please create two 2 request!");
          return null;
        }
      }

      if (toTimeHour >= 22 && fromTimeHour >= 22) {
        setValidate(true);
        return listOTTypeSelect?.indexOf("Night Overtime");
      }
      setValidate(true);
      return listOTTypeSelect?.indexOf(typeOfOT);
    }
  };

  return (
    <div>
      <Dialog onClose={handleClose} open={open}>
        <div className="shared__userinfomation">
          <div className="userinformation__title">OVERTIME REQUEST</div>
          <div className="userinformation__form">
            <div className="userinformation__form-section-02">
              <div className="form__section02-col01">
                <DatePickerInput
                  name="date"
                  label="Date"
                  form={formOT}
                  disable={requestOT.overtimeId === undefined ? false : true}
                  dateInit={requestOT?.date}
                  onchange={onDateChange}
                />
              </div>
            </div>

            <div className="userinformation__form-section-02">
              <div className="form__section02-col01">
                <TimePickerInput
                  name="fromTime"
                  label="Start Time"
                  form={formOT}
                  timeInit={requestOT.fromTime}
                  onChange={handleFromTimeChange}
                />
              </div>

              <div className="form__section02-col01">
                <TimePickerInput
                  name="toTime"
                  label="End Time"
                  form={formOT}
                  timeInit={requestOT.toTime}
                  onChange={handleToTimeChange}
                />
              </div>
            </div>

            <div className="userinformation__form-section-03">
              <DropdownListSelectionNoForm
                label="OverTime Type"
                listSelection={listOTTypeSelect}
                initIndex={OTTypeIndex}
                disable={true}
              />
            </div>

            <div className="userinformation__form-section-03">
              <AutocompleteSelection
                name="projectName"
                label="Project Name"
                form={formOT}
                listSelection={listProjectSelect}
                initIndex={projectIndex}
              />
            </div>

            <div className="userinformation__form-section-03">
              <div className="form__section02-col01">
                <TextInputField
                  name="reason"
                  label="Work description"
                  rows={5}
                  form={formOT}
                />
              </div>
            </div>

            <div
              className="userinformation__form-section-06"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                disabled={!validate}
                variant="contained"
                style={{ width: "100px" }}
                onClick={formOT.handleSubmit(fetchSubmitOT)}
                startIcon={<TelegramIcon />}
              >
                SUBMIT
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      {confirmPopup}
      <CustomToastComponent />
    </div>
  );
}

CreateOTPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CreateOTPopup;
