import Logout from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { logout } from "E_REDUX_STORE/userSlice";
import StorageKeys from "F_UTILS/constants/StorageKeys";
import UrlConstant from "F_UTILS/constants/UrlConstant";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

MenuProfile.propTypes = {};

function MenuProfile(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const userCur = useSelector((state) => state.user.current);

  const user = {
    name: "Pham Hoan",
    avatar:
      "https://lastfm.freetls.fastly.net/i/u/770x0/2fae42b9c6ca5cafc214ee2954056ebc.jpg",
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickProfile = () => {
    navigate("/userinfo");
  };
  const handleClickSetting = () => {
    navigate("/userinfo/settings");
  };
  const handleClickLogout = async () => {
    const action = logout();
    await dispatch(action);
    navigate("/login");
  };

  useEffect(() => {
    // console.log(userCur);
    return () => {};
  }, [user, localStorage.getItem(StorageKeys.user)]);

  const lastName = userCur.fullName?.trim().split(" ").pop().charAt(0);

  function stringToColor(string) {
    if (string == null) return;
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name}`,
    };
  }

  const userMenu = (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 18,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem onClick={handleClickProfile}>
        <Avatar
          {...stringAvatar(lastName)}
          src={`${UrlConstant.REACT_APP_DATABASE_URL}${userCur.avatar}`}
        />
        My Profile
      </MenuItem>
      <Divider />

      <MenuItem onClick={handleClickSetting}>
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        Settings
      </MenuItem>
      <MenuItem onClick={handleClickLogout}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <IconButton
        size="large"
        edge="end"
        aria-label="account of current user"
        aria-haspopup="true"
        color="inherit"
        onClick={handleClick}
      >
        <Avatar
          alt={user.name}
          src={`${UrlConstant.REACT_APP_DATABASE_URL}${userCur.avatar}`}
          {...stringAvatar(lastName)}
          sx={{ width: 35, height: 35 }}
        />
      </IconButton>
      {userMenu}

      {/* <Menu
        anchorEl={userIcon}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        id={1}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openMenu}
        onClose={{}}
      >
        <MenuItem onClick={handleClickProfile}>Profile</MenuItem>
        <MenuItem onClick={handleClickAccount}>My account</MenuItem>
      </Menu> */}
    </div>
  );
}

export default MenuProfile;
