import React from "react";
import PropTypes from "prop-types";
import { List } from "@mui/material";
import TagObjectItem from "../TagObjectItem";

ListCateObject.propTypes = {};

function ListCateObject(props) {
  const { list, RemoveObject, AddObject, changNameObject } = props;

  const handleClickRemove = (item) => {
    if (RemoveObject) RemoveObject(item);
  };
  const handleAddObject = () => {
    if (AddObject) AddObject();
  };

  const handleChangeName = (item) => {
    if (changNameObject) changNameObject(item);
  };

  list.sort((a, b) => (a.name > b.name ? 1 : -1));

  return (
    <div style={{ height: "100%" }}>
      <List
        sx={{
          width: "100%",
          position: "relative",
          overflow: "auto",
          height: "100%",
        }}
      >
        {list.map((item, index) => (
          <TagObjectItem
            key={item?.name + index}
            _item={item}
            ClickRemove={handleClickRemove}
            ChangeName={handleChangeName}
          />
        ))}
        {
          <div className="overview__objectItem" onClick={handleAddObject}>
            <div className="overview__objectItem-new">
              <i className="fas fa-plus-circle fa-lg"></i>
            </div>
          </div>
        }
      </List>
    </div>
  );
}

export default ListCateObject;
