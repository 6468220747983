import {
  Button,
  Dialog,
  FormControlLabel,
  MenuItem,
  Radio,
  Select,
  Stack,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import MemberTagPopup from "../../../A_SHARED_COMPONENT/MemberTagPopup";
import CircleIcon from "@mui/icons-material/Circle";
import { makeStyles } from "@material-ui/core/styles";
import TextInputField from "A_SHARED_COMPONENT/TextInputField";
import { format } from "date-fns";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import '../CalendarService.scss';

const FlexSelect = styled(Select)`
  &
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    display: flex;
  }
`;

const styles = theme => ({
  button: {
    ":disabled": {
      backgroundColor: "red"
    }
  }
});

const useStyle = makeStyles((theme) => ({
  input__all: {
    margin: "5px 0",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  input_label: {
    marginBottom: "3px",
    fontWeight: 600,
    fontSize: "16px",
  },
}));

const schema = yup
  .object()
  .shape({
    content: yup.string().required("Please enter the content of note."),
  })
  .required();

function NoteForm({
  open,
  userList,
  assignTo,
  noteCalendarInit,
  onSaveNote,
  onDeleteNote,
  onClose,
}) {
  const classes = useStyle();

  const [noteCalendar, setNoteCalendar] = useState(noteCalendarInit);
  const currentUser = useSelector((state) => state.user.current);
  const role = currentUser?.role;
  const canEdit =
    currentUser?.userId === noteCalendarInit.createdBy ? true : false;

  useEffect(() => {
    setNoteCalendar(noteCalendarInit);
  }, [noteCalendarInit]);

  const formNote = useForm({
    defaultValues: {
      noteCalendar,
    },
    resolver: yupResolver(schema),
  });

  const [userSelected, setUserSelected] = useState(assignTo);

  const checkBoxHandle = () => {
    if (canEdit) {
      setNoteCalendar({ ...noteCalendar, isPublic: !noteCalendar.isPublic });
    }
  };

  const colorChangeHandle = (event) => {
    setNoteCalendar({ ...noteCalendar, color: event.target.value });
  };

  const onUserSelectHandle = (event, values) => {
    if (values?.length > 0) {
      if (values?.findIndex((x) => x === currentUser?.userId) < 0) {
        values.push(currentUser?.userId);
      }
      setUserSelected(values);
    } else {
      setUserSelected([currentUser?.userId]);
    }
  };

  const handleDeleteCalendar = async () => {
    let calendarId = noteCalendarInit.calendarId;
    onDeleteNote(calendarId);
  };

  const saveFormHandle = () => {
    let data = {
      ...noteCalendar,
      content: formNote.getValues().content,
    };

    onSaveNote(data, userSelected);
    setUserSelected([]);
    onClose();
  };

  return (
    <Dialog sx={{ zIndex: "1299" }} open={open} onClose={onClose}>
      <div className="shared__userinfomation" style={{ width: "600px" }}>
        <div className="userinformation__title">
          NOTE ({format(new Date(noteCalendar?.date), "dd/MM/yyyy")})
        </div>
        <div className="userinformation__form">
          <div className="userinformation__form-section-03">
            <div className={classes.input__all}>
              <TextInputField
                name="content"
                label="Content"
                rows={7}
                form={formNote}
                placeholder="Note something here..."
                fullWidth
                disable={!canEdit}
                defaultValue={noteCalendarInit?.content}
              />
            </div>
          </div>

          <div className="userinformation__form-section-03">
            <div className={classes.input__all}>
              <div className={classes.input_label}>Privacy</div>
              <Stack direction="row" spacing={2} disabled={canEdit}>
                <FormControlLabel
                  disabled={!canEdit}
                  value={!canEdit ? "disabled" : ""}
                  control={
                    <Radio
                      disabled={!canEdit}
                      checked={
                        noteCalendar
                          ? noteCalendar.isPublic
                            ? true
                            : false
                          : true
                      }
                    />
                  }
                  onClick={checkBoxHandle}
                  label="Public"
                />
                <FormControlLabel
                  value={!canEdit ? "disabled" : ""}
                  disabled={!canEdit}
                  control={
                    <Radio
                      disabled={!canEdit}
                      checked={
                        noteCalendar
                          ? noteCalendar.isPublic
                            ? false
                            : true
                          : false
                      }
                    />
                  }
                  onClick={checkBoxHandle}
                  label="Private"
                />
              </Stack>
            </div>
          </div>

          <div className="userinformation__form-section-03">
            <div className={classes.input__all}>
              <div className={classes.input_label}>Color</div>
              <FlexSelect
                value={noteCalendar ? noteCalendar.color : ""}
                onChange={colorChangeHandle}
                form={formNote}
                disabled={!canEdit}
              >
                <MenuItem value={"success"}>
                  <CircleIcon
                    sx={{
                      color: "var(--bs-success)",
                      width: 20,
                      height: 20,
                      marginRight: "6px",
                    }}
                  />
                  Success
                </MenuItem>
                <MenuItem className={classes["hi"]} value={"danger"}>
                  <CircleIcon
                    sx={{
                      color: "var(--bs-danger)",
                      width: 20,
                      height: 20,
                      marginRight: "6px",
                    }}
                  />
                  Danger
                </MenuItem>
                <MenuItem value={"warning"}>
                  <CircleIcon
                    sx={{
                      color: "var(--bs-warning)",
                      width: 20,
                      height: 20,
                      marginRight: "6px",
                    }}
                  />
                  Warning
                </MenuItem>
                <MenuItem value={"info"}>
                  <CircleIcon
                    sx={{
                      color: "var(--bs-info)",
                      width: 20,
                      height: 20,
                      marginRight: "6px",
                    }}
                  />
                  Info
                </MenuItem>
              </FlexSelect>
            </div>
          </div>

          {role === "Administrator" ? (
            <div className="userinformation__form-section-03">
              <div className={classes.input__all}>
                <div className={classes.input_label}>Assign to</div>
                <MemberTagPopup
                  isDisabled={!canEdit}
                  optionsList={userList}
                  defaultValue={assignTo}
                  form={formNote}
                  onSelectValue={onUserSelectHandle}
                />
              </div>
            </div>
          ) : null}

          <div
            className="userinformation__form-section-04"
            style={
              canEdit
                ? { justifyContent: "space-between" }
                : { justifyContent: "flex-end" }
            }
          >
            {canEdit ? (
              <Button
                className="button-delete"
                variant="contained"
                style={{ width: "100px" }}
                onClick={() => handleDeleteCalendar(noteCalendar?.date)}
                startIcon={<DeleteIcon />}
                color="error"
                disabled={!noteCalendarInit.calendarId}
              >
                DELETE
              </Button>
            ) : null}
            <div className="btn-ok">
              <Button
                className="button-save"
                variant="contained"
                style={{ width: "100px" }}
                startIcon={<SaveIcon />}
                disabled={!canEdit}
                onClick={formNote.handleSubmit(saveFormHandle)}
              >
                SAVE
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default NoteForm;
