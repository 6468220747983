import classes from '../CalendarService.module.scss'
import { Avatar, IconButton, MenuItem, Typography } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useState } from 'react';
import UrlConstant from 'F_UTILS/constants/UrlConstant';

function UserMenuItem(props) {


    function onUserSelectHandle() {
        props.onSelectUser(props.user);
    }

    return (
        <MenuItem className={classes["menu_user__item"]} key={props.userId} onClick={onUserSelectHandle}>
            <Avatar src={`${UrlConstant.REACT_APP_DATABASE_URL}${props.user?.avatar}`} className={classes["item__avatar"]} />
            <Typography className={classes["item__text"]}>
                {props.user.fullName}
            </Typography>
        </MenuItem>
    )
}

export default UserMenuItem;