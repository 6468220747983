import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import ListCateObject from "../ListCateObject";
import AdminTimeSheetAPI from "B_API/AdminTimesheetAPI";

CategoryAndObject.propTypes = {};

function CategoryAndObject(props) {
  const { currentTeamId } = props;
  const [value, setValue] = React.useState("category");
  const [listCategories, setListCategories] = useState([]);
  const [listObjects, setListObject] = useState([]);
  const [update, setUpdate] = useState(false);

  //Get List Cate
  useEffect(() => {
    const fetchGetCategory = async () => {
      try {
        const response = await AdminTimeSheetAPI.getCategoryByTeamId(
          currentTeamId
        );
        setListCategories(response.data);
      } catch (error) {
        console.log("Error get Category ", error);
      }
    };
    fetchGetCategory();
  }, [currentTeamId, update]);

  //Get List Object
  useEffect(() => {
    const fetchGetObjects = async () => {
      try {
        const response = await AdminTimeSheetAPI.getObjectByTeamId(
          currentTeamId
        );
        setListObject(response.data);
      } catch (error) {
        console.log("Error get Category " + error);
      }
    };
    fetchGetObjects();
  }, [currentTeamId, update]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRemoveCate = async (cate) => {
    const newList = listCategories.filter(
      (cat) => cat.timesheetCategoryId !== cate.timesheetCategoryId
    );

    try {
      if (cate.timesheetCategoryId !== "_") {
        var response = await AdminTimeSheetAPI.deleteCategory(
          cate.timesheetCategoryId
        );
        if (response.status !== 200) {
          console.log("Error delete category");
          setListCategories([...listCategories]);
          return;
        }
      }
      setListCategories(newList);
    } catch (err) {
      console.log("Error delete category: ", err);
      setListCategories([...listCategories]);
    }
  };

  const handleRemoveObject = async (obj) => {
    const newList = listObjects.filter(
      (ob) => ob.timesheetObjectId !== obj.timesheetObjectId
    );

    try {
      if (obj.timesheetObjectId !== "_") {
        var response = await AdminTimeSheetAPI.deleteObject(
          obj.timesheetObjectId
        );
        if (response.status !== 200) {
          console.log("Error delete object");
          setListObject([...listObjects]);
          return;
        }
      }
      setListObject(newList);
    } catch (err) {
      console.log("Error delete category: ", err);
      setListObject([...listObjects]);
    }
    //update delete api
    if (obj.id !== "_") {
    }
    setListObject(newList);
  };

  const handleAddObject = (obj) => {
    const newObject = "New Object";
    if (!!listObjects.find((x) => x.name === newObject)) return;

    setListObject([
      ...listObjects,
      { name: newObject, timesheetObjectId: "_" },
    ]);
  };

  const handleAddCate = (obj) => {
    // console.log("CALLING NEW CATE");
    const newCate = "New Category";
    if (!!listCategories.find((x) => x.name === newCate)) return;

    setListCategories([
      ...listCategories,
      { name: newCate, timesheetCategoryId: "_" },
    ]);
    // console.log("SET CATE DONE: ", listCategories);
  };

  const handleChangeObject = async (newItem) => {
    try {
      var response;
      if (newItem.timesheetObjectId !== "_") {
        response = await AdminTimeSheetAPI.updateObject({
          timesheetObjectId: newItem.timesheetObjectId,
          name: newItem.name,
        });
      } else {
        response = await AdminTimeSheetAPI.createNewObject({
          teamId: currentTeamId,
          name: newItem.name,
          isActive: 1,
          isPublic: 0,
        });
      }

      if (response.status === 200) {
        setUpdate(!update);
      } else {
        setListObject([...listObjects]);
      }
    } catch (error) {
      setListObject([...listObjects]);
    }
  };

  const handleChangeCate = async (newItem) => {
    try {
      var response;
      if (newItem.timesheetCategoryId !== "_") {
        response = await AdminTimeSheetAPI.updateCategory({
          timesheetCategoryId: newItem.timesheetCategoryId,
          name: newItem.name,
        });
      } else {
        response = await AdminTimeSheetAPI.createNewCategory({
          teamId: currentTeamId,
          name: newItem.name,
          isActive: 1,
          isPublic: 0,
        });
      }

      if (response.status === 200) {
        setUpdate(!update);
      } else {
        console.log("ERROR UPDATE: ", response.errors.Name);
        setListCategories([...listCategories]);
      }
    } catch (error) {
      console.log("Cannot update to server: ", error);
      setListCategories([...listCategories]);
    }
  };

  return (
    <>
      <div>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="secondary tabs example"
          >
            <Tab value="category" label="CATEGORY" sx={{ fontWeight: 600 }} />
            <Tab value="object" label="OBJECT" sx={{ fontWeight: 600 }} />
          </Tabs>
        </Box>
      </div>

      <div className="overview__cateObjectContent">
        {value === "category" ? (
          <ListCateObject
            list={listCategories}
            RemoveObject={handleRemoveCate}
            AddObject={handleAddCate}
            changNameObject={handleChangeCate}
          />
        ) : (
          <ListCateObject
            list={listObjects}
            RemoveObject={handleRemoveObject}
            AddObject={handleAddObject}
            changNameObject={handleChangeObject}
          />
        )}
      </div>
    </>
  );
}

export default CategoryAndObject;
