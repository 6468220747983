import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DatePickerInput from "A_SHARED_COMPONENT/DatePickerInput";
import DropdownListSelection from "A_SHARED_COMPONENT/DropdownListSelection";
import TextInputField from "A_SHARED_COMPONENT/TextInputField";
import PropTypes from "prop-types";
import { useState } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SaveIcon from "@mui/icons-material/Save";
import AdminUserAPI from "B_API/AdminUserAPI";
import UserSettingAPI from "B_API/UserSettingAPI";
import UrlConstant from "F_UTILS/constants/UrlConstant";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    fullName: yup.string().required("Please enter name."),
    staffId: yup.string().required("Please enter staff id."),
    email: yup.string().email("Invalid email").required("Please enter email."),
  })
  .required();

function UserInformationPopup(props) {
  let {
    onClose,
    open,
    userInfo,
    disable,
    isNew,
    setShowSuccessPopup,
    setShowError,
    setListAllUser,
    listTeamInCompany,
    listRole,
  } = props;

  const currentUser = useSelector((state) => state.user.current);
  const isAdmin = currentUser?.role === "Administrator" ? true : false;

  const [avatarPath, setAvatarPath] = useState(
    `${UrlConstant.REACT_APP_DATABASE_URL}${userInfo?.avatar}`
  );

  const [selectedFile, setSelectedFile] = useState();

  const handleGotoDashboard = () => {
    alert("Go to dashboard");
  };

  const handleClose = () => {
    onClose();
  };

  const listGender = [
    { id: 1, type: "Male" },
    { id: 2, type: "Female" },
  ];
  const handleSubmit = async () => {
    var valueSubmit = form.getValues();

    let teamId, userCreated;
    if (
      typeof valueSubmit?.team === "string" ||
      valueSubmit?.team instanceof String
    ) {
      teamId = listTeamInCompany.find(
        (team) => team.teamCode === valueSubmit?.team
      ).teamId;
    } else {
      teamId = valueSubmit?.team;
    }

    valueSubmit = {
      ...userInfo,
      birthday: moment(valueSubmit.birthday).format("YYYY-MM-DD"),
      teamId: teamId,
      address: valueSubmit?.address,
      gender: valueSubmit?.gender || "Male",
      dateStartWork: moment(valueSubmit?.dateStartWork).format("YYYY-MM-DD"),
      socialInsuranceBookNo: valueSubmit?.socialInsuranceBookNo,
      idNo: valueSubmit?.idNo,
      staffId: valueSubmit?.staffId,
      orderNo: Number(valueSubmit?.staffId.replace(/\D/g, "")),
      issuedTo:
        valueSubmit?.issuedTo !== null
          ? moment(valueSubmit?.issuedTo).format("YYYY-MM-DD")
          : null,
      issuedBy: valueSubmit?.issuedBy,
      cmnd: valueSubmit?.cmnd,
      email: valueSubmit?.email,
      fullName: valueSubmit?.fullName?.trim(),
      ngaycap: valueSubmit?.ngaycap,
      phoneNumber: valueSubmit?.phoneNumber,
      role: valueSubmit?.role,
      roleId: listRole.find((item) => item.roleName === valueSubmit?.role)
        .roleId,
      aboutMe: valueSubmit?.aboutMe,
    };

    let statusPost = true,
      statusUpload = true;

    try {
      //update info
      if (isNew) {
        console.log("valueSubmit ", valueSubmit);

        const response = await AdminUserAPI.createNewUser(valueSubmit);
        if (response?.status !== 200) {
          statusPost = false;
        } else {
          const getUserCreated = await AdminUserAPI.getUserByEmail(
            valueSubmit.email
          );
          userCreated = getUserCreated.data;
        }
      } else {
        // await AdminUserAPI.UpdateUserCredential(valueSubmit);
        console.log("valueSubmit ", valueSubmit);
        const response = await AdminUserAPI.UpdateUserInfoAdmin(valueSubmit);

        if (response?.status !== 200) {
          statusPost = false;
        }
      }
      //update avatar
    } catch (error) {
      console.log("Error post user ", error);

      if (error.response.status === 400) {
        if (error.response.data === "Email already in use.") {
          if (setShowError) setShowError("Email has already existed.");
          statusPost = false;
          return;
        } else if (error.response.data === "Staff Id is duplicate.") {
          if (setShowError) setShowError("Staff Id is duplicate.");
          statusPost = false;
          return;
        }
      }
    }

    const formData = new FormData();
    formData.append("selectedFile", selectedFile);

    let uploadData = {
      formData: formData,
      userId: userInfo?.userId || userCreated?.userId,
    };

    if (selectedFile !== undefined) {
      try {
        const responseUpLoad = await UserSettingAPI.uploadAvatar(uploadData);
        if (responseUpLoad.status === 200) {
          setAvatarPath(URL.createObjectURL(selectedFile));
        } else if (responseUpLoad.status !== 200) {
          statusUpload = false;
        }
      } catch (error) {
        statusUpload = false;
        console.log("Error upload avatar", error);
      }
    }

    if (statusPost && statusUpload) {
      const response = await AdminUserAPI.getListUserCredentialByTeamId(0);
      if (setListAllUser) {
        setListAllUser(response.data);
      }
      if (setShowSuccessPopup) setShowSuccessPopup();
      onClose();
    } else {
      if (setShowError) setShowError();
    }
  };

  //Code Toan sua
  const form = useForm({
    defaultValues: {
      ...userInfo,
      birthday: userInfo?.birthday || new Date(),
      team: userInfo?.teamId || listTeamInCompany[0]?.teamId || 1,
      role: userInfo?.role || listRole[3]?.roleName || 0,
      issuedTo: userInfo?.issuedTo || null,
    },
    resolver: yupResolver(schema),
  });

  const addDefaultSrc = (ev) => {
    ev.target.src = `${UrlConstant.REACT_APP_DATABASE_URL}/database/images/default.jpg`;
  };

  const handleFileSelect = async (event) => {
    let file = event.target.files[0];

    if (file.type === "image/jpeg" || file.type === "image/png") {
      setSelectedFile(file);
      setAvatarPath(URL.createObjectURL(file));
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      {isAdmin ? (
        <>
          <div className="adview__userinfomation">
            <div className="userinformation__title">USER INFORMATION</div>
            <div className="userinformation__adform">
              <div className="userinformation__adform-section-01">
                <div className="adform__section01-col01">
                  <div className="image-upload">
                    <label htmlFor="file-input">
                      <img
                        src={avatarPath}
                        alt="avatar"
                        onError={addDefaultSrc}
                      />
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      onChange={handleFileSelect}
                      accept="image/*"
                    />
                  </div>
                </div>
                <div className="adform__section01-col02">
                  <TextInputField
                    name="fullName"
                    label="Name"
                    form={form}
                    disable={disable}
                  />
                  <DropdownListSelection
                    name="gender"
                    label="Gender"
                    disable={disable}
                    form={form}
                    listSelection={listGender.map((item) => item.type)}
                    initIndex={
                      listGender.find((item) => item.type === userInfo?.gender)
                        ?.id - 1 || 0
                    }
                  />
                </div>

                <div className="adform__section01-col02">
                  <DatePickerInput
                    name="birthday"
                    label="Date Of Birth"
                    form={form}
                    disable={disable}
                    dateInit={userInfo?.birthday}
                  />
                  <DatePickerInput
                    name="dateStartWork"
                    label="Date Start Work"
                    form={form}
                    disable={disable}
                    dateInit={userInfo?.dateStartWork}
                  />
                </div>
              </div>

              <div className="userinformation__adform-section-02">
                <div className="adform__section02-col01">
                  <TextInputField
                    name="staffId"
                    label="Staff Id"
                    form={form}
                    disable={disable}
                  />
                </div>
                <div className="adform__section02-col02">
                  <DropdownListSelection
                    name="team"
                    label="Team"
                    disable={disable}
                    form={form}
                    listSelection={listTeamInCompany.map(
                      (team) => team.teamCode
                    )}
                    initIndex={userInfo?.teamId - 1}
                  />
                </div>
                <div className="adform__section02-col03">
                  <DropdownListSelection
                    name="role"
                    label="Role"
                    form={form}
                    disable={disable}
                    listSelection={listRole.map((item) => item.roleName)}
                    initIndex={
                      userInfo?.role
                        ? listRole.find(
                            (item) => item.roleName === userInfo?.role
                          ).roleId - 1
                        : 3
                    }
                  />
                </div>
              </div>

              <div className="userinformation__adform-section-03">
                <div className="adform__section03-col01">
                  <TextInputField
                    name="idNo"
                    label="ID No"
                    form={form}
                    disable={disable}
                  />
                </div>
                <div className="adform__section03-col02">
                  <DatePickerInput
                    name="issuedTo"
                    label="Issued On"
                    form={form}
                    disable={disable}
                    dateInit={userInfo?.issuedTo || null}
                  />
                </div>
              </div>

              <div className="userinformation__form-section-04">
                <TextInputField
                  name="issuedBy"
                  label="Issued By"
                  form={form}
                  disable={disable}
                />
              </div>

              <div className="userinformation__adform-section-03">
                <div className="adform__section03-col01">
                  <TextInputField
                    name="socialInsuranceBookNo"
                    label="Social Insurance No."
                    form={form}
                    disable={disable}
                  />
                </div>
                <div className="adform__section03-col02">
                  <TextInputField
                    name="phoneNumber"
                    label="Phone Number"
                    form={form}
                    disable={disable}
                  />
                </div>
              </div>

              <div className="userinformation__form-section-04">
                <TextInputField
                  name="email"
                  label="Email"
                  type="email"
                  form={form}
                  disable={disable}
                />
              </div>

              <div className="userinformation__form-section-04">
                <TextInputField
                  name="address"
                  label="Address"
                  form={form}
                  disable={disable}
                />
              </div>
              <div className="userinformation__form-section-04">
                <TextInputField
                  name="aboutMe"
                  label="About Me"
                  form={form}
                  width="50%"
                  rows={3}
                  disable={disable}
                />
              </div>

              <div className="userinformation__adform-section-05">
                {/* <div className="btn-dashboard">
                  <Button
                    variant="contained"
                    style={{ width: "140px" }}
                    disabled={true}
                    startIcon={<DashboardIcon />}
                    onClick={handleGotoDashboard}
                    className={"btn-dashboard__hidden"}
                  >
                    Dashboard
                  </Button>
                </div> */}
                <div className="btn-ok">
                  <Button
                    variant="contained"
                    style={{ width: "100px" }}
                    startIcon={<SaveIcon />}
                    onClick={form.handleSubmit(handleSubmit)}
                  >
                    SAVE
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="shared__userinfomation">
            <div className="userinformation__title">USER INFORMATION</div>
            <div className="userinformation__form">
              <div className="userinformation__form-section-01">
                <div className="form__section01-col01">
                  <img src={avatarPath} alt="avatar" onError={addDefaultSrc} />
                </div>
                <div className="form__section01-col02">
                  <TextInputField
                    name="fullName"
                    label="Name"
                    form={form}
                    disable={disable}
                  />
                  <DatePickerInput
                    name="birthday"
                    label="Date Of Birth"
                    form={form}
                    disable={disable}
                    dateInit={userInfo?.birthday}
                  />
                </div>
              </div>
              <div className="userinformation__form-section-02">
                <div className="form__section02-col01">
                  <TextInputField
                    name="staffId"
                    label="Staff Id"
                    form={form}
                    disable={disable}
                  />
                </div>
                <div className="form__section02-col02">
                  <DropdownListSelection
                    name="team"
                    label="Team"
                    disable={disable}
                    form={form}
                    listSelection={listTeamInCompany.map(
                      (team) => team.teamCode
                    )}
                    initIndex={userInfo?.teamId - 1}
                  />
                </div>
              </div>

              <div className="userinformation__form-section-02">
                <div className="form__section02-col01">
                  <DropdownListSelection
                    name="role"
                    label="Role"
                    form={form}
                    disable={disable}
                    listSelection={listRole.map((item) => item.roleName)}
                    initIndex={
                      userInfo?.role
                        ? listRole.find(
                            (item) => item.roleName === userInfo?.role
                          ).roleId - 1
                        : 3
                    }
                  />
                </div>
                <div className="form__section02-col02">
                  <TextInputField
                    name="phoneNumber"
                    label="Phone Number"
                    form={form}
                    disable={disable}
                  />
                </div>
              </div>

              <div className="userinformation__form-section-03">
                <TextInputField
                  name="email"
                  label="Email"
                  type="email"
                  form={form}
                  disable={disable}
                />
                <TextInputField
                  name="address"
                  label="Address"
                  form={form}
                  disable={disable}
                />
                <TextInputField
                  name="aboutMe"
                  label="About Me"
                  form={form}
                  width="50%"
                  rows={3}
                  disable={disable}
                />
              </div>

              <div
                className="userinformation__form-section-04"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div>
                  <Button
                    variant="contained"
                    style={{ width: "100px" }}
                    startIcon={<SaveIcon />}
                    onClick={handleClose}
                  >
                    OK
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Dialog>
  );
}

UserInformationPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default UserInformationPopup;
