import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";

function SelectionTeamField({
  width,
  height,
  listSelection,
  initTeamId,
  className,
  id,
  onTeamChange,
  leftAlign,
  marginRight,
  showAll,
  listTeams,
}) {
  const [selected, setSelected] = React.useState(initTeamId);
  const handleChange = (event) => {
    var selectedValue = event.target.value;
    setSelected(selectedValue);

    if (onTeamChange) {
      onTeamChange(selectedValue);
    }
  };

  const renderTeam =
    listTeams.sort((a, b) => a.teamName.localeCompare(b.teamName)) || [];
  return (
    <Box
      className={className}
      sx={{ width: width, marginRight: marginRight || 0 }}
    >
      <FormControl fullWidth>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          id={id}
          sx={{
            fontWeight: "600 !important",
            color: "var(--bs-stratos)",
            left: leftAlign || window.screen.width < 1024 ? 0 : -width / 2,
          }}
          value={selected}
          onChange={handleChange}
        >
          {showAll && (
            <MenuItem sx={{ fontWeight: 600 }} value={0} key={0}>
              All
            </MenuItem>
          )}
          {renderTeam?.map((team) => (
            <MenuItem
              sx={{ fontWeight: 600 }}
              value={team.teamId}
              key={team.teamId}
            >
              {team.teamName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default SelectionTeamField;
