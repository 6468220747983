import axiosClient from "./axiosClient";
import axiosClientDownloadFile from "./axiosClientDownLoadFile";

const ExportAPI = {
  // async getSummaryOfTimesheet(params) {
  //   const fromDate = params.dateFrom;
  //   const toDate = params.dateTo;

  //   const accessToken = localStorage.getItem(StorageKeys.access);
  //   const url = `/Reports/GetReportSummaryTimesheetOfTimesheet/?fromDate=${fromDate}&toDate=${toDate}`;
  //   return axiosClient.request({
  //     method: "GET",
  //     url: url,
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   });
  // },

  async getKeyGoogleSheet() {
    const url = `/Reports/GetGoogleTimesheetKeys`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async getSummaryOfTimesheet(params) {
    const fromDate = params.dateFrom;
    const toDate = params.dateTo;
    const url = `/Reports/GetSummaryOfTimesheetByDateRange?fromDate=${fromDate}&toDate=${toDate}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async getSummaryOfTeam(params) {
    const fromDate = params.dateFrom;
    const toDate = params.dateTo;

    const url = `/Reports/GetReportSummaryTimesheetOfTeamsToExcel/?fromDate=${fromDate}&toDate=${toDate}`;

    return axiosClientDownloadFile.request({
      method: "GET",
      url: url,
      responseType: "blob",
    });
  },

  async exportLeaveDaysManagament(year) {
    const url = `/Reports/GetReportLeaveDaysOfStaff/${year}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async summaryAsPersonByMonth(userId, year) {
    const url = `/Reports/SummaryAsPersonByMonth/${userId}/${year}`;
    return axiosClientDownloadFile.request({
      method: "GET",
      url: url,
      responseType: "blob",
    });
  },

  async downloadSummaryOfTimesheet(params) {
    const fromDate = params.dateFrom;
    const toDate = params.dateTo;
    const url = `/Reports/GetSummaryOfTimesheetByDateRangeToExcel?fromDate=${fromDate}&toDate=${toDate}`;
    return axiosClientDownloadFile.request({
      method: "GET",
      url: url,
      responseType: "blob",
    });
  },

  async getReportWorkingHoursSummary(params) {
    const url = `/Reports/GetReportWorkingHoursSummaryToExcel/?fromDate=${params?.dateFrom}&toDate=${params?.dateTo}`;
    return axiosClientDownloadFile.request({
      method: "GET",
      url: url,
      responseType: "blob",
    });
  },
};
export default ExportAPI;
