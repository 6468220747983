import React, { useState } from "react";
// import { DatePicker, LocalizationProvider } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { TextField } from "@mui/material";
import moment from "moment";

function SelectDateTo(props) {
  const endOfMonth = moment().utc().endOf("month").set({ hour: 0, minute: 0 });

  const [dateTo, setDateTo] = useState(endOfMonth);

  const handleChangeDateTo = (newValue) => {
    setDateTo(newValue);
    props.setDateTo(newValue);
  };
  return (
    <>
      <div className="date-to">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            openTo="day"
            views={["day"]}
            inputFormat="dd/MM/yyyy"
            value={dateTo}
            onChange={handleChangeDateTo}
            renderInput={(params) => (
              <TextField {...params} helperText={null} />
            )}
          />
        </LocalizationProvider>
      </div>
    </>
  );
}

export default SelectDateTo;
