import React, { useState, useEffect } from "react";
import "./AdminUserService.scss";
import UserTab from "./components/UserTab";
import TeamAndGroupTab from "./components/TeamAndGroupTab";
import { Tab, Tabs } from "@mui/material";
import AdminUserAPI from "B_API/AdminUserAPI";
import AdminTeamAPI from "B_API/AdminTeamAPI";

AdminUserService.propTypes = {};

function AdminUserService(props) {
  const initTeamId = JSON.parse(localStorage.getItem("user"))?.teamId;
  const [value, setValue] = React.useState("userTab");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [listTeamOrGroup, setListTeamOrGroup] = useState([]);

  useEffect(() => {
    const fetchGetTeam = async () => {
      try {
        const response = await AdminTeamAPI.getTeamOrGroup(0);
        setListTeamOrGroup(response.data);
      } catch (error) {
        console.log("Error get Team ", error);
      }
    };
    fetchGetTeam();
  }, []);

  const [listAllUsers, setListAllUser] = useState([]);

  useEffect(() => {
    const fetchGetAllUsers = async () => {
      try {
        const response = await AdminUserAPI.getListUserCredentialByTeamId(
          initTeamId || 0
        );
        setListAllUser(response.data);
      } catch (error) {
        console.log("Error get User List ", error);
      }
    };
    fetchGetAllUsers();
  }, []);

  const [listTeams, setListTeams] = React.useState([]);
  React.useEffect(() => {
    const fetchListTeams = async () => {
      try {
        const response = await AdminTeamAPI.getTeamOrGroup(1);
        if (response.status === 200) setListTeams(response?.data);
      } catch (error) {
        console.log("Failed to fetch list teams");
      }
    };
    fetchListTeams();
  }, []);

  return (
    <div className="admin__user">
      <div className="admin__user--navbar">
        <div className="navbar__column">
          <Tabs value={value} onChange={handleChange} TabIndicatorProps={{style: {background:'var(--bs-regal-blue)' }}}>
            <Tab value="userTab" label="USER" sx={{ fontWeight: 600}} />
            <Tab
              value="teamAndGroupTab"
              label="TEAM AND GROUP"
              sx={{ fontWeight: 600 }}
            />
          </Tabs>
        </div>
      </div>

      <div className="admin__user--content">
        {value === "userTab" ? (
          <UserTab
            initTeamId={initTeamId}
            listAllUsers={listAllUsers}
            setListAllUser={setListAllUser}
            listTeamInCompany={listTeams}
          />
        ) : (
          <TeamAndGroupTab
            initTeamId={initTeamId}
            listTeamOrGroup={listTeamOrGroup}
            listAllUsers={listAllUsers}
            setListTeamOrGroup={setListTeamOrGroup}
            listTeamInCompany={listTeams}
          />
        )}
      </div>
    </div>
  );
}

export default AdminUserService;
