import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";
import TextInputField from "A_SHARED_COMPONENT/TextInputField";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import DatePickerInput from "A_SHARED_COMPONENT/DatePickerInput";
import PublicHolidayAPI from "B_API/PublicHolidayAPI";
import { ToastShowError, ToastShowSuccess } from "F_UTILS/Notify";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Please enter the holiday name."),
    fromDate: yup.string().required("Please enter from date."),
    toDate: yup.string().required("Please enter to date."),
  })
  .required();

function HolidayManagementPopup(props) {
  let { onClose, open, holidayInfo, isNew } = props;

  const formHoliday = useForm({
    defaultValues: {
      ...holidayInfo,
    },
    resolver: yupResolver(schema),
  });

  const handleSubmitHoliday = async () => {
    var values = formHoliday.getValues();

    let valueSubmit = {
      ...holidayInfo,
      name: values?.name.trim(),
      fromDate: moment(values?.fromDate).format("YYYY-MM-DD"),
      toDate: moment(values?.toDate).format("YYYY-MM-DD"),
      year: new Date(values?.toDate).getFullYear(),
    };

    let response;
    if (valueSubmit?.fromDate.localeCompare(valueSubmit?.toDate) === 1) {
      ToastShowError(
        "Error when try added public holiday. Date from is less than date to."
      );
    } else {
      if (isNew) {
        response = await PublicHolidayAPI.addHoliday(valueSubmit);
      } else {
        response = await PublicHolidayAPI.updateHolidayInfo(valueSubmit);
      }

      if (response.status === 200) {
        ToastShowSuccess("Add public holiday success.");
      } else {
        ToastShowError("Error when try added public holiday.");
      }
      onClose();
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleDeleteHoliday = async (holidayId) => {
    const response = await PublicHolidayAPI.DeleteHoliday(holidayId);

    if (response.status === 200) {
      ToastShowSuccess("Delete holiday success.");
    } else {
      ToastShowError("Error when try delete public holiday.");
    }
    onClose();
  };

  return (
    <div>
      <Dialog onClose={handleClose} open={open}>
        <div className="shared__userinfomation">
          <div className="userinformation__title">PUBLIC HOLIDAY</div>
          <div className="userinformation__form">
            <div className="userinformation__form-section-03">
              <div className="form__section02-col01">
                <TextInputField
                  name="name"
                  label="Holiday Name"
                  form={formHoliday}
                />
              </div>
            </div>

            <div className="userinformation__form-section-02">
              <div className="form__section02-col01">
                <DatePickerInput
                  name="fromDate"
                  label="From Date"
                  form={formHoliday}
                  dateInit={holidayInfo.fromDate || new Date()}
                />
              </div>

              <div className="form__section02-col01">
                <DatePickerInput
                  name="toDate"
                  label="To Date"
                  form={formHoliday}
                  dateInit={holidayInfo.toDate || new Date()}
                />
              </div>
            </div>

            <div className="userinformation__form-section-04">
              <Button
                className="button-delete"
                variant="contained"
                style={{ width: "100px" }}
                onClick={() =>
                  handleDeleteHoliday(holidayInfo?.publicHolidayOfYearId)
                }
                startIcon={<DeleteIcon />}
                disabled={isNew}
              >
                DELETE
              </Button>
              <Button
                className="button-save"
                variant="contained"
                style={{ width: "100px" }}
                onClick={formHoliday.handleSubmit(handleSubmitHoliday)}
                startIcon={<SaveIcon />}
              >
                SAVE
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <CustomToastComponent />
    </div>
  );
}

HolidayManagementPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default HolidayManagementPopup;
