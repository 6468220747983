import React from "react";
import SearchField from "A_SHARED_COMPONENT/SearchField";
import { makeStyles } from "@material-ui/core/styles";
import MemberList from "Z_SERVICES/f_AdminUserServices/components/TeamAndGroupTab/GroupManagerPopup/AddMember/MemberList";

AddMember.propTypes = {};
const useStyle = makeStyles((theme) => ({
  input__all: {
    margin: "5px 0",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  input_label: {
    marginBottom: "3px",
    fontWeight: 600,
    fontSize: "16px",
  },
}));
function AddMember({ memberList, listUsersActive, handleAddMember }) {
  const classes = useStyle();
  const [textSearch, setTextSearch] = React.useState("");

  const textSearchChange = (text) => {
    setTextSearch(text);
  };
  const searchStyle = {
    backgroundColor: "white",
    backgroundColorHover: "white",
    margin: 0,
  };

  return (
    <>
      <div className="userinformation__form-section-03">
        <div className={classes.input__all}>
          <div className={classes.input_label}>Add Member</div>
          <SearchField
            {...searchStyle}
            textSearchChange={textSearchChange}
            textSearch={textSearch}
            borderColor="#c3c3c3"
            searchId="groupSearch"
          />
        </div>
      </div>

      <div
        className="userinformation__form-section-03"
        style={{
          height: "320px",
          border: "1px solid rgb(196 196 196)",
          borderRadius: "4px",
          background: "#efefef",
        }}
      >
        <MemberList
          listUsersActive={listUsersActive}
          memberList={memberList}
          textSearch={textSearch}
          changeAddUser={handleAddMember}
        />
      </div>
    </>
  );
}

export default AddMember;
