import { getToken } from "../../firebase";
import React, { useState, useEffect } from "react";

const Notifications = (props) => {
  const [isTokenFound, setTokenFound] = useState(false);

  console.log("Token found", isTokenFound);

  // To load once
  useEffect(() => {
    let data;

    async function tokenFunc() {
      data = await getToken(setTokenFound);
      return data;
    }

    tokenFunc();
  }, [setTokenFound]);

  useEffect(() => {
    return () => {
      setTokenFound(false);
    };
  }, []);

  return <></>;
};

Notifications.propTypes = {};

export default Notifications;
