import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DatePickerInput from "A_SHARED_COMPONENT/DatePickerInput";
import ListDataSectionField from "A_SHARED_COMPONENT/ListDataSectionFeild";
import TextInputField from "A_SHARED_COMPONENT/TextInputField";
import TextInputFieldProject from "A_SHARED_COMPONENT/TextInputFieldProjectInfo";
import AdminTimeSheetAPI from "B_API/AdminTimesheetAPI";
import { ToastContext } from "Z_SERVICES/g_AdminTimeSheetServices";
import PropTypes from "prop-types";
import * as React from "react";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProjectGroupAddPart from "../OverviewTab/ProjectGroupAddPart";
import DropdownListProject from "./DropdownListProject";

const schema = yup
  .object()
  .shape({
    projectCodeAbbr: yup.string().required("please enter the code."),
    status: yup.string().required("please enter the status."),
    fullProjectName: yup.string().required("please enter the name."),
    created_date: yup.date().required("please enter the date."),
  })
  .required();

function ProjectInfoPopUp(props) {
  let {
    onClose,
    open,
    project,
    isNew,
    formSubmitUpdate,
    currentTeamId,
    handleClickDelete,
    listInfoProjectInit,
    handleOnSubmit,
  } = props;

  const listStatus = ["active", "inactive"];
  const listProjectType = ["T", "I"];
  const listTeamCode = ["R", "B", "C", "SBD", "CAD"];

  const colorStatus = ["green", "gray"];

  const handleClose = () => {
    onClose();
  };

  const {
    showToastCreateSuccess,
    showToastCreateError,
    showToastDeleteSuccess,
    showToastDeleteError,
    showToastUpdateSuccess,
    showToastUpdateError,
  } = useContext(ToastContext);

  var [projectGroups, setProjectGroups] = React.useState([]);

  React.useEffect(() => {
    const fetchProjectGroupByProjectId = async () => {
      try {
        if (!isNew) {
          const res =
            await AdminTimeSheetAPI.GetListViewTeamGroupProjectByTimeSheetProjectId(
              project.timesheetProjectId
            );

          if (res.status === 200) {
            setProjectGroups(res.data);
          }
        }
      } catch (error) {
        console.log("Error with fetch team groups by projectid");
      }
    };
    fetchProjectGroupByProjectId();
  }, []);

  const handleAddGroup = (listGroup) => {
    var newProjectGroups = [...listGroup];
    setProjectGroups(newProjectGroups);
  };

  const [showConfirm, setShowConfirm] = React.useState({
    show: false,
    callback: null,
  });

  const handleSubmitForm = (values) => {
    //CONFIRM POPUP

    const callBackFunction = async () => {
      try {
        if (isNew) {
          //CREATE NEW

          const dataSubmit = {
            timesheetProjectId: project?.timesheetProjectId,
            code: values?.projectCode,
            name: values?.fullProjectName,
            teamId: currentTeamId,

            startedDate:
              values?.created_date !== null
                ? new Date(values.created_date)
                    .toISOString()
                    .slice(0, 19)
                    .replace("T", " ")
                : null,
            finishedDate:
              values?.finished_date !== null
                ? new Date(values.finished_date)
                    .toISOString()
                    .slice(0, 19)
                    .replace("T", " ")
                : null,
            isActive: 1,
            isPublic: 0,

            fiscalYear: listInfoProjectInit?.fiscalAndNumber?.fiscalYear,
            projectNumber: listInfoProjectInit?.fiscalAndNumber?.projectNumber,

            projectCode: values?.projectCodeAbbr || "",
            projectDescription: values?.projectDescription || "",
            clientId:
              listInfoProjectInit?.listClients?.find(
                (item) => item.clientCode === values?.clientCode
              ).clientId || 0,
            projectType: values?.projectType || "",
            teamCode: values?.teamCode || "",
          };

          console.log("dataSubmit ", dataSubmit);

          const res = await AdminTimeSheetAPI.CreateProjectTimeSheetId(
            dataSubmit
          );
          if (res.status !== 200) {
            console.log("Create new project failed.");
            showToastCreateError();
            return;
          }
          const projectId = res.data.timesheetProjectId;
          const res2 = await AdminTimeSheetAPI.UpdateMultipleTeamProject({
            timesheetProjectId: projectId,
            listTeamId: projectGroups.map((group) => group.teamId),
          });

          if (res2.status === 200) {
            showToastCreateSuccess();
            handleOnSubmit();
          } else if (res2.status !== 200) {
            showToastCreateError();
          }
        } else {
          //UPDATE
          const data = {
            timesheetProjectId: project?.timesheetProjectId,
            code: project?.projectCode,
            name: values?.fullProjectName,
            teamId: currentTeamId,

            startedDate: new Date(values.created_date.setHours(12, 0, 0, 0))
              .toISOString()
              .slice(0, 19)
              .replace("T", " "),
            finishedDate:
              values?.finished_date !== null
                ? new Date(values.finished_date)
                    .toISOString()
                    .slice(0, 19)
                    .replace("T", " ")
                : null,
            isActive: values?.status === "active" ? 1 : 0,
            isPublic: 0,

            fiscalYear:
              project?.fiscalYear ||
              listInfoProjectInit?.fiscalAndNumber?.fiscalYear,
            projectNumber:
              project?.projectNumber ||
              listInfoProjectInit?.fiscalAndNumber?.projectNumber,

            projectCode: values?.projectCodeAbbr || "",

            projectDescription: values?.projectDescription || "",
            clientId:
              listInfoProjectInit?.listClients?.find(
                (item) => item.clientCode === values?.clientCode
              ).clientId || 0,

            projectType: project?.projectType || values?.projectType || "",
            teamCode: project?.teamCode || values?.teamCode || "",
          };

          console.log("dataSubmit ", data);

          const res = await AdminTimeSheetAPI.UpdateProjectTimeSheetId(
            data,
            project.timesheetProjectId
          );

          if (res.status !== 200) {
            console.log("Update project fail.");
            showToastUpdateError();
            return;
          }

          const res2 = await AdminTimeSheetAPI.UpdateMultipleTeamProject({
            timesheetProjectId: project.timesheetProjectId,
            listTeamId: projectGroups.map((group) => group.teamId),
          });

          // console.log("GROUP: ", projectGroups);
          // console.log("COUNT");
          let members = 0;

          projectGroups.forEach((group) => {
            // console.log(group.members);
            members += group.members;
          });

          if (res.status === 200 && res2.status === 200) {
            showToastUpdateSuccess();
            handleOnSubmit();
          }

          if (res2.status !== 200) {
            showToastUpdateError();
            console.log("Update groups to project fail.");
          } else {
            if (formSubmitUpdate)
              formSubmitUpdate({
                ...project,
                numberOfMembers: members,
                projectCode: values.project_code,
                projectName: values.project_name,

                startedDate: new Date(values.created_date.setHours(12, 0, 0, 0))
                  .toISOString()
                  .slice(0, 19)
                  .replace("T", " "),
                finishedDate:
                  values?.finished_date !== null
                    ? new Date(values.finished_date)
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " ")
                    : null,
                isActive: values?.status === "active" ? 1 : 0,
              });
          }
        }
        // update group into project
      } catch (error) {
        console.log("ERROR PUSH GROUP: ", error);
        showToastUpdateError();
      }
    };
    callBackFunction();
    onClose();
  };

  const handleDeleteProject = async (projectId) => {
    //API TO DELETE
    const callBackFunction = async () => {
      setShowConfirm({
        show: false,
        callback: null,
      });

      if (projectId) {
        try {
          const res = await AdminTimeSheetAPI.DeleteProjectTimeSheetId(
            projectId
          );
          if (res.status !== 200) {
            showToastDeleteError();
            console.log("Delete project fail.");
          } else {
            showToastDeleteSuccess();
          }
        } catch (error) {
          // ToastShowError("Delete project fail.");
          showToastDeleteError();
          console.log("Delete project fail.");
        }
        if (handleClickDelete) handleClickDelete();
      }
      onClose();
    };

    callBackFunction();
    onClose();
  };

  const form = useForm({
    defaultValues: {
      fiscalYear:
        project?.fiscalYear || listInfoProjectInit?.fiscalAndNumber?.fiscalYear,
      projectNumber:
        project?.projectNumber ||
        listInfoProjectInit?.fiscalAndNumber?.projectNumber,
      projectCodeAbbr: project?.projectCodeAbbr || "",
      status: project?.isActive === 1 ? "active" : "inactive",
      created_date: project?.startedDate || new Date(),
      finished_date: project?.finishedDate || null,
      projectDescription: project?.projectDescription || "",
      clientCode: project?.clientCode || "NONE",
      projectType: project?.projectType || "T",
      teamCode: project?.teamCode || "R",
    },
    resolver: yupResolver(schema),
  });

  let fullProjectNameObj = {
    fiscalYear:
      project?.fiscalYear || listInfoProjectInit?.fiscalAndNumber?.fiscalYear,
    projectCodeAbbr: project?.projectCodeAbbr || "",
    projectDescription: project?.projectDescription || "",
    clientCode: project?.clientCode || "",
    projectType: project?.projectType || "T",
    teamCode: project?.teamCode || "R",
    projectNumber:
      project?.projectNumber ||
      listInfoProjectInit?.fiscalAndNumber?.projectNumber,
  };

  let fullProjectNameInit =
    project?.projectName ||
    `${fullProjectNameObj?.fiscalYear}${fullProjectNameObj.projectType}${fullProjectNameObj.projectNumber}${fullProjectNameObj.teamCode}.${fullProjectNameObj.projectCodeAbbr}.${fullProjectNameObj.clientCode}-${fullProjectNameObj.projectDescription}`;

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="adview__userinfomation">
        <div className="userinformation__title">PROJECT INFORMATION</div>
        <div className="userinformation__adform">
          <div className="userinformation__adform-section-02">
            <div className="adform__section02-col01">
              <TextInputField
                name="fiscalYear"
                label="Fiscal Year"
                disable={true}
                form={form}
              />
            </div>
            <div className="adform__section02-col02">
              <DropdownListProject
                name="projectType"
                label="Project Type"
                form={form}
                listSelection={listProjectType}
                fontWeight={600}
                initIndex={
                  listProjectType.indexOf(
                    listProjectType.find(
                      (project) => project.projectType === project?.projectType
                    )
                  ) || 0
                }
              />
            </div>
            <div className="adform__section02-col03">
              <TextInputField
                name="projectNumber"
                label="Project Number"
                disable={true}
                form={form}
              />
            </div>
          </div>

          <div className="userinformation__adform-section-02">
            <div className="adform__section02-col01">
              <TextInputFieldProject
                name="projectCodeAbbr"
                label="Project Code"
                value={form.getValues().projectCodeAbbr}
                form={form}
              />
            </div>
            <div className="adform__section02-col02">
              <DropdownListProject
                name="teamCode"
                label="Team"
                form={form}
                listSelection={listTeamCode}
                fontWeight={600}
                initIndex={
                  listTeamCode.indexOf(project?.teamCode) < 0
                    ? 0
                    : listTeamCode.indexOf(project?.teamCode)
                }
              />
            </div>
            <div className="adform__section02-col03">
              <DropdownListProject
                name="clientCode"
                label="Client"
                form={form}
                listSelection={listInfoProjectInit?.listClients?.map(
                  (client) => client.clientCode
                )}
                fontWeight={600}
                initIndex={
                  listInfoProjectInit?.listClients?.indexOf(
                    listInfoProjectInit?.listClients?.find(
                      (client) => client.clientCode === project?.clientCode
                    )
                  ) || 0
                }
              />
            </div>
          </div>

          <div className="userinformation__adform-section-03">
            <div className="adform__section03-col01">
              <TextInputFieldProject
                name="projectDescription"
                label="Project Description"
                value={form.getValues().projectDescription}
                defaultValue={form.getValues().projectDescription}
                form={form}
              />
            </div>
            <div className="adform__section03-col02">
              <ListDataSectionField
                name="status"
                label="Status"
                form={form}
                listSelection={listStatus}
                fontWeight={600}
                color={colorStatus}
                initIndex={
                  project?.isActive === undefined
                    ? 0
                    : project?.isActive === 1
                    ? 0
                    : 1
                }
              />
            </div>
          </div>

          <div className="userinformation__adform-section-03">
            <TextInputField
              name="fullProjectName"
              label="Full Project Name"
              disable={true}
              defaultValue={fullProjectNameInit}
              form={form}
            />
          </div>

          <div className="userinformation__adform-section-06">
            <div className="adform__section06-col01">
              <DatePickerInput
                name="created_date"
                label="Created Date"
                form={form}
                dateInit={project?.startedDate}
              />
            </div>
            <div className="adform__section06-col01">
              <DatePickerInput
                name="finished_date"
                label="Finished Date"
                form={form}
                dateInit={project?.finishedDate || null}
              />
            </div>
          </div>

          <ProjectGroupAddPart
            projectGroups={projectGroups}
            handleAddGroup={handleAddGroup}
            projectId={project?.timesheetProjectId}
          />

          <div className="userinformation__adform-section-07">
            <Button
              className="button-delete"
              variant="contained"
              style={{ width: "100px" }}
              onClick={() => handleDeleteProject(project?.timesheetProjectId)}
              startIcon={<DeleteIcon />}
              color="error"
              disabled={isNew}
            >
              DELETE
            </Button>
            <Button
              className="button-save"
              variant="contained"
              style={{ width: "100px" }}
              onClick={form.handleSubmit(handleSubmitForm)}
              startIcon={<SaveIcon />}
            >
              SAVE
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

ProjectInfoPopUp.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ProjectInfoPopUp;
