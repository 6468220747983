import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function SortListLeaveForm({ width, height, valueInit, handleOnStatusChange }) {
  const [selected, setSelected] = React.useState(valueInit || 0);

  const handleChange = async (event) => {
    let valueSelected = event.target.value;

    //Sẽ gửi value này lên phía back-end. phía backend trả lại list render theo value
    setSelected(valueSelected);
    if (handleOnStatusChange) handleOnStatusChange(valueSelected);
  };

  return (
    <Box sx={{ width: width }}>
      <FormControl fullWidth>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          sx={{
            fontWeight: "600 !important",
            paddingRight: "15px",
          }}
          value={selected}
          onChange={handleChange}
        >
          <MenuItem sx={{ fontWeight: 600 }} value={0}>
            All
          </MenuItem>
          <MenuItem
            sx={{ fontWeight: 600, color: "#FF8C00" }}
            value={1}
            key={1}
          >
            <div className="status--pending">Pending</div>
          </MenuItem>
          <MenuItem
            sx={{ fontWeight: 600, color: "#28a745" }}
            value={2}
            key={2}
          >
            <div className="status--approved">Approved</div>
          </MenuItem>
          <MenuItem
            sx={{ fontWeight: 600, color: "#dc3545" }}
            value={3}
            key={3}
          >
            <div className="status--rejected">Rejected</div>
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default SortListLeaveForm;
