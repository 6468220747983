import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import StorageKeys from "F_UTILS/constants/StorageKeys";
import AuthApi from "B_API/AuthAPI";
import AdminUserAPI from "B_API/AdminUserAPI";
import TokenServices from "B_API/TokenServices";

// createAsyncThunk cái này sử dụng cho login và register
export const login = createAsyncThunk("Auth/Login", async (payload) => {
  try {
    const response = await AuthApi.Login(payload);
    TokenServices.addToken(response.data);

    const email = JSON.parse(response.config.data).username;
    const responseUser = await AuthApi.GetUserCredentialByEmail({
      email: email,
      access: !!localStorage.getItem(StorageKeys.access)
        ? localStorage.getItem(StorageKeys.access)
        : response.data.accessToken,
    });
    const user = { ...responseUser };
    const data = {
      ...user.data,
    };
    TokenServices.setUser(data);

    return data;
  } catch (error) {
    console.log(error);
    return error.message;
  }
});

export const loginByGoogleAccount = createAsyncThunk(
  "Auth/Login",
  async (payload) => {
    try {
      const response = await AuthApi.LoginByGoogleAccount(payload);
      TokenServices.addToken(response.data);

      const email = payload.email;
      const responseUser = await AuthApi.GetUserCredentialByEmail({
        email: email,
        access: !!localStorage.getItem(StorageKeys.access)
          ? localStorage.getItem(StorageKeys.access)
          : response.data.accessToken,
      });
      const user = { ...responseUser };
      const data = {
        ...user.data,
      };
      TokenServices.setUser(data);

      return data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

export const refreshToken = createAsyncThunk(
  "token/refresh",
  async (payload) => {
    try {
      const response = await AuthApi.RefreshToken(payload);
      TokenServices.addToken(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      window.location.reload(true);
      TokenServices.removeUser();
      return error.message;
    }
  }
);

export const updateUserCredentialByCommonUserAsync = createAsyncThunk(
  "Users/updateUserCredentialByCommonUserAsync",
  async (payload) => {
    try {
      const response = await AdminUserAPI.UpdateUserCredential(payload);
      const email = payload.email;
      const responseUser = await AuthApi.GetUserCredentialByEmail({
        email: email,
        access: !!localStorage.getItem(StorageKeys.access)
          ? localStorage.getItem(StorageKeys.access)
          : response.data.accessToken,
      });
      const user = { ...responseUser };
      const data = {
        ...user.data,
      };
      TokenServices.setUser(data);

      return data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

export const getListViewUserCombineTeam = createAsyncThunk(
  "Users/getListViewUserCombineTeam",
  async (payload) => {
    try {
      const response = await AdminUserAPI.getListViewUserCombineTeam();
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

export const getUserCredentialByEmail = createAsyncThunk(
  "Users/GetUserCredentialByEmail",
  async (payload) => {
    try {
      const responseUser = await AuthApi.GetUserCredentialByEmail({
        email: payload,
      });
      const user = { ...responseUser };
      const data = {
        ...user.data,
      };
      TokenServices.setUser(data);
      return data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    current: JSON.parse(localStorage.getItem(StorageKeys.user)) || {},
    settings: {},
    status: "idle",
    error: null,
    listUsers: [{}],
  },
  reducers: {
    logout(state) {
      state.current = {};
      TokenServices.removeUser();
    },
  },
  extraReducers: {
    [login.pending]: (state, action) => {
      state.status = "loading";
    },
    [login.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.status = "succeeded";
    },
    [login.rejected]: (state, action) => {
      TokenServices.removeUser();
      state.current = {};
      state.status = action.error.message;
    },
    [updateUserCredentialByCommonUserAsync.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateUserCredentialByCommonUserAsync.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.status = "succeeded";
    },
    [updateUserCredentialByCommonUserAsync.rejected]: (state, action) => {
      state.status = action.error.message;
      TokenServices.removeUser();
      state.current = {};
    },
    [refreshToken.pending]: (state, action) => {
      state.status = "loading";
    },
    [refreshToken.fulfilled]: (state, action) => {
      if (!!action.payload.accessToken === true) {
        TokenServices.addToken(action.payload);
        state.status = "succeeded";
      } else {
        state.status = action.payload;
        TokenServices.removeUser();
        state.current = {};
      }
    },
    [refreshToken.rejected]: (state, action) => {
      state.status = action.error.message;
      TokenServices.removeUser();
      state.current = {};
    },
    [getListViewUserCombineTeam.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListViewUserCombineTeam.fulfilled]: (state, action) => {
      state.listUsers = action.payload;
      state.status = "succeeded";
    },
    [getListViewUserCombineTeam.rejected]: (state, action) => {
      state.status = action.error.message;
      state.listUsers = [{}];
    },
    [getUserCredentialByEmail.pending]: (state, action) => {
      state.status = "loading";
    },
    [getUserCredentialByEmail.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.status = "succeeded";
    },
    [getUserCredentialByEmail.rejected]: (state, action) => {
      state.status = action.error.message;
      TokenServices.removeUser();
      state.current = {};
    },
  },
});

const { actions, reducer } = userSlice;
export const { logout } = actions;
export default reducer;
