import axiosClient from "./axiosClient";

const ResetPasswordAPI = {
    async SendEmailRequest(email) {
        const url = `/Notification/ConfirmResetPassword/${email}`;
        return axiosClient.request({
            method: "POST",
            url: url,
        });
    },
}

export default ResetPasswordAPI;