import "./loading.css";

function LoadingAnimation(props) {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="text-loading" style={{ paddingTop: "20px" }}>
        <p>{props?.textLoading || "Loading"}</p>
      </div>
    </div>
  );
}

export default LoadingAnimation;
