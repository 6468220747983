import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import TextInputField from "A_SHARED_COMPONENT/TextInputField";
import ResetPasswordAPI from "B_API/ResetPasswordAPI";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

ResetPassForm.propTypes = {};

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required("Please enter the email")
      .email("Please enter the valid email"),
  })
  .required();

function ResetPassForm(props) {
  const [showNotiSuccess, setShowNotiSuccess] = useState(false);
  const [showNotiFail, setShowNotiSuccessFail] = useState(false);
  const changeModeLogin = props.changeModeLogin;

  const form = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = (values) => {
    console.log("reset pass: ", values);
    //request to server for reset password

    // if res ===200
    fetchInitData(values.email);
  };
  const handleChangeModeReset = () => {
    if (changeModeLogin) changeModeLogin();
  };

  const fetchInitData = async (email)=>
    {
      try{
        console.log(email);
        const response = await ResetPasswordAPI.SendEmailRequest(email);
        console.log(response);
        if(response.status === 200){
          setShowNotiSuccessFail(false);
          setShowNotiSuccess(true);
        }
        else{
          setShowNotiSuccessFail(true);
          setShowNotiSuccess(false);
        }

      }catch(err){
        setShowNotiSuccessFail(true);
        setShowNotiSuccess(false);
      }
    }


  return (
    <form className="login__sign-form">
      <div className="login__sign-resetemail">
        <TextInputField
          name="email"
          label="Email"
          placeholder="email@ttdsignco.com"
          form={form}
        />
        {showNotiSuccess && (
          <h6>
            New password were sent to your email address. Please check your
            email!
          </h6>
        )}
        {showNotiFail && (
          <h6 style={{color:'red'}}>
            Your email does not exist!
          </h6>
        )}
      </div>

      <div className="login__sign-others">
        <Button
          className="button-login"
          fullWidth
          variant="contained"
          color="primary"
          onClick={form.handleSubmit(handleOnSubmit)}
        >
          RESET PASSWORD
        </Button>
        <p className="login__change-mode" onClick={handleChangeModeReset}>
          Already have account?
        </p>
      </div>
    </form>
  );
}

export default ResetPassForm;
