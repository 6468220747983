import { Badge } from "@mui/material";
import classNames from "classnames";
import "./sharedMainPage.scss";

MenuItem.propTypes = {};

function MenuItem({ navigation, onClick, numberNotice }) {
  const onClickItem = (item) => {
    if (onClick) onClick(item);
  };

  return (
    <div
      className={classNames({
        navigation__item: true,
        active: navigation.active,
        disable: navigation.disable,
      })}
      onClick={() => {
        onClickItem(navigation);
      }}
    >
      <div className="navigation__item-icon">
        <i className={navigation.icon}></i>
      </div>
      {window.screen.width > 640 && <p>{navigation.name}</p>}
      <Badge
        style={{ marginLeft: "15px" }}
        badgeContent={numberNotice || 0}
        color="error"
      />
    </div>
  );
}

export default MenuItem;
