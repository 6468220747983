import MenuItem from "../shared/MenuItem";

MemberNavigation.propTypes = {};

function MemberNavigation(props) {
  const { navigation, navigationClick } = props;

  const handleOnClick = (value) => {
    if (navigationClick) navigationClick(value);
  };
  return (
    <div className="navigation__member">
      {navigation.map((item, index) => (
        <MenuItem key={index} navigation={item} onClick={handleOnClick} />
      ))}
    </div>
  );
}

export default MemberNavigation;
