import { List } from "@mui/material";
import React, { useContext, useState } from "react";
import SortUpDownIcon from "Z_SERVICES/g_AdminTimeSheetServices/shared/SortUpDownIcon";
import ProjectRowItem from "../ProjectRowItem";

ProjectSummary.propTypes = {};

function ProjectSummary({
  textSearch,
  listProject,
  onDeleteProject,
  handleChangeActiveProject,
  handleOnSubmit,
}) {
  const [sort, setSort] = useState({ dir: "up", para: "status" });

  const handleClickSort = (values) => {
    setSort(values);
  };
  const onChangeActiveProject = () => {
    if (handleChangeActiveProject) handleChangeActiveProject();
  };
  const handleClickSubmit = () => {
    if (handleOnSubmit) handleOnSubmit();
  };

  const handleClickDelete = () => {
    if (onDeleteProject) onDeleteProject();
  };
  let renderData = listProject;

  function SortRenderData(sortField) {
    let sortBy;
    switch (sortField.para) {
      case "project_code":
        sortBy = "projectCode";
        break;
      case "members":
        sortBy = "numberOfMembers";
        break;
      case "hours":
        sortBy = "normalHours";
        break;
      case "created_date":
        sortBy = "startedDate";
        break;
      case "finished_date":
        sortBy = "finishedDate";
        break;
      case "status":
        sortBy = "isActive";
        break;
      default:
        sortBy = "projectCode";
    }

    if (textSearch !== "") {
      renderData = listProject.filter(
        (project) =>
          project.projectName
            .toLowerCase()
            .includes(textSearch.toLowerCase()) ||
          project.projectCode.toLowerCase().includes(textSearch.toLowerCase())
      );
    }

    if (sortField) {
      if (
        sortField.para === "startedDate" ||
        sortField.para === "finishedDate"
      ) {
      }
      if (sortField.dir === "up") {
        renderData.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1));
      } else {
        renderData.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1)).reverse();
      }
    }

    renderData = renderData.sort((a, b) => (a.isActive < b.isActive ? 1 : -1));
  }

  SortRenderData(sort);
  return (
    <>
      <div className="overview-project__header">
        <div className="overview-project__col01">
          <div className="overview-project__header-sort">
            <p>Project Code</p>
            <SortUpDownIcon param="project_code" ClickSort={handleClickSort} />
          </div>
        </div>
        <div className="overview-project__col03">
          <div className="overview-project__header-sort">
            <p>Project Name</p>
          </div>
        </div>
        <div className="overview-project__col01">
          <div className="overview-project__header-sort">
            <p>Members</p>
            <SortUpDownIcon param="members" ClickSort={handleClickSort} />
          </div>
        </div>
        <div className="overview-project__col01">
          <div className="overview-project__header-sort">
            <p>Hours</p>
            <SortUpDownIcon param="hours" ClickSort={handleClickSort} />
          </div>
        </div>
        <div className="overview-project__col01">
          <div className="overview-project__header-sort">
            <p>Created Date</p>
            <SortUpDownIcon param="created_date" ClickSort={handleClickSort} />
          </div>
        </div>
        <div className="overview-project__col01">
          <div className="overview-project__header-sort">
            <p>Finished Date</p>
            <SortUpDownIcon param="finished_date" ClickSort={handleClickSort} />
          </div>
        </div>
        <div className="overview-project__col01">
          <div className="overview-project__header-sort">
            <p>Status</p>
            <SortUpDownIcon param="status" ClickSort={handleClickSort} />
          </div>
        </div>
        <div className="overview-project__col01">
          <div className="overview-project__header-sort none__border">
            <p className="none__border">Detail</p>
          </div>
        </div>
      </div>
      <div className="overview-project__list">
        <List
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            overflow: "auto",
          }}
        >
          {renderData.map((project) => (
            <ProjectRowItem
              key={project.timesheetProjectId}
              projectData={project}
              clickDelete={handleClickDelete}
              handleChangeActiveProject={onChangeActiveProject}
              clickOnSubmit={handleClickSubmit}
              handleOnSubmit={handleOnSubmit}
            />
          ))}
        </List>
      </div>
    </>
  );
}

export default ProjectSummary;
