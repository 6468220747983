import React from "react";
ColumnTime.propTypes = {};

function ColumnTime({ hours, minutes, color = "gray" }) {
  return (
    <div className="column__time" style={{ color: color }}>
      <div className="column__time-hours">
        {isNaN(hours) ? "00" : hours}
        <div className="column__time-decoration">H</div>
      </div>
      <div className="column__time-minutes">
        {isNaN(minutes) ? "00" : minutes}
        <div className="column__time-decoration">M</div>
      </div>
    </div>
  );
}

export default ColumnTime;
