import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import TeamAPI from "B_API/AdminTeamAPI";

// createAsyncThunk cái này sử dụng cho login và register
export const getTeams = createAsyncThunk(
    "Team/GetTeams",
    async (payload) => {
        try {
            const response = await TeamAPI.getTeam(payload);
            return response.data;
        } catch (error) {
            console.log(error);
            return error.message;
        }
    }
);

const commonSlice = createSlice({
    name: "common",
    initialState: {
        teams: [],
        status: "idle",
        error: null,
    },
    reducers: {},
    extraReducers: {
        [getTeams.pending]: (state, action) => {
            state.status = "loading";
        },
        [getTeams.fulfilled]: (state, action) => {
            state.teams = action.payload;
            state.status = "succeeded";
        },
        [getTeams.rejected]: (state, action) => {
            state.status = action.error.message;
        },
    },
});

const { actions, reducer } = commonSlice;
export const { } = actions;
export default reducer;
