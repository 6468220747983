import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";
import HolidayManagementPopup from "../HolidayManagementPopup";
import moment from "moment";
import { useState } from "react";

function PublicHolidayTable(props) {
  const [holidayManagementPopup, setHolidayManagementPopup] = useState({
    show: false,
  });

  const [holidayInfo, setHolidayInfo] = useState({});

  const handleClose = () => {
    setHolidayManagementPopup({ show: false });
    props.onClose();
  };

  const columns = [
    {
      field: "id",
      headerName: "No",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      flex: 1,
      type: "text",
      hide: true,
    },
    {
      field: "no",
      headerName: "No",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      headerClassName: "MuiDataGrid-header",
      type: "text",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      flex: 5,
      width: 250,
    },
    {
      field: "fromDate",
      headerName: "Start Date",
      headerAlign: "center",
      headerClassName: "MuiDataGrid-header",
      type: "date",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      flex: 2,
      renderCell: (params) => {
        let value = params.value.replace("T", " ").substring(0, 11);
        return <div>{value}</div>;
      },
      width: 120,
    },
    {
      field: "toDate",
      headerName: "End Date",
      headerClassName: "MuiDataGrid-header",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      headerAlign: "center",
      type: "date",
      flex: 2,
      renderCell: (params) => {
        let value = params.value.replace("T", " ").substring(0, 11);
        return <div>{value}</div>;
      },
      width: 120,
    },
    {
      field: "month",
      headerName: "Month",
      headerClassName: "MuiDataGrid-header",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      headerAlign: "center",
      type: "text",
      flex: 2,
      renderCell: (params) => {
        let value = String(params.value).padStart(2, "0");
        return <div>{value}</div>;
      },
      minWidth: 50,
    },
    {
      field: "year",
      headerName: "Year",
      headerClassName: "MuiDataGrid-header",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      headerAlign: "center",
      type: "text",
      flex: 2,
      minWidth: 50,
    },
    {
      field: "edit",
      headerName: "Edit",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      minWidth: 100,
      sortable: false,
      cellClassName:
        "MuiDataGrid-tablecell MuiDataGrid-cell--textCenter list-ot__edit",
      flex: 1,
      renderCell: (params) => {
        const onClickEdit = async () => {
          const data = params.row;
          const dataInput = {
            ...data,
            fromDate: moment(data.fromDate),
            toDate: moment(data.toDate),
          };

          setHolidayInfo(dataInput);

          setHolidayManagementPopup({ show: true });
        };
        return (
          <div
            onClick={onClickEdit}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <EditIcon sx={{ color: "var(--bs-regal-blue)" }} />
          </div>
        );
      },
    },
  ];

  let popup = null;
  if (holidayManagementPopup.show === true) {
    popup = (
      <HolidayManagementPopup
        open={holidayManagementPopup.show}
        holidayInfo={holidayInfo}
        onClose={handleClose}
        isNew={false}
      />
    );
  }

  return (
    <>
      <DataGrid
        rows={props.publicHolidays}
        rowHeight={40}
        columns={columns}
        getRowId={(row) => row.publicHolidayOfYearId}
        pageSize={15}
        rowsPerPageOptions={[7]}
      />
      {popup}
      <CustomToastComponent />
    </>
  );
}

export default PublicHolidayTable;
