import React from 'react';
import { Grid, Stack, Typography, Box, Button } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

function MailExpired() {
    return (
        <Box>
            <Grid
                item
                spacing={3}
                sx={{
                    position: 'absolute',
                    height: 500,
                    top: '50%',
                    left: '50%',
                    width: '600px',
                    height: '100px',
                    marginTop: '-200px',
                    marginLeft: '-300px',
                }}
            >


                <Grid
                    item
                    xs={12}
                    sx={{
                        height: 200,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyItems: 'center',
                        alignItems: 'center',
                    }}
                >
                    <SentimentVeryDissatisfiedIcon align="center" style={{ fontSize: 100, color:'#e94353' }}></SentimentVeryDissatisfiedIcon>
                    <Typography marginTop={2} color="primary" variant="body1" sx={{ marginBottom: '20px', fontSize: '18px', marginTop: '40px' }}>
                        This link is expired. Please login to change status.
                    </Typography>
                    <Button component="a" href="https://app.ttdesignco.com/" disableElevation 
                            fullWidth size="large" type="submit" variant="contained" color="primary" sx={{ width: 200 }}>
                        Go To Home Page
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default MailExpired;