import { Box, Tab, Tabs } from "@mui/material";
import StorageKeys from "F_UTILS/constants/StorageKeys";
import UrlConstant from "F_UTILS/constants/UrlConstant";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import avatar from "X_ASSETS/a_Images/LogIn/avatar.jpg";
import AdvancedSetting from "./components/AdvancedSetting";
import SecuritySetting from "./components/SecuritySetting";
import UserProfileAccount from "./components/UserProfileAccount";
import "./UserInformationService.scss";
import UserSettingAPI from "B_API/UserSettingAPI";

UserInformation.propTypes = {};

const DUMMY_PROFILE_DATA = {
  avatar: avatar,
  name: "John Weak",
  phone: "012345",
  aboutMe: "T&T Design Co",
  email: "ttd@gmail.com",
  emailNotification: false,
  timeZone: "(UTC+8)",
  language: "Japanese",
};

function UserInformation(props) {
  const userCurrent = useSelector((state) => state.user.current);
  const location = useLocation();
  const [value, setValue] = useState(
    location.pathname.includes("settings") ? "security" : "profile"
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {}, [userCurrent, localStorage.getItem(StorageKeys.user)]);
  
  const listTImeZone = [
    "Almaty (UTC +6)",
    "BangKok, Ha Noi, Jakarta (UTC +7)",
    "HongKong, Manila, Singapore(UTC +8)",
  ];
  
  const listLanguage = ["English", "Japanese", "Vietnamese"];
  
  let initPropsObj = {
    userSettingId: 0,
    userId: JSON.parse(localStorage.getItem("user"))?.userId || 0,
    emailNotification: 0,
    timezone: listTImeZone[1],
    language: listLanguage[0],
  };
  

  const [advancedSetting, setAdvancedSetting] = useState(initPropsObj);

  useEffect(() =>{
    const fetchInitData = async ()=>
    {
      try{
        const response = await UserSettingAPI.GetUserSetting();
        if(response.status === 200){
          setAdvancedSetting (response.data)
        }
        else{
          console.log('fetch init failed!');
        }

      }catch(err){
        console.log('fetch init failed! ', err);
      }
    }
    fetchInitData();
  },[]);

  return (
    <>
      {/* <Routes>
              <Route path="/" element={<UserProfileAccount />} />
              <Route path="/settings" element={<UserSettings />} />
            </Routes> */}
      <div className="user-setting">
        <div className="user-setting__title">User Setting</div>
        <Box
          className="user-setting__body"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tabs
            sx={{
              "& .MuiTabs-flexContainer": {
              },
            }}
            className="user-setting__tabs"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              className={`tabs__tab ${
                value == "profile" ? "tabs__tab--active" : ""
              }`}
              label="Profile"
              value="profile"
            />
            <Tab
              className={`tabs__tab ${
                value == "security" ? "tabs__tab--active" : ""
              }`}
              label="Account and Security"
              value="security"
            />
            <Tab
              // disabled
              className={`tabs__tab ${
                value == "advanced" ? "tabs__tab--active" : ""
              }`}
              label="Advanced"
              value="advanced"
              // style={{ textDecoration: "line-through" }}
            />
          </Tabs>
          <Box className="user-setting__tab-panel">
            {value == "profile" ? (
              <UserProfileAccount
                avatar={`${UrlConstant.REACT_APP_DATABASE_URL}${userCurrent.avatar}`}
                name={userCurrent.fullName}
                phone={userCurrent.phoneNumber}
                aboutMe={userCurrent.aboutMe}
              />
            ) : null}
            {value == "security" ? (
              <SecuritySetting email={userCurrent.email} />
            ) : null}
            {value == "advanced" ? (
              <AdvancedSetting
              advancedSetting={advancedSetting}
              setAdvancedSetting = {setAdvancedSetting}
              listLanguage={listLanguage}
              listTImeZone={listTImeZone}
              />
            ) : null}
          </Box>
        </Box>
      </div>
    </>
  );
}

export default UserInformation;
