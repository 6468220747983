import axiosClient from "./axiosClient";

const AdminTimeSheetAPI = {
  //GET MEMBERS INIT
  async getMembersInit(params) {
    const url = `/Reports/GetMembersInActiveProjectByTeamId/${params.id}`;
    return axiosClient.get(url);
  },

  //GET HOURS INIT
  async getProjectHourInit(params) {
    const url = `/Reports/GetActiveProjectSummaryByTeamId/${params.id}`;
    return axiosClient.get(url);
  },

  //GET PROJECT LIST BY TEAM INIT
  async getProjectListInit(params) {
    const url = `/Reports/GetProjectSummaryByTeamId/${params.id}`;
    return axiosClient.get(url);
  },

  //GET PROJECT LIST BY TEAM INIT HAM MOI
  async GetProjectListByTeamId(params) {
    const url = `/Reports/GetProjectSummaryByTeamId/${params.id}`;
    return axiosClient.get(url);
  },

  //CATEGORY
  async getCategory(params) {
    const url = "/TimesheetCategory";
    return axiosClient.get(url);
  },

  async getCategoryByTeamId(teamId) {
    const url = `/TimesheetCategory/GetAllTimesheetCategoriesByTeamId/${teamId}`;
    return axiosClient.get(url);
  },

  async updateCategory(params) {
    const url = `/TimesheetCategory/${params.timesheetCategoryId}`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: {
        name: params.name,
      },
    });
  },

  async deleteCategory(timesheetCategoryId) {
    const url = `/TimesheetCategory/${timesheetCategoryId}`;
    return axiosClient.request({
      method: "DELETE",
      url: url,
    });
  },
  async createNewCategory(params) {
    const url = `/TimesheetCategory`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params,
    });
  },

  //OBJECT
  async getObject(params) {
    const url = "/TimesheetObject";
    return axiosClient.get(url);
  },
  async getObjectByTeamId(teamId) {
    const url = `/TimesheetObject/GetAllTimesheetObjectsByTeamId/${teamId}`;
    return axiosClient.get(url);
  },

  async updateObject(params) {
    const url = `/TimesheetObject/${params.timesheetObjectId}`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: {
        name: params.name,
      },
    });
  },

  async deleteObject(timesheetObjectId) {
    const url = `/TimesheetObject/${timesheetObjectId}`;
    return axiosClient.request({
      method: "DELETE",
      url: url,
    });
  },

  async createNewObject(params) {
    const url = `/TimesheetObject`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params,
    });
  },

  //PROJECT LIST
  async getListProject(params) {
    const url = `/TimesheetProject`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  //TEAM LIST
  async GetListTeamGroupProject(params) {
    const url = `/TeamProject/GetListViewTeamGroupProject`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  //TEAM LIST BY PROJECT ID
  async GetListViewTeamGroupProjectByTimeSheetProjectId(teamId) {
    const url = `/TeamProject/GetListViewTeamGroupProjectByTimeSheetProjectId/${teamId}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async CreateProjectTimeSheetId(data) {
    const url = `/TimeSheetProject`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: data,
    });
  },

  async UpdateProjectTimeSheetId(data, projectId) {
    const url = `/TimeSheetProject/${projectId}`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: data,
    });
  },

  async DeleteProjectTimeSheetId(projectId) {
    const url = `/TimeSheetProject/${projectId}`;
    return axiosClient.request({
      method: "DELETE",
      url: url,
    });
  },

  //POST GROUPTEAM INTO PROJECTID
  async UpdateMultipleTeamProject(data) {
    const url = `/TeamProject/UpdateMultipleTeamProject`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: data,
    });
  },

  async getTimesheetInfo(params) {
    const userId = JSON.parse(localStorage.getItem("user")).userId;

    const url = `/Timesheet/GetValidTimesheetByOrderNoAndDate/${userId}/${params}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  // Edit time of timesheet
  async editTimesheet(params) {
    const url = `/Timesheet/UpdateTimesheetByAdmin`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: params,
    });
  },

  // Lock edit time of timesheet
  async lockTimesheet(params) {
    const url = `/Timesheet/ToggleLockTimesheet`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: params,
    });
  },
};
export default AdminTimeSheetAPI;
