import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AdminTeamAPI from "B_API/AdminTeamAPI";
import * as React from "react";

function SelectionTeamAndGroup({
  width,
  height,
  listSelection,
  defaultIndex = 0,
  className,
  setListTeam,
  initGroupType,
  handleOnGroupChange,
  id,
}) {
  const handleChange = async (event) => {
    let value = event.target.value;

    const response = await AdminTeamAPI.getTeamOrGroup(value);
    if (setListTeam) setListTeam(response.data);
    handleOnGroupChange(value);
  };

  return (
    <Box className={className} sx={{ width: width }}>
      <FormControl fullWidth>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          id={id}
          sx={{
            fontWeight: "600 !important",
            color: "var(--bs-stratos)",
          }}
          value={initGroupType}
          onChange={handleChange}
        >
          <MenuItem sx={{ fontWeight: 600 }} value={0}>
            All
          </MenuItem>
          <MenuItem sx={{ fontWeight: 600 }} value={1}>
            Team
          </MenuItem>
          <MenuItem sx={{ fontWeight: 600 }} value={2}>
            Group
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default SelectionTeamAndGroup;
