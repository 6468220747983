// import PropTypes from "prop-types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  MenuList,
  Paper,
  Stack,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CustomizeCalendarPicker from "A_SHARED_COMPONENT/CustomizeCalendarPicker";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";
import CalendarAPI from "B_API/CalendarAPI";
import {
  addDays,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  format,
  getDay,
  getWeek,
  startOfMonth,
  subDays,
} from "date-fns";
import { getListViewUserCombineTeam } from "E_REDUX_STORE/commonSlice";
import UrlConstant from "F_UTILS/constants/UrlConstant";
import { ToastShowError, ToastShowSuccess } from "F_UTILS/Notify";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import classes from "./CalendarService.module.scss";
import DateCalendar from "./components/DateCalendar";
import NoteForm from "./components/NoteForm";
import UserMenuItem from "./components/UserMenuItem";
import UserSearch from "./components/UserSearch";

// CalendarService.propTypes = {};

function CalendarService(props) {
  const navigate = useNavigate();
  const { userId } = useParams();
  const dispatch = useDispatch();
  const combineUsers = useSelector((state) => state.common.combineUsers);
  const commonStatus = useSelector((state) => state.common.status);
  const currentUser = useSelector((state) => state.user.current);

  const getListUser = async function () {
    const action = getListViewUserCombineTeam();
    await dispatch(action);
  };

  const [calendarList, setCalendarList] = useState({
    list: [{}],
  });

  const getListCalendars = async function (params) {
    try {
      if (params?.userId) {
        const response =
          await CalendarAPI.getListVwCalendarAssignmentByUserIdAndMonth(params);

        if (response.status === 200) {
          setCalendarList({
            list: response.data,
          });
        }
      }
    } catch (error) {
      console.log(error);
      return error.message;
    }
  };

  //define date
  const [dayObj, setDay] = useState(new Date());
  // const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const weekDayShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const today = new Date();
  const startDayOfMonth = startOfMonth(dayObj);
  const endDayOfMonth = endOfMonth(dayObj);
  const daysOfMonth = eachDayOfInterval({
    start: startDayOfMonth,
    end: endDayOfMonth,
  });
  const eachSundayOfWeek = eachWeekOfInterval({
    start: startDayOfMonth,
    end: endDayOfMonth,
  });
  const weeks = eachSundayOfWeek.map((w) => {
    return getWeek(w, {
      weekStartsOn: 0,
      firstWeekContainsDate: 6,
    });
  });

  // //auth user
  // const [authUser, setCurrentUser] = useState(currentUser);

  //owner of calendar
  const [user, setUser] = useState({
    // userId: currentUser.userId || userId,
    // avatar: currentUser.avatar,
    // fullName: currentUser.fullName,
    // isAdmin: currentUser.role === "member" ? true : false
  });
  const [userList, setUserList] = useState({
    list: combineUsers,
  });

  // const [openCalendar, setOpenCalendar] = useState(false);

  const selectListBtnRef = useRef();
  const menuUserRef = useRef();
  const dateNameWrapRef = useRef();
  const [selectListValues, setSelectListValues] = useState({
    left: 0,
    top: 0,
    width: 300,
    isOpen: false,
  });

  //note calendar
  const [noteCalendar, setNoteCalendar] = useState({
    calendarId: null,
    date: null,
    userId: null,
    content: "",
    color: "success",
    isPublic: true,
  });

  const setNoteById = useCallback(
    (noteId) => {
      let note = calendarList.list.find((x) => x.calendarId === noteId);
      if (!!note) {
        setNoteCalendar({
          calendarId: note.calendarId,
          date: note.date,
          userId: note.userId,
          content: note.content,
          color: note.color,
          isPublic: note.isPublic,
          assignTo: note?.listUser,
          createdBy: note?.createdBy,
        });
      } else {
        setNoteCalendar({
          calendarId: null,
          date: null,
          userId: null,
          content: "",
          color: "success",
          isPublic: true,
          createdBy: currentUser?.userId,
          assignTo: note?.listUser,
        });
      }
    },
    [calendarList.list]
  );

  const setNoteByDate = useCallback((date) => {
    setNoteCalendar({
      calendarId: null,
      date: date,
      userId: null,
      content: "",
      color: "success",
      isPublic: true,
      createdBy: currentUser?.userId,
      assignTo: [],
    });
  }, []);

  //open modal form calendar
  const [openNoteForm, setOpenNoteForm] = useState(false);

  //function
  const changeCalendarHandle = useCallback(() => {
    //get list calendar on change
    getListCalendars({ month: format(dayObj, "MM"), userId: user.userId });
    let calendarValues = calendarList.list;
    if (currentUser.userId !== user.userId) {
      calendarValues = calendarList.list.filter(
        (x) => x.isPublic === true && x.userId === user.userId
      );
    }
    setCalendarList({ list: calendarValues });
  }, [currentUser, user, dayObj]);

  function handleCalendarNext(newDay) {
    //next month
    setDay(newDay);
  }

  function handleCalendarPrev(newDay) {
    //previous month
    setDay(newDay);
  }

  const handleCalendarChange = (newDay) => {
    setDay(newDay);
  };

  function toggleUserSelect() {
    const { offsetTop, offsetLeft, offsetHeight, offsetWidth } =
      selectListBtnRef.current;
    const menuUserWidth = menuUserRef.current
      ? menuUserRef.current.offsetWidth
      : 300;
    setSelectListValues({
      ...selectListValues,
      left: offsetLeft,
      top: offsetTop + offsetHeight,
      width:
        menuUserWidth -
        (menuUserWidth > 290
          ? offsetWidth + 32
          : menuUserWidth > 190
          ? offsetWidth / 2 + 10
          : 50),
      isOpen: !selectListValues.isOpen,
    });
  }

  const userSelectHandle = (user) => {
    setUser({
      userId: user.userId,
      avatar: user.avatar,
      fullName: user.fullName,
      isAdmin: user.isAdmin,
    });
    navigate(`/calendar/${user.userId}`);
  };

  const searchUserHandle = (userInput) => {
    setUserList({
      list: combineUsers.filter((x) =>
        x.fullName.toLowerCase().includes(userInput.toLowerCase())
      ),
    });
  };

  const toggleNoteFormHandle = (note) => {
    if (!!note) {
      setNoteById(note);
      setOpenNoteForm(!openNoteForm);
    }
  };

  const createNewNoteByDate = (date) => {
    setNoteByDate(date);
    setOpenNoteForm(!openNoteForm);
  };

  const changePrivacyHandle = (id) => {
    let item = calendarList.list.find((cd) => cd.calendarId === id);

    if (
      currentUser.userId === item.userId &&
      currentUser.userId === user.userId
    ) {
      item.isPublic = !item.isPublic;
      let dataSource = {
        calendar: item,
        listUserId: item.listUser.map((x) => x.userId),
      };
      updatePrivacyNote(dataSource);
    } else {
      return;
    }
  };

  const updatePrivacyNote = async (note) => {
    try {
      const response = await CalendarAPI.updateCalendarAndAssignment(note);
      getListCalendars({ month: format(dayObj, "MM"), userId: userId });
    } catch (error) {
      // setShowError({
      //   show: true,
      //   title: "Error",
      //   message: error,
      // });
      ToastShowError("Your note updated failed.");
    }
  };

  const updateNote = async (note) => {
    try {
      // console.log("NOTE UPDATE... ", note);
      setOpenNoteForm(!openNoteForm);
      const response = await CalendarAPI.updateCalendarAndAssignment(note);

      // toggleNoteFormHandle(note);

      if (response.status === 200) {
        // setShowSuccess({
        //   show: true,
        //   title: "Success",
        //   message: "Your note has been updated.",
        // });
        getListCalendars({ month: format(dayObj, "MM"), userId: userId });
        ToastShowSuccess("Your note has been updated.");
      } else {
        // setShowError({
        //   show: true,
        //   title: "Error",
        //   message: "Your note updated failed.",
        // });
        ToastShowError("Your note updated failed.");
      }
    } catch (error) {
      console.log("Error when update note ", error);
    }
  };

  const saveNoteHandle = (note, userListParam) => {
    setNoteCalendar({
      ...note,
    });
    let dataSource = {
      calendar: note,
      listUserId:
        userListParam?.length > 0 ? userListParam : [Number.parseInt(userId)],
    };

    updateNote(dataSource);
    // const callBackFunction = async () => {
    //   setShowConfirm({
    //     show: false,
    //     callback: null,
    //   });

    //   updateNote(dataSource);
    // };
    // setShowConfirm({ show: true, callback: callBackFunction });
  };

  const deleteNoteHandle = async (calendarId) => {
    // const callBackFunction = async () => {
    // setShowConfirm({
    //   show: false,
    //   callback: null,
    // });

    setOpenNoteForm(!openNoteForm);
    const response = await CalendarAPI.deleteCalendarById(calendarId);

    if (response.status === 200) {
      getListCalendars({ month: format(dayObj, "MM"), userId: user.userId });
      ToastShowSuccess("Your note has been deleted.");
    } else {
      ToastShowError("Your note delete failed.");
    }

    // setShowConfirm({
    //   show: true,
    //   description: "Do you want to delete this note?",
    //   callback: callBackFunction,
    // });
  };

  //close menu user selection when clicked away
  function clickAwaySelectUser() {
    if (selectListValues.isOpen) {
      setSelectListValues({ ...selectListValues, isOpen: false });
      setUserList({
        list: combineUsers,
      });
    }
  }

  useEffect(() => {
    changeCalendarHandle();
  }, [changeCalendarHandle]);

  useEffect(() => {
    const fetchData = async () => {
      if (combineUsers?.length == 0 && userList.list.length == 0) {
        getListUser();
      } else {
        setUserList({
          list: combineUsers,
        });
        if (!!userId) {
          let user = userList.list.find((x) => x.userId == userId);
          if (!!user) {
            setUser({
              userId: user.userId,
              avatar: user.avatar,
              fullName: user.fullName,
              isAdmin: user.isAdmin,
            });
          }
        }
      }
      if (userList.list.length == 0) {
        setUserList({
          list: combineUsers,
        });
      }
    };
    fetchData();
    return () => {};
  }, [commonStatus, dispatch, combineUsers]);

  useEffect(() => {
    getListCalendars({ month: format(dayObj, "MM"), userId: userId });
    if (!!userList.list) {
      let user = userList.list.find((x) => x.userId == userId);
      if (!!user) {
        setUser({
          userId: user.userId,
          avatar: user.avatar,
          fullName: user.fullName,
          isAdmin: user.isAdmin,
        });
      }
    }
  }, [userId]);

  return (
    <>
      <Grid className={classes["calendar-wrap"]} container>
        <Grid item lg={12} xs={12} sx={{ width: "" }}>
          <Card>
            <CardContent className={classes["calendar"]}>
              <div className={`${classes["calendar__header"]}`}>
                <div className={classes["user__wrap"]}>
                  <Stack
                    className={classes["user__container"]}
                    direction="row"
                    spacing={1}
                    ref={menuUserRef}
                  >
                    <Avatar
                      className={classes["user__avatar"]}
                      alt={user.fullName}
                      src={`${UrlConstant.REACT_APP_DATABASE_URL}${user?.avatar}`}
                    />
                    <div className={classes["user__text"]}>{user.fullName}</div>
                    <ClickAwayListener onClickAway={clickAwaySelectUser}>
                      <div>
                        <IconButton
                          aria-label="calendar"
                          className={`${classes["user__button"]} ${classes["button"]}`}
                          ref={selectListBtnRef}
                          onClick={toggleUserSelect}
                        >
                          <KeyboardArrowDownIcon
                            className={classes["button__icon"]}
                          />
                        </IconButton>
                        {selectListValues.isOpen ? (
                          <Box
                            className={classes["menu_user_wrap"]}
                            sx={{
                              "& > :not(style)": {
                                position: "absolute",
                                left:
                                  selectListValues.left -
                                  selectListValues.width,
                                top: selectListValues.top,
                              },
                            }}
                          >
                            <Paper
                              elevation={3}
                              className={classes["menu_user"]}
                            >
                              <UserSearch
                                onSearchInputChange={searchUserHandle}
                              />

                              <MenuList
                                className={`${classes["menu_user__container"]} ${classes["scrollable"]}`}
                              >
                                {userList.list.map((user) => (
                                  <UserMenuItem
                                    key={user.userId}
                                    user={user}
                                    onSelectUser={userSelectHandle}
                                  />
                                ))}
                              </MenuList>
                            </Paper>
                          </Box>
                        ) : null}
                      </div>
                    </ClickAwayListener>
                  </Stack>
                </div>
                <CustomizeCalendarPicker
                  dayObj={dayObj}
                  view={["month"]}
                  onCalendarPrevClick={handleCalendarPrev}
                  onCalendarNextClick={handleCalendarNext}
                  onCalendarChange={handleCalendarChange}
                />
                <div className={classes["none__wrap"]}></div>
              </div>
              <div className={classes["calendar__body"]}>
                <div className={classes["week__wrap"]}>
                  {weeks.map((w) => (
                    <div className={classes["week"]} key={w}>
                      <div className={classes["week__text"]}>{w}</div>
                    </div>
                  ))}
                </div>
                <div className={classes["date__wrap"]}>
                  <div
                    className={classes["date-name__wrap"]}
                    ref={dateNameWrapRef}
                  >
                    {weekDayShort.map((w) => (
                      <div className={classes["date-name"]} key={w}>
                        <div className={classes["date-name__text"]}>{w}</div>
                      </div>
                    ))}
                  </div>
                  {_.range(getDay(startDayOfMonth)).map((i) => (
                    <DateCalendar
                      key={i}
                      date={subDays(
                        startDayOfMonth,
                        getDay(startDayOfMonth) - i
                      )}
                      status="inactive"
                    />
                  ))}
                  {_.range(daysOfMonth.length).map((i) => (
                    <DateCalendar
                      key={i}
                      date={addDays(startDayOfMonth, i)}
                      status={
                        format(addDays(startDayOfMonth, i), "dd/MM/yyyy") ===
                        format(today, "dd/MM/yyyy")
                          ? "active"
                          : ""
                      }
                      listNote={
                        calendarList.list
                          ? calendarList.list.filter(
                              (data) =>
                                (!!data?.date
                                  ? format(new Date(data?.date), "yyyy/MM/dd")
                                  : format(new Date(), "yyyy/MM/dd")) ===
                                format(
                                  addDays(startDayOfMonth, i),
                                  "yyyy/MM/dd"
                                )
                            )
                          : []
                      }
                      user={currentUser.userId}
                      isAuth={currentUser.userId === user.userId}
                      changePrivacyHandler={changePrivacyHandle}
                      openNoteFormHandler={toggleNoteFormHandle}
                      createNewFormHander={createNewNoteByDate}
                    />
                  ))}
                  {_.range(6 - getDay(endDayOfMonth)).map((i) => (
                    <DateCalendar
                      key={i}
                      date={addDays(endDayOfMonth, i + 1)}
                      status="inactive"
                    />
                  ))}
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <div className="calendar"></div>
      </Grid>
      <NoteForm
        open={openNoteForm}
        noteCalendarInit={noteCalendar}
        calendarId={noteCalendar.calendarId}
        calendarList={calendarList?.list}
        userId={currentUser.userId}
        assignTo={noteCalendar.assignTo}
        userList={userList.list}
        isAdmin={currentUser.role === "member" ? true : false}
        onSaveNote={saveNoteHandle}
        onDeleteNote={deleteNoteHandle}
        onClose={toggleNoteFormHandle}
      />

      <CustomToastComponent />
    </>
  );
}

export default CalendarService;
