import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TimeSheetAPI from "B_API/TimeSheetAPI";

// createAsyncThunk cái này sử dụng cho login và register
export const getTimesheetByUserIdAndDate = createAsyncThunk(
  "Timesheet/GetTimesheetByUserIdAndDate",
  async (payload) => {
    try {
      const response = await TimeSheetAPI.getTimesheetInfo(payload);
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

export const getListTimesheetsByMonthAndYear = createAsyncThunk(
  "Timesheet/GetListTimesheetsByMonthAndYear",
  async (payload) => {
    try {
      const response =
        await TimeSheetAPI.GetListTimesheetByUserIdAndMonthAndYear(payload);
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

export const getListTimesheetTasksByMonthAndYear = createAsyncThunk(
  "TimesheetTask/GetListTimesheetTasksByMonthAndYear",
  async (payload) => {
    try {
      const response =
        await TimeSheetAPI.GetListTimesheetTaskByUserIdAndMonthAndYear(payload);
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

export const getListTimesheetTasksByTimesheetIdAndProjectId = createAsyncThunk(
  "TimesheetTask/GetListTimesheetTasksByTimesheetIdAndProjectId",
  async (payload) => {
    try {
      const response =
        await TimeSheetAPI.GetListVwTimesheetTaskByTimesheetIdAndProjectId(
          payload
        );
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

export const getListTaskSummaryHoursByMonthAndYear = createAsyncThunk(
  "TimesheetTask/getListTaskSummaryHoursByMonthAndYear",
  async (payload) => {
    try {
      const response =
        await TimeSheetAPI.GetListVwTimesheetTaskSummaryHoursByUserIdAndMonthAndYear(
          payload
        );
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

const timesheetSlice = createSlice({
  name: "timesheet",
  initialState: {
    current: {},
    timesheets: [],
    taskSummaryHours: [],
    tasks: [],
    taskDetails: [],
    status: "idle",
    userSelected: "",
    daySelected: null,
    error: null,
  },
  reducers: {
    changeUserSelected: (state, action) => {
      state.userSelected = action.payload;
    },
    changeDaySelected: (state, action) => {
      state.daySelected = action.payload;
    },
  },
  extraReducers: {
    [getTimesheetByUserIdAndDate.pending]: (state, action) => {
      state.status = "loading";
    },
    [getTimesheetByUserIdAndDate.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.status = "succeeded";
    },
    [getTimesheetByUserIdAndDate.rejected]: (state, action) => {
      state.status = action.error.message;
    },
    [getListTimesheetsByMonthAndYear.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListTimesheetsByMonthAndYear.fulfilled]: (state, action) => {
      state.timesheets = action.payload;
      state.status = "succeeded";
    },
    [getListTimesheetsByMonthAndYear.rejected]: (state, action) => {
      state.status = action.error.message;
    },
    [getListTimesheetTasksByMonthAndYear.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListTimesheetTasksByMonthAndYear.fulfilled]: (state, action) => {
      state.tasks = action.payload;
      state.status = "succeeded";
    },
    [getListTimesheetTasksByMonthAndYear.rejected]: (state, action) => {
      state.status = action.error.message;
    },
    [getListTaskSummaryHoursByMonthAndYear.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListTaskSummaryHoursByMonthAndYear.fulfilled]: (state, action) => {
      state.taskSummaryHours = action.payload;
      state.status = "succeeded";
    },
    [getListTaskSummaryHoursByMonthAndYear.rejected]: (state, action) => {
      state.status = action.error.message;
    },
    [getListTimesheetTasksByTimesheetIdAndProjectId.pending]: (
      state,
      action
    ) => {
      state.status = "loading";
    },
    [getListTimesheetTasksByTimesheetIdAndProjectId.fulfilled]: (
      state,
      action
    ) => {
      state.taskDetails = action.payload;
      state.status = "succeeded";
    },
    [getListTimesheetTasksByTimesheetIdAndProjectId.rejected]: (
      state,
      action
    ) => {
      state.status = action.error.message;
    },
  },
});

const { actions, reducer } = timesheetSlice;
export const { changeUserSelected, changeDaySelected } = actions;
export default reducer;
