import { GoogleSpreadsheet } from "google-spreadsheet";

//Trong list cac project thi ham nay su dung de filter va loai bo cac member, moi member chi xuat hien 1 lan duy nhat.

function getUnique(arr, comp) {
  const unique = arr
    .map((e) => e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
}

//Ham nay dung de tao ra list member fill o hang dau tien.

function getMemberNameList(divisionData, listHeaderInit) {
  let dataOfDivision = divisionData?.projectList;

  let listHeader = listHeaderInit;
  //Get list name all members nhung bi trung lap
  var listMemberData = dataOfDivision?.map((item) => item.memberList);
  let arrNameDuplicate = [];
  listMemberData?.forEach((array) => {
    arrNameDuplicate = arrNameDuplicate.concat(array);
  });
  // Xoa cac trung lap, chi giu lai moi ten 1 lan duy nhat
  let arrayNameUnique = getUnique(arrNameDuplicate, "memberId");
  // Doi Total xuong xuoi cung cua list
  arrayNameUnique.push(arrayNameUnique.shift());
  //Hoan thanh tao header cua bang
  arrayNameUnique?.forEach(function (item) {
    listHeader.push(item?.memberName);
  });
  return listHeader;
}

//Ham nay de get list project ghi vao cot dau tien

function createProjectList(divisionData) {
  let dataOfDivision = divisionData?.projectList;

  //Lay danh sach tat ca cac project
  let listProjectName = dataOfDivision?.map((item) => item.projectName);

  //Doi Project = "Office" xuong cuoi cung
  listProjectName?.push(listProjectName?.shift());

  return listProjectName;
}

export const appendSpreadsheet = async (data, sheetName, keyTeam) => {
  // Config variables
  const SPREADSHEET_ID = keyTeam?.spreadsheetId;
  const SHEET_ID = keyTeam?.sheetId;
  const CLIENT_EMAIL = keyTeam?.clientEmail;
  const PRIVATE_KEY = keyTeam?.privateKey.replace(/\\n/g, "\n").trim();

  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

  var divisonListObject = Object.values(data)[0];
  var divisonListArr = Object.values(divisonListObject)[0];

  console.log(" divisonListArr ", divisonListArr);
  var RDData = divisonListArr?.find(
    (divison) => divison.divisionName === "R&D"
  );
  var CIM1Data = divisonListArr?.find(
    (divison) => divison.divisionName === "CIM1"
  );
  var CIM2Data = divisonListArr?.find(
    (divison) => divison.divisionName === "CIM2"
  );
  var BIMData = divisonListArr?.find(
    (divison) => divison.divisionName === "BIM"
  );

  var listCIM1HeaderInit = ["Project", "Divison"];
  var listCIM2HeaderInit = ["Project", "Divison"];
  var listBIMHeaderInit = ["Project", "Divison"];
  var listRDHeaderInit = ["Project", "Divison"];

  let listRDHeader = getMemberNameList(RDData, listRDHeaderInit);
  let listBIMHeader = getMemberNameList(BIMData, listBIMHeaderInit);
  let listCIM1Header = getMemberNameList(CIM1Data, listCIM1HeaderInit);
  let listCIM2Header = getMemberNameList(CIM2Data, listCIM2HeaderInit);

  let listBIMProject = createProjectList(BIMData);
  let listCIM1Project = createProjectList(CIM1Data);
  let listCIM2Project = createProjectList(CIM2Data);

  let listRDProject = createProjectList(RDData);

  await doc.useServiceAccountAuth({
    client_email: CLIENT_EMAIL,
    private_key: PRIVATE_KEY,
  });
  // loads document properties and worksheets
  await doc.loadInfo();

  // const sheet = doc.sheetsById[SHEET_ID];
  // // copy a new sheet
  // sheet.copyToSpreadsheet("1ohJWN27aOYnqEm0EF5WepH2xI8tdm5T99YUTvJbTPgs");
  // let lastSheet = doc.sheetCount;

  // const sheetActive = doc.sheetsByIndex[lastSheet - 1];
  const sheetActive = doc.sheetsById[SHEET_ID];
  await sheetActive.clear();

  await sheetActive.loadCells("A1:Z100");

  for (let i = 0; i < 90; i++) {
    let index = 1 + i;
    for (let j = 0; j < 16; j++) {
      let column = String.fromCharCode(j + 97).toUpperCase();

      //format border
      sheetActive.getCellByA1(`${column}${index}`).borders = {
        top: { style: "DOTTED" },
        right: { style: "DOTTED" },
        bottom: { style: "DOTTED" },
        left: { style: "DOTTED" },
      };

      sheetActive.getCellByA1(`${column}${index}`).backgroundColor = {
        red: 1,
        green: 1,
        blue: 1,
      };
    }
  }
  if (listCIM1Project !== undefined) {
    if (listCIM1Project?.length > 0) {
      //Ghi dong Header dau tien
      for (let i = 1; i < listCIM1Header?.length + 1; i++) {
        let cellActive = `${String.fromCharCode(i + 96).toUpperCase()}1`;
        sheetActive.getCellByA1(cellActive).value = listCIM1Header[i - 1];

        sheetActive.getCellByA1(cellActive).backgroundColor = {
          red: 0.74,
          green: 0.84,
          blue: 0.93,
        };

        sheetActive.getCellByA1(cellActive).backgroundColor = {
          red: 0.74,
          green: 0.84,
          blue: 0.93,
        };

        sheetActive.getCellByA1(cellActive).borders = {
          top: { style: "SOLID" },
          right: { style: "SOLID" },
          bottom: { style: "SOLID" },
          left: { style: "SOLID" },
        };
      }
      //Ghi cot project
      for (let i = 1; i < listCIM1Project?.length + 1; i++) {
        let projectName = listCIM1Project[i - 1];
        sheetActive.getCellByA1(`A${i + 1}`).value = projectName;
        sheetActive.getCellByA1(`B${i + 1}`).value = "CIM1";
      }

      for (let i = 1; i < listCIM1Project?.length + 1; i++) {
        let projectName = listCIM1Project[i - 1];
        let listMemberInProject = CIM1Data.projectList.filter(
          (project) => project.projectName === projectName
        )[0].memberList;

        //Kiem tra neu ten = ten Header thi se write data
        listCIM1Header.forEach((name) => {
          let cellIndex = `${String.fromCharCode(
            listCIM1Header.indexOf(name) + 97
          ).toUpperCase()}${i + 1}`;
          listMemberInProject.forEach((item) => {
            sheetActive.getCellByA1(cellIndex).borders = {
              top: { style: "SOLID" },
              right: { style: "SOLID" },
              bottom: { style: "SOLID" },
              left: { style: "SOLID" },
            };

            if (item.memberName === name) {
              if (name === "Total") {
                sheetActive.getCellByA1(cellIndex).backgroundColor = {
                  red: 1,
                  green: 1,
                  blue: 0,
                };
              }
              sheetActive.getCellByA1(cellIndex).value =
                item.normalTime + item.ot;
            }
          });
        });
      }
    }
  }

  //CIM 2 TEAM
  const CIM2Index = listCIM1Project?.length + 4 || 1;

  if (listCIM2Project !== undefined) {
    if (listCIM2Project?.length > 0) {
      //Ghi dong Header dau tien
      for (let i = 1; i < listCIM2Header?.length + 1; i++) {
        let cellIndex = `${String.fromCharCode(
          i + 96
        ).toUpperCase()}${CIM2Index}`;

        sheetActive.getCellByA1(cellIndex).value = listCIM2Header[i - 1];

        sheetActive.getCellByA1(cellIndex).backgroundColor = {
          red: 0.74,
          green: 0.84,
          blue: 0.93,
        };

        sheetActive.getCellByA1(cellIndex).borders = {
          top: { style: "SOLID" },
          right: { style: "SOLID" },
          bottom: { style: "SOLID" },
          left: { style: "SOLID" },
        };
      }
      //Ghi cot project
      for (let i = 1; i < listCIM2Project?.length + 1; i++) {
        let projectName = listCIM2Project[i - 1];
        sheetActive.getCellByA1(`A${i + CIM2Index}`).value = projectName;
        sheetActive.getCellByA1(`B${i + CIM2Index}`).value = "CIM2";
      }

      for (let i = 1; i < listCIM2Project?.length + 1; i++) {
        let projectName = listCIM2Project[i - 1];
        let listMemberInProject = CIM2Data.projectList.filter(
          (project) => project.projectName === projectName
        )[0].memberList;

        //Kiem tra neu ten = ten Header thi se write data
        listCIM2Header.forEach((name) => {
          let cellIndex = `${String.fromCharCode(
            listCIM2Header.indexOf(name) + 97
          ).toUpperCase()}${i + CIM2Index}`;
          listMemberInProject.forEach((item) => {
            sheetActive.getCellByA1(cellIndex).borders = {
              top: { style: "SOLID" },
              right: { style: "SOLID" },
              bottom: { style: "SOLID" },
              left: { style: "SOLID" },
            };
            if (item.memberName === name) {
              if (name === "Total") {
                sheetActive.getCellByA1(cellIndex).backgroundColor = {
                  red: 1,
                  green: 1,
                  blue: 0,
                };
              }
              sheetActive.getCellByA1(cellIndex).value =
                item.normalTime + item.ot;

              sheetActive.getCellByA1(cellIndex).textFormat = {
                foregroundColor: {
                  red: 1,
                },
              };
            }
          });
        });
      }
    }
  }

  //BIM TEAM
  const BIMIndex = CIM2Index + listCIM2Project?.length + 3 || 1;

  if (listBIMProject !== undefined) {
    if (listBIMProject?.length > 0) {
      //Ghi dong Header dau tien
      for (let i = 1; i < listBIMHeader?.length + 1; i++) {
        let cellIndex = `${String.fromCharCode(
          i + 96
        ).toUpperCase()}${BIMIndex}`;

        sheetActive.getCellByA1(cellIndex).value = listBIMHeader[i - 1];

        sheetActive.getCellByA1(cellIndex).backgroundColor = {
          red: 0.74,
          green: 0.84,
          blue: 0.93,
        };

        sheetActive.getCellByA1(cellIndex).borders = {
          top: { style: "SOLID" },
          right: { style: "SOLID" },
          bottom: { style: "SOLID" },
          left: { style: "SOLID" },
        };
      }
      //Ghi cot project
      for (let i = 1; i < listBIMProject?.length + 1; i++) {
        let projectName = listBIMProject[i - 1];
        sheetActive.getCellByA1(`A${i + BIMIndex}`).value = projectName;
        sheetActive.getCellByA1(`B${i + BIMIndex}`).value = "BIM";
      }

      for (let i = 1; i < listBIMProject?.length + 1; i++) {
        let projectName = listBIMProject[i - 1];
        let listMemberInProject = BIMData.projectList.filter(
          (project) => project.projectName === projectName
        )[0].memberList;

        //Kiem tra neu ten = ten Header thi se write data
        listBIMHeader.forEach((name) => {
          let cellIndex = `${String.fromCharCode(
            listBIMHeader.indexOf(name) + 97
          ).toUpperCase()}${i + BIMIndex}`;
          listMemberInProject.forEach((item) => {
            sheetActive.getCellByA1(cellIndex).borders = {
              top: { style: "SOLID" },
              right: { style: "SOLID" },
              bottom: { style: "SOLID" },
              left: { style: "SOLID" },
            };
            if (item.memberName === name) {
              if (name === "Total") {
                sheetActive.getCellByA1(cellIndex).backgroundColor = {
                  red: 1,
                  green: 1,
                  blue: 0,
                };
              }
              sheetActive.getCellByA1(cellIndex).value =
                item.normalTime + item.ot;

              sheetActive.getCellByA1(cellIndex).textFormat = {
                foregroundColor: {
                  red: 1,
                },
              };
            }
          });
        });
      }
    }
  }

  //R&D Team
  const RDIndex = BIMIndex + listBIMProject?.length + 3 || 1;

  if (listRDProject?.length > 0) {
    //Ghi dong Header dau tien
    for (let i = 1; i < listRDHeader?.length + 1; i++) {
      let cellIndex = `${String.fromCharCode(i + 96).toUpperCase()}${RDIndex}`;
      sheetActive.getCellByA1(cellIndex).value = listRDHeader[i - 1];

      sheetActive.getCellByA1(cellIndex).backgroundColor = {
        red: 0.74,
        green: 0.84,
        blue: 0.93,
      };

      sheetActive.getCellByA1(cellIndex).borders = {
        top: { style: "SOLID" },
        right: { style: "SOLID" },
        bottom: { style: "SOLID" },
        left: { style: "SOLID" },
      };
    }
    //Ghi cot project
    for (let i = 1; i < listRDProject?.length + 1; i++) {
      let projectName = listRDProject[i - 1];
      sheetActive.getCellByA1(`A${i + RDIndex}`).value = projectName;
      sheetActive.getCellByA1(`B${i + RDIndex}`).value = "R&D";
    }

    for (let i = 1; i < listRDProject?.length + 1; i++) {
      let projectName = listRDProject[i - 1];
      let listMemberInProject = RDData.projectList.filter(
        (project) => project.projectName === projectName
      )[0].memberList;

      //Kiem tra neu ten = ten Header thi se write data
      listRDHeader.forEach((name) => {
        let cellIndex = `${String.fromCharCode(
          listRDHeader.indexOf(name) + 97
        ).toUpperCase()}${i + RDIndex}`;

        listMemberInProject.forEach((item) => {
          sheetActive.getCellByA1(cellIndex).borders = {
            top: { style: "SOLID" },
            right: { style: "SOLID" },
            bottom: { style: "SOLID" },
            left: { style: "SOLID" },
          };

          if (item.memberName === name) {
            if (name === "Total") {
              sheetActive.getCellByA1(cellIndex).backgroundColor = {
                red: 1,
                green: 1,
                blue: 0,
              };
            }
            sheetActive.getCellByA1(cellIndex).textFormat = {
              foregroundColor: {
                red: 1,
              },
            };

            sheetActive.getCellByA1(cellIndex).value =
              item.normalTime + item.ot;
          }
        });
      });
    }
  }

  //Format sheet
  for (let i = 1; i < 100; i++) {
    for (let j = 1; j < 27; j++) {
      let columnName = String.fromCharCode(j + 96).toUpperCase();
      let cellCurrent = sheetActive.getCellByA1(`${columnName}${i}`);

      //set text o giua o theo 2 truc
      cellCurrent.verticalAlignment = "MIDDLE";
      if (columnName !== "A") {
        cellCurrent.horizontalAlignment = "CENTER";
      }

      //set chu mau do
      if (
        columnName === "A" ||
        columnName === "B" ||
        i === 1 ||
        i === BIMIndex ||
        i === RDIndex
      ) {
        cellCurrent.textFormat = {
          fontFamily: "Arial",
          fontSize: 10,
          bold: true,
        };
      } else {
        cellCurrent.textFormat = {
          foregroundColor: { red: 1 },
          fontFamily: "Arial",
          fontSize: 10,
          bold: true,
        };
      }
    }
  }

  await sheetActive.saveUpdatedCells(); // saves both cells in one API call

  // Change name  of sheet
  sheetActive.updateProperties({
    title: sheetName,
    tabColor: {
      blue: 0.8,
    },
  });
  await sheetActive.saveUpdatedCells(); // saves both cells in one API call

  // writeData(
  //   sheetActive,
  //   1,
  //   CIMData,
  //   getMemberNameList(CIMData),
  //   createProjectList(CIMData)
  // );

  const url = `https://docs.google.com/spreadsheets/d/1ohJWN27aOYnqEm0EF5WepH2xI8tdm5T99YUTvJbTPgs/edit#gid=${sheetActive.sheetId}`;
  window.open(url);
};
