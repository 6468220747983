// @ts-nocheck
import axiosClient from "./axiosClient";

const AdminTeamAPI = {
  async getTeam() {
    const url = "/Team";
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async getTeamOrGroup(id) {
    const url = `/Team/GetVwTeamByFilter/${id}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async createTeamOrGroup(params) {
    const url = `/Team/`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params,
    });
  },

  async changeTeamOrGroupInfo(params) {
    const url = `/Team/${params.teamId}`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: params,
    });
  },
};
export default AdminTeamAPI;
