import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import background from "X_ASSETS/a_Images/LogIn/LoginBackground.png";
import logo from "X_ASSETS/a_Images/shared/TTDLogo2.jpg";
import ResetPassForm from "./components/ResetPassForm";
import SignInForm from "./components/SignInForm";
import "./LogInServices.scss";
LogInService.propTypes = {};

function LogInService() {
  const [modeSignIn, setModeSignIn] = useState(true);

  const changeModeReset = () => {
    setModeSignIn(false);
  };
  const changeModeLogin = () => {
    setModeSignIn(true);
  };

  useEffect(() => {
    return () => {

    }
  }, []);


  return (
    <div
      className="login__background"
      style={{
        background: `linear-gradient(0, rgb(0 0 0 / 81%), rgb(175 175 175 / 25%)), url(${background})`,
      }}
    >
      <Paper className="login__card" elevation={4}>
        <div className="login__card-logo">
          <img src={logo} alt="ttd-logo" />
          <p>{"T&T Design"}</p>
        </div>
        {!modeSignIn && <ResetPassForm changeModeLogin={changeModeLogin} />}
        {modeSignIn && <SignInForm changeModeReset={changeModeReset} />}
      </Paper>
    </div>
  );
}

export default LogInService;
