import * as React from "react";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";

export default function CheckboxesGroup(props) {
  const { initObject, changeFilters } = props;
  const [filterObject, setFilterObject] = React.useState(initObject);

  if (!initObject) return;

  const handleChange = (event) => {
    console.clear();

    const name = event.target.name;
    const value = event.target.checked;

    const newFilterObject = [...filterObject];
    const index = newFilterObject.findIndex((item) => item.name === name);
    newFilterObject[index].value = value;

    const checkAllCheked = newFilterObject.some(
      (item) => item.name === "All" && item.value === true
    );

    const checkOthers = newFilterObject
      .filter((item) => item.name !== "All")
      .every((item) => item.value === true);

    let listCheckAll = newFilterObject.map((item) => ({
      ...item,
      value: true,
    }));

    let listUncheckAll = newFilterObject.map((item) => ({
      ...item,
      value: false,
    }));
    //Kiem tra co phai la check all hay khong
    if (name === "All") {
      //Neu checkbox checkall duoc check thi check tat ca checkbox
      if (checkAllCheked) {
        setFilterObject(listCheckAll);
        if (changeFilters) changeFilters(listCheckAll);
        //Nguoc lai bo check tat ca checkbox
      } else {
        setFilterObject(listUncheckAll);
        if (changeFilters) changeFilters(listUncheckAll);
      }
    } else {
      //Neu khong phai la check all thi se kiem tra xem 4 check box co dang duoc check hay khong, neu co se checkall
      if (checkOthers) {
        setFilterObject(listCheckAll);
        if (changeFilters) changeFilters(listCheckAll);
        //Neu khong thi set binh thuong
      } else {
        let newFilterObjectSet = newFilterObject.map((item) =>
          item.name === "All" ? { ...item, value: false } : item
        );
        setFilterObject(newFilterObjectSet);
        if (changeFilters) changeFilters(newFilterObjectSet);
      }
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <FormControl
        component="div"
        className="shared__checkbox-formControl"
        variant="standard"
      >
        <FormGroup>
          {filterObject.map((item, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={item.value}
                  onChange={handleChange}
                  name={item.name}
                  size="small"
                  sx={{ p: 1, ml: 2 }}
                />
              }
              label={item.name}
              labelPlacement="start"
              sx={{ display: "flex", justifyContent: "space-between", ml: 0 }}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Box>
  );
}
