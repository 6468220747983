import React from "react";
import PropTypes from "prop-types";
import { Paper } from "@mui/material";
import { height, width } from "@mui/system";
import "./../shared_component.scss";
TagContent.propTypes = {};

function TagContent({
  title = "TITLE",
  width = "400px",
  height = "130px",
  marginTop = "0px",
  marginBtn = "0px",
  marginLeft = "0px",
  marginRight = "0px",
  lineHeight = "100%",
  lineColor,
  children,
  dataFlow = "DownToTop",
  id,
}) {
  const styleObject = {
    width: width,
    height: height,
    marginTop: marginTop,
    marginBtn: marginBtn,
    marginLeft: marginLeft,
    marginRight: marginRight,
  };

  return (
    <Paper
      style={styleObject}
      elevation={3}
      square
      className="tag__content"
      id={id}
    >
      <div
        className="tag__content-leftline"
        style={{ height: lineHeight, backgroundColor: lineColor }}
      ></div>
      <div
        className="tag__content-right"
        style={{
          justifyContent:
            dataFlow === "TopToDown" ? "flex-start" : "space-between",
        }}
      >
        <div className="tag__content-title">{title}</div>
        <div className="tag__content-data">{children}</div>
      </div>
    </Paper>
  );
}

export default TagContent;
