import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TeamAPI from "B_API/AdminTeamAPI";
import AdminUserAPI from "B_API/AdminUserAPI";
import TimeSheetAPI from "B_API/TimeSheetAPI";

// createAsyncThunk cái này sử dụng cho login và register
export const getTeams = createAsyncThunk("Team/GetTeams", async (payload) => {
  try {
    const response = await TeamAPI.getTeam(payload);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.message;
  }
});

export const getListViewUserCombineTeam = createAsyncThunk(
  "Users/getListViewUserCombineTeam",
  async (payload) => {
    try {
      const response = await AdminUserAPI.getListViewUserCombineTeam();
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

export const getAllTimesheetProjectsByUserId = createAsyncThunk(
  "TimesheetProject/GetAllTimesheetProjectsByUserId",
  async (payload) => {
    try {
      const response = await TimeSheetAPI.GetAllTimesheetProjectsByUserId(
        payload
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

export const getTimesheetObjectsByTeamId = createAsyncThunk(
  "TimesheetObject/getTimesheetObjectsByTeamId",
  async (payload) => {
    try {
      const response = await TimeSheetAPI.GetAllTimesheetObjectsByTeamId(
        payload
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

export const getTimesheetCategoriesByTeamId = createAsyncThunk(
  "TimesheetCategory/getTimesheetCategoriesByTeamId",
  async (payload) => {
    try {
      const response = await TimeSheetAPI.GetAllTimesheetCategoriesByTeamId(
        payload
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

const commonSlice = createSlice({
  name: "common",
  initialState: {
    teams: [],
    combineUsers: [],
    timesheetProjects: [],
    timesheetCategories: [],
    timesheetObjects: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: {
    [getTeams.pending]: (state, action) => {
      state.status = "loading";
    },
    [getTeams.fulfilled]: (state, action) => {
      state.teams = action.payload;
      state.status = "succeeded";
    },
    [getTeams.rejected]: (state, action) => {
      state.status = action.error.message;
    },
    [getListViewUserCombineTeam.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListViewUserCombineTeam.fulfilled]: (state, action) => {
      state.combineUsers = action.payload;
      state.status = "succeeded";
    },
    [getListViewUserCombineTeam.rejected]: (state, action) => {
      state.status = action.error.message;
    },
    [getAllTimesheetProjectsByUserId.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllTimesheetProjectsByUserId.fulfilled]: (state, action) => {
      state.timesheetProjects = action.payload;
      state.status = "succeeded";
    },
    [getAllTimesheetProjectsByUserId.rejected]: (state, action) => {
      state.status = action.error.message;
    },
    [getTimesheetObjectsByTeamId.pending]: (state, action) => {
      state.status = "loading";
    },
    [getTimesheetObjectsByTeamId.fulfilled]: (state, action) => {
      state.timesheetObjects = action.payload;
      state.status = "succeeded";
    },
    [getTimesheetObjectsByTeamId.rejected]: (state, action) => {
      state.status = action.error.message;
    },
    [getTimesheetCategoriesByTeamId.pending]: (state, action) => {
      state.status = "loading";
    },
    [getTimesheetCategoriesByTeamId.fulfilled]: (state, action) => {
      state.timesheetCategories = action.payload;
      state.status = "succeeded";
    },
    [getTimesheetCategoriesByTeamId.rejected]: (state, action) => {
      state.status = action.error.message;
    },
  },
});

const { actions, reducer } = commonSlice;
export const {} = actions;
export default reducer;
