import React from "react";
import PropTypes from "prop-types";

ProjectGroupItem.propTypes = {};

function ProjectGroupItem({ group, isAdded, onClickAdd }) {
  const handleClickAdd = (group) => {
    if (onClickAdd) onClickAdd(group);
  };

  const AddIcon = (
    <i className="fas fa-plus-circle" style={{ color: "var(--bs-new-green)" }}></i>
  );
  const RemoveIcon = (
    <i className="fas fa-minus-circle" style={{ color: "var(--bs-new-red)" }}></i>
  );
  return (
    <div
      className="project-group__item"
      style={{ fontWeight: !!isAdded ? 600 : 400, fontStyle: "italic" }}
    >
      <div className="project-group__col02">{group.teamName}</div>
      <div className="project-group__col01">{group.members}</div>
      <div
        className="project-group__col01"
        onClick={() => handleClickAdd(group)}
      >
        {!!isAdded ? RemoveIcon : AddIcon}
      </div>
    </div>
  );
}

export default ProjectGroupItem;
