import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CalendarAPI from "B_API/CalendarAPI";

// createAsyncThunk cái này sử dụng cho login và register
export const GetListVwCalendarAssignmentByUserId = createAsyncThunk(
  "Calendar/GetListVwCalendarAssignmentByUserId",
  async (payload) => {
    try {
      const response = await CalendarAPI.getListVwCalendarAssignmentByUserId();
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

export const GetListVwCalendarAssignmentByUserIdAndMonth = createAsyncThunk(
  "Calendar/GetListVwCalendarAssignmentByUserIdAndMonth",
  async (payload) => {
    try {
      const response =
        await CalendarAPI.getListVwCalendarAssignmentByUserIdAndMonth(payload);
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    calendars: [{}],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: {
    [GetListVwCalendarAssignmentByUserId.pending]: (state, action) => {
      state.status = "loading";
    },
    [GetListVwCalendarAssignmentByUserId.fulfilled]: (state, action) => {
      state.calendars = action.payload;
      state.status = "succeeded";
    },
    [GetListVwCalendarAssignmentByUserId.rejected]: (state, action) => {
      state.status = action.error.message;
    },
    [GetListVwCalendarAssignmentByUserIdAndMonth.pending]: (state, action) => {
      state.status = "loading";
    },
    [GetListVwCalendarAssignmentByUserIdAndMonth.fulfilled]: (
      state,
      action
    ) => {
      state.calendars = action.payload;
      state.status = "succeeded";
    },
    [GetListVwCalendarAssignmentByUserIdAndMonth.rejected]: (state, action) => {
      state.status = action.error.message;
    },
  },
});

const { actions, reducer } = calendarSlice;
export const {} = actions;
export default reducer;
