import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, List } from "@mui/material";
import CreateNewFolder from "@mui/icons-material/CreateNewFolder";
import ProjectGroupItem from "./ProjectGroupItem";
import AdminTimeSheetAPI from "B_API/AdminTimesheetAPI";
import { useNavigate } from "react-router-dom";

ProjectGroupList.propTypes = {};

function ProjectGroupList({ changeAddGroup, textSearch, projectId }) {
  const navigate = useNavigate();
  var [listGroups, setListGroup] = useState([]);
  useEffect(() => {
    const fetchGetListTeamGroup = async () => {
      try {
        const response = await AdminTimeSheetAPI.GetListTeamGroupProject();
        setListGroup(response.data);
      } catch (error) {
        console.log("Error get Category " + error);
      }
    };
    fetchGetListTeamGroup();
  }, []);

  const paddingRight = listGroups.length > 3 ? "15px" : "0";
  const [listAdded, setListAdded] = useState([]);
  React.useEffect(() => {
    const fetchProjectGroupByProjectId = async () => {
      try {
        if (projectId) {
          const res =
            await AdminTimeSheetAPI.GetListViewTeamGroupProjectByTimeSheetProjectId(
              projectId
            );

          if (res.status === 200) {
            setListAdded(res.data);
          }
        }
      } catch (error) {
        console.log("Error with fetch team groups by projectid");
      }
    };
    fetchProjectGroupByProjectId();
  }, []);

  //Fetch tại đây
  // console.log("projectGroup ", projectGroup);

  const handleChangeAddGroup = (group) => {
    const newListAdded = [...listAdded];
    const index = newListAdded.findIndex((grp) => grp.teamId === group.teamId);
    if (index !== -1) {
      newListAdded.splice(index, 1);
    } else {
      newListAdded.push(group);
    }

    if (changeAddGroup) changeAddGroup(newListAdded);
    setListAdded(newListAdded);
  };

  let renderSorted = [...listGroups];
  if (textSearch && textSearch !== "") {
    renderSorted = renderSorted.filter((grp) =>
      grp.teamName.toLowerCase().includes(textSearch.toLowerCase())
    );
  }

  renderSorted = renderSorted.sort((a, b) =>
    listAdded.some((grp) => grp.teamId === a.teamId) ? -1 : 1
  );

  const handleClickNewGroup = () => {
    navigate("/admin/user");
  };
  return (
    <div className="project-group__list">
      <div
        className="project-group__header"
        style={{ paddingRight: paddingRight }}
      >
        <div className="project-group__col02">Group Name</div>
        <div className="project-group__col01">Members</div>
        <div className="project-group__col01">Add</div>
      </div>
      <div className="project-group__content">
        <List
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            overflow: "auto",
          }}
        >
          {renderSorted.map((group) => (
            <ProjectGroupItem
              key={group.teamId}
              group={group}
              isAdded={listAdded.some((grp) => grp.teamId === group.teamId)}
              onClickAdd={handleChangeAddGroup}
            />
          ))}
        </List>
      </div>
      <div className="project-group__footer">
        <Button
        className="button--new"
          variant="contained"
          style={{ width: "150px" }}
          color="success"
          startIcon={<CreateNewFolder />}
          onClick={handleClickNewGroup}
        >
          NEW GROUP
        </Button>
      </div>
    </div>
  );
}

export default ProjectGroupList;
