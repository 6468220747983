import { Divider, Menu, MenuItem } from "@mui/material";
import UserInformationPopup from "A_SHARED_COMPONENT/UserInformationPopup";
import AdminTeamAPI from "B_API/AdminTeamAPI";
import RoleAPI from "B_API/RolesAPI";
import UrlConstant from "F_UTILS/constants/UrlConstant";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./../shared_component.scss";

MemberItemStatus.propTypes = {};

function MemberItemStatus({
  user,
  setShowSuccessPopup,
  setShowError,
  listTeamInCompany,
  listRole,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleDialogClose = (value) => {
    setOpenDialog(false);
  };

  const currentUser = useSelector((state) => state.user.current);

  let userPopUp = null;
  if (openDialog === true) {
    userPopUp = (
      <UserInformationPopup
        listTeamInCompany={listTeamInCompany}
        userInfo={user}
        open={openDialog}
        onClose={handleDialogClose}
        setShowSuccessPopup={setShowSuccessPopup}
        setShowError={setShowError}
        disable={currentUser?.role === "Administrator" ? false : true}
        listRole={listRole}
      />
    );
  }

  let color = "gray";
  switch (user?.state) {
    case "available":
      color = "green";
      break;
    case "wfh":
      color = "rgb(2 136 209)";
      break;
    case "business":
      color = "yellow";
      break;
    case "busy":
      color = "red";
      break;
    case "unavailable":
      color = "gray";
      break;
    default:
      color = "gray";
      break;
  }

  const clickUserItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickViewInformation = () => {
    setOpenDialog(true);
  };

  const clickViewCalendar = () => {
    navigate(`/calendar/${user.userId}`);
  };
  const userMenu = (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: -2,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem onClick={clickViewInformation}>View Information</MenuItem>
      <Divider />
      <MenuItem onClick={clickViewCalendar}>View Calendar</MenuItem>
    </Menu>
  );

  return (
    <>
      <div className="memberitem__status" onClick={clickUserItem}>
        <div
          className="memberitem__status-avatar"
          style={{
            backgroundImage: `url(${UrlConstant.REACT_APP_DATABASE_URL}${user?.avatar})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div className="memberitem__status-name">{user?.fullName}</div>
        <div
          className="memberitem__status-icon"
          style={{ backgroundColor: color }}
        ></div>
      </div>
      {userMenu}
      {userPopUp}
    </>
  );
}

export default MemberItemStatus;
