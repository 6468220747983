import React from "react";
import PropTypes from "prop-types";

MemberItem.propTypes = {};

function MemberItem({ user, isAdded, onClickAdd }) {
  const handleClickAdd = (user) => {
    if (onClickAdd) onClickAdd(user);
  };

  const AddIcon = (
    <i className="fas fa-plus-circle" style={{ color: "var(--bs-new-green)" }}></i>
  );
  const RemoveIcon = (
    <i className="fas fa-minus-circle" style={{ color: "var(--bs-new-red)" }}></i>
  );
  return (
    <div
      className="project-group__item"
      style={{ fontWeight: !!isAdded ? 600 : 400, fontStyle: "italic" }}
    >
      <div className="project-group__col02">{user.fullName}</div>
      <div className="project-group__col01">
        {user.role === "Administrator" ? "Admin" : "Member"}
      </div>
      <div
        className="project-group__col01"
        onClick={() => handleClickAdd(user)}
      >
        {!!isAdded ? RemoveIcon : AddIcon}
      </div>
    </div>
  );
}

export default MemberItem;
