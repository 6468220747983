import React from "react";
import PropTypes from "prop-types";
import "./AdminOverTimeService.scss";
import { Tabs } from "@material-ui/core";
import { Tab } from "@mui/material";
import OTRequestTab from "./components/RequestTab";
AdminOverTimeService.propTypes = {};

function AdminOverTimeService(props) {
  const [value, setValue] = React.useState("requestTab");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="admin__overtime">
      <div className="admin__overtime--navbar">
        <div className="navbar__column">
          <Tabs TabIndicatorProps={{style: {background:'var(--bs-regal-blue)', color:'red'}}}
           
          value={value} onChange={handleChange}>
            <Tab value="requestTab" label="Request" sx={{ fontWeight: 600 }} color='red'/>
            <Tab
              value="overViewTab"
              label="Overview"
              sx={{ fontWeight: 600 }}
              disabled
            />
          </Tabs>
        </div>
      </div>

      <div className="admin__overtime--content">
        <OTRequestTab />
      </div>
    </div>
  );
}

export default AdminOverTimeService;
