import axiosClient from "./axiosClient";

const AdminOverTimeAPI = {
  async getListOverTimeAdmin(status) {
    const teamId = JSON.parse(localStorage.getItem("user")).teamId;
    const url = `/Overtime/GetListViewOvertimesByTeamIdAndStatus/${teamId}/${status}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async getListOverTimeAdminByTeamIdAndStatus(teamId, status) {
    const url = `/Overtime/GetListViewOvertimesByTeamIdAndStatus/${teamId}/${status}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async getListOverTimeType(params) {
    const url = `/OvertimeType`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async postOverTimeRequest(params) {
    const url = "/Overtime";
    return axiosClient.request({
      method: "POST",
      url: url,

      data: params,
    });
  },

  async updateActualHour(fromDate, toDate) {
    const url = `/Overtime/UpdateActualHour/${fromDate}/${toDate}`;
    return axiosClient.request({
      method: "POST",
      url: url,
    });
  },

  async changeStatusOverTime(params) {
    const url = `/Overtime/ChangeStatusOvertimeById/${params.overtimeId}`;
    return axiosClient.request({
      method: "PUT",
      url: url,

      data: params,
    });
  },

  async putOverTimeRequest(params) {
    const url = `/Overtime/UpdateOvertimeRequest/${params.overtimeId}`;
    return axiosClient.request({
      method: "PUT",
      url: url,

      data: params,
    });
  },
};
export default AdminOverTimeAPI;
