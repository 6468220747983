import { useCallback, useEffect, useState } from "react";
import classes from "../TimeSheetService.module.scss";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import styled from "styled-components";

function TimeSheetItem(props) {
  const [projectName, setProjectName] = useState(
    props.projectName || "No Info"
  );
  const [hours, setHours] = useState(props.hours || 8.0);
  const [color, setColor] = useState(props.color || "info");

  const onPropsChange = useCallback(() => {
    setProjectName(props.projectName || "none");
    setHours(props.hours || 8.0);
    setColor(props.color || "info");
  }, [props]);

  useEffect(() => {
    onPropsChange();
  }, [onPropsChange]);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      border: "1px solid #dadde9",
    },
  }));

  return (
    <HtmlTooltip title={projectName} placement="top-end" disableFocusListener>
      <div
        className={`${classes["timesheet-item"]} ${
          classes["timesheet-item--" + color]
        }`}
      >
        <div className={classes["timesheet-item__text"]}>
          <div className={classes["text__project"]}>{projectName}</div>
          <div className={classes["text__time"]}>{hours}</div>
        </div>
      </div>
    </HtmlTooltip>
  );
}

export default TimeSheetItem;
