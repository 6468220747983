import store from "E_REDUX_STORE/store";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HashRouter, Route, Routes } from "react-router-dom";
import MailSuccess from "Z_SERVICES/n_MailSuccess";
import MailExpired from "Z_SERVICES/o_MailExpired";
import MailRequest from "Z_SERVICES/p_MailRequest";
import ErrorPage from "Z_SERVICES/q_ErrorPage";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <HashRouter>
        <Routes>
          <Route path="/*" element={<App />} />
          <Route path="/email/success" element={<MailSuccess />} />
          <Route path="/email/expired" element={<MailExpired />} />
          <Route
            path={`/mail-request/:notification/:baseUrl/:params`}
            element={<MailRequest />}
          />
          <Route
            path="/error"
            element={<ErrorPage/>}
          />
        </Routes>
      </HashRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
