import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as echarts from "echarts";
import AdminTimeSheetAPI from "B_API/AdminTimesheetAPI";
import AdminUserAPI from "B_API/AdminUserAPI";

MemberPipeChart.propTypes = {};

function MemberPipeChart({ currentTeamId, changeProjectActive }) {
  var dataProjectMembersInit = [
    {
      value: 0,
      name: "No Project",
    },
  ];

  var [dataProMembers, setPropMembers] = React.useState(dataProjectMembersInit);
  const [totalMember, setTotalMember] = useState(0);
  function getUnique(arr, comp) {
    const unique = arr
      .map((e) => e[comp])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }
  //Get MemberPipe Chart Init
  const [listAllUsers, setListAllUser] = useState([]);

  useEffect(() => {
    const fetchGetAllUsers = async () => {
      try {
        const response = await AdminUserAPI.getListUserCredentialByTeamId(
          currentTeamId || 0
        );
        setListAllUser(response.data);
      } catch (error) {
        console.log("Error get User List ", error);
      }
    };
    fetchGetAllUsers();
  }, [currentTeamId]);

  useEffect(() => {
    const fetchGetMemberListInit = async () => {
      try {
        var params = {
          id: currentTeamId,
        };

        const response = await AdminTimeSheetAPI.getMembersInit(params);

        var data = response.data.map((pro) => ({
          value: pro.numberOfMembers,
          name: `${pro.code} - ${pro.projectName}`,
        }));

        //get total member
        let listMember = [];
        response.data
          .map((pro) => pro.members)
          ?.forEach((array) => {
            listMember = listMember.concat(array);
          });
        //Xoa cac phan tu trung nhau
        let listMembersUnique = getUnique(listMember, "userId");

        setTotalMember(listMembersUnique.length);

        setPropMembers(data);
      } catch (error) {
        console.log("Error get MembersInit ", error);
      }
    };
    fetchGetMemberListInit();
  }, [currentTeamId, changeProjectActive]);

  useEffect(() => {
    var option = {
      title: {
        text: `Members In Team: ${totalMember}/${listAllUsers.length}`,
        left: "left",
      },
      color: [
        '#1f78b4',
        '#a6cee3',
        '#b2df8a',
        '#33a02c',
        '#fb9a99',
        '#e31a1c',
        '#fdc06f',
        '#ff7f00',
        '#cab2d6',
        '#6a3d9a',
        '#ffff99',
        '#b15928',
        '#a6cee3',
        '#1f78b4'
      ],   
      tooltip: {
        trigger: "item",
      },
      legend: {
        show: false,
      },
      series: [
        {
          name: "Members",
          type: "pie",
          center: ["50%", "55%"],
          radius: "70%",
          label: { formatter: "{b}\n{c} staff" },

          data: dataProMembers,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
    var chartDom = document.getElementById("admin__memberChart");
    var myChart = echarts.init(chartDom);
    option && myChart.setOption(option);
  }, [dataProMembers]);
  return (
    <>
      <div id="admin__memberChart" className="overview__pipechart"></div>
    </>
  );
}

export default MemberPipeChart;
