import React, { useContext } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ToastShowError, ToastShowSuccess } from "F_UTILS/Notify";
import AdminOverTimeAPI from "B_API/AdminOverTimeAPI";
import { useDispatch } from "react-redux";
import { getOTandLeavePending } from "E_REDUX_STORE/OTandLeaveCount";

import { FetchData } from "../index";

function ChangeStatusOverTime({ width, data, valueInit, id }) {
  const { fetchAllOverTimeRequest } = useContext(FetchData);

  const [selected, setSelected] = React.useState(valueInit || "All");

  const dispatch = useDispatch();

  const handleChange = async (event) => {
    let valueSelected = event.target.value;

    let status = 0;
    if (valueSelected === "Pending") {
      status = 1;
    } else if (valueSelected === "Approved") {
      status = 2;
    } else if (valueSelected === "Rejected") {
      status = 3;
    }

    let requestOT = { ...data, statusDefinitionId: status };

    try {
      const res = await AdminOverTimeAPI.changeStatusOverTime(requestOT);
      if (res.status === 200) {
        setSelected(valueSelected);
        ToastShowSuccess("Change status done.");
        fetchAllOverTimeRequest();
      }
    } catch (error) {
      console.log("Error change status of overtime request ", error);
      ToastShowError("Change status failed.");
    }

    const actions = getOTandLeavePending();
    await dispatch(actions);
  };

  return (
    <>
      <Box sx={{ width: width }}>
        <FormControl fullWidth>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id={id}
            sx={{
              fontWeight: "600 !important",
              paddingRight: "15px",
            }}
            value={selected}
            onChange={handleChange}
          >
            <MenuItem
              sx={{ fontWeight: 600, color: "#FF8C00" }}
              value="Pending"
            >
              <div className="status--pending">Pending</div>
            </MenuItem>
            <MenuItem
              sx={{ fontWeight: 600, color: "#28a745" }}
              value="Approved"
            >
              <div className="status--approved">Approved</div>
            </MenuItem>
            <MenuItem
              sx={{ fontWeight: 600, color: "#dc3545" }}
              value="Rejected"
            >
              <div className="status--rejected">Rejected</div>
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
}

export default ChangeStatusOverTime;
