import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";

import classes from "../TimeSheetService.module.scss";

function SelectedProjectItems({
  lockMode,
  initProp,
  listProject,
  handleProjectChange,
}) {
  const projecName = listProject.find(
    (pro) => pro.timesheetProjectId === initProp
  )?.name;

  const listProjectName = listProject.map((project) => project.name);

  const [selected, setSelected] = useState(projecName || listProject[0]?.name);

  const handleProjectChanged = (event, newValue) => {
    if (newValue) {
      setSelected(newValue);

      const projecIdChanged = listProject.find(
        (pro) => pro.name === newValue
      )?.timesheetProjectId;

      if (handleProjectChange) handleProjectChange(projecIdChanged);
    }
  };

  useEffect(() => {
    setSelected(projecName);
  }, [initProp]);

  return (
    <>
      <Autocomplete
        className={`${classes["group__input--project"]}`}
        sx={{
          marginTop: "10px",
          ".MuiOutlinedInput-root": {
            padding: "0 !important",
          },
        }}
        value={projecName ? `${projecName}` : ""}
        disabled={lockMode}
        options={listProjectName}
        renderInput={(params) => <TextField {...params} />}
        onChange={(event, newValue) => {
          handleProjectChanged(event, newValue);
        }}
      ></Autocomplete>
    </>
  );
}

export default SelectedProjectItems;
