function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}
export default function convertMsToHM(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;
  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}

export const calcTotalFingerPrint = (timeIn, timeOut) => {
  let dateIn = new Date(timeIn);
  let dateOut = new Date(timeOut);

  //FIX TIME IN TO 8H00
  if (dateIn.getHours() < 8) {
    dateIn = new Date(
      dateIn.getFullYear(),
      dateIn.getMonth(),
      dateIn.getDate(),
      8,
      0,
      0
    );
  }

  if (dateIn.getHours() === 12) {
    dateIn = new Date(
      dateIn.getFullYear(),
      dateIn.getMonth(),
      dateIn.getDate(),
      13,
      0,
      0
    );
  }

  //IF TIME OUT = 12 -> TO = 13 AND SUBTRACT 1
  if (dateOut.getHours() === 12) {
    dateOut = new Date(
      dateOut.getFullYear(),
      dateOut.getMonth(),
      dateOut.getDate(),
      13,
      0,
      0
    );
  }

  if (dateIn.getHours() < 12 && dateOut.getHours() < 12) {
    return dateOut - dateIn;
  } else if (dateIn.getHours() < 12 && dateOut.getHours() > 12) {
    return dateOut - dateIn - 60 * 60 * 1000;
  } else if (dateIn.getHours() > 12 && dateOut.getHours() > 12) {
    return dateOut - dateIn;
  } else {
    return null;
  }
};
