import { createSlice } from "@reduxjs/toolkit";

const StatusSlice = createSlice({
  name: "UserStatus",
  initialState: [],
  reducers: {
    changeStatus: (state, action) => {
      //update lại cái state =>  check lại cái này nhé.
      return [...action.payload];
    },
  },
});

const { actions, reducer } = StatusSlice;
export const { changeStatus } = actions;
export default reducer;
