import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
// import { DatePicker } from "@mui/lab";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { makeStyles } from "@material-ui/core/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const useStyle = makeStyles((theme) => ({
  input__all: {
    margin: "5px 0",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  input_label: {
    marginBottom: "3px",
    fontWeight: 600,
    fontSize: "16px",
  },
}));

function DatePickerInput(props) {
  const classes = useStyle();

  const { form, name, label, disable, dateInit, onchange } = props;
  const { errors, formState } = form;
  const hasError = errors[name];
  const [value, setValue] = useState(dateInit || null);

  const handleChange = (date) => {
    form.setValue(name, date, {
      shouldValidate: true,
    });
    setValue(date);
    if (onchange) onchange(date);
  };

  return (
    <div className={classes.input__all}>
      <div className={classes.input_label}>{label}</div>

      <Controller
        control={form.control}
        name={name}
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              openTo="day"
              views={["day"]}
              value={value}
              disabled={disable}
              inputFormat="dd/MM/yyyy"
              onChange={(date) => handleChange(date, field)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!hasError}
                  helperText={!!hasError && "please enter the date"}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />
    </div>
  );
}
export default DatePickerInput;
