import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function FilterPublicHolidayByYear({
  width,
  height,
  valueInit,
  listAllHolidays,
  filterByYear,
}) {
  var listYear = [...new Set(listAllHolidays.map((item) => item.year))];

  const [selected, setSelected] = React.useState(valueInit);

  const handleChange = async (event) => {
    let valueSelected = event.target.value;
    setSelected(valueSelected);
    filterByYear(valueSelected);
  };

  return (
    <Box sx={{ width: width, paddingLeft: "15px" }}>
      <FormControl fullWidth>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          sx={{
            fontWeight: "600 !important",
          }}
          value={selected}
          onChange={handleChange}
        >
          {listYear.map((year) => {
            return (
              <MenuItem sx={{ fontWeight: 600 }} value={year} key={year}>
                {year}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default FilterPublicHolidayByYear;
