import axiosClient from "./axiosClient";

const AdminUserAPI = {
  async getAllUsers(params) {
    const url = "/Users/GetAllUsers";
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async getListViewUserCombineTeam(params) {
    const url = "/Users/GetViewListUserCombineTeams";
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async getListUserCredentialByTeamId(id) {
    const url = `/Users/GetListUserCredentialByTeamId/${id}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async getListUserByTeamId(id) {
    const url = `/TeamUser/GetTeamUserByTeamId/${id}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async ChangeStateByUserId(id) {
    const url = `/Users/ChangeStateByUserId/${id}`;
    return axiosClient.request({
      method: "PUT",
      url: url,
    });
  },

  async createNewUser(params) {
    const url = `/Users/CreateNewUserCredential`;
    return axiosClient.request({
      method: "POST",
      url: url,

      data: params,
    });
  },

  async getUserByEmail(email) {
    const url = `/Users/GetUserByEmail/${email}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async getUserById(userId) {
    const url = `/Users/GetUserCredentialById/${userId}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async deactiveUser(params) {
    const url = `/Users/UpdateUserCredential`;
    return axiosClient.request({
      method: "PUT",
      url: url,

      data: params,
    });
  },

  async UpdateUserCredential(data) {
    const url = "/Users/UpdateUserCredentialByCommonUserAsync";
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: data,
    });
  },

  async UpdateUserInfoAdmin(data) {
    const url = "/Users/UpdateUserCredential";
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: data,
    });
  },

  async UpdateAccountSetting(data) {
    const url = "/Users/UpdateAccountSetting";
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: data,
    });
  },

  //GROUP AND TEAM
  async createTeamByListUser(params) {
    const url = "/TeamUser/UpdateMultipleTeamUser";
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params,
    });
  },

  async changeTeamInfo(params) {
    const url = `/Team/${params.teamId}`;
    return axiosClient.request({
      method: "PUT",
      url: url,

      data: params,
    });
  },

  async changeTeamStatus(params) {
    const url = `/Team/DeactiveTeamById/${params.teamId}`;
    return axiosClient.request({
      method: "PUT",
      url: url,

      data: params,
    });
  },
};
export default AdminUserAPI;
