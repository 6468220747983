import React, { createContext, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./AdminTimeSheetService.scss";
import SelectionTeamField from "./components/SelectionTeamField";
import OverViewTab from "./components/OverviewTab";
import ReportTab from "./components/ReportTab";
import AdminTeamAPI from "B_API/AdminTeamAPI";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";
import { ToastShowError, ToastShowSuccess } from "F_UTILS/Notify";
import AdminUserAPI from "B_API/AdminUserAPI";

AdminTimeSheetService.propTypes = {};
export const ToastContext = createContext();

function AdminTimeSheetService(props) {
  const [value, setValue] = React.useState("overview");
  const initTeamId = JSON.parse(localStorage.getItem("user"))?.teamId;
  const [currentTeamId, setTeamId] = React.useState(initTeamId || 2);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOnTeamChange = (teamId) => {
    setTeamId(teamId);
  };

  const [listTeams, setListTeams] = React.useState([]);
  const [listUsers, setListUsers] = React.useState([]);

  React.useEffect(() => {
    const fetchListTeams = async () => {
      try {
        const response = await AdminTeamAPI.getTeamOrGroup(1);
        if (response.status === 200) setListTeams(response?.data);

        const response2 = await AdminUserAPI.getListViewUserCombineTeam();
        if (response2.status === 200) setListUsers(response2.data);
      } catch (error) {
        console.log("Failed to fetch list teams");
      }
    };
    fetchListTeams();
  }, []);

  const showToastCreateSuccess = () => {
    ToastShowSuccess("Create project success.");
  };
  const showToastCreateError = () => {
    ToastShowError("Create project fail.");
  };
  const showToastDeleteSuccess = () => {
    ToastShowSuccess("Delete project success.");
  };
  const showToastDeleteError = () => {
    ToastShowError("Delete project fail.");
  };
  const showToastUpdateSuccess = () => {
    ToastShowSuccess("Update project success.");
  };
  const showToastUpdateError = () => {
    ToastShowError("Update project fail.");
  };
  return (
    <div className="admin__timesheet">
      <div className="admin__timesheet-navbar">
        <div className="navbar__column">
          <Tabs
            TabIndicatorProps={{
              style: { background: "var(--bs-regal-blue)" },
            }}
            value={value}
            aria-label="secondary tabs example"
            onChange={handleChange}
          >
            <Tab value="overview" label="OVERVIEW" sx={{ fontWeight: 600 }} />
            <Tab value="report" label="REPORT" sx={{ fontWeight: 600 }} />
          </Tabs>
        </div>
        {value === "overview" ? (
          <div className="navbar__column">
            <SelectionTeamField
              width={200}
              id="admin__selection-team-id"
              initTeamId={currentTeamId}
              onTeamChange={handleOnTeamChange}
              listTeams={listTeams}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="admin__timesheet-content">
        {value === "overview" ? (
          <ToastContext.Provider
            value={{
              showToastCreateSuccess,
              showToastCreateError,
              showToastDeleteSuccess,
              showToastDeleteError,
              showToastUpdateSuccess,
              showToastUpdateError,
            }}
          >
            <OverViewTab currentTeamId={currentTeamId} />
          </ToastContext.Provider>
        ) : (
          <ReportTab listUsers={listUsers} />
        )}
      </div>
      <CustomToastComponent />
    </div>
  );
}

export default AdminTimeSheetService;
