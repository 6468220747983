import { Popover } from "@mui/material";
import classNames from "classnames";
import React from "react";
import ListNoticeAbsence from "../ListNoticeOrAbsence";

DateItemBlock.propTypes = {};

function DateItemBlock({ dateItem }) {
  const dateCheck = new Date().getDate();

  const [anchorElNotice, setAnchorElNotice] = React.useState(null);

  const handlePopoverNoticeOpen = (event) => {
    const target = event.currentTarget;
    setAnchorElNotice(target);
  };
  const handlePopoverNoticeClose = () => {
    setAnchorElNotice(null);
  };
  const openNotice = Boolean(anchorElNotice);

  const [anchorElAbsence, setAnchorElAbsence] = React.useState(null);

  const handlePopoverAbsenceOpen = (event) => {
    const target = event.currentTarget;
    setAnchorElAbsence(target);
  };
  const handlePopoverAbsenceClose = () => {
    setAnchorElAbsence(null);
  };
  const openAbsence = Boolean(anchorElAbsence);

  return (
    <div
      className={classNames({
        dashboard__dateitem: true,
        "dashboard__dateitem-today": dateItem?.date === dateCheck,
      })}
      style={{
        background: !!dateItem?.date
          ? "#fff"
          : "var(--bs-gray-new)",
      }}
    >
      <div className="dashboard__dateitem-number">
        {dateItem?.date?.getDate()}
      </div>
      <div className="dashboard__dateitem-notices">
        {dateItem?.notices?.length !== 0 && (
          <div
            name="notice"
            className="dashboard__dateitem-circle"
            style={{ backgroundColor: "var(--bs-button-new)" }}
            onClick={handlePopoverNoticeOpen}
            // onMouseLeave={handlePopoverNoticeClose}
          />
        )}
        {dateItem?.absence?.length !== 0 && (
          <div
            name="absence"
            className="dashboard__dateitem-circle"
            style={{ backgroundColor: "var(--bs-stratos)" }}
            onClick={handlePopoverAbsenceOpen}
            // onMouseLeave={handlePopoverAbsenceClose}
          />
        )}
      </div>

      <Popover
        id="mouse-over-popover"
        // sx={{
        //   pointerEvents: "none",
        // }}
        open={openNotice}
        anchorEl={anchorElNotice}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handlePopoverNoticeClose}
        disableRestoreFocus
      >
        <div className="dashboard__popover">
          <div style={{ fontWeight: "600" }}>Notice:</div>
          {dateItem?.notices?.map((item, index) => (
            <ListNoticeAbsence
              key={item?.content + index}
              type="notice"
              item={item}
            />
          ))}
        </div>
      </Popover>

      <Popover
        id="mouse-over-popover"
        // sx={{
        //   pointerEvents: "none",
        // }}
        open={openAbsence}
        anchorEl={anchorElAbsence}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handlePopoverAbsenceClose}
        disableRestoreFocus
      >
        <div className="dashboard__popover">
          <div style={{ fontWeight: "600" }}>Absence:</div>
          {dateItem?.absence?.map((item, index) => (
            <ListNoticeAbsence
              key={item?.content + index}
              type="absence"
              item={item}
            />
          ))}
        </div>
      </Popover>
    </div>
  );
}

export default DateItemBlock;
