import { Button, Dialog } from "@mui/material";
import Slide from "@mui/material/Slide";
import "A_SHARED_COMPONENT/shared_component.scss";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

SuccessPopup.propTypes = {};

function SuccessPopup({
  onClose,
  open,
  onConfirmed,
  title,
  data,
  description,
}) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
      keepMounted
    >
      <div className="shared__success-popup">
        <div className="success-popup__title">{title}</div>
        <div className="success-popup__icon">
          <i className="far fa-check-circle"></i>
        </div>
        <div className="success-popup__description">{description}</div>
        <div className="success-popup__footer">
          <Button
            variant="contained"
            sx={{ width: "100px" }}
            onClick={handleClose}
          >
            OK
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default SuccessPopup;
