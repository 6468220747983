import axiosClient from "./axiosClient";

const LeaveFormAPI = {
  async getLeaveType(params) {
    const url = `/LeaveType`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async getLeaveForm(params) {
    const userId = JSON.parse(localStorage.getItem("user")).userId;

    const url = `/Reports/GetCommonLeaveFormComponentByUserId/${userId}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async postLeaveForm(params) {
    const url = `/LeaveForm`;
    return axiosClient.request({
      method: "POST",
      url: url,

      data: params,
    });
  },
};
export default LeaveFormAPI;
