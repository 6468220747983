import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import DashBoard from "Z_SERVICES/a_DashBoard";
import CalendarService from "Z_SERVICES/b_CalendarService";
import TimeSheetService from "Z_SERVICES/c_TimeSheetService";
import TimeSheetDetailPage from "Z_SERVICES/c_TimeSheetService/TimeSheetDetailPage";
import ExportPdf from "Z_SERVICES/d_LeaveFormService/components/ExportPage";
import LeaveFormService from "Z_SERVICES/d_LeaveFormService/LeaveFormService";
import NewLeaveRequest from "Z_SERVICES/d_LeaveFormService/NewLeaveRequest";
import OverTimeService from "Z_SERVICES/e_OverTimeService";
import AdminUserService from "Z_SERVICES/f_AdminUserServices";
import AdminTimeSheetService from "Z_SERVICES/g_AdminTimeSheetServices";
import AdminLeaveFormService from "Z_SERVICES/h_AdminLeaveFormServices";
import AdminOverTimeService from "Z_SERVICES/i_AdminOverTimeServices";
import LogInService from "Z_SERVICES/k_LogInServices";
import UserInformation from "Z_SERVICES/m_UserInformationService";
import MenuTopBar from "./components/MenuTopBar";
import NavigationFullBar from "./components/NavigationFullBar";
import "./MainPageService.scss";

MainPageService.propTypes = {};

function MainPageService(props) {
  const [isLogin, setIsLogin] = useState(props.isLogin);
  const currentUserState = useSelector((state) => state.user.current);

  useEffect(() => {
    setIsLogin(props.isLogin);
  }, [props]);

  useEffect(() => {
    return () => {
      setIsLogin(false);
    };
  }, []);
  return (
    <div className="mainservice">
      {isLogin ? (
        <div className="mainservice__navigate">
          <NavigationFullBar />
        </div>
      ) : null}

      <div className="mainservice__right">
        {isLogin ? (
          <div className="mainservice__topbar">
            <MenuTopBar />
          </div>
        ) : null}

        <div className="mainservice__mainview">
          <Routes>
            {/* MEMBER ROUTER */}
            <Route path="/" element={<DashBoard />} />
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path={`/calendar/:userId`} element={<CalendarService />} />
            <Route path="/timesheet" element={<TimeSheetService />} />
            <Route path="/overtime" element={<OverTimeService />} />
            <Route path="/leaveform" element={<LeaveFormService />} />
            <Route path="/leaveform/newrequest" element={<NewLeaveRequest />} />
            <Route path="/leaveform/pdf" element={<ExportPdf />} />
            <Route path="/chatroom" element={<h1>chatroom</h1>} />
            <Route path="/filestorage" element={<h1>filestorage</h1>} />
            <Route path="/project" element={<h1>project</h1>} />
            <Route path="/settings" element={<h1>settings</h1>} />

            {/* ADMIN ROUTER */}
            <Route path="/admin/user" element={<AdminUserService />} />
            <Route
              path="/admin/timesheet"
              element={<AdminTimeSheetService />}
            />
            <Route path="/admin/overtime" element={<AdminOverTimeService />} />
            <Route
              path="/admin/leaveform"
              element={<AdminLeaveFormService />}
            />

            {/* OTHER PAGE */}
            <Route path="userinfo/*" element={<UserInformation />} />
            <Route path="login/*" element={<LogInService />} />
            <Route
              path="/timesheet/detail/:userIdParam/:date"
              element={<TimeSheetDetailPage />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default MainPageService;
