import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import SelectionField from "A_SHARED_COMPONENT/SelectionField";
import React from "react";
import MenuProfile from "../MenuProfile";
import Notification from "../Notification";
import "./MenuTopBar.scss";
import UrlConstant from "F_UTILS/constants/UrlConstant";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

import { useDispatch } from "react-redux";
import { changeStatus } from "E_REDUX_STORE/statusSlice";

MenuTopBar.propTypes = {};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),

    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
      "font-size": "14px",
    },
  },
}));

function MenuTopBar(props) {
  const [connection, setConnection] = React.useState(null);
  const dispatch = useDispatch();

  const sendMessage = async (status) => {
    try {
      await connection.invoke("ChangeStatus", status);
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    const userIdString = JSON.parse(
      localStorage.getItem("user")
    ).userId.toString();

    if (UrlConstant.REACT_APP_DATABASE_URL === "https://api3.ttdesignco.com")
      return;

    const connection = new HubConnectionBuilder()
      .withUrl(`${UrlConstant.REACT_APP_DATABASE_URL}/statusHub`)
      .configureLogging(LogLevel.Information)
      .build();
    connection.on("ReceiveMessage", (user, status) => {
      //do something you like
    });

    connection.on("UsersInRoom", (users) => {
      // console.log("USER IN ROOM: ", users);
      const action = changeStatus(users);
      dispatch(action);
    });

    connection.onclose((e) => {
      setConnection();
    });
    connection
      .start()
      .then(() =>
        connection.invoke("JoinRoom", userIdString, "available", "TTDRoom")
      )
      .then(() => setConnection(connection));

    return () => {
      if (connection) connection.stop();
    };
  }, []);

  let textSearch = "";
  const handleChangeText = (e) => {
    textSearch = e.target.value;
    // console.log("Search: ", textSearch);
  };
  const hanldeOnSubmitSearch = () => {
    // console.log("Submit Search: ", textSearch);
  };

  const handleClickMenuBar = () => {
    // console.log("Click menu bar");
  };

  const onChangeStatusHub = (value) => {
    sendMessage(value);
  };

  return (
    <div className="menutopbar">
      <div className="menutopbar__left">
        <i className="fas fa-bars fa-lg" onClick={handleClickMenuBar}></i>

        <div>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={handleChangeText}
              onSubmit={hanldeOnSubmitSearch}
              disabled
            />
          </Search>
        </div>
      </div>

      <div className="menutopbar__right">
        <Notification />
        <SelectionField
          className="menutopbar__right-status"
          width={150}
          initStatus={null}
          onChangeStatusHub={onChangeStatusHub}
        />
        <MenuProfile />
      </div>
    </div>
  );
}

export default MenuTopBar;
