const { GoogleSpreadsheet } = require("google-spreadsheet");

export const updateSheet = async (dataTimeSheet, valueSubmit, keyTimeSheet) => {
  const SPREADSHEET_ID = keyTimeSheet?.spreadsheetId;
  const clientEmail = keyTimeSheet?.clientEmail;
  const privateKey = keyTimeSheet?.privateKey.replace(/\\n/g, "\n").trim();

  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

  await doc.useServiceAccountAuth({
    // env var values are copied from service account credentials generated by google
    // see "Authentication" section in docs for more info
    client_email: clientEmail,
    private_key: privateKey,
  });

  const startDate = valueSubmit.dateFrom;
  const endDate = valueSubmit.dateTo;

  const headerTitle = `BẢNG TỔNG KẾT NGÀY/GIỜ CÔNG TỪ ${startDate} ĐẾN ${endDate} \nThe summary of Timesheet From ${startDate} To ${endDate}`;

  const rowsColumn = [
    "no",
    "fullName",
    "staffId",
    "workingDaysInMonth",
    "dayWorkAtOffice",
    "unpaidLeave",
    "paidLeave",
    "publicHoliday",
    "normalWorkingTime",
    "unpaidLeaveHours",
    "paidLeaveHours",
    "publicHolidayHours",
    "normalOvertimeHours",
    "weekendOvertimeHours",
    "nightOvertimeHours",
    "publicHolidayOvertimeHours",
  ];
  await doc.loadInfo(); // loads document properties and worksheets
  const sheet = doc.sheetsByIndex[0]; // or use doc.sheetsById[id] or doc.sheetsByTitle[title]
  // Ghi tieu de
  await sheet.loadCells("A1:S100");
  sheet.getCellByA1("B1").value = headerTitle;
  sheet.getCellByA1("C2").value = startDate;
  sheet.getCellByA1("D2").value = endDate;

  //index
  var startIndex = 6;

  for (let i = 0; i < 90; i++) {
    let index = startIndex + i;
    for (let j = 0; j < 16; j++) {
      let column = String.fromCharCode(j + 97).toUpperCase();
      sheet.getCellByA1(`${column}${index}`).value = "";

      //format border
      sheet.getCellByA1(`${column}${index}`).borders = {
        top: { style: "DOTTED" },
        right: { style: "DOTTED" },
        bottom: { style: "DOTTED" },
        left: { style: "DOTTED" },
      };

      sheet.getCellByA1(`${column}${index}`).backgroundColor = {
        red: 1,
        green: 1,
        blue: 1,
      };
    }
  }
  await sheet.saveUpdatedCells();

  //index

  //Add them cot no
  var dataAddNo = dataTimeSheet.map((request, index) => ({
    no: index + 1,
    ...request,
  }));

  //Ghi du lieu vao sheet
  for (let i = 0; i < dataAddNo.length; i++) {
    let personData = dataAddNo[i];
    let index = startIndex + i;
    for (let j = 0; j < 16; j++) {
      let column = String.fromCharCode(j + 97).toUpperCase();
      let valueAppend = personData[rowsColumn[j]];
      if (valueAppend !== 0) {
        sheet.getCellByA1(`${column}${index}`).value = valueAppend;
      } else {
        sheet.getCellByA1(`${column}${index}`).value = "";
      }

      //format color
      if (personData.teamId === 1) {
        sheet.getCellByA1(`${column}${index}`).backgroundColor = {
          red: 0.86,
          green: 0.9,
          blue: 0.88,
        };
      } else if (personData.teamId === 2) {
        sheet.getCellByA1(`${column}${index}`).backgroundColor = {
          red: 0.87,
          green: 0.72,
          blue: 0.53,
        };
      } else if (personData.teamId === 3) {
        sheet.getCellByA1(`${column}${index}`).backgroundColor = {
          red: 0.78,
          green: 0.84,
          blue: 0.93,
        };
      } else if (personData.teamId === 4) {
        sheet.getCellByA1(`${column}${index}`).backgroundColor = {
          red: 0.48,
          green: 0.75,
          blue: 0.42,
        };
      }
      //format border
      sheet.getCellByA1(`${column}${index}`).borders = {
        top: { style: "SOLID" },
        right: { style: "SOLID" },
        bottom: { style: "SOLID" },
        left: { style: "SOLID" },
      };

      //format text
      sheet.getCellByA1(`${column}${index}`).textFormat = {
        fontFamily: "Arial",
      };
    }
  }

  await sheet.saveUpdatedCells();
  let url =
    "https://docs.google.com/spreadsheets/d/1W5QW6IEmtU4wShjCA6qkkI1xSO4CQcHC6tIlaFa7sZw/edit#gid=2091316008";

  // return <ExportDonePopup open={true} url={url}></ExportDonePopup>;
  window.open(url);
};
