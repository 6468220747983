import { Button } from "@mui/material";
import React from "react";
import ReactToPrint from "react-to-print";
import ExportPdf from "../ExportPage";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

class ExportPdfComponent extends React.Component {
  render() {
    return (
      <div>
        <ExportPdf
          ref={(response) => (this.componentRef = response)}
          listRequestPrint={this.props.listRequestPrint}
          yearPrint={this.props.yearPrint}
        />

        <ReactToPrint
          content={() => this.componentRef}
          trigger={() => (
            <Button
              className="button__export-pdf"
              variant="contained"
              size="small"
              startIcon={<PictureAsPdfIcon />}
              sx={{
                height: "40px",
                lineHeight: "1",
              }}
            >
              Export pdf
            </Button>
          )}
        />
      </div>
    );
  }
}

export default ExportPdfComponent;
