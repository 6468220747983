import axiosClient from "./axiosClient";

const EmailNotificationAPI = {
  async sendingRequest(url) {
    return axiosClient.request({
      method: "POST",
      url: url,
    });
  },
};
export default EmailNotificationAPI;
