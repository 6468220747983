import LeaveFormRequestTable from "./RequestTableList/RequestTable";
import React, { useEffect, useState } from "react";
import AdminLeaveFormAPI from "B_API/AdminLeaveFormAPI";
import SortListLeaveForm from "./SortListLeaveForm";
import SelectionTeamField from "Z_SERVICES/g_AdminTimeSheetServices/components/SelectionTeamField";
import AdminTeamAPI from "B_API/AdminTeamAPI";
import FilterLeaveFormByMonth from "./FilterLeaveFormByMonth";

RequestTab.propTypes = {};

function RequestTab() {
  const initTeamId = JSON.parse(localStorage.getItem("user"))?.teamId;
  let setInitTeam = initTeamId === 4 ? 0 : initTeamId;

  const [status, setStatus] = React.useState(0);
  const [dateSelected, setDateSelected] = React.useState(new Date());

  const [currentTeamId, setTeamId] = React.useState(setInitTeam ?? 0);

  const [listLeave, setListLeave] = useState([]);

  useEffect(() => {
    const fetchAllOverTimeRequest = async () => {
      try {
        const response = await AdminLeaveFormAPI.getListLeaveByTeamIdAndStatus(
          currentTeamId,
          status
        );

        let listLeaveSorted = response.data.sort((first, second) =>
          first.fromDate < second.fromDate ? 1 : -1
        );

        setListLeave(listLeaveSorted);
      } catch (error) {
        console.log("Error get list leave form ", error);
      }
    };
    fetchAllOverTimeRequest();
  }, [currentTeamId, status]);

  const handleOnTeamChange = (teamId) => {
    setTeamId(teamId);
  };

  const handleFilterByMonth = (date) => {
    setDateSelected(date);
  };

  const handleOnStatusChange = (status) => {
    setStatus(status);
  };

  const [listTeams, setListTeams] = React.useState([]);

  React.useEffect(() => {
    const fetchListTeams = async () => {
      try {
        const response = await AdminTeamAPI.getTeamOrGroup(1);
        if (response.status === 200) setListTeams(response?.data);
      } catch (error) {
        console.log("Failed to fetch list teams");
      }
    };
    fetchListTeams();
  }, []);

  let renderList,
    renderListFilterByMonth = [];

  if (status === 0) {
    renderList = [...listLeave].sort((a, b) => {
      if (a.status === "Pending") return -1;
      if (b.status === "Pending") return 1;
      return a?.status > b?.status ? 1 : -1;
    });

    renderListFilterByMonth = [...renderList].filter((leave) => {
      let monthOfLeave = new Date(leave.fromDate);
      if (
        monthOfLeave.getMonth() === dateSelected.getMonth() &&
        monthOfLeave.getFullYear() === dateSelected.getFullYear()
      ) {
        return leave;
      }
    });
  } else {
    renderList = [...listLeave].sort((a, b) =>
      a?.fromDate > b?.fromDate ? -1 : 1
    );

    renderListFilterByMonth = [...renderList].filter((leave) => {
      let monthOfLeave = new Date(leave.fromDate);
      if (
        monthOfLeave.getMonth() === dateSelected.getMonth() &&
        monthOfLeave.getFullYear() === dateSelected.getFullYear()
      ) {
        return leave;
      }
    });
  }

  return (
    <div className="admin__leaveform--requesttab">
      <div className="requesttab__filter--condition">
        <div className="status-selection">
          <SelectionTeamField
            width={200}
            marginRight={2}
            leftAlign={1}
            showAll
            listTeams={listTeams}
            id="admin__selection-team-id"
            initTeamId={currentTeamId}
            onTeamChange={handleOnTeamChange}
          />
          <SortListLeaveForm
            width={200}
            id="admin__selection-team-id"
            isLeaveForm={true}
            valueInit={status}
            handleOnStatusChange={handleOnStatusChange}
          />
          <FilterLeaveFormByMonth
            width={200}
            isLeaveForm={true}
            valueInit={dateSelected.getMonth()}
            onFilterByMonth={handleFilterByMonth}
          />
        </div>
      </div>
      <div className="requesttab__table">
        <LeaveFormRequestTable listLeaveInit={renderListFilterByMonth} />
      </div>
    </div>
  );
}

export default RequestTab;
