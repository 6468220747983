import React, { useState } from "react";
import { useNavigate } from "react-router";
import logo from "X_ASSETS/a_Images/shared/TTDLogo2.jpg";
import AdminNavigation from "../AdminFullBar";
import MemberNavigation from "../MemberFullBar";
import "./NavigationFullBar.scss";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getOTandLeavePending } from "E_REDUX_STORE/OTandLeaveCount";

NavigationFullBar.propTypes = {};

function NavigationFullBar(props) {
  const currentUserState = useSelector((state) => state.user.current);

  const NAVIGATION = [
    {
      id: 1,
      name: "DASHBOARD",
      part: "member",
      active: false,
      disable: false,
      icon: "fas fa-tachometer-alt",
      link: "/dashboard",
    },

    {
      id: 3,
      name: "TIME SHEET",
      part: "member",
      active: false,
      disable: false,
      icon: "fas fa-business-time",
      link: "/timesheet",
    },
    {
      id: 4,
      name: "OVER TIME",
      part: "member",
      active: false,
      disable: false,
      icon: "fas fa-clock",
      link: "/overtime",
    },
    {
      id: 5,
      name: "LEAVE FORM",
      part: "member",
      active: false,
      disable: false,
      icon: "fas fa-envelope-open-text",
      link: "/leaveform",
    },
    {
      id: 2,
      name: "CALENDAR",
      part: "member",
      active: false,
      disable: false,
      icon: "fas fa-calendar-alt",
      link: `/calendar/${currentUserState.userId}`,
    },
    {
      id: 6,
      name: "CHAT ROOM",
      part: "member",
      active: false,
      disable: true,
      icon: "fas fa-comments",
      link: "/chatroom",
    },
    {
      id: 7,
      name: "FILE STORAGE",
      part: "member",
      active: false,
      disable: true,
      icon: "fas fa-hdd",
      link: "/filestorage",
    },
    {
      id: 8,
      name: "PROJECT MANAGEMENT",
      part: "member",
      active: false,
      disable: true,
      icon: "fas fa-project-diagram",
      link: "/project",
    },
    {
      id: 9,
      name: "SETTINGS",
      part: "member",
      active: false,
      disable: true,
      icon: "fas fa-cogs",
      link: "/settings",
    },
    {
      id: 10,
      name: "USERS",
      part: "admin",
      active: false,
      disable: false,
      icon: "fas fa-users-cog",
      link: "/admin/user",
    },
    {
      id: 11,
      name: "TIME SHEET",
      part: "admin",
      active: false,
      disable: false,
      icon: "fas fa-business-time",
      link: "/admin/timesheet",
    },
    {
      id: 12,
      name: "OVER TIME",
      part: "admin",
      active: false,
      disable: false,
      icon: "fas fa-clock",
      link: "/admin/overtime",
    },
    {
      id: 13,
      name: "LEAVE FORM",
      part: "admin",
      active: false,
      disable: false,
      icon: "fas fa-envelope-open-text",
      link: "/admin/leaveform",
    },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentURL = location.pathname;

  const currentNav = NAVIGATION.map((item, index) => {
    if (item.link === currentURL || (index === 0 && currentURL === "/login")) {
      return {
        ...item,
        active: true,
      };
    } else if (currentURL === "/leaveform/newrequest" && item.id === 5) {
      return {
        ...item,
        active: true,
      };
    } else {
      return item;
    }
  });

  const [navigation, setNavigation] = useState(currentNav);

  const useLocationChange = (action) => {
    const location = useLocation();
    React.useEffect(() => {
      action(location);
    }, [location]);
  };

  useLocationChange((location) => {
    const newNavigation = NAVIGATION.map((item) => {
      if (item.link === location.pathname) {
        return {
          ...item,
          active: true,
        };
      } else if (
        location.pathname === "/leaveform/newrequest" &&
        item.id === 5
      ) {
        return {
          ...item,
          active: true,
        };
      } else if (
        location.pathname.includes("/timesheet") &&
        item.id === 3 &&
        !location.pathname.includes("admin/timesheet")
      ) {
        return {
          ...item,
          active: true,
        };
      } else if (location.pathname.includes("/calendar") && item.id === 2) {
        return {
          ...item,
          active: true,
        };
      } else {
        return item;
      }
    });

    setNavigation(newNavigation);
  });
  const handleOnClick = async (value) => {
    if (value.disable) return;

    const newNavigation = NAVIGATION.map((item) =>
      item.link === value.link
        ? {
            ...item,
            active: true,
          }
        : item
    );

    setNavigation(newNavigation);
    navigate(value.link);

    const actions = getOTandLeavePending();
    await dispatch(actions);
  };

  const clickToHomePage = () => {
    navigate("/dashboard");
  };

  return (
    <div className="navigation">
      <div className="navigation__logo" onClick={clickToHomePage}>
        <img src={logo} alt="ttd-logo" />
        {window.screen.width > 639 && <p>{"T&T Design"}</p>}
      </div>
      <MemberNavigation
        navigation={navigation.filter((item) => item.part === "member")}
        navigationClick={handleOnClick}
      />
      <AdminNavigation
        navigation={navigation.filter((item) => item.part === "admin")}
        navigationClick={handleOnClick}
      />
    </div>
  );
}

export default NavigationFullBar;
