import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LogInService from "Z_SERVICES/k_LogInServices";
import "./App.scss";
import MainPageService from "./Z_SERVICES/z_mainpage";

//notification by SonNH
import { onMessageListener, getToken } from "./firebase";
import ReactNotificationComponent from "A_SHARED_COMPONENT/Notification/ToastNotification";
import Notifications from "A_SHARED_COMPONENT/Notification/Notification";
import StorageKeys from "F_UTILS/constants/StorageKeys";
import { refreshToken } from "E_REDUX_STORE/userSlice";
import {
  getAllTimesheetProjectsByUserId,
  getListViewUserCombineTeam,
  getTeams,
  getTimesheetCategoriesByTeamId,
  getTimesheetObjectsByTeamId,
} from "E_REDUX_STORE/commonSlice";
import TokenServices from "B_API/TokenServices";

function App() {
  const user = useSelector((state) => state.user.current);
  const userStatus = useSelector((state) => state.user.status);
  const [isLogin, setIsLogin] = useState(!!user.userId);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  // const [timer, setTimer] = useState(0);
  const dispatch = useDispatch();

  const commonStatus = useSelector((state) => state.common.status);
  // const [listUsers, setListUsers] = useState(listUsersSelector);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  const refreshTokenFunc = async function () {
    const token = localStorage.getItem(StorageKeys.refresh);
    if (!!user.userId && !!token) {
      const actions = refreshToken({ token: token, userEmail: user.userName });
      await dispatch(actions);
      if (userStatus === "idle") {
        if (!!user.userId == false) {
          TokenServices.removeUser();
          window.location.reload(true);
          return <LogInService />;
        }
      } else if (userStatus === "succeeded") {
      } else {
        // clearInterval();
        TokenServices.removeUser();
        window.location.reload(true);
        return <LogInService />;
      }
    }
  };

  const getListTeam = async function () {
    const action = getTeams();
    await dispatch(action);
  };

  const getListUser = async function () {
    const action = getListViewUserCombineTeam();
    await dispatch(action);
  };

  const getListProjects = async function () {
    const action = getAllTimesheetProjectsByUserId(user?.userId);
    await dispatch(action);
  };

  const getListObjects = async function () {
    const action = getTimesheetObjectsByTeamId(user?.teamId);
    await dispatch(action);
  };

  const getListCategories = async function () {
    const action = getTimesheetCategoriesByTeamId(user?.teamId);
    await dispatch(action);
  };

  const getListCommonComponent = async function () {
    getListUser();
    getListTeam();
    getListProjects();
    getListObjects();
    getListCategories();
  };

  useEffect(() => {
    let interval = setInterval(async () => {
      refreshTokenFunc();
    }, 300000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // useEffect bên dưới : Nếu button Remember me không được tick thì dữ liệu Local Storage sẽ bị clear()
  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();

      const rememberUser =
        TokenServices.getLocalStorageRemember() === "true" ? true : false;
      if (!rememberUser) {
        TokenServices.removeUser();
      }
    };

    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  useEffect(() => {
    if (!!user.userId === true) {
      setIsLogin(true);
      if (commonStatus === "idle") {
        getListCommonComponent();
      }
    } else {
      setIsLogin(false);
      return <LogInService />;
    }
  }, [user, commonStatus]);

  if (Notification.permission !== "default") {
    Notification.requestPermission();
  }

  if (!!user.userId == false) {
    return <LogInService />;
  } else {
    return (
      <>
        <MainPageService isLogin={isLogin} />
        {show ? (
          <ReactNotificationComponent
            title={notification.title}
            body={notification.body}
          />
        ) : (
          <></>
        )}
        <Notifications />
      </>
    );
  }
}

export default App;
