import HistoryIcon from "@mui/icons-material/History";
import { Button, Dialog, Fab, List, Paper } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import MemberListStatus from "A_SHARED_COMPONENT/MemberListStatus";
import TagContent from "A_SHARED_COMPONENT/TagContent";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CalendarBlock from "./components/CalendarBlock";
import NoticeItemBlock from "./components/NoticeItemBlock";
import PipeChartProject from "./components/PipeChartProject";
import "./Dashboard.scss";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import { useDispatch, useSelector } from "react-redux";
import { getTimesheetByUserIdAndDate } from "E_REDUX_STORE/timesheetSlice";
import {
  getReportsOfLeaveByUserId,
  getSummaryOfTimesheetByDateRange,
  getProjectHoursByUserIdAndDateRange,
} from "E_REDUX_STORE/reportSlice";

import { format } from "date-fns";
import { GetListVwCalendarAssignmentByUserId } from "E_REDUX_STORE/calendarSlice";

DashBoard.propTypes = {};
const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    bottom: 30,
    right: 5,
  },
});

function DashBoard(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  const userSelector = useSelector((state) => state.user.current);
  const timesheetsState = useSelector((state) => state.timesheet.current);
  const reportOfLeavesState = useSelector(
    (state) => state.report.reportOfLeaves
  );
  const summaryOfTimesheetState = useSelector(
    (state) => state.report.summaryOfTimesheets
  );
  const projectHoursState = useSelector((state) => state.report.projectHours);
  const calendarsState = useSelector((state) => state.calendar.calendars || []);
  const dispatch = useDispatch();

  const margin = "15px";

  const handleClickHistoryLeave = () => {
    navigate("/leaveform");
  };
  const [openMemberList, setOpenMemberList] = useState(false);
  const handleClickMemberListPopup = () => {
    setOpenMemberList(true);
  };
  const handleCloseMemberList = () => {
    setOpenMemberList(false);
  };

  const getTimesheet = async function () {
    // const currDate = new Date();
    let params = {
      userId: userSelector?.userId,
      currDate: format(new Date(), "yyyy-MM-dd"),
    };

    const actions = getTimesheetByUserIdAndDate(params);
    await dispatch(actions);
  };

  const getSummaryOfTimesheet = async function (month, year) {
    const actions = getSummaryOfTimesheetByDateRange({
      month: month,
      year: year,
    });
    await dispatch(actions);
  };

  const getReportOfLeave = async function () {
    const actions = getReportsOfLeaveByUserId(userSelector?.userId);
    await dispatch(actions);
  };

  const getListCalendarByUserId = async function () {
    const actions = GetListVwCalendarAssignmentByUserId(userSelector?.userId);
    await dispatch(actions);
  };

  const getProjectHours = async function (fromDate, toDate) {
    const actions = getProjectHoursByUserIdAndDateRange({
      fromDate,
      toDate,
    });
    await dispatch(actions);
  };

  useEffect(() => {
    //init date
    const currDate = new Date();
    const currMonth = format(new Date(currDate), "MM");
    const fromDate = new Date(
      currDate.getFullYear(),
      currDate.getMonth(),
      1
    ).toDateString();

    const toDate = new Date(
      currDate.getFullYear(),
      currDate.getMonth() + 1,
      0
    ).toDateString();

    getTimesheet();

    getReportOfLeave();
    getSummaryOfTimesheet(currMonth, currDate.getFullYear());
    getProjectHours(fromDate, toDate);

    getListCalendarByUserId();
  }, []);

  useEffect(() => {
    return () => {};
  });

  return (
    <>
      <div className="dashboard">
        <div className="dashboard__left">
          <div className="dashboard__left-section">
            <div className="dashboard__left-col01">
              <TagContent title="TIME IN" width="48%">
                <div className="dashboard__time-tag">
                  {timesheetsState?.timeIn
                    ? format(new Date(timesheetsState.timeIn), "HH:mm")
                    : "--:--"}
                </div>
              </TagContent>

              <TagContent title="TIME OUT" width="50%" marginLeft={margin}>
                <div className="dashboard__time-tag">
                  {timesheetsState?.timeOut
                    ? format(new Date(timesheetsState.timeOut), "HH:mm")
                    : "--:--"}
                </div>
              </TagContent>
            </div>
            <div className="dashboard__left-col02">
              <TagContent title="REST OF LEAVE DAYS" width="100%">
                <div className="dashboard__leaves-tag">
                  <div className="leaves__tag-days">
                    {reportOfLeavesState?.totalLeaveDays}
                  </div>
                  <Button
                    className="button-history"
                    variant="contained"
                    size="small"
                    color="info"
                    startIcon={<HistoryIcon />}
                    sx={{
                      height: "40px",
                      lineHeight: "1",
                    }}
                    onClick={handleClickHistoryLeave}
                  >
                    History
                  </Button>
                </div>
              </TagContent>
            </div>
          </div>
          <div className="dashboard__left-section">
            <div className="dashboard__left-col01">
              <TagContent
                title="TODAY 'S NOTICE"
                marginTop={margin}
                width="100%"
                lineHeight="150px"
                height="330px"
                dataFlow="TopToDown"
                id="dashboard__notice-id"
              >
                <List
                  sx={{
                    width: "100%",
                    position: "relative",
                    overflow: "auto",
                    height: "275px",
                  }}
                  subheader={<li />}
                  className="dashboard__list-notices"
                >
                  {calendarsState?.map((item) => (
                    <NoticeItemBlock
                      key={`${item.calendarId}-${item.calendarId}`}
                      notice={item.content}
                      color={item.color}
                    />
                  ))}
                </List>
              </TagContent>
            </div>
            <div className="dashboard__left-col02">
              <CalendarBlock
                marginTop={margin}
                width="100%"
                lineHeight="200px"
                height="330px"
                fetchSummaryOfTimesheet={getSummaryOfTimesheet}
                fetchProjectHours={getProjectHours}
              />
            </div>
          </div>
          {window.screen.width > 1200 && (
            <div className="dashboard__left-section">
              <TagContent
                title="PROJECT ANALYSIS"
                marginTop={margin}
                width="100%"
                lineHeight="150px"
                height="fit-content"
                id="dashboard__project-id"
              >
                <PipeChartProject
                  summaryOfTimesheet={summaryOfTimesheetState?.find(
                    (x) => x.userId === userSelector.userId
                  )}
                  pieChart={projectHoursState}
                />
              </TagContent>
            </div>
          )}
        </div>
        {window.screen.width > 639 && (
          <Paper
            square
            elevation={3}
            component="div"
            className="dashboard__message"
          >
            <MemberListStatus />
          </Paper>
        )}
      </div>
      {window.screen.width < 1200 && (
        <TagContent
          title="PROJECT ANALYSIS"
          marginTop={margin}
          width="100%"
          lineHeight="150px"
          height="fit-content"
          id="dashboard__project-id"
        >
          <PipeChartProject />
        </TagContent>
      )}
      {window.screen.width < 640 && (
        <>
          <Fab
            color="secondary"
            size="medium"
            aria-label="edit"
            id="floating__member-list-id"
            onClick={handleClickMemberListPopup}
          >
            <ContactMailIcon />
          </Fab>
          <Dialog
            onClose={handleCloseMemberList}
            open={openMemberList}
            components="div"
            classes={{
              paper: classes.dialog,
            }}
          >
            <div className="floating__member-list">
              <MemberListStatus />
            </div>
          </Dialog>
        </>
      )}
    </>
  );
}

export default DashBoard;
