import React, { useEffect, useState } from "react";
import "./NewLeaveRequest.scss";
import TagContent from "A_SHARED_COMPONENT/TagContent";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import { useNavigate } from "react-router";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TelegramIcon from "@mui/icons-material/Telegram";
import * as yup from "yup";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerInput from "A_SHARED_COMPONENT/DatePickerInput";
import DropdownListSelection from "A_SHARED_COMPONENT/DropdownListSelection";
import TextInputField from "A_SHARED_COMPONENT/TextInputField";
import TimePickerInput from "A_SHARED_COMPONENT/TimePickerInput/TimeSelectForm";
import ConfirmPopup from "A_SHARED_COMPONENT/ConfirmPopup";
import LeaveFormAPI from "B_API/LeaveFormAPI";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getOTandLeavePending } from "E_REDUX_STORE/OTandLeaveCount";
import { ToastShowError, ToastShowSuccess } from "F_UTILS/Notify";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";

NewLeaveRequest.propTypes = {};

const schema = yup
  .object()
  .shape({
    reason: yup.string().required("Please input reason"),
  })
  .required();

function NewLeaveRequest(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickHistoryLeave = () => {
    navigate("/leaveform");
  };

  const { control } = useForm();

  const date = new Date();
  const { state } = useLocation();

  const leaveDays = state.leaveDays;
  const [listLeaveType, setListLeaveType] = useState([]);

  //Get list leave type
  useEffect(() => {
    const fetchListProject = async () => {
      try {
        const response = await LeaveFormAPI.getLeaveType();
        setListLeaveType(
          response.data.map((leave) => ({
            leaveTypeId: leave.leaveTypeId,
            type: leave.type,
          }))
        );
      } catch (error) {
        console.log("Error get List Leave Type", error);
      }
    };
    fetchListProject();
  }, []);

  const listleaveTypeSelect = listLeaveType.map((leave) => leave.type);
  const [leaveType, setLeaveType] = useState("Annual Leave");
  const dateNext = date.setDate(date.getDate() + 1);

  const form = useForm({
    defaultValues: {
      leaveType: "Annual Leave",
      startDate: dateNext,
      startTime: moment().set("hour", 8).set("minutes", 0).set("second", 0),
      endDate: dateNext,
      endTime: "12:00 PM",
      status: "Pending",
      reason: "",
    },
    resolver: yupResolver(schema),
  });

  const [showConfirm, setShowConfirm] = useState({
    show: false,
    callback: null,
  });

  const listEndTime = ["12:00 PM", "05:00 PM"];

  const handleCloseConfirm = () => {
    setShowConfirm({
      show: false,
      callback: null,
    });
  };

  const handleChangeLeaveType = (event) => {
    setLeaveType(event.target.value);
  };

  //Submit data to server
  const handleSubmitForm = () => {
    const data = form.getValues();

    let userId = JSON.parse(localStorage.getItem("user")).userId;

    var startDateSelected = moment(data.startDate).format("YYYY-MM-DD");
    var endDateSelected = moment(data.endDate).format("YYYY-MM-DD");

    var fromDateSubmit = startDateSelected.concat(
      "T",
      data.startTime.set("second", 0).format("HH:mm:ss")
    );

    var toDateSubmit;

    if (data.endTime === "05:00 PM") {
      toDateSubmit = endDateSelected.concat(
        "T",
        data.endTime.replace("05:00 PM", "17:00:00")
      );
    } else if (data.endTime === "12:00 PM") {
      toDateSubmit = endDateSelected.concat(
        "T",
        data.endTime.replace("12:00 PM", "12:00:00")
      );
    } else {
      toDateSubmit = endDateSelected.concat(
        "T",
        data.endTime.set("second", 0).format("HH:mm:ss")
      );
    }

    var leaveTypeIdSelected = listLeaveType.find(
      (leave) => leave.type === leaveType
    ).leaveTypeId;

    //Data leave form submit
    var valueSubmit = {
      leaveTypeId: leaveTypeIdSelected,
      reason: data.reason,
      fromDate: fromDateSubmit,
      toDate: toDateSubmit,
      userId: userId,
      hours: moment
        .duration(moment(toDateSubmit).diff(moment(fromDateSubmit)))
        .asHours()
        .toFixed(2),
    };

    let summerMonth = [7, 8, 9, 10];
    // console.log("valueSubmit ", valueSubmit);

    var isWeekend =
      new Date(startDateSelected).getDay() === 6 ||
      new Date(startDateSelected).getDay() === 0 ||
      new Date(endDateSelected).getDay() === 0 ||
      new Date(endDateSelected).getDay() === 0;

    //Check weekend and date from, date to
    if (isWeekend) {
      ToastShowError("Unable to create leave form at the weekend.");
    } else if (fromDateSubmit.localeCompare(toDateSubmit) === 1) {
      ToastShowError("Start time must be less than end time.");
    } else if (
      !summerMonth.includes(new Date(fromDateSubmit).getMonth() + 1) &&
      leaveTypeIdSelected === 3
    ) {
      ToastShowError(
        "You cannot submit a leave request for months other than July, August, September, and October"
      );
    } else {
      //CONFIRM POPUP
      const callBackFunction = async () => {
        setShowConfirm({
          show: false,
          callback: null,
        });

        try {
          const response = await LeaveFormAPI.postLeaveForm(valueSubmit);
          if (response.status === 200) {
            if (
              response.data ===
              "Your leave hours exceeded allowable leave hours!"
            ) {
              ToastShowError(
                "Your leave hours exceeded allowable leave hours!"
              );
            } else {
              ToastShowSuccess(
                "Leave request has been sent to admin successfully"
              );
            }
          }
        } catch (error) {
          console.log("Error POST LeaveForm ", error);
          if (error.response.status === 400) {
            ToastShowError(error.response.data, 5000);
          }
        }

        const actions = getOTandLeavePending();
        await dispatch(actions);
      };

      setShowConfirm({ show: true, callback: callBackFunction });
    }
  };

  const [annualLeave, setAnnualLeave] = useState(0);
  const [summerLeave, setSummerLeave] = useState(0);

  useEffect(() => {
    const fetchListLeaveRequest = async () => {
      try {
        const response = await LeaveFormAPI.getLeaveForm();
        if (response.status === 200) {
          var leaveInfo = response.data.viewReportOfLeaveResource;
          setAnnualLeave(leaveInfo.restOfAnnualLeave);
          setSummerLeave(leaveInfo.restOfSummerLeave);
        }
      } catch (error) {
        console.log("Error get List Request Leave Form", error);
      }
    };
    fetchListLeaveRequest();
  }, []);

  const annualText = `ANNUAL: ${annualLeave} DAYS`;
  const summmerText = `SUMMER: ${summerLeave} DAYS`;
  return (
    <div className="request">
      <div className="request__card">
        <TagContent title="REST OF LEAVE DAY">
          <div className="card__content">
            <div className="card__content-number">{leaveDays}</div>
            <div className="card__content-days">DAYS</div>
            <div
              style={{ paddingLeft: 0, marginLeft: "40px" }}
              className="card__content-leave-content"
            >
              <div style={{ width: "150px" }}>{annualText}</div>
              <div style={{ width: "150px" }}>{summmerText}</div>
            </div>
            <div className="card__content-button">
              <Button
                className="card__content-button-history-btn"
                variant="contained"
                size="small"
                color="info"
                startIcon={<HistoryIcon />}
                sx={{
                  height: "40px",
                  lineHeight: "1",
                }}
                onClick={handleClickHistoryLeave}
              >
                History
              </Button>
            </div>
          </div>
        </TagContent>
      </div>

      <div className="request__infor css-1jfhi0p-MuiPaper-root">
        <div className="request__infor--input">
          <div className="leave-type">
            <Controller
              name="leaveType"
              control={control}
              render={({ onChange }) => (
                <FormControl fullWidth className="condition__dropdown--box__2">
                  <Select
                    value={leaveType}
                    onChange={(e) => {
                      handleChangeLeaveType(e);
                    }}
                  >
                    {listleaveTypeSelect.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          <p>{item}</p>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            />
          </div>

          <div className="reason">
            <TextInputField name="reason" label="Reason" rows={9} form={form} />
          </div>

          <div className="datetime-picker">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className="date-picker">
                <DatePickerInput
                  name="startDate"
                  label="Start Date"
                  form={form}
                  dateInit={dateNext}
                />
              </div>
              <div className="time-picker">
                <TimePickerInput
                  name="startTime"
                  label="Start Time"
                  form={form}
                  timeInit={moment().set("hour", 8).set("minutes", 0)}
                />
              </div>
            </LocalizationProvider>
          </div>

          <div className="datetime-picker">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className="date-picker">
                <DatePickerInput
                  name="endDate"
                  label="End Date"
                  form={form}
                  dateInit={dateNext}
                />
              </div>
              <div className="time-picker">
                {/* <TimePickerInput
                  name="endTime"
                  label="End Time"
                  form={form}
                  timeInit={moment().set("hour", 12).set("minutes", 0)}
                /> */}

                {leaveType !== "Unpaid Leave" && leaveType !== "Compensatory" ? (
                  <DropdownListSelection
                    name="endTime"
                    label="End Time"
                    form={form}
                    listSelection={listEndTime}
                    initIndex={0}
                  />
                ) : (
                  <TimePickerInput
                    name="endTime"
                    label="End Time"
                    form={form}
                    timeInit={moment().set("hour", 8).set("minutes", 0)}
                  />
                )}
              </div>
            </LocalizationProvider>
          </div>

          <div className="button">
            <Button
              className="button-submit"
              variant="contained"
              size="small"
              color="success"
              startIcon={<TelegramIcon />}
              sx={{
                height: "40px",
                lineHeight: "1",
              }}
              onClick={form.handleSubmit(handleSubmitForm)}
            >
              Submit
            </Button>
          </div>
          <ConfirmPopup
            open={showConfirm.show}
            onClose={handleCloseConfirm}
            onConfirmed={showConfirm.callback}
            title="Are you sure?"
            fromPage="Leave Form"
            description="Do you want to submit request?"
          />
        </div>
      </div>
      <CustomToastComponent />
    </div>
  );
}

export default NewLeaveRequest;
