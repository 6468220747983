import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import EditTimesheetPopup from "A_SHARED_COMPONENT/EditTimesheetPopup/EditTimesheetPopup";
import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import classes from "../TimeSheetService.module.scss";
import TimeSheetItem from "./TimeSheetItem";
import LockIcon from "@mui/icons-material/Lock";

function timeToDecimal(t) {
  if (t !== "--:--" && t !== null && t !== undefined) {
    var arr = t.split(":");
    var dec = parseInt((arr[1] / 6) * 10, 10);

    return parseFloat(parseInt(arr[0], 10) + "." + (dec < 10 ? "0" : "") + dec);
  } else return 0;
}

function DateTimeSheet(props) {
  let initPropsObj = {
    timesheetId: props.timesheetId || null,
    date: props.date || new Date(),
    status: props.status || null,
    timeIn: props.timeIn || "--:--",
    timeOut: props.timeOut || "--:--",
    totalFingerPrint: props.totalFingerPrint || "--:--",
    failedTime: props.failedTime,
    totalTime: props.totalTime || null,
    enough8h: props.enough8h,
    ot: props.ot || null,
    note: props.note || null,
    currentRole: props.currentRole,
    lock: props.lock || 1,
    listTask: props.listTask || [],
    listOvertimes: props.listOvertimes || [],
    listLeaveForm: props.listLeaveForm || [],
    holidayInfo: props.holidayInfo || false,
  };
  const [propsObj, setPropsObj] = useState(initPropsObj);
  const onPropsChange = useCallback(() => {
    setPropsObj(initPropsObj);
  }, [props]);

  const onOpenTaskDetailHandler = async () => {
    if (propsObj.status !== "inactive") {
      let detailObj = {
        date: propsObj.date || new Date(),
        timeIn: propsObj.timeIn || "--:--",
        timeOut: propsObj.timeOut || "--:--",
        totalFingerPrint: propsObj.totalFingerPrint || "--:--",
        failedTime: propsObj.failedTime,
        totalTime: propsObj.totalTime || "00:00",
        enough8h: propsObj.enough8h || null,
        note: propsObj.note || null,
        timesheetId: propsObj.timesheetId || null,
        listTask: propsObj.listTask || [],
        listOvertimes: propsObj.listOvertimes || [],
        listLeaveForm: propsObj.listLeaveForm || [],
        holidayInfo: propsObj.holidayInfo || false,
      };
      props.onOpenTaskDetail(detailObj);
    }
  };

  const totalLeaveHours =
    props.listLeaveForm?.reduce(
      (total, item) => total + timeToDecimal(item.hours),
      0
    ) || 0;

  const totalHoursTask =
    props.listTask?.reduce(
      (total, item) => total + timeToDecimal(item.hours),
      0
    ) + totalLeaveHours || 0;

  const totalFingerPrintAndLeave =
    timeToDecimal(props?.totalFingerPrint) + totalLeaveHours;

  const [openEdit, setOpenEdit] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditTime = () => {
    setOpenEdit(true);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    onPropsChange();
  }, [onPropsChange]);

  useEffect(() => {
    return () => {};
  });
  const today = new Date().getTime();
  const dateProps = new Date(propsObj.date).getTime();

  // var dateWarning =
  //   totalHoursTask < 8 || totalFingerPrintAndLeave < 8 ? true : false;

  var dateWarning =
    props.date.getDay() === 6 ||
    props.date.getDay() === 0 ||
    !!propsObj.holidayInfo.isHoliday ||
    dateProps > today ||
    (props.timeIn !== "--:--" && props.timeOut !== "--:--")
      ? false
      : true;

  let editPopup = null;
  if (openEdit === true) {
    editPopup = (
      <EditTimesheetPopup
        timesheetId={props?.timesheetId}
        isOpen={openEdit}
        date={props?.date}
        timeIn={props?.timeIn}
        timeOut={props?.timeOut}
        setOpenEdit={setOpenEdit}
        showEditSuccess={props?.showEditSuccess}
      ></EditTimesheetPopup>
    );
  }

  return (
    <div
      className={`${classes["date__container"]} 
                ${
                  propsObj.status === "active"
                    ? classes["date__container--active"]
                    : propsObj.status === "inactive"
                    ? classes["date__container--fade"]
                    : ""
                }  ${
        propsObj.dayOfWeek === true ? classes["date__container--weekend"] : ""
      }
 
      `}
    >
      <Box className={`${classes["date"]}`}>
        <div className={classes["date__text"]}>
          <div className={classes["date__time"]}>
            {propsObj.status !== "inactive" ? (
              <>
                <div
                  className={`${classes["time__in"]} ${
                    propsObj.timeIn !== "--:--"
                      ? classes["date__time--success"]
                      : null
                  }`}
                >
                  In: {propsObj.timeIn}
                </div>

                <div
                  className={`${classes["time__out"]} ${
                    propsObj.timeOut !== "--:--"
                      ? classes["date__time--danger"]
                      : null
                  }`}
                >
                  Out: {propsObj.timeOut}
                </div>

                <div
                  className={`${classes["edit__button"]} 
                  `}
                >
                  {initPropsObj.currentRole === "Administrator" ? (
                    props.lock === 1 ? (
                      <EditIcon
                        style={{
                          cursor: "pointer",
                          fontSize: "16px",
                          marginLeft: "10%",
                        }}
                        onClick={handleEditTime}
                      ></EditIcon>
                    ) : (
                      <Tooltip
                        title="Timesheet has been locked. You cannot edit."
                        placement="bottom"
                      >
                        <LockIcon
                          style={{
                            fontSize: "16px",
                            marginLeft: "10%",
                          }}
                        ></LockIcon>
                      </Tooltip>
                    )
                  ) : null}

                  {initPropsObj.currentRole !== "Administrator" &&
                  props.lock === 0 ? (
                    <Tooltip
                      title="Timesheet has been locked. You cannot edit."
                      placement="bottom"
                    >
                      <LockIcon
                        style={{
                          fontSize: "16px",
                          marginLeft: "10%",
                        }}
                      ></LockIcon>
                    </Tooltip>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
          <div className={classes["date__day"]}>
            {propsObj.note ? (
              <>
                <IconButton
                  className={classes["note__button"]}
                  onClick={handleClick}
                >
                  <ChatBubbleIcon className={classes["button__icon"]} />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Typography sx={{ p: 2 }}>{propsObj.note}</Typography>
                </Popover>
              </>
            ) : null}

            <div className={classes["day__text"]}>
              {format(propsObj.date, "dd")}
            </div>
          </div>
        </div>
        <div
          className={classes["timesheet"]}
          onDoubleClick={onOpenTaskDetailHandler}
        >
          <Stack spacing={0.25} className={`${classes["scrollable"]}`}>
            {propsObj.holidayInfo.isHoliday ? (
              <TimeSheetItem
                projectName={
                  propsObj.holidayInfo?.holidayInfo.name || "Holiday"
                }
                hours="08:00"
                color="leave-task"
              />
            ) : (
              <>
                {propsObj.listLeaveForm.length > 0
                  ? propsObj.listLeaveForm.map((d, index) => (
                      <TimeSheetItem
                        key={index}
                        projectName={d.type}
                        hours={d.hours}
                        color="leave-task"
                      />
                    ))
                  : null}
                {propsObj.listTask.length > 0
                  ? propsObj.listTask.map((d, index) => (
                      <TimeSheetItem
                        key={index}
                        projectName={d.timesheetProjectName}
                        hours={d.hours}
                        color={d.color}
                      />
                    ))
                  : null}
                {propsObj.listOvertimes.length > 0
                  ? propsObj.listOvertimes.map((d, index) => (
                      <TimeSheetItem
                        key={index}
                        projectName={`OT :  ${d.projectName}`}
                        hours={d.hours}
                        color="ot-task"
                      />
                    ))
                  : null}
              </>
            )}
          </Stack>
        </div>

        <div className={classes["summary"]}>
          <div
            className={`${classes["summary__ot"]} ${
              !propsObj.ot ? classes["hidden"] : ""
            }`}
          >
            OT: {propsObj.ot}
          </div>
          <div
            className={`${classes["summary__total"]} ${
              !propsObj.totalTime ? classes["hidden"] : ""
            }`}
          >
            <span
              style={{
                color: propsObj.failedTime
                  ? "violet"
                  : propsObj.enough8h
                  ? null
                  : "#ff8c00",
              }}
            >{`${propsObj.totalTime}`}</span>
            <span
              style={{ color: "gray" }}
            >{` /${propsObj.totalFingerPrint}`}</span>
          </div>
        </div>
      </Box>
      {editPopup}
    </div>
  );
}

export default DateTimeSheet;
