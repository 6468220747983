import { IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ClearIcon from "@mui/icons-material/Clear";
import * as React from "react";
import { useState } from "react";
import DeletePopup from "A_SHARED_COMPONENT/DeletePopup";
import GroupManagerPopUp from "../GroupManagerPopup";
import RestoreIcon from "@mui/icons-material/Restore";
import { makeStyles } from "@mui/styles";
import SuccessPopup from "A_SHARED_COMPONENT/SuccessPopup";
import AdminTeamAPI from "B_API/AdminTeamAPI";

function TeamTable({
  width,
  height,
  listSelection,
  className,
  listAllUsers,
  listTeam,
  setListTeam,
  currentTeamId,
  currentGroupType,
  setShowSuccessPopup,
  setShowErrorPopup,
}) {
  const useStyles = makeStyles({
    inactive: {
      backgroundColor: "#eff0f3",
      color: "#001858",
    },
  });

  const [groupInfo, setGroupInfo] = useState();

  const [showGroupManagerPopup, setShowGroupManagerPopup] = useState({
    show: false,
  });
  const handleClose = () => {
    setShowGroupManagerPopup({ show: false });
  };

  const [showDeletePopup, setShowDeletePopup] = useState({
    show: false,
  });

  const handleCloseDeletePopup = () => {
    setShowDeletePopup({ show: false });
  };

  const handleSuccessClose = async () => {
    setShowDeletePopup({ show: false });
    if (setShowSuccessPopup) setShowSuccessPopup();
    const response = await AdminTeamAPI.getTeamOrGroup(0);
    if (setListTeam) setListTeam(response.data);
  };

  let groupManagerPopup = null;
  if (showGroupManagerPopup.show === true) {
    groupManagerPopup = (
      <GroupManagerPopUp
        open={showGroupManagerPopup.show}
        groupInfo={groupInfo}
        isNew={false}
        setShowSuccessPopup={handleSuccessClose}
        setShowErrorPopup={setShowErrorPopup}
        listAllUsers={listAllUsers}
        onClose={handleClose}
      />
    );
  }

  let popup = null;
  if (showDeletePopup.show === true) {
    popup = (
      <DeletePopup
        open={showDeletePopup.show}
        onClose={handleCloseDeletePopup}
        data={groupInfo}
        setListTeam={setListTeam}
        setShowSucessPopup={handleSuccessClose}
        isGroup={true}
      />
    );
  }
  const listActive = [
    {
      id :1,
      name: "active"
    },
    {
      id :0,
      name: "inactive"
    }
  ]
  const columns = [
    {
      field: "teamId",
      headerName: "Id",
      headerAlign: "center",
      headerClassName: "MuiDataGrid-header",
      type: "text",
      cellClassName: "MuiDataGrid-tablecell",
      hide: true,
      flex: 2,
    },
    {
      field: "teamCode",
      headerName: "TEAM CODE",
      headerAlign: "center",
      headerClassName: "MuiDataGrid-header",
      cellClassName: "MuiDataGrid-tablecell",
      type: "text",
      flex: 2,
      minWidth: 120,
    },
    {
      field: "teamName",
      headerName: "TEAM NAME",
      headerAlign: "center",
      headerClassName: "MuiDataGrid-header",
      cellClassName: "MuiDataGrid-tablecell",
      type: "text",
      flex: 3,
      minWidth: 200,
    },
    {
      field: "type",
      headerName: "TYPE",
      headerAlign: "center",
      headerClassName: "MuiDataGrid-header",
      cellClassName: "MuiDataGrid-tablecell",
      type: "text",
      flex: 3,
      hide: true,
      minWidth: 90,
    },
    {
      field: "teamDescription",
      headerName: "LEADER",
      headerClassName: "MuiDataGrid-header",
      cellClassName: "MuiDataGrid-tablecell",
      headerAlign: "center",
      flex: 3,
      type: "text",
      minWidth: 120,
    },
    {
      field: "member",
      headerName: "NUMBER OF MEMBER",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      flex: 2,
      type: "text",
      minWidth: 75,
    },
    {
      field: "createdDate",
      headerName: "CREATE DATE",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      flex: 3,
      type: "date",
      minWidth: 100,
      renderCell: (params) => {
        let value = params.value.replace("T", " ");
        return <div>{value}</div>;
      },
    },
    {
      field: "active",
      headerName: "STATUS",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
      flex: 1,
      type: "text",
      minWidth: 75,
      renderCell: (params) => {
        var cellRender = "";
        if (params.value === "active") {
          cellRender = <div className="status status--active">active</div>;
        } else if (params.value === "inactive") {
          cellRender = <div className="status status--inactive">inactive</div>;
        }
        return cellRender;
      },
    },
    {
      field: "action",
      headerName: "ACTION",
      headerClassName: "MuiDataGrid-header",
      headerAlign: "center",
      cellClassName: "MuiDataGrid-tablecell MuiDataGrid-cell--textCenter ",
      sortable: false,
      minWidth: 80,

      renderCell: (params) => {
        const dataInput = listTeam.find((x) => x.teamId === params.row.teamId);
        const onClickEdit = async (params) => {
          setGroupInfo(dataInput);
          setShowGroupManagerPopup({ show: true });
        };
        const onClickDelete = async () => {
          setGroupInfo(dataInput);
          setShowDeletePopup({ show: true });
        };
        if (params.row.isDepartment === 1) {
          return (
            <div>
              <IconButton onClick={onClickEdit}>
                <AssignmentIcon />
              </IconButton>
            </div>
          );
        } else {
          if (params.row.isActive === 1) {
            return (
              <div>
                <IconButton onClick={onClickEdit}>
                  <AssignmentIcon />
                </IconButton>
                <IconButton onClick={onClickDelete}>
                  <ClearIcon />
                </IconButton>
              </div>
            );
          } else {
            return (
              <div>
                <IconButton onClick={onClickEdit}>
                  <AssignmentIcon />
                </IconButton>
                <IconButton onClick={onClickDelete}>
                  <RestoreIcon />
                </IconButton>
              </div>
            );
          }
        }
      },
      flex: 1,
    },
  ];

  const classes = useStyles();

  let renderListTeam = listTeam;

  if (currentTeamId !== 0) {
    renderListTeam = renderListTeam.filter(
      (x) => x.manageTeamBy === currentTeamId
    );
  }
  if (currentGroupType === 1) {
    renderListTeam = renderListTeam.filter(
      (x) => x.isDepartment === parseInt(currentGroupType)
    );
  }
  if (currentGroupType === 2) {
    renderListTeam = renderListTeam.filter((x) => x.isDepartment === 0);
  }
  const reRenderListTeam = renderListTeam.map((request, index) => ({
      ...request,
      active: listActive.filter(st => st.id === request.isActive)[0]?.name
    }));
  return (
    <div className="data-table">
      <DataGrid
        rows={reRenderListTeam}
        getRowId={(row) => row.teamId}
        rowHeight={40}
        columns={columns}
        pageSize={15}
        rowsPerPageOptions={[7]}
        getRowClassName={(params) => {
          return params.row.isActive === 0 ? classes.inactive : "";
        }}
      />
      {groupManagerPopup}
      {popup}
    </div>
  );
}

export default TeamTable;
