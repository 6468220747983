import React, { useState } from "react";
import "A_SHARED_COMPONENT/shared_component.scss";
import { Button, Dialog } from "@mui/material";
import Slide from "@mui/material/Slide";
import AdminUserAPI from "B_API/AdminUserAPI";
import AdminTeamAPI from "B_API/AdminTeamAPI";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

DeletePopup.propTypes = {};

function DeletePopup({
  open,
  onClose,
  onConfirmed,
  isGroup,
  data,
  setListAllUser,
  setShowSucessPopup,
  setListTeam,
  teamSelected,
}) {
  const handleClose = () => {
    onClose();
  };

  const [showDeletePopup, setShowDeletePopup] = useState({
    show: open,
  });

  const handlesConfirmed = async () => {
    if (onConfirmed) onConfirmed();
    //Đoạn này sẽ gửi ID lên server, sau đó server tìm theo id rồi set status thành Inactive
    if (isGroup) {
      let dataSubmit = {
        ...data,
        isActive: data?.isActive === 0 ? 1 : 0,
      };

      await AdminUserAPI.changeTeamStatus(dataSubmit);
      const response = await AdminTeamAPI.getTeamOrGroup(0);
      if (setListTeam) setListTeam(response.data);
    } else {
      await AdminUserAPI.ChangeStateByUserId(data?.userId);

      const response = await AdminUserAPI.getListUserCredentialByTeamId(
        teamSelected
      );
      setListAllUser(response.data);
    }

    if (setShowSucessPopup) setShowSucessPopup();

    setShowDeletePopup({
      show: false,
    });
  };

  let description = "";
  let title = "";
  let btnName = "";
  if (!isGroup) {
    if (data.status === "active") {
      title = "Deactive user";
      description = "Are you sure change status of user to Inactive?";
      btnName = "Deactive";
    } else {
      title = "Active user";
      description = "Are you sure change status of user to Active?";
      btnName = "active";
    }
  } else {
    if (data.isActive === 1) {
      title = "Deactive group";
      description = "Are you sure change status of group to Deactive?";
      btnName = "Deactive";
    } else {
      title = "Active group";
      description = "Are you sure change status of group to Active?";
      btnName = "Active";
    }
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={showDeletePopup.show}
        TransitionComponent={Transition}
        keepMounted
      >
        <div className="shared__delete-popup">
          <div className="delete-popup__title">{title}</div>
          <div className="delete-popup__icon">
            {data.status === "active" ? (
              <i className="far fa-times-circle"></i>
            ) : (
              <i
                className="fa fa-check-circle"
                aria-hidden="true"
                style={{ color: "green" }}
              ></i>
            )}
          </div>
          <div className="delete-popup__description">{description}</div>
          <div className="delete-popup__footer">
            <Button
              className="button--cancel"
              variant="contained"
              onClick={handleClose}
              color="error"
              sx={{ width: "100px" }}
            >
              CANCEL
            </Button>
            <Button
              className="button--delete"
              variant="contained"
              onClick={handlesConfirmed}
              sx={{ width: "100px" }}
            >
              {btnName}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default DeletePopup;
