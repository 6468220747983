import { Button } from "@mui/material";
import React from "react";
import ReactToPrint from "react-to-print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ExportPdfOT from "../ExportPage";

class ExportPdfOTComponent extends React.Component {
  render() {
    return (
      <div>
        <ExportPdfOT
          ref={(response) => (this.componentRef = response)}
          listRequestPrint={this.props.listRequestPrint}
          totalWeekday={this.props.totalWeekday}
          totalWeekend={this.props.totalWeekend}
          totalNightOvertime={this.props.totalNightOvertime}
          totalPublicHoliday={this.props.totalPublicHoliday}
          userInfo={this.props.userInfo}
        />

        <ReactToPrint
          content={() => this.componentRef}
          trigger={() => (
            <Button
              className="button__export-pdf"
              variant="contained"
              size="small"
              startIcon={<PictureAsPdfIcon />}
              sx={{
                height: "40px",
                lineHeight: "1",
              }}
            >
              Export pdf
            </Button>
          )}
        />
      </div>
    );
  }
}

export default ExportPdfOTComponent;
