import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import TeamTable from "./TeamTable";
import GroupManagerPopUp from "./GroupManagerPopup";
import SelectionTeamAndGroup from "./SelectionTeamAndGroup";
import AdminTeamAPI from "B_API/AdminTeamAPI";
import SelectionTeamField from "Z_SERVICES/g_AdminTimeSheetServices/components/SelectionTeamField";
import AdminUserAPI from "B_API/AdminUserAPI";
import { ToastShowError, ToastShowSuccess } from "F_UTILS/Notify";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";

TeamAndGroupTab.propTypes = {};

function TeamAndGroupTab(props) {
  const initTeamId = JSON.parse(localStorage.getItem("user"))?.teamId;

  const [groupInfo, setGroupInfo] = useState({});
  const { listTeamOrGroup, setListTeamOrGroup, listTeamInCompany } = props;

  const [AllUsers, setAllUser] = useState([]);

  useEffect(() => {
    const fetchGetAllUsers = async () => {
      try {
        const response = await AdminUserAPI.getListUserCredentialByTeamId(0);
        setAllUser(response.data);
      } catch (error) {
        console.log("Error get User List ", error);
      }
    };
    fetchGetAllUsers();
  }, []);

  const [newDialog, setNewDialog] = useState({
    show: false,
  });

  const handleClose = () => {
    setNewDialog({ show: false });
  };

  const handleCreateNewGroup = () => {
    setGroupInfo({
      teamCode: "",
      isDepartment: 0,
      name: "",
      leader: "",
    });
    setNewDialog({ show: true });
  };

  const setShowSuccessPopup = async () => {
    ToastShowSuccess("Team and group has been updated successfully.");
    const response = await AdminTeamAPI.getTeamOrGroup(0);
    if (setListTeamOrGroup) setListTeamOrGroup(response.data);
  };
  const setShowError = (message) => {
    ToastShowError(message || "Team and group updated failed.");
  };

  let popup = null;
  if (newDialog.show === true) {
    popup = (
      <GroupManagerPopUp
        open={newDialog.show}
        groupInfo={groupInfo}
        isNew={true}
        setShowSuccessPopup={setShowSuccessPopup}
        setShowErrorPopup={setShowError}
        listAllUsers={AllUsers.filter((user) => user.status === "active")}
        onClose={handleClose}
      />
    );
  }

  const [currentTeamId, setTeamId] = React.useState(initTeamId || 0);
  const handleOnTeamChange = (teamId) => {
    setTeamId(teamId);
  };

  const [groupType, setGroupType] = React.useState(0);
  const handleOnGroupChange = (value) => {
    setGroupType(value);
  };

  return (
    <div className="user-content__group">
      <div className="user-content__group--selection">
        <div className="group-selection">
          <SelectionTeamField
            width={200}
            marginRight={2}
            leftAlign={1}
            showAll
            id="admin__selection-team-id"
            initTeamId={currentTeamId}
            listTeams={listTeamInCompany}
            onTeamChange={handleOnTeamChange}
          />
          <SelectionTeamAndGroup
            width={200}
            id="0"
            initGroupType={groupType}
            handleOnGroupChange={handleOnGroupChange}
            setListTeam={setListTeamOrGroup}
          />
        </div>
        <div className="group-summary">
          <h5>Total of team : {listTeamOrGroup.length} team</h5>
        </div>
        <div className="group-create">
          <Button
            className="button--create"
            variant="contained"
            startIcon={<GroupAddIcon />}
            sx={{ width: "150px", marginLeft: "5px" }}
            size="small"
            onClick={handleCreateNewGroup}
          >
            Create New
          </Button>
        </div>
      </div>

      <div className="user-content__group--table">
        <TeamTable
          listTeam={listTeamOrGroup}
          listAllUsers={AllUsers}
          setListTeam={setListTeamOrGroup}
          currentTeamId={currentTeamId}
          currentGroupType={groupType}
          setShowSuccessPopup={setShowSuccessPopup}
          setShowErrorPopup={setShowError}
        ></TeamTable>
      </div>
      {popup}
      <CustomToastComponent />
    </div>
  );
}

export default TeamAndGroupTab;
