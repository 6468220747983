import StorageKeys from "F_UTILS/constants/StorageKeys";
import axiosClient from "./axiosClient";

const AuthApi = {
  Login(data) {
    const url = "/login";
    return axiosClient.request({
      method: "POST",
      url: url,
      data: data,
    });
  },
  LoginByGoogleAccount(params) {
    const url = `/loginbygoogleaccount?token=${params.token}`;
    return axiosClient.request({
      method: "POST",
      url: url,
    });
  },
  RefreshToken(data) {
    const url = "/token/refresh";
    return axiosClient.request({
      method: "POST",
      url: url,
      data: data,
    });
  },
  Logout(data) {
    const url = "/token/revoke";
    return axiosClient.request({
      method: "POST",
      url: url,
      data: data,
    });
  },
  async GetUserCredentialByEmail(params) {
    const accessToken = localStorage.getItem(StorageKeys.access);
    const url = `Users/GetUserCredentialByEmail?email=${params.email}`;
    return axiosClient.request({
      method: "GET",
      url: url,
      headers: {
        Authorization: `Bearer ${params.access ? params.access : accessToken}`,
      },
    });
  },
};

export default AuthApi;
