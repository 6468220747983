import React from "react";
import PropTypes from "prop-types";

NoticeItemBlock.propTypes = {};

function NoticeItemBlock({ notice, color }) {

  if (color === "success") {
    color = `#34c38f`
  } else if (color === "danger") {
    color = `#f46a6a`
  } else if (color === "warning") {
    color = `#f1b44c`
  } else {
    color = `#74788d`
  }

  return (
    <div className="dashboard__noticeblock" style={{ backgroundColor: color }}>
      {notice}
    </div>
  );
}

export default NoticeItemBlock;
