import { TextField, MenuItem, Button } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useCallback, useEffect, useState } from "react";
import classes from "../TimeSheetService.module.scss";
import * as yup from "yup";
import moment from "moment";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup
  .object()
  .shape({
    description: yup.string().required("Please enter the description"),
  })
  .required();

function TaskComponent(props) {
  const form = useForm({
    defaultValues: {
      email: "",
      description: "",
    },
    resolver: yupResolver(schema),
  });

  let initProps = {
    index: props.index,
    listCategory: props.listCategory || [],
    listObject: props.listObject || [],
    timesheetTaskId: props.timesheetTaskId || "",
    timesheetProjectId: props.timesheetProjectId || "",
    timesheetCategoryId:
      props.timesheetCategoryId || props.listCategory[0]?.timesheetCategoryId,
    timesheetObjectId:
      props.timesheetObjectId || props.listObject[0]?.timesheetObjectId,
    description: props.description || "",
    hours: props.hours || "",
  };
  const [propsObj, setPropsObj] = useState(initProps);

  const onPropsChange = useCallback(() => {
    setPropsObj(initProps);
  }, [props]);

  const categoryChangeHandler = (event) => {
    setPropsObj({ ...propsObj, timesheetCategoryId: event.target.value });
    if (propsObj.index < 0) {
      props.onCategoryValueChange(event.target.value);
    } else {
      props.onCategoryValueChange({
        value: event.target.value,
        index: propsObj.index,
      });
    }
  };
  const objectChangeHandler = (event) => {
    setPropsObj({ ...propsObj, timesheetObjectId: event.target.value });
    if (propsObj.index < 0) {
      props.onObjectValueChange(event.target.value);
    } else {
      props.onObjectValueChange({
        value: event.target.value,
        index: propsObj.index,
      });
    }
  };
  const descriptionChangeHandler = (event) => {
    // props.onDescriptionValueChange(event.target.value);
    setPropsObj({ ...propsObj, description: event.target.value });
    if (propsObj.index > -1) {
      props.onDescriptionValueChange({
        value: event.target.value,
        index: propsObj.index,
      });
    } else {
      props.onDescriptionValueChange(event.target.value);
    }
  };
  const hourChangeHandler = (event) => {
    setPropsObj({ ...propsObj, hours: event.target.value });
    if (propsObj.index > -1) {
      props.onHoursValueChange({
        value: event.target.value,
        index: propsObj.index,
      });
    } else {
      props.onHoursValueChange(event.target.value);
    }
  };
  const hourPickerHandler = (event) => {
    setPropsObj({ ...propsObj, hours: event.format("HH:mm") });
  };

  const onRemoveClickHandler = (event) => {
    props.onRemoveClick(propsObj.index);
  };

  useEffect(() => {
    onPropsChange();
  }, [onPropsChange]);

  return (
    <form className={classes["body__row"]}>
      <TextField
        className={`${classes["row__input"]} ${classes["row__input--category"]}`}
        select
        value={
          propsObj.timesheetCategoryId ||
          initProps.listCategory[0]?.timesheetCategoryId ||
          0
        }
        onChange={categoryChangeHandler}
        name="category"
        form={form}
        disabled={props?.lockMode}
      >
        {propsObj.listCategory.map((option, index) => (
          <MenuItem
            key={`${index}-${option.timesheetCategoryId}`}
            value={option.timesheetCategoryId || 0}
          >
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        className={`${classes["row__input"]} ${classes["row__input--object"]}`}
        id="outlined-select-currency"
        select
        value={
          propsObj.timesheetObjectId ||
          initProps.listObject[0]?.timesheetObjectId ||
          0
        }
        disabled={props?.lockMode}
        onChange={objectChangeHandler}
      >
        {propsObj.listObject.map((option) => (
          <MenuItem
            key={option.timesheetObjectId}
            value={option.timesheetObjectId}
          >
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        className={`${classes["row__input"]} ${classes["row__input--description"]}`}
        variant="outlined"
        value={propsObj.description}
        onChange={descriptionChangeHandler}
        placeholder="Work description"
        onBlur={(event) => {
          if (propsObj.index > -1) {
            props.onDescriptionValueChange({
              value: event.target.value,
              index: propsObj.index,
            });
          } else {
            props.onDescriptionValueChange(event.target.value);
          }
        }}
        name="description"
        autoFocus
        form={form}
        disabled={props?.lockMode}
      />

      <TextField
        className={`${classes["row__input"]} ${classes["row__input--hour"]}`}
        variant="outlined"
        value={propsObj?.hours || ""}
        onChange={hourChangeHandler}
        disabled={props?.lockMode}
        placeholder="Ex: 1.0, 2.5"
        onBlur={(event) => {
          if (propsObj.index > -1) {
            props.onHoursValueChange({
              value: event.target.value,
              index: propsObj.index,
            });
          } else {
            props.onHoursValueChange(event.target.value);
          }
        }}
      />

      {propsObj.index > -1 ? (
        <Button
          className={`${classes["row__button"]} 
                            ${classes["button"]} 
                            ${classes["--danger"]}`}
          variant="contained"
          startIcon={<RemoveCircleOutlineIcon style={{ fontSize: "27px" }} />}
          onClick={onRemoveClickHandler}
          disabled={props?.lockMode}
        ></Button>
      ) : (
        <div className={`${classes[""]} ${classes["row__button"]} `}></div>
      )}
    </form>
  );
}

export default TaskComponent;
