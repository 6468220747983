import React from "react";
import PropTypes from "prop-types";

StatusItem.propTypes = {};

function StatusItem({ name, color }) {
  return (
    <div className="menutopbar__status">
      <div
        className="menutopbar__circle"
        style={{ backgroundColor: color }}
      ></div>
      <div className="menutopbar__text">{name}</div>
    </div>
  );
}

export default StatusItem;
