import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Controller } from "react-hook-form";
import { TextFields } from "@material-ui/icons";

TextInputFieldProject.propTypes = {};
const useStyle = makeStyles((theme) => ({
  input__all: {
    margin: "5px 0",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  input_label: {
    marginBottom: "3px",
    fontWeight: 600,
    fontSize: "16px",
  },
}));

function TextInputFieldProject(props) {
  const classes = useStyle();
  const {
    name,
    form,
    label,
    placeholder,
    rows,
    type,
    disable,
    defaultValue,
    value,
    multiline,
  } = props;

  const { errors, formState } = form;
  const hasError = errors[name];
  const hasTouched = formState.touched[name];

  const [valueChanged, setValueChanged] = useState(value);

  const handleChange = (e) => {
    if (e) {
      setValueChanged(`${valueChanged}e.target.value`);

      form.setValue(name, e.target.value, {
        shouldValidate: true,
      });

      let valueOfForm = form.getValues();
      let {
        fiscalYear,
        projectCodeAbbr,
        projectDescription,
        clientCode,
        projectType,
        teamCode,
        projectNumber,
      } = valueOfForm;

      if (clientCode !== "NONE") {
        form.setValue(
          "fullProjectName",
          `${fiscalYear}${projectType}${projectNumber}${teamCode}.${projectCodeAbbr}-${clientCode}.${projectDescription}`,
          {
            shouldValidate: true,
          }
        );
      } else {
        form.setValue(
          "fullProjectName",
          `${fiscalYear}${projectType}${projectNumber}${teamCode}.${projectCodeAbbr}-${projectDescription}`,
          {
            shouldValidate: true,
          }
        );
      }
    }
  };

  return (
    <div className={classes.input__all}>
      <div className={classes.input_label}>{label}</div>

      <Controller
        name={name}
        control={form.control}
        render={(obj) => {
          let value = obj?.value;
          return (
            <TextField
              disabled={disable}
              variant="outlined"
              onChange={(e) => handleChange(e)}
              defaultValue={value}
            />
          );
        }}
        // TRANSFER
        type={type}
        size="small"
        variant="outlined"
        placeholder={placeholder}
        fullWidth={true}
        disabled={disable}
        minRows={rows}
        multiline={!!multiline ? false : true}
        error={!!hasError}
        helperText={errors[name]?.message}
      />
    </div>
  );
}

export default TextInputFieldProject;
