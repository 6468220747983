import React, { useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ChangeStatusLeavePopup from "../ChangStatusLeavePopup";
import ChangeStatusSuccessPopup from "../SuccessPopup";
import ErrorPopup from "A_SHARED_COMPONENT/ErrorPopup";
import { useDispatch } from "react-redux";
import { getOTandLeavePending } from "E_REDUX_STORE/OTandLeaveCount";
import { ToastShowError, ToastShowSuccess } from "F_UTILS/Notify";
import AdminLeaveFormAPI from "B_API/AdminLeaveFormAPI";

function ChangeStatusLeaveForm({ width, data, valueInit, id }) {
  const dispatch = useDispatch();

  const [selected, setSelected] = React.useState(valueInit || "All");

  const [showError, setShowError] = useState({
    show: false,
    description: "Error",
  });

  const handleErrorPopupClose = () => {
    setShowError({
      show: false,
      description: "Error",
    });
  };
  const handleSuccessPopupClose = () => {
    //
  };

  var listLeaveType = [
    { id: 1, leaveType: "Annual Leave" },
    { id: 2, leaveType: "Sick Leave" },
    { id: 3, leaveType: "Summer Leave" },
    { id: 4, leaveType: "Wedding Leave" },
    { id: 5, leaveType: "Maternity" },
    { id: 6, leaveType: "Unpaid Leave" },
    { id: 7, leaveType: "Compensatory" },
  ];

  const handleChange = async (event) => {
    let valueSelected = event.target.value;

    let status = 0;
    if (valueSelected === "Pending") {
      status = 1;
    } else if (valueSelected === "Approved") {
      status = 2;
    } else if (valueSelected === "Rejected") {
      status = 3;
    }

    let requestLeave = {
      ...data,
      statusDefinitionId: status,
      status: valueSelected,
      leaveTypeId: listLeaveType.find((leave) => leave.leaveType === data.type)
        .id,
    };

    try {
      var res = await AdminLeaveFormAPI.changeLeaveStatus(requestLeave);

      if (valueSelected === "Approved") {
        if (res.data === "You used the wedding leave day!") {
          ToastShowError("No enough of wedding leaves.");
        } else if (
          res.data ===
          "Your rest of leave is not enough to claim paid leave. Please submit an application for unpaid leave instead, thanks!"
        ) {
          ToastShowError(
            "Your rest of leave is not enough to claim paid leave. Please submit an application for unpaid leave instead, thanks!"
          );
        } else if (
          res.data === "Your leave hours exceeded allowable leave hours!"
        ) {
          ToastShowError(
            "Unable to appoved because the leave registration exceeded the allowed hours."
          );
        } else {
          ToastShowSuccess("Change status done.");
          setSelected(valueSelected);
        }
      } else {
        ToastShowSuccess("Change status done.");
        setSelected(valueSelected);
      }
    } catch (error) {
      console.log("Error change status of leaveform request ", error);
    }
    const actions = getOTandLeavePending();
    await dispatch(actions);
  };

  let errorPopup = null;
  if (showError.show === true) {
    errorPopup = (
      <ErrorPopup
        open={showError.show}
        title="Error"
        description={showError.description}
        onClose={handleErrorPopupClose}
      />
    );
  }

  return (
    <Box sx={{ width: width }}>
      <FormControl fullWidth>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          id={id}
          sx={{
            fontWeight: "600 !important",
            paddingRight: "15px",
          }}
          value={selected}
          onChange={handleChange}
        >
          <MenuItem sx={{ fontWeight: 600, color: "#FF8C00" }} value="Pending">
            <div className="status--pending">Pending</div>
          </MenuItem>
          <MenuItem sx={{ fontWeight: 600, color: "#28a745" }} value="Approved">
            <div className="status--approved">Approved</div>
          </MenuItem>
          <MenuItem sx={{ fontWeight: 600, color: "#dc3545" }} value="Rejected">
            <div className="status--rejected">Rejected</div>
          </MenuItem>
        </Select>
      </FormControl>
      {errorPopup}
    </Box>
  );
}

export default ChangeStatusLeaveForm;
