import axiosClient from "./axiosClient";

const RoleAPI = {
  async getAllRoles(params) {
    const url = `/Role/GetAllRoles`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },
};

export default RoleAPI;
