import classNames from "classnames";
import moment from "moment";
import React, { useContext } from "react";
import ProjectInfoPopUp from "../../ProjectInformationPopup";
import { ProjectInfoInitContext } from "../ProjectManagement";

ProjectRowItem.propTypes = {};

function ProjectRowItem({
  projectData,
  clickDelete,
  handleChangeActiveProject,
  handleOnSubmit,
  clickOnSubmit,
}) {
  const { listInfoProjectInit, currentTeamId } = useContext(
    ProjectInfoInitContext
  );
  const [openDialog, setOpenDialog] = React.useState({
    show: false,
  });

  const handleDialogClose = (value) => {
    setOpenDialog({ show: false });
  };

  const handleClickDetail = () => {
    setOpenDialog({ show: true });
  };

  const handleClickDelete = () => {
    if (clickDelete) clickDelete();
  };
  const handleFormSubmit = (submitProject) => {
    //Check update status
    if (projectData.isActive !== submitProject.isActive) {
      if (handleChangeActiveProject) handleChangeActiveProject();
    }
    if (clickOnSubmit) clickOnSubmit();
  };

  let projectInfoPopup = null;
  if (openDialog.show === true) {
    projectInfoPopup = (
      <ProjectInfoPopUp
        open={openDialog.show}
        onClose={handleDialogClose}
        project={projectData}
        isNew={false}
        listInfoProjectInit={listInfoProjectInit}
        currentTeamId={currentTeamId}
        setNewDialog={setOpenDialog}
        handleOnSubmit={handleOnSubmit}
        formSubmitUpdate={handleFormSubmit}
        handleClickDelete={handleClickDelete}
      />
    );
  }

  return (
    <div
      className={classNames({
        "overview-project__item": true,
        inactive: projectData?.isActive === 0,
      })}
    >
      <div className="overview-project__col01">
        <h6>{projectData?.projectCode}</h6>
      </div>
      <div className="overview-project__col03">
        <h6 className="align-left">{projectData?.projectName}</h6>
      </div>
      <div className="overview-project__col01">
        <h6>{projectData.numberOfMembers}</h6>
      </div>
      <div className="overview-project__col01">
        <h6>
          {Number(projectData?.normalHours + projectData?.otHours).toFixed(2)}
        </h6>
      </div>
      <div className="overview-project__col01">
        <h6>{moment(projectData?.startedDate).format("MM/DD/YYYY")}</h6>
      </div>
      <div className="overview-project__col01">
        <h6>
          {projectData?.finishedDate !== null
            ? moment(projectData?.finishedDate).format("MM/DD/YYYY")
            : ""}
        </h6>
      </div>
      <div className="overview-project__col01">
        <h6
          className={classNames({
            status__active: projectData?.isActive === 1,
          })}
        >
          {projectData?.isActive === 1 ? "active" : "inactive"}
        </h6>
      </div>
      <div className="overview-project__col01">
        <h6
          className={classNames({
            "project__view-detail": true,
          })}
          onClick={handleClickDetail}
        >
          View
        </h6>
      </div>
      {projectInfoPopup}
    </div>
  );
}

export default ProjectRowItem;
