import React from "react";
import PropTypes from "prop-types";

SortUpDownIcon.propTypes = {};

function SortUpDownIcon({ param, ClickSort }) {
  const handleClickSort = (values) => {
    if (ClickSort) ClickSort(values);
  };

  return (
    <div className="overview-project__sort">
      <div className="sort__section">
        <i
          className="fas fa-caret-up"
          onClick={() => handleClickSort({ dir: "up", para: param })}
        ></i>
      </div>
      <div className="sort__section">
        <i
          className="fas fa-caret-down"
          onClick={() => handleClickSort({ dir: "down", para: param })}
        ></i>
      </div>
    </div>
  );
}

export default SortUpDownIcon;
