import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import DateItemBlock from "../DataItemBlock";
import DashBoardAPI from "B_API/DashBoardAPI";

CalendarBlock.propTypes = {};

function getFirstDayOfMonth(year, month) {
  return new Date(year, month, 1);
}

function CalendarBlock({
  width = "400px",
  height = "230px",
  marginTop = "0",
  marginBtn = "0",
  marginLeft = "0",
  marginRight = "0",
  fetchSummaryOfTimesheet,
  fetchProjectHours,
}) {
  const styleObject = {
    width: width,
    height: height,
    marginTop: marginTop,
    marginBtn: marginBtn,
    marginLeft: marginLeft,
    marginRight: marginRight,
  };

  const dayName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [currentDate, setCurrentDate] = useState(new Date());
  const [dateItems, setDateItems] = useState([]);
  //Update BY API

  useEffect(() => {
    const fetchSummaryCalendar = async () => {
      try {
        var response = await DashBoardAPI.getSummaryCalendar(
          currentDate.getMonth() + 1,
          currentDate.getFullYear()
        );

        var leaveformOverviews = response.data.leaveformOverviews;

        let leaveformOverviewsFilter = leaveformOverviews.filter(
          (leave, index) =>
            index ===
            leaveformOverviews.findIndex(
              (other) =>
                leave.fullName === other.fullName &&
                leave.fromDate.substring(0, 10) ===
                  other.fromDate.substring(0, 10) &&
                leave.toDate.substring(0, 10) === other.toDate.substring(0, 10)
            )
        );

        var listCalendarAssignment = response.data.listCalendarAssignment;

        var newDateItems = [];
        var startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).getDay();
        var endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        ).getDate();

        for (var i = 0; i < 35; i++) {
          if (i >= startDate && i < startDate + endDate) {
            const current = new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              i + 1 - startDate
            );
            newDateItems.push({
              date: current,
              absence:
                leaveformOverviewsFilter !== null
                  ? leaveformOverviewsFilter.filter(
                      (x) =>
                        new Date(x.fromDate).getDate() <= current.getDate() &&
                        new Date(x.toDate).getDate() >= current.getDate()
                    )
                  : [],
              notices:
                leaveformOverviewsFilter !== null
                  ? listCalendarAssignment.filter(
                      (x) => current.getDate() === new Date(x.date).getDate()
                    )
                  : [],
            });
          } else {
            newDateItems.push({
              date: null,
              absence: [],
              notices: [],
            });
          }
        }

        setDateItems(newDateItems);
      } catch (err) {
        console.log("FAILED TO FETCH SUMMARY CALENDAR: ", err);
      }
    };
    fetchSummaryCalendar();
  }, [currentDate]);

  const handlePreviousMonth = () => {
    let newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() - 1);
    setCurrentDate(newDate);

    const fromDate = new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      1
    ).toDateString();

    const toDate = new Date(
      newDate.getFullYear(),
      newDate.getMonth() + 1,
      0
    ).toDateString();

    fetchSummaryOfTimesheet(newDate.getMonth() + 1, newDate.getFullYear());
    fetchProjectHours(fromDate, toDate);
  };

  const handleNextMonth = () => {
    let newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() + 1);

    setCurrentDate(newDate);

    const fromDate = new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      1
    ).toDateString();

    const toDate = new Date(
      newDate.getFullYear(),
      newDate.getMonth() + 1,
      0
    ).toDateString();

    fetchSummaryOfTimesheet(newDate.getMonth() + 1, newDate.getFullYear());
    fetchProjectHours(fromDate, toDate);
  };

  return (
    <Paper
      className="dashboard__calendar"
      style={styleObject}
      square
      elevation={3}
      component="div"
    >
      <div className="dashboard__calendar-month">
        <i onClick={handlePreviousMonth} className="fas fa-chevron-left"></i>
        <div
          style={{ textTransform: "uppercase", width: "200px" }}
        >{`${currentDate?.toLocaleString("default", {
          month: "long",
        })} ${currentDate.getFullYear()}`}</div>
        <i onClick={handleNextMonth} className="fas fa-chevron-right"></i>
      </div>
      <div className="dashboard__calendar-date">
        <div className="dashboard__calendar-dayname">
          {dayName.map((day, index) => (
            <div key={index} className="dashboard__calendar-day">
              {day}
            </div>
          ))}
        </div>
        <div className="dashboard__calendar-daydetail">
          {dateItems?.map((dateItem, index) => (
            <DateItemBlock key={index} dateItem={dateItem} />
          ))}
        </div>
      </div>
    </Paper>
  );
}

export default CalendarBlock;
