import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import ConfirmPopup from "A_SHARED_COMPONENT/ConfirmPopup";
import ErrorPopup from "A_SHARED_COMPONENT/ErrorPopup";
import SuccessPopup from "A_SHARED_COMPONENT/SuccessPopup";
import TextInputField from "A_SHARED_COMPONENT/TextInputField";
import AdminUserAPI from "B_API/AdminUserAPI";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as yup from "yup";
import PasswordField from "Z_SERVICES/k_LogInServices/components/PasswordFeild";
import "../../UserInformationService.scss";
import { ToastShowError, ToastShowSuccess } from "F_UTILS/Notify";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";

SecuritySetting.propTypes = {};

function SecuritySetting(props) {
  const user = useSelector((state) => state.user.current);
  let initPropsObj = {
    email: props.email || "",
    newPassword: props.newPassword || "",
    oldPassword: props.oldPassword || "",
    confirmedPassword: props.confirmedPassword || "",
  };
  const [securitySetting, setSecuritySetting] = useState(initPropsObj);

  const [propsObj, setPropsObj] = useState(initPropsObj);
  const onPropsChange = useCallback(() => {
    setPropsObj(initPropsObj);
  }, [props]);

  useEffect(() => {
    onPropsChange();
  }, [onPropsChange]);

  const schema = yup
    .object()
    .shape({
      oldPassword: yup.string().required("Please enter the password"),
      newPassword: yup.string().required("Please enter the password"),
      confirmedPassword: yup
        .string()
        .required("Please enter the password")
        .test(
          "confirmedPassword",
          "Confirm passwords must matched new password",
          function (value) {
            return this.parent.newPassword === value;
          }
        ),
    })
    .required();

  const form = useForm({
    defaultValues: {
      ...securitySetting,
      newPassword: "",
      oldPassword: "",
      confirmedPassword: "",
    },
    resolver: yupResolver(schema),
  });

  const handleSubmitForm = (values) => {
    const data = {
      ...user,
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };

    //CONFIRM POPUP
    const callBackFunction = async () => {
      // Post data into api
      try {
        var response = await AdminUserAPI.UpdateAccountSetting(data);
        if (response.status === 200) {
          ToastShowSuccess("Update password successfully!");
          //clear form
          form.reset({
            ...securitySetting,
            newPassword: "",
            oldPassword: "",
            confirmedPassword: "",
          });
        } else {
          ToastShowError("Your password is not correct. Please check your password again!");
        }
      } catch (err) {
        ToastShowError("Your password is not correct. Please check your password again!");
      }
    };
    callBackFunction();
  };

  return (
    <>
      <Box className="security">
        <div className="security__form">
          <div className="form__input">
            <TextInputField
              name="email"
              label="Email"
              form={form}
              width="50%"
              disable={true}
            />
          </div>
          <div className="form__input">
            <PasswordField
              type="password"
              name="oldPassword"
              label="Old Password"
              placeholder="*******"
              form={form}
            />
          </div>
          <div className="form__input">
            <PasswordField
              type="password"
              name="newPassword"
              label="New Password"
              placeholder="*******"
              form={form}
            />
          </div>
          <div className="form__input">
            <PasswordField
              type="password"
              name="confirmedPassword"
              label="Confirm New Password"
              placeholder="*******"
              form={form}
            />
          </div>
        </div>
        <div className="form__button">
          <Button
            className="button"
            variant="contained"
            onClick={form.handleSubmit(handleSubmitForm)}
          >
            Save
          </Button>
        </div>
      </Box>
      <CustomToastComponent />
    </>
  );
}

export default SecuritySetting;
