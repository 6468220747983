import React, { useEffect, useState } from "react";
import UserSettingAPI from "B_API/UserSettingAPI";
import "./ProfileAvatar.scss";
import { Avatar, Button } from "@mui/material";
import ConfirmPopup from "A_SHARED_COMPONENT/ConfirmPopup";
import ErrorPopup from "A_SHARED_COMPONENT/ErrorPopup";
import SuccessPopup from "A_SHARED_COMPONENT/SuccessPopup";
import { useSelector } from "react-redux";

const ProfileAvatar = (props) => {
  const [avatar, setAvatar] = useState(props.avatar || "");
  const currentUser = useSelector((state) => state.user.current);
  // a local state to store the currently selected file.
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [preview, setPreview] = useState(null);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  const [showConfirm, setShowConfirm] = useState({
    show: false,
    callback: null,
  });

  const handleCloseConfirm = () => {
    setShowConfirm({
      show: false,
      callback: null,
    });
  };

  const handleOnCloseError = () => {
    setSelectedFile(undefined);
    setPreview(undefined);
    setShowError(false);
  };
  const handleOnSuccessError = () => {
    setSelectedFile(undefined);
    setPreview(undefined);
    setShowSuccess(false);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name}`,
    };
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const callBackFunction = async () => {
      setShowConfirm({
        show: false,
        callback: null,
      });
      const formData = new FormData();
      formData.append("selectedFile", selectedFile);

      let uploadData = {
        formData: formData,
        userId: currentUser.userId,
      };
      try {
        const response = await UserSettingAPI.uploadAvatar(uploadData);
        if (response.status === 200) {
          setAvatar(URL.createObjectURL(selectedFile));
          setShowSuccess(true);
          props.onUploadSubmit();
        }
      } catch (error) {
        setErrorMessage(`${error}`);
        setShowError(true);
      }
    };
    setShowConfirm({ show: true, callback: callBackFunction });
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleAvatarCancel = () => {
    setPreview(undefined);
    setSelectedFile(undefined);
  };

  useEffect(() => {
    if (avatar != props.avatar) {
      setAvatar(props.avatar);
    }
  }, [props]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <>
      <div className="profile__title">Your Avatar</div>
      <div className="profile__avatar">
        {!!preview ? (
          <>
            <Avatar
              variant="square"
              className="avatar__image"
              alt="Your Avatar"
              src={preview}
              {...stringAvatar(props.lastName)}
            />
          </>
        ) : (
          <Avatar
            variant="square"
            className="avatar__image"
            alt="Your Avatar"
            src={props.avatar}
            {...stringAvatar(props.lastName)}
          />
        )}

        <Button
          component="label"
          className="avatar__button"
          variant="contained"
        >
          <input
            type="file"
            hidden
            onChange={handleFileSelect}
            accept="image/*"
          />
          UPLOAD AVATAR
        </Button>
        {!!selectedFile ? (
          <>
            <Button
              className="avatar__button avatar__button--success"
              variant="contained"
              onClick={handleSubmit}
            >
              Confirm
            </Button>
            <Button
              className="avatar__button avatar__button--danger"
              variant="contained"
              disabled={false}
              onClick={handleAvatarCancel}
            >
              Cancel
            </Button>
          </>
        ) : null}
      </div>

      <ConfirmPopup
        open={showConfirm.show}
        onClose={handleCloseConfirm}
        onConfirmed={showConfirm.callback}
        title="Are you sure?"
        description="Do you want to save your avatar?"
      />
      <ErrorPopup
        open={showError}
        title="Error"
        description={errorMessage}
        onClose={handleOnCloseError}
      />
      <SuccessPopup
        open={showSuccess}
        title="Success"
        description="Update your avatar successfully."
        onClose={handleOnSuccessError}
      />
    </>
  );
};

export default ProfileAvatar;
