import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";
import { Controller } from "react-hook-form";
import "./style.scss";

const useStyle = makeStyles((theme) => ({
  input__all: {
    margin: "5px 0",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    whiteSpace: "pre-line",
    wordBreak: "break-all",
  },

  input_label: {
    marginBottom: "3px",
    fontWeight: 600,
    fontSize: "16px",
    textTransform: "capitalize",
  },
}));

function DropdownListSelectionNoForm(props) {
  const classes = useStyle();

  const { label, disable, listSelection, initIndex } = props;
  return (
    <div className={classes.input__all}>
      <div className={classes.input_label}>{label}</div>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={initIndex}
              disabled={disable}
            >
              {listSelection.map((item, index) => (
                <MenuItem key={index} value={index}>
                  <p>{item}</p>
                </MenuItem>
              ))}
            </Select>
    </div>
  );
}

export default DropdownListSelectionNoForm;
