import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import commonReducer from "./commonSlice";
import timesheetReducer from "./timesheetSlice";
import reportReducer from "./reportSlice";
import teamReducer from "./teamSlice";
import calendarReducer from "./calendarSlice";
import StatusReducer from "./statusSlice";
import countOTandLeavePendingReducer from "./OTandLeaveCount";

const rootReducer = {
  user: userReducer,
  timesheet: timesheetReducer,
  report: reportReducer,
  team: teamReducer,
  calendar: calendarReducer,
  common: commonReducer,
  status: StatusReducer,
  OtLeavePending: countOTandLeavePendingReducer,
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
