import axiosClient from "./axiosClient";

const ProjectAPI = {
  getCategory: (params) => {
    const url = "/TimesheetCategory";
    return axiosClient.get(url);
  },

  getObject: (params) => {
    const url = "/TimesheetObject";
    return axiosClient.get(url);
  },

  async GetClients(params) {
    const url = "/TimesheetProject/GetClients";
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async GetProjectList(params) {
    const url = "/TimesheetProject/GetProjectList";
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async GetInitProjectCode(params) {
    const url = "/TimesheetProject/GetInitProjectCode";
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  getObject: (params) => {
    const url = "/TimesheetObject";
    return axiosClient.get(url);
  },

  async getProjectByUser(params) {
    const teamId = JSON.parse(localStorage.getItem("user")).teamId;

    const url = `/TimesheetProject/GetAllTimesheetProjectsByTeamId/${teamId}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },
};
export default ProjectAPI;
