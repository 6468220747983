import axiosClient from "./axiosClient";

const AdminLeaveFormAPI = {
  async getListLeave() {
    const url = `/Reports/GetVwLeaveformListRequest`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async getListLeaveByTeamIdAndStatus(teamId, statusId) {
    const url = `/Reports/GetVwLeaveformListRequestByTeamIdAndStatusId/${teamId}/${statusId}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async getListLeaveByStatus(Status) {
    const url = `/Reports/GetVwLeaveformListRequestByStatusId/${Status}`;

    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async changeLeaveStatus(params) {
    const url = `/Leaveform/ChangeStatusOfRequest/${params.leaveformId}`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: params,
    });
  },
};
export default AdminLeaveFormAPI;
