import classes from "../CalendarService.module.scss";

import { Box, Paper, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function UserSearch(props) {
  let onChangeHandle = (event) => {
    props.onSearchInputChange(event.target.value);
  };

  return (
    <Paper className={classes["menu_user__search"]}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
        className={classes["search__wrap"]}
      >
        <SearchIcon className={classes["search__icon"]} />
        <TextField
          className={classes["search__input"]}
          placeholder="Search..."
          variant="standard"
          onChange={onChangeHandle}
        />
      </Box>
    </Paper>
  );
}

export default UserSearch;
