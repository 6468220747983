import { yupResolver } from "@hookform/resolvers/yup";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button } from "@mui/material";
import PublicHolidayAPI from "B_API/PublicHolidayAPI";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import FilterPublicHolidayByYear from "./FilterPublicHolidayByYear";
import HolidayManagementPopup from "./HolidayManagementPopup";
import PublicHolidayTable from "./PublicHolidayTable/PublicHolidayTable";

PublicHolidayTab.propTypes = {};
const schema = yup
  .object()
  .shape({
    name: yup.string().required("Please enter the holiday name."),
    fromDate: yup.string().required("Please enter from date."),
    toDate: yup.string().required("Please enter to date."),
  })
  .required();

function PublicHolidayTab() {
  const initTeamId = JSON.parse(localStorage.getItem("user"))?.teamId;
  let setInitTeam = initTeamId === 4 ? 0 : initTeamId;

  const [status, setStatus] = React.useState(0);
  const [dateSelected, setDateSelected] = React.useState(new Date());

  const [currentTeamId, setTeamId] = React.useState(setInitTeam ?? 0);

  const [publicHolidays, setPulicHolidays] = useState([]);
  const [listAllHolidays, setListAllHolidays] = useState([]);

  const [holidayManagementPopup, setHolidayManagementPopup] = useState({
    show: false,
  });

  const holidayInfo = {
    name: "",
    fromDate: new Date(),
    toDate: new Date(),
  };

  const fetchGetPublicHolidays = async () => {
    try {
      const response = await PublicHolidayAPI.GetPublicHolidayRequest();

      setListAllHolidays(response.data);
      var listHolidays = response.data
        .filter((item) => item.year === new Date().getFullYear())
        .map((request, index) => ({
          ...request,
          no: index + 1,
          month: new Date(request?.fromDate).getMonth() + 1,
        }));
      setPulicHolidays(listHolidays);
    } catch (error) {
      console.log("Error get list Holiday", error);
    }
  };

  useEffect(() => {
    fetchGetPublicHolidays();
  }, []);

  const onClose = () => {
    setHolidayManagementPopup({ show: false });
    fetchGetPublicHolidays();
  };

  const handleAddHoliday = () => {
    setHolidayManagementPopup({ show: true });
  };

  const handleFilterByYear = (year) => {
    setPulicHolidays(
      listAllHolidays
        .filter((item) => item.year === year)
        .map((request, index) => ({
          ...request,
          no: index + 1,
          month: new Date(request?.fromDate).getMonth() + 1,
        }))
    );
  };

  let popup = null;
  if (holidayManagementPopup.show === true) {
    popup = (
      <HolidayManagementPopup
        open={holidayManagementPopup.show}
        holidayInfo={holidayInfo}
        onClose={onClose}
        isNew={true}
      />
    );
  }

  return (
    <div className="admin__leaveform--requesttab">
      <div
        className="requesttab__filter--condition"
        style={{ justifyContent: "space-between" }}
      >
        <FilterPublicHolidayByYear
          listAllHolidays={listAllHolidays}
          valueInit={new Date().getFullYear()}
          filterByYear={handleFilterByYear}
          width="200px"
        ></FilterPublicHolidayByYear>
        <Button
          className="button__create"
          variant="contained"
          size="small"
          color="success"
          startIcon={<AddCircleIcon />}
          sx={{
            height: "40px",
            lineHeight: "1",
          }}
          onClick={handleAddHoliday}
        >
          Create New
        </Button>
      </div>
      <div className="requesttab__table">
        <PublicHolidayTable publicHolidays={publicHolidays} onClose={onClose} />
      </div>
      {popup}
    </div>
  );
}

export default PublicHolidayTab;
