import axiosClient from "./axiosClient";

const TimeSheetAPI = {
  //01. GET time in, out, note, overtimes[], timesheetTasks[] by date param (y-m-d)
  async getTimesheetInfo(params) {
    // const userId = JSON.parse(localStorage.getItem("user")).userId;

    const url = `/Timesheet/GetValidTimesheetByOrderNoAndDate/${params.userId}/${params.currDate}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  //02. Get time in, out, note, ot, total all day in month
  async GetListTimesheetByUserIdAndMonthAndYear(params) {
    // const userId = JSON.parse(localStorage.getItem("user")).userId;

    const url = `/Timesheet/GetListVwTimesheetByUserIdAndMonthAndYear?userId=${params.userId}&month=${params.month}&year=${params.year}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  //03. Get all row of timesheetTaskId in all day of month (taskId, timesheetId, projectid, cate, object, des, hours, isover, name, date)
  async GetListTimesheetTaskByUserIdAndMonthAndYear(params) {
    // const userId = JSON.parse(localStorage.getItem("user")).userId;

    const url = `/TimesheetTask/GetListVwTimesheetTaskByUserIdAndMonthAndYear?userId=${params.userId}&month=${params.month}&year=${params.year}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  //04. Get list row task by timesheetID vs projectID => ProjectChange
  async GetListVwTimesheetTaskByTimesheetIdAndProjectId(params) {
    const url = `/TimesheetTask/GetListVwTimesheetTaskByTimesheetIdAndProjectId?timesheetId=${params.timesheetId}&projectId=${params.timesheetProjectId}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  //05. Task summary of projectId, hours, projectName
  async GetListVwTimesheetTaskSummaryHoursByUserIdAndMonthAndYear(params) {
    // const userId = JSON.parse(localStorage.getItem("user")).userId;

    const url = `/TimesheetTask/GetListVwTimesheetTaskSummaryHoursByUserIdAndMonthAndYear?userId=${params.userId}&month=${params.month}&year=${params.year}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  //06. Summary task by date (by timesheetId)
  async GetListVwTimesheetTaskSummaryHoursByUserIdAndTimesheetId(params) {
    // const userId = JSON.parse(localStorage.getItem("user")).userId;

    const url = `/TimesheetTask/GetListVwTimesheetTaskSummaryHoursByUserIdAndTimesheetId?userId=${params.userId}&timesheetId=${params.timesheetId}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  //Get all project by userID
  async GetAllTimesheetProjectsByUserId(userId) {
    // const userId = JSON.parse(localStorage.getItem("user")).userId;

    const url = `/TimesheetProject/GetAllTimesheetProjectsByUserId/${userId}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  //Get all Cate by teamID
  async GetAllTimesheetCategoriesByTeamId(teamId) {
    // const teamId = JSON.parse(localStorage.getItem("user")).teamId;

    const url = `/TimesheetCategory/GetAllTimesheetCategoriesByTeamId/${teamId}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  //Get all object by teamID
  async GetAllTimesheetObjectsByTeamId(teamId) {
    // const teamId = JSON.parse(localStorage.getItem("user")).teamId;

    const url = `/TimesheetObject/GetAllTimesheetObjectsByTeamId/${teamId}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  //08. get timesheet info by date (in,out, ot, hours)
  async GetVwTimesheetByUserIdAndDate(params) {
    // const userId = JSON.parse(localStorage.getItem("user")).userId;

    const url = `/Timesheet/GetVwTimesheetByUserIdAndDate?userId=${params.userId}&date=${params.date}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async UpdateMultipleTimesheetTask(params) {
    const url = `/TimesheetTask/UpdateMultipleTimesheetTask`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params,
    });
  },

  async DeleteTimesheetTaskByTimesheetProjectIdTimesheetId(params) {
    const url = `/TimesheetTask/DeleteTimesheetTaskByTimesheetProjectIdTimesheetId?timesheetProjectId=${params.timesheetProjectId}&timesheetId=${params.timesheetId}`;
    return axiosClient.request({
      method: "DELETE",
      url: url,
    });
  },

  async UpdateNoteTimesheet(params) {
    const url = `/Timesheet/UpdateNoteTimesheet`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: params,
    });
  },
};
export default TimeSheetAPI;
