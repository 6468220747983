import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import StatusItem from "./StatusItem";

function SelectionField({
  width,
  height,
  listSelection,
  defaultIndex = 0,
  className,
  initStatus,
  onChangeStatusHub,
}) {
  const [selected, setSelected] = React.useState(initStatus || "available");

  const handleChange = (event) => {
    let value = event.target.value;
    setSelected(value);
    if (onChangeStatusHub) onChangeStatusHub(value);
    //Trạng thái này gửi lên redux store.
  };

  return (
    <Box className={className} sx={{ width: width }}>
      <FormControl fullWidth>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selected}
          onChange={handleChange}
        >
          <MenuItem value="available">
            <StatusItem name="available" color="var(--bs-weekday)" />
          </MenuItem>
          <MenuItem value="wfh">
            <StatusItem name="WFH" color="var(--bs-bondi-blue)" />
          </MenuItem>
          <MenuItem value="business">
            <StatusItem name="business" color="var(--bs-pending)" />
          </MenuItem>
          <MenuItem value="busy">
            <StatusItem name="busy" color="red" />
          </MenuItem>
          <MenuItem value="unavailable">
            <StatusItem name="unavailable" color="gray" />
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default SelectionField;
