import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

const useStyle = makeStyles((theme) => ({
  input__all: {
    margin: "5px 0",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  input_label: {
    marginBottom: "3px",
    fontWeight: 600,
    fontSize: "16px",
  },
}));

PasswordField.propTypes = {};

function PasswordField(props) {
  const classes = useStyle();
  const { name, form, label, placeholder, onEnter, type } = props;

  const { errors, formState } = form;
  const hasError = errors[name];
  const hasTouched = formState.touched[name];

  const [showPassword, SetShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    SetShowPassword(!showPassword);
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (onEnter) onEnter();
    }
  };

  return (
    <div className={classes.input__all}>
      <div className={classes.input_label}>{label}</div>
      <FormControl sx={{ width: "100%" }} variant="outlined" error={!!hasError}>
        <Controller
          name={name}
          control={form.control}
          as={OutlinedInput}
          // TRANSFER

          type={showPassword ? "text" : "password"}
          variant="outlined"
          placeholder={placeholder}
          fullWidth={true}
          size="small"
          onKeyPress={onKeyPress}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
                size="small"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          // OTHER
        />
        <FormHelperText id={name}>{errors[name]?.message}</FormHelperText>
      </FormControl>
    </div>
  );
}

export default PasswordField;
