import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Dialog, Paper } from "@mui/material";
import Fab from "@mui/material/Fab";
import Slide from "@mui/material/Slide";
import ProjectAPI from "B_API/ProjectAPI";
import React, { useEffect, useState } from "react";
import CategoryAndObject from "./CategoryAndObject";
import MemberPipeChart from "./MemberPipeChart";
import ProjectManagement from "./ProjectManagement";
import ProjectTimeBar from "./ProjectTimeBar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

OverViewTab.propTypes = {};
const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    top: 80,
    right: 1,
  },
});

function OverViewTab({ currentTeamId }) {
  const classes = useStyles();
  const [showCateObject, setShowCateObject] = useState(false);
  const [changeProjectActive, setChangeProjectActive] = useState(false);

  const handleClose = () => {
    setShowCateObject(false);
  };

  const handleClickCateObject = () => {
    setShowCateObject(true);
  };
  const onChangeActiveProject = () => {
    setChangeProjectActive(!changeProjectActive);
  };
  const [listInfoProjectInit, setListInfoProjectInit] = React.useState([]);

  useEffect(() => {
    const fetchProjectInfoInit = async () => {
      try {
        const res = await ProjectAPI.GetInitProjectCode();
        const res1 = await ProjectAPI.GetClients();
        setListInfoProjectInit({
          fiscalAndNumber: res.data,
          listClients: res1.data,
        });
      } catch (error) {
        console.log("Error get project hours " + error);
      }
    };
    fetchProjectInfoInit();
  }, []);

  return (
    <div className="admin__timesheet-overviewtab">
      <div className="overview__left">
        <div className="overview__left-section">
          <Paper elevation={3} square className="overview__left-col01">
            <MemberPipeChart
              currentTeamId={currentTeamId}
              changeProjectActive={changeProjectActive}
            />
          </Paper>
          <Paper elevation={3} square className="overview__left-col02">
            <ProjectTimeBar
              currentTeamId={currentTeamId}
              changeProjectActive={changeProjectActive}
            />
          </Paper>
        </div>
        <div className="overview__left-section">
          <ProjectManagement
            currentTeamId={currentTeamId}
            handleChangeActiveProject={onChangeActiveProject}
            listInfoProjectInit={listInfoProjectInit}
            setListInfoProjectInit={setListInfoProjectInit}
          />
        </div>
      </div>

      {window.screen.width > 1199 && (
        <Paper component="div" elevation={3} square className="overview__right">
          <CategoryAndObject currentTeamId={currentTeamId} />
        </Paper>
      )}
      {window.screen.width < 1199 && (
        <>
          <Fab
            variant="extended"
            size="small"
            color="secondary"
            aria-label="add"
            onClick={handleClickCateObject}
            style={{
              position: "fixed",
              right: "10px",
              top: "70px",
              paddingRight: "15px",
              opacity: 0.7,
            }}
          >
            <KeyboardArrowLeftIcon />
            Categories
          </Fab>
          {/* NHỮNG HÀM CLICK NÀY CỰC NGUY HIỂM LÀ RENDER LẠI TOÀN BỘ COMPONENT NÊN */}
          {/* CHECK LẠI GIẢI PHÁP */}
          <Dialog
            onClose={handleClose}
            open={showCateObject}
            TransitionComponent={Transition}
            classes={{
              paper: classes.dialog,
            }}
          >
            <div className="overview__cateobject-dialog">
              <div className="overview__right">
                <CategoryAndObject currentTeamId={currentTeamId} />
              </div>
            </div>
          </Dialog>
        </>
      )}
    </div>
  );
}

export default OverViewTab;
