import React from "react";
import PropTypes from "prop-types";
import { EditText } from "react-edit-text";
TagObjectItem.propTypes = {};

function TagObjectItem({ _item, ClickRemove, ChangeName }) {
  const [item, setItem] = React.useState(_item || {});

  const handleClickRemove = (id) => {
    if (ClickRemove) ClickRemove(id);
  };

  const handleChangeName = (nameObject) => {
    setItem({
      ...item,
      name: nameObject,
    });
  };

  const handleSaveName = (newObject) => {
    ChangeName({
      ...item,
      name: newObject.value,
    });
  };
  const newCate = "New Category";
  const newObject = "New Object";
  return (
    <div className="overview__objectItem">
      <EditText
        name="textbox1"
        style={{
          height: "100%",
          width: "100%",
          marginLeft: "2px",
          padding: "12px",
          lineHeight: 1,
          fontSize: "16px",
          backgroundColor: "rgb(211, 211, 211)",
          color:
            item.name === newCate || item.name === newObject
              ? "rgb(30,144,255)"
              : "black",
          fontWeight: "600",
        }}
        value={item.name}
        onChange={handleChangeName}
        onSave={handleSaveName}
      />
      {!item.isUsed ? (
        <div className="overview__objectItem-remove">
          <i
            className="fas fa-minus-circle fa-lg"
            onClick={() => handleClickRemove(item)}
          ></i>
        </div>
      ) : (
        <div className="overview__objectItem-remove">
          <i
            style={{ cursor: "context-menu", color: "#7d8597" }}
            className="fas fa-minus-circle fa-lg"
            title="Cannot delete"
          ></i>
        </div>
      )}
    </div>
  );
}

export default TagObjectItem;
