import { Dialog, Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import ConfirmPopup from "A_SHARED_COMPONENT/ConfirmPopup";
import TextInputField from "A_SHARED_COMPONENT/TextInputField";
import TelegramIcon from "@mui/icons-material/Telegram";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";

function NotePopup(props) {
  let initProps = {
    isOpen: props.isOpen || false,
    content: props.content || "",
  };

  const [propsObj, setPropsObj] = useState(initProps);

  const togglerHandle = () => {
    if (props.toggler) {
      props.toggler();
    } else {
      setPropsObj({ ...propsObj, isOpen: !propsObj.isOpen });
    }
  };

  const closePopupHandle = () => {
    //ToanVD: Comment lai de fix bug auto show popup
    // setPropsObj({ ...propsObj, isOpen: false });
    props.setIsOpenNotePopup(false);
  };

  const onPropsChange = useCallback(() => {
    setPropsObj(initProps);
  }, [props]);

  const contentChangeHandler = (event) => {
    setPropsObj({ ...propsObj, content: event.target.value });
  };

  const submitHandler = () => {
    const content = form.getValues().content;

    setPropsObj({ ...propsObj, content: content });

    props.onSubmit({
      note: content || null,
    });
    togglerHandle();
  };

  const handleDeleteNote = () => {
    const content = null;

    setPropsObj({ ...propsObj, content: content });

    props.onSubmit({
      note: null,
    });
    togglerHandle();
  };

  const form = useForm({
    defaultValues: {
      content: props?.content || null,
    },
    resolver: yupResolver(),
  });

  useEffect(() => {
    onPropsChange();
  }, [onPropsChange]);

  return (
    <div>
      <Dialog open={propsObj.isOpen} onClose={closePopupHandle}>
        <div className="shared__userinfomation">
          <div className="userinformation__title">NOTE</div>
          <div className="userinformation__form">
            <div className="userinformation__form-section-03">
              <div className="form__section02-col01">
                <TextInputField
                  name="content"
                  label="Comment"
                  rows={7}
                  form={form}
                  defaultValue={props?.content || null}
                />
              </div>
            </div>

            <div
              className="userinformation__form-section-06"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="contained"
                style={{ width: "100px" }}
                onClick={handleDeleteNote}
                startIcon={<DeleteIcon />}
                color="error"
                disabled={!props.content}
              >
                DELETE
              </Button>

              <Button
                variant="contained"
                style={{ width: "100px" }}
                onClick={submitHandler}
                startIcon={<TelegramIcon />}
              >
                SUBMIT
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default NotePopup;
