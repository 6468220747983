import { Box, Paper, IconButton } from "@mui/material";
import {
  addDays,
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  startOfMonth,
  subDays,
  subMonths,
} from "date-fns";
import { useCallback, useEffect, useState } from "react";
import classes from "./CustomizeCalendarV2.module.scss";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import _ from "lodash";

function CustomizeCalendarV2(props) {
  let initProps = {
    date: new Date(props.date) || new Date(),
  };
  const [propsObj, setPropsObj] = useState(initProps);
  const [disableNext, setDisableNext] = useState(false);

  const weekDayShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const today = new Date();
  const startDayOfMonth = startOfMonth(propsObj.date);
  const endDayOfMonth = endOfMonth(propsObj.date);
  const daysOfMonth = eachDayOfInterval({
    start: startDayOfMonth,
    end: endDayOfMonth,
  });

  const prevHandle = () => {
    setPropsObj({
      ...propsObj,
      date: subMonths(propsObj.date, 1),
    });

    // if (propsObj.date.getMonth() + 1 === currentMonth) {
    //   setDisableNext(true);
    // } else {
    //   setDisableNext(false);
    // }

    props.onCalendarChange(subMonths(propsObj.date, 1));
  };
  const nextHandle = () => {
    setPropsObj({
      ...propsObj,
      date: addMonths(propsObj.date, 1),
    });
    // if (propsObj.date.getMonth() + 1 === currentMonth) {
    //   setDisableNext(true);
    // } else {
    //   setDisableNext(false);
    // }
    props.onCalendarChange(addMonths(propsObj.date, 1));
  };
  const onCalendarClick = (dateParam) => {
    props.onCalendarChange(dateParam);
  };

  const onPropsChange = useCallback(() => {
    setPropsObj(initProps);
  }, [props]);

  useEffect(() => {
    onPropsChange();
  }, [onPropsChange]);

  return (
    <Paper
      className={classes["customized-calendar"]}
      style={{ borderRadius: 0 }}
    >
      <div className={classes["calendar__header"]}>
        <IconButton
          aria-label="previous"
          className={`${classes["calendar-picker__button"]}`}
          onClick={prevHandle}
        >
          <NavigateBeforeIcon className={`${classes["button__icon"]}`} />
        </IconButton>
        <div className={classes["calendar__text"]}>
          {format(propsObj.date, "MMM - yyyy")}
        </div>
        <IconButton
          aria-label="previous"
          className={`${classes["calendar-picker__button"]}`}
          // disabled={disableNext}
          onClick={nextHandle}
        >
          <NavigateNextIcon className={`${classes["button__icon"]}`} />
        </IconButton>
      </div>
      <div className={classes["calendar__week"]}>
        {weekDayShort.map((d) => (
          <div className={classes["week__cell"]} key={d}>
            {d}
          </div>
        ))}
      </div>
      <div className={classes["calendar__body"]}>
        {_.range(getDay(startDayOfMonth)).map((i) => (
          <div
            className={`${classes["day__cell"]} ${classes["--faded"]}`}
            key={i}
          >
            <div className={`${classes["day__text"]} ${classes["--faded"]} `}>
              {format(
                subDays(startDayOfMonth, getDay(startDayOfMonth) - i),
                "dd"
              )}
            </div>
          </div>
        ))}

        {_.range(daysOfMonth.length).map((i) => (
          <div className={`${classes["day__cell"]} `} key={i}>
            <Box
              className={`${classes["day__text"]} 
                        ${
                          format(addDays(startDayOfMonth, i), "dd-MM-yyyy") ===
                          format(today, "dd-MM-yyyy")
                            ? classes["--active"]
                            : ""
                        }
                        
                        ${
                          format(addDays(startDayOfMonth, i), "dd") ===
                          format(props.date, "dd")
                            ? classes["--selected"]
                            : ""
                        }`}
              onClick={() => {
                onCalendarClick(addDays(startDayOfMonth, i));
              }}
            >
              {format(addDays(startDayOfMonth, i), "dd")}
            </Box>
          </div>
        ))}

        {_.range(6 - getDay(endDayOfMonth)).map((i) => (
          <div className={`${classes["day__cell"]}`} key={i}>
            <div className={`${classes["day__text"]} ${classes["--faded"]}`}>
              {format(addDays(endDayOfMonth, i + 1), "dd")}
            </div>
          </div>
        ))}
      </div>
    </Paper>
  );
}

export default CustomizeCalendarV2;
