import classes from "./CustomizeCalendarPicker.module.scss";
import { Grid, IconButton, Stack, Paper, Box } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { addMonths, format, subMonths } from "date-fns";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {  LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { CalendarPicker } from "@mui/lab";
import { CalendarPicker } from "@mui/x-date-pickers";
import { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";

const CustomizedCalendarPicker = styled((props) => {});

function CustomizeCalendarPicker(props) {
  const [dayObj, setDayObj] = useState(props.dayObj || new Date());
  const [calendarView, setCalendarView] = useState(
    props.calendarView || ["month"]
  );
  const [openCalendar, setOpenCalendar] = useState(false);

  const calendarBtnRef = useRef();
  const calendarRef = useRef();
  const [calendarBtnValues, setCalendarBtnValues] = useState({
    left: 0,
    top: 0,
  });

  const handleCalendarPrev = () => {
    let newDay = subMonths(dayObj, 1);
    props.onCalendarPrevClick(newDay);
  };

  const handleCalendarNext = () => {
    let newDay = addMonths(dayObj, 1);
    props.onCalendarNextClick(newDay);
  };

  const handleCalendarChange = (newDay) => {
    props.onCalendarChange(newDay);
  };

  const toggleCalendar = () => {
    const { offsetTop, offsetLeft, offsetHeight, offsetWidth } =
      calendarBtnRef.current;
    const calendarWidth = calendarRef.current.offsetWidth;
    setCalendarBtnValues({
      ...calendarBtnValues,
      left: offsetLeft - (calendarWidth - offsetWidth),
      top: offsetTop + offsetHeight,
    });
    setOpenCalendar(!openCalendar);
  };

  //close calendar popup when clicked away
  const clickAwayCalendar = () => {
    if (openCalendar) {
      setOpenCalendar(false);
    }
  };

  useEffect(() => {
    setDayObj(props.dayObj || new Date());
  }, [props]);

  return (
    <Stack
      className={`${classes["calendar-picker__wrap"]}`}
      direction="row"
      spacing={1}
      ref={calendarRef}
    >
      <IconButton
        aria-label="previous"
        className={`${classes["calendar-picker__button"]}`}
        onClick={handleCalendarPrev}
      >
        <NavigateBeforeIcon className={`${classes["button__icon"]}`} />
      </IconButton>
      <div className={`${classes["calendar-picker__text"]}`}>
        {format(dayObj, "MMMM - yyyy")}
      </div>
      <ClickAwayListener onClickAway={clickAwayCalendar}>
        <div>
          <IconButton
            aria-label="calendar"
            className={`${classes["calendar-picker__button"]}`}
            ref={calendarBtnRef}
            onClick={toggleCalendar}
          >
            <CalendarTodayIcon className={`${classes["button__icon"]}`} />
          </IconButton>
          {openCalendar ? (
            <Box
              // className={`${classes["calendar-picker-popup__wrap"]}`}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  // width: calendarBtnValues.width + 10,
                  height: "auto",
                  position: "absolute",
                  left: calendarBtnValues.left,
                  top: calendarBtnValues.top,
                  margin: "auto",
                  backgroundColor: "#f8f8fb",
                  zIndex: 1,
                },
              }}
            >
              <Paper
                className={`${classes["calendar-picker-popup"]}`}
                elevation={3}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid>
                    <CalendarPicker
                      views={calendarView}
                      date={dayObj}
                      onMonthChange={(newDate) => {
                        handleCalendarChange(newDate);
                      }}
                      onChange={(newDate) => {
                        handleCalendarChange(newDate);
                      }}
                    />
                  </Grid>
                </LocalizationProvider>
              </Paper>
            </Box>
          ) : null}
        </div>
      </ClickAwayListener>
      <IconButton
        aria-label="previous"
        className={`${classes["calendar-picker__button"]}`}
        onClick={handleCalendarNext}
      >
        <NavigateNextIcon className={`${classes["button__icon"]}`} />
      </IconButton>
    </Stack>
  );
}

export default CustomizeCalendarPicker;
