import React, { useEffect, useState } from "react";
import { List } from "@mui/material";
import MemberItem from "../MemberItem";

MemberList.propTypes = {};

function MemberList({
  memberList,
  listUsersActive,
  changeAddUser,
  textSearch,
}) {
  const [listAdded, setListAdded] = useState([]);

  useEffect(() => {
    setListAdded(memberList);
  }, [memberList]);

  const paddingRight = "20px";

  const handleChangeAddMember = (user) => {
    let newListAdded = [...listAdded];
    const index = newListAdded.findIndex(
      (person) => person.userId === user.userId
    );

    if (index !== -1) {
      newListAdded.splice(index, 1);
    } else {
      newListAdded.push(user);
    }

    if (changeAddUser) changeAddUser(newListAdded);
    setListAdded(newListAdded);
  };

  let renderSorted = [...listUsersActive];

  if (textSearch && textSearch !== "") {
    renderSorted = renderSorted.filter((person) =>
      person.fullName.toLowerCase().includes(textSearch.toLowerCase())
    );
  }
  renderSorted = renderSorted.sort((a, b) =>
    listAdded.some((person) => person.userId === a.userId) ? -1 : 1
  );

  return (
    <div className="member__list" style={{height: '340px' }}>
      <div className="member__header" style={{ paddingRight: paddingRight }}>
        <div className="member__col02">Name</div>
        <div className="member__col01">Role</div>
        <div className="member__col01">Add</div>
      </div>
      <div className="member__content">
        <List
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            overflow: "auto",
          }}
        >
          {renderSorted.map((user) => (
            <MemberItem
              key={user.userId}
              user={user}
              isAdded={listAdded.some(
                (person) => person.userId === user.userId
              )}
              onClickAdd={handleChangeAddMember}
            />
          ))}
        </List>
      </div>
    </div>
  );
}

export default MemberList;
