import {
  Grid,
  Paper,
  Box,
  Card,
  CardContent,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import classes from "../TimeSheetService.module.scss";
import TaskComponent from "./TaskComponent";
import TagContent from "A_SHARED_COMPONENT/TagContent";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import CustomizeCalendarV2 from "A_SHARED_COMPONENT/CustomizeCalendarV2";
import NotePopup from "./NotePopup";
import TimeSheetAPI from "B_API/TimeSheetAPI";
import EditRequestSuccessPopup from "Z_SERVICES/e_OverTimeService/components/SuccessPopup";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import CreateOTPopup from "Z_SERVICES/e_OverTimeService/components/CreateOTPopup";
import SelectedProjectItems from "./SelectedProjectItems";
import convertMsToHM from "F_UTILS/DateTimeUtils";
import { ToastShowError, ToastShowSuccess } from "F_UTILS/Notify";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";
import AdminUserAPI from "B_API/AdminUserAPI";
import { useSelector } from "react-redux";
import LockIcon from "@mui/icons-material/Lock";

function TimeSheetComponent({
  userIdParam,
  detailObj,
  onBackToTimeSheet,
  listOTType,
  listProject,
  onRefresh,
  onUpdateTaskDone,
  initProjectId,
  totalFingerPrint,
  lockMode,
  listHolidays,
}) {
  const moment = require("moment");

  let checkIsHoliday = (dayCheck, holiday) => {
    const compareDate = moment(new Date(dayCheck)).format("YYYY-MM-DD");
    const startDate = moment(new Date(holiday?.fromDate)).format("YYYY-MM-DD");
    const endDate = moment(new Date(holiday?.toDate)).format("YYYY-MM-DD");
    return moment(compareDate).isBetween(startDate, endDate, null, "[]");
  };
  const [listTaskDetail, setListTaskDetail] = useState([]);
  const [listCateObject, setListCateObject] = useState({
    cateList: [],
    objectList: [],
  });

  const initProp =
    initProjectId ||
    detailObj.listTask[0]?.timesheetProjectId ||
    listProject[0]?.timesheetProjectId ||
    1;

  const [selectedProp, setSelectedProp] = useState({
    projectId: initProp || 1,
    date: detailObj.date || 1,
  });

  var dateIsInWeekend =
    detailObj.date.getDay() === 6 || detailObj.date.getDay() === 0
      ? true
      : false;

  var canRegisterTS =
    (detailObj.timeIn === "--:--" && detailObj.timeOut === "--:--") ||
    detailObj.timeIn === "--:--" ||
    detailObj.timeOut === "--:--"
      ? false
      : true;

  let registedMaxHoursLeave = detailObj.leaveHours === 8 ? true : false;

  const [userView, setUserView] = useState(null);

  const currentUserState = useSelector((state) => state.user.current);

  const isUser =
    currentUserState?.userId === parseInt(userIdParam) ? true : false;

  let isHoliday = listHolidays
    ?.map((holiday) => checkIsHoliday(detailObj.date, holiday))
    .some((item) => item === true);

  useEffect(() => {
    const fetchCateObject = async () => {
      try {
        const res = await AdminUserAPI.getUserById(userIdParam);
        const teamIdParams = res.data?.teamId;
        setUserView(res.data);
        const response1 = await TimeSheetAPI.GetAllTimesheetCategoriesByTeamId(
          teamIdParams
        );
        const response2 = await TimeSheetAPI.GetAllTimesheetObjectsByTeamId(
          teamIdParams
        );

        setListCateObject({
          cateList: response1.data.sort((a, b) => (a.name > b.name ? 1 : -1)),
          objectList: response2.data.sort((a, b) => (a.name > b.name ? 1 : -1)),
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchCateObject();
  }, [selectedProp]);

  const backToTimeSheetPageHandler = () => {
    if (onBackToTimeSheet) onBackToTimeSheet();
  };

  const viewTaskDetailHandle = useCallback((task) => {
    const projectId = task?.timesheetProjectId;

    setNewTaskObj({
      ...newTaskObj,
      timesheetProjectId: projectId || null,
    });
    setSelectedProp({
      projectId: projectId || null,
      date: detailObj.date || null,
    });
  });

  //FUNCTION OF OLD TASK
  const onAddedCategoryValueChangeHandler = (obj) => {
    var listChanged = [...listTaskDetail];
    listChanged[obj.index].timesheetCategoryId = obj.value;
    listChanged[obj.index].timesheetProjectId = selectedProp.projectId;
    setListTaskDetail(listChanged);
  };

  const onAddedObjectValueChangeHandler = (obj) => {
    var listChanged = [...listTaskDetail];
    listChanged[obj.index].timesheetObjectId = obj.value;
    listChanged[obj.index].timesheetProjectId = selectedProp.projectId;
    setListTaskDetail(listChanged);
  };

  const onAddedHoursValueChangeHandler = (obj) => {
    var listChanged = [...listTaskDetail];
    listChanged[obj.index].hours = obj.value;
    listChanged[obj.index].timesheetProjectId = selectedProp.projectId;
    setListTaskDetail(listChanged);
  };

  const onAddedDescriptionValueChangeHandler = (obj) => {
    var listChanged = [...listTaskDetail];
    listChanged[obj.index].description = obj.value;
    listChanged[obj.index].timesheetProjectId = selectedProp.projectId;
    setListTaskDetail(listChanged);
  };

  const handleProjectChange = (value) => {
    setSelectedProp({
      projectId: value || null,
      date: detailObj.date || null,
    });
    setNewTaskObj({
      ...newTaskObj,
      timesheetProjectId: value,
    });
  };

  const onRemoveTaskHandler = (index) => {
    var listChanged = [...listTaskDetail];
    if (index > -1) {
      listChanged.splice(index, 1);
    }
    setListTaskDetail(listChanged);
  };
  const [timesheetID, setTimesheetID] = useState(detailObj?.timesheetId);

  // console.log("detailObj ", detailObj);

  const fetchDataTaskRows = async () => {
    try {
      if (timesheetID) {
        const response =
          await TimeSheetAPI.GetListVwTimesheetTaskByTimesheetIdAndProjectId({
            timesheetId: timesheetID,
            timesheetProjectId: selectedProp.projectId,
          });
        setListTaskDetail(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDataTaskRows();
  }, [selectedProp]);
  //NEW TASK
  const initNewTask = {
    timesheetTaskId: "",
    timesheetId: timesheetID,
    userId: userIdParam,

    timesheetProjectId: selectedProp.projectId,

    timesheetCategoryId: listCateObject.cateList[0]?.timesheetCategoryId || 1,
    timesheetObjectId: listCateObject.objectList[0]?.timesheetObjectId || 1,
    description: "",
    hours: "",
  };

  const [newTaskObj, setNewTaskObj] = useState(initNewTask);

  useEffect(() => {
    setSelectedProp({
      projectId: initProp || null,
      date: detailObj.date || null,
    });
  }, [detailObj]);

  useEffect(() => {
    const getTimesheetId = async () => {
      try {
        let params = {
          userId: userIdParam,
          date: format(new Date(detailObj.date), "yyyy-MM-dd"),
        };

        const dateData = await TimeSheetAPI.GetVwTimesheetByUserIdAndDate(
          params
        );

        setTimesheetID(dateData.data.timesheetId);
        setNewTaskObj({
          ...newTaskObj,
          timesheetId: dateData.data.timesheetId,
        });
      } catch (error) {
        console.log("Error get list Overtime ", error);
      }
    };
    getTimesheetId();
  }, [detailObj.date]);
  //FUNCTION NEW TASK
  const onCategoryValueChangeHandler = (value) => {
    setNewTaskObj({
      ...newTaskObj,
      timesheetCategoryId: value,
    });
  };
  const onObjectValueChangeHandler = (value) => {
    setNewTaskObj({
      ...newTaskObj,
      timesheetObjectId: value,
    });
  };
  const onHoursValueChangeHandler = (value) => {
    setNewTaskObj({
      ...newTaskObj,
      hours: value,
    });
  };
  const onDescriptionValueChangeHandler = (value) => {
    setNewTaskObj({
      ...newTaskObj,
      description: value,
    });
  };

  //open popup
  const [isOpenOtPopup, setIsOpenOtPopup] = useState(false);
  const otPopupToggler = () => {
    setIsOpenOtPopup(!isOpenOtPopup);
  };

  const [showSuccessPopup, setShowSuccessPopup] = useState({ show: false });
  const handleCloseSucess = () => {
    setShowSuccessPopup({ show: false });
  };

  const startTimeDefault = moment()
    .set("hour", 17)
    .set("minutes", 0)
    .set("second", 0);

  const endTimeDefault = moment()
    .set("hour", 18)
    .set("minutes", 0)
    .set("second", 0);

  var requestOTInit = {
    date: new Date(detailObj.date),
    fromTime: startTimeDefault,
    toTime: endTimeDefault,
    timesheetProjectId: 1,
    reason: "",
    projectName: "",
    overtimeTypeId: 1,
    overtimeType: "Weekday",
    statusDefinitionId: 1,
  };

  const onCloseOTPopup = () => {
    setIsOpenOtPopup(false);
  };

  const otSubmitHandle = (obj) => {
    console.log(obj);
  };

  const totalFingerPrintTime =
    Math.round((totalFingerPrint * 100) / 3600000) / 100;
  const hoursMaxWork = 8 - detailObj?.leaveHours;

  //UPDATE TASK DETAIL
  const updateTimesheetTask = async function () {
    try {
      // console.log("listTaskDetail ", listTaskDetail);
      if (listTaskDetail.length > 0) {
        // Check input failed
        var existed = listTaskDetail.find((x) => CheckHourFailed(x.hours));
        if (existed) {
          ToastShowError(`Input hours: ${existed.hours} was wrong.`);
          return;
        }

        //Check if hours in fingerprint is less than user input => reject.
        if (totalFingerPrint !== null) {
          const sumHours = listTaskDetail.reduce((accumulator, object) => {
            return accumulator + parseFloat(object.hours);
          }, 0);

          const cloneProjects = detailObj.listTask.map((x) => ({
            timesheetProjectId: x.timesheetProjectId,
            hours: x.hours,
          }));
          const index = cloneProjects?.findIndex(
            (obj) =>
              obj.timesheetProjectId == listTaskDetail[0]?.timesheetProjectId
          );
          index > -1
            ? (cloneProjects[index].hours = sumHours)
            : cloneProjects.push({ hours: sumHours });

          const totalHours = cloneProjects.reduce((accumulator, object) => {
            return accumulator + parseFloat(object.hours);
          }, 0);

          if (totalHours > totalFingerPrintTime) {
            const message = `Total hours are more than ${convertMsToHM(
              totalFingerPrint
            )} (h) fingerprint hours.`;
            ToastShowError(message);
            return;
          } else if (totalHours > 8.0) {
            const message = `Total hours are more than 08.00 hours.`;
            ToastShowError(message);
            return;
          } else if (totalHours > hoursMaxWork) {
            const message = `Error. You can only work max ${hoursMaxWork} hours because you registed ${detailObj?.leaveHours} hours leave.`;
            ToastShowError(message);
            return;
          } else if (hoursMaxWork === 8 && detailObj?.leaveHours > 0) {
            const message = `Error. You cannot register because you registed ${detailObj?.leaveHours} hours leave.`;
            ToastShowError(message);
            return;
          }
        }

        //Update database.
        const response = await TimeSheetAPI.UpdateMultipleTimesheetTask(
          listTaskDetail
        );

        if (response.status === 200) {
          ToastShowSuccess("Your timesheet has been updated.");
          if (onUpdateTaskDone) {
            const taskUpdate = {
              projectId: selectedProp.projectId,
              taskDetail: listTaskDetail,
            };

            onUpdateTaskDone(taskUpdate);
          }
        } else {
          ToastShowError("Your timesheet updated failed.");
        }
      } else {
        var deletedObject = {
          timesheetProjectId: selectedProp.projectId,
          timesheetId: detailObj.timesheetId,
        };

        var existed = detailObj.listTask?.find(
          (x) => x.timesheetProjectId === deletedObject.timesheetProjectId
        );
        if (!existed) return;

        const response =
          await TimeSheetAPI.DeleteTimesheetTaskByTimesheetProjectIdTimesheetId(
            deletedObject
          );
        if (response.status === 204) {
          ToastShowSuccess("Your timesheet has been deleted.");
          if (onUpdateTaskDone)
            onUpdateTaskDone({
              projectId: selectedProp.projectId,
              taskDetail: listTaskDetail,
            });
        } else {
          ToastShowError("Your timesheet deleted failed.");
        }
      }
    } catch (error) {
      console.log(error);
      ToastShowError("Your timesheet updated failed.");
    }
  };

  const timesheetSaveHandle = () => {
    updateTimesheetTask();
  };

  const [isOpenNotePopup, setIsOpenNotePopup] = useState(false);
  const notePopupToggler = () => {
    setIsOpenNotePopup(!isOpenNotePopup);
  };

  const noteSubmitHandle = (obj) => {
    const note = {
      timesheetId: detailObj.timesheetId,
      note: obj.note,
    };

    updateNote(note);
  };

  const convertTime = (time) => {
    var mins = time * 60;
    var hours = mins / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    rhours = rhours.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });

    rminutes = rminutes.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return rhours + ":" + rminutes;
  };

  const calendarChangeHandle = async (dateObj) => {
    window.location.href = `#/timesheet/detail/${userIdParam}/${format(
      new Date(dateObj),
      "dd-MM-yyyy"
    )}`;

    let params = {
      userId: userIdParam,
      date: format(new Date(dateObj), "yyyy-MM-dd"),
    };
    const dateData = await TimeSheetAPI.GetVwTimesheetByUserIdAndDate(params);
    setTimesheetID(dateData.data.timesheetId);
    setNewTaskObj({
      timesheetTaskId: "",
      timesheetId: dateData.data.timesheetId,
      userId: userIdParam,

      timesheetProjectId: selectedProp.projectId,
      timesheetCategoryId: listCateObject.cateList[0]?.timesheetCategoryId || 1,
      timesheetObjectId: listCateObject.objectList[0]?.timesheetObjectId || 1,

      description: "",
      hours: "",
    });
  };

  const addNewTimesheetTask = function (task) {
    var listAdd = [...listTaskDetail] || [];
    listAdd.push(task);
    setListTaskDetail(listAdd);
    setNewTaskObj({
      timesheetTaskId: "",
      timesheetId: timesheetID,
      userId: userIdParam,

      timesheetProjectId: selectedProp.projectId,
      timesheetCategoryId:
        listCateObject.cateList[0]?.timesheetCategoryId || null,
      timesheetObjectId:
        listCateObject.objectList[0]?.timesheetObjectId || null,
      description: "",
      hours: "",
    });
  };

  const saveNewDetailHandle = useCallback(() => {
    addNewTimesheetTask(newTaskObj);
  });

  const updateNote = async function (note) {
    try {
      const response = await TimeSheetAPI.UpdateNoteTimesheet(note);
      if (response.status === 200) {
        ToastShowSuccess("Note has been updated.");
      } else {
        ToastShowError("Note has been updated failed.");
      }
      onRefresh();
    } catch (error) {
      console.log(error);
      ToastShowError("Note has been updated failed.");
    }
  };

  const currentDayShow = detailObj?.date?.getDay();

  const CheckHourFailed = (hours) => {
    return (
      hours.toString().includes(",") ||
      isNaN(parseFloat(hours)) ||
      parseFloat(hours) <= 0 ||
      (parseFloat(hours) * 100) % 25 !== 0
    );
  };

  const disableAdd =
    newTaskObj.description?.trim() === "" || CheckHourFailed(newTaskObj.hours);

  const totalTimeAndLeave = totalFingerPrintTime + detailObj.leaveHours;

  return (
    <>
      <Paper
        className={classes["timesheet-component"]}
        sx={{
          backgroundColor: "#f8f8fb",
        }}
      >
        <Grid container spacing={2}>
          {currentUserState?.role === "Administrator" ? (
            <Grid item lg={12} className={classes["ts-card-info"]}>
              <Card elevation={3} className={classes["card-info__name"]}>
                {/* <Typography variant="h5" sx={{ m: 2 }}>
                 
                </Typography> */}

                <div className="tag__content-title" style={{ margin: "20px" }}>
                  TIMESHEET OF : {userView?.fullName}
                </div>
              </Card>
            </Grid>
          ) : null}

          <Grid item lg={3} className={classes["time-in__wrap"]}>
            <TagContent title="TIME IN" height="100%" width="100%">
              <div
                className={`${classes["timesheet__time-tag"]} ${
                  detailObj.timeIn !== "--:--" ? classes["color--info"] : null
                }`}
              >
                {detailObj.timeIn}
              </div>
            </TagContent>
          </Grid>
          <Grid item lg={3} className={classes["time-out__wrap"]}>
            <TagContent title="TIME OUT" height="100%" width="100%">
              <div
                className={`${classes["timesheet__time-tag"]} ${
                  detailObj.timeOut !== "--:--" ? classes["color--info"] : null
                }`}
              >
                {detailObj.timeOut}
              </div>
            </TagContent>
          </Grid>
          <Grid item lg={3} className={classes["time-date__wrap"]}>
            <TagContent title="DATE" height="100%" width="100%">
              <div className={`${classes["timesheet__date-tag"]}`}>
                <div className={`${classes["date-tag__day"]}`}>
                  {currentDayShow === 0
                    ? "Sunday"
                    : currentDayShow === 1
                    ? "Monday"
                    : currentDayShow === 2
                    ? "Tuesday"
                    : currentDayShow === 3
                    ? "Wednesday"
                    : currentDayShow === 4
                    ? "Thusday"
                    : currentDayShow === 5
                    ? "Friday"
                    : "Saturday"}
                </div>
                <div className={`${classes["date-tag__date"]}`}>
                  {format(detailObj.date, "yyyy/MM/dd")}
                </div>
              </div>
            </TagContent>
          </Grid>
          <Grid item lg={3}>
            <Card sx={{ height: "100%", borderRadius: 0 }} elevation={3}>
              <div className={classes["timesheet-calendar__container"]}>
                <div className={classes["timesheet__note-noti"]}>
                  <div className={classes["note__title"]}>Note</div>
                  <IconButton
                    className={classes["note__button"]}
                    onClick={notePopupToggler}
                  >
                    <ChatBubbleIcon className={classes["button__icon"]} />
                  </IconButton>
                </div>
                <div className={classes["timesheet__calendar"]}>
                  <CustomizeCalendarV2
                    date={detailObj.date}
                    onCalendarChange={calendarChangeHandle}
                  />
                </div>
              </div>
            </Card>
          </Grid>

          <Grid
            item
            lg={8}
            className={classes["project-detail-component__wrap"]}
          >
            <Card sx={{ height: "100%" }} elevation={3}>
              <CardContent className={classes["project-detail-component"]}>
                <div className={`${classes["form-component__group"]}  `}>
                  <div className={classes["group__title"]}>Project Name</div>
                  <div className={classes["group__input"]}>
                    <SelectedProjectItems
                      initProp={selectedProp.projectId}
                      listProject={listProject}
                      lockMode={
                        lockMode ||
                        dateIsInWeekend ||
                        !canRegisterTS ||
                        isHoliday ||
                        registedMaxHoursLeave
                      }
                      handleProjectChange={handleProjectChange}
                    />
                  </div>
                </div>
                {lockMode ? (
                  <div className={classes["form-component__icon--lock"]}>
                    <Tooltip
                      title="Timesheet has been locked by admin. You cannot edit."
                      placement="bottom"
                    >
                      <LockIcon
                        style={{
                          fontSize: "40px",
                          color: "#001B48",
                          cursor: "pointer",
                        }}
                      ></LockIcon>
                    </Tooltip>
                  </div>
                ) : isHoliday ||
                  dateIsInWeekend ||
                  registedMaxHoursLeave ||
                  !canRegisterTS ||
                  totalTimeAndLeave < 8 ? (
                  <div className={classes["form-component__icon--lock"]}>
                    <Tooltip
                      title={
                        isHoliday
                          ? "You cannot register timesheet because today is holiday. Please register as Overtime by clicking the OT Request button in the lower left corner."
                          : dateIsInWeekend
                          ? "You cannot register timesheet task on Saturday or Sunday. Please register as Overtime by clicking the OT Request button in the lower left corner."
                          : registedMaxHoursLeave
                          ? "You cannot register timesheet task because you registed 8 hours leave. Please register as Overtime by clicking the OT Request button in the lower left corner."
                          : !canRegisterTS
                          ? "You cannot register timesheet task because the timekeeper doesn't have Time in or Time out data. Please contact your team leader to edit timesheet."
                          : totalTimeAndLeave < 8
                          ? "The total number of timekeeping hours and your registered time off is not enough 8 hours. If you forget your timekeeping, contact the leader to correct it."
                          : null
                      }
                      placement="bottom"
                    >
                      <ReportGmailerrorredIcon
                        style={{
                          fontSize: "40px",
                          color: "#FFBB33",
                          cursor: "pointer",
                        }}
                      ></ReportGmailerrorredIcon>
                    </Tooltip>
                  </div>
                ) : null}

                <div className={classes["task-container"]}>
                  <div className={classes["task-container__header"]}>
                    <div
                      className={`${classes["header__col"]} ${classes["header__col--category"]}`}
                    >
                      Category
                    </div>
                    <div
                      className={`${classes["header__col"]} ${classes["header__col--object"]}`}
                    >
                      Object
                    </div>
                    <div
                      className={`${classes["header__col"]} ${classes["header__col--description"]}`}
                    >
                      Work description
                    </div>
                    <div
                      className={`${classes["header__col"]} ${classes["header__col--hour"]}`}
                    >
                      Hours
                    </div>
                  </div>
                  <div className={classes["task-container__header--mobile"]}>
                    Task list
                  </div>

                  <div className={classes["scrollable"]}>
                    <div className={classes["task-container__body"]}>
                      {listTaskDetail.length > 0
                        ? listTaskDetail.map((detail, index) => (
                            <TaskComponent
                              key={index}
                              index={index}
                              detail={detail}
                              listCategory={listCateObject.cateList}
                              listObject={listCateObject.objectList}
                              timesheetTaskId={detail.timesheetTaskId}
                              timesheetProjectId={detail.timesheetProjectId}
                              timesheetCategoryId={detail.timesheetCategoryId}
                              timesheetObjectId={detail.timesheetObjectId}
                              description={detail.description}
                              hours={detail.hours}
                              onCategoryValueChange={
                                onAddedCategoryValueChangeHandler
                              }
                              onObjectValueChange={
                                onAddedObjectValueChangeHandler
                              }
                              onHoursValueChange={
                                onAddedHoursValueChangeHandler
                              }
                              onDescriptionValueChange={
                                onAddedDescriptionValueChangeHandler
                              }
                              onRemoveClick={onRemoveTaskHandler}
                              lockMode={
                                lockMode ||
                                dateIsInWeekend ||
                                !canRegisterTS ||
                                isHoliday ||
                                registedMaxHoursLeave
                              }
                            />
                          ))
                        : null}
                      <TaskComponent
                        index={-1}
                        listCategory={listCateObject.cateList}
                        listObject={listCateObject.objectList}
                        timesheetProjectId={selectedProp.projectId}
                        timesheetCategoryId={newTaskObj.timesheetCategoryId}
                        timesheetObjectId={newTaskObj.timesheetObjectId}
                        description={newTaskObj.description}
                        hours={newTaskObj.hours}
                        onCategoryValueChange={onCategoryValueChangeHandler}
                        onObjectValueChange={onObjectValueChangeHandler}
                        onHoursValueChange={onHoursValueChangeHandler}
                        onDescriptionValueChange={
                          onDescriptionValueChangeHandler
                        }
                        onRemoveClick={onRemoveTaskHandler}
                        lockMode={
                          lockMode ||
                          dateIsInWeekend ||
                          !canRegisterTS ||
                          isHoliday ||
                          registedMaxHoursLeave
                        }
                      />
                    </div>
                    <div
                      className={`${classes["button-container"]} ${classes["button-container--center"]}`}
                    >
                      <Button
                        className={`${classes[""]}
                                                        ${classes["button"]} 
                                                        ${classes["--normal"]}`}
                        disabled={disableAdd}
                        style={{ width: 100 }}
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={saveNewDetailHandle}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                </div>
                <div
                  className={`${classes["button-container"]} ${classes["button-container--space-between"]}`}
                >
                  <Button
                    className={`${classes[""]}
                     ${classes["button"]} 
                     ${classes["--warning"]}`}
                    variant="contained"
                    startIcon={<WatchLaterIcon />}
                    onClick={otPopupToggler}
                    // disabled={!isUser || lockMode}
                    disabled
                    style={{ visibility: "hidden" }}
                  >
                    OT Request
                  </Button>
                  <Button
                    className={`${classes[""]}
                      ${classes["button"]} 
                      ${classes["--success"]}`}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    style={{ width: 100 }}
                    disabled={
                      lockMode || !canRegisterTS || registedMaxHoursLeave
                    }
                    onClick={timesheetSaveHandle}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={4} className={classes["project-list__wrap"]}>
            <Card sx={{ height: "100%" }} elevation={3}>
              <CardContent className={classes["project-list"]}>
                <div className={classes["project-list__header"]}>
                  <div className={`${classes["header__title"]}`}>
                    List Project
                  </div>
                  <div
                    className={`${classes["header__title"]} ${classes["color--success"]}`}
                  >
                    {`Total: ${detailObj.totalTime || "--:--"} / ${
                      totalFingerPrint
                        ? convertMsToHM(totalFingerPrint)
                        : "--:--"
                    }`}
                  </div>
                </div>

                <div className={`${classes["project-list__body"]}`}>
                  <div className={classes["scrollable"]}>
                    {detailObj.listTask.map((task, index) => (
                      <Box
                        key={index}
                        className={classes["project__row"]}
                        onClick={() => {
                          viewTaskDetailHandle(task);
                        }}
                      >
                        <div className={classes["project__name"]}>
                          {task.timesheetProjectName}
                        </div>
                        <div className={classes["project__time"]}>
                          {task.hours.toString().includes(":")
                            ? task.hours
                            : convertTime(task.hours)}
                        </div>
                      </Box>
                    ))}
                  </div>
                </div>
                <div className={`${classes["project-list__button-container"]}`}>
                  <Button
                    className={`${classes[""]}
                    ${classes["button"]} 
                    ${classes["--info"]}`}
                    variant="contained"
                    startIcon={<ArrowBackIcon />}
                    onClick={backToTimeSheetPageHandler}
                  >
                    Timesheet page
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
      <CustomToastComponent />

      {isOpenOtPopup && (
        <CreateOTPopup
          open={isOpenOtPopup}
          onSubmit={otSubmitHandle}
          listProjectInfo={listProject}
          onClose={onCloseOTPopup}
          listOTType={listOTType}
          requestOT={requestOTInit}
          setShowSuccessPopup={setShowSuccessPopup}
        />
      )}

      <EditRequestSuccessPopup
        open={showSuccessPopup.show}
        onClose={handleCloseSucess}
      />

      <NotePopup
        toggler={notePopupToggler}
        isOpen={isOpenNotePopup}
        setIsOpenNotePopup={setIsOpenNotePopup}
        content={detailObj.note}
        onSubmit={noteSubmitHandle}
      />
    </>
  );
}

export default TimeSheetComponent;
