import axiosClient from "./axiosClient";

const ReportsAPI = {
  async getReportsOfLeaveByUserId(params) {
    const url = `/Reports/GetVwReportOfLeavesByUserId/${params}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async getSummaryOfTimesheetByDateRange(month, year) {
    const url = `/Reports/GetSummaryOfTimesheetByMonth/${year}/${month}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async getProjectHoursByUserIdAndDateRange(params) {
    const userId = JSON.parse(localStorage.getItem("user")).userId;

    const url = `/Reports/GetVwProjectHourByUserIdAndDateRange?userId=${userId}&fromDate=${params.fromDate}&toDate=${params.toDate}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },
};
export default ReportsAPI;
