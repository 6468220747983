import React, { useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import ChangeStatusOverTime from "../ChangeStatusOT";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";

const listStatus = [
  {
    id: 1,
    name: "Pending",
  },
  {
    id: 2,
    name: "Approved",
  },
  {
    id: 3,
    name: "Rejected",
  },
];

const columns = [
  {
    field: "status",
    headerName: "Status",
    headerClassName: "MuiDataGrid-header",
    headerAlign: "center",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    width: 120,
    renderCell: (params) => {
      var renderCell = "";
      const data = params.row;
      if (params.value === "Pending") {
        renderCell = (
          <ChangeStatusOverTime
            isCell={true}
            width={120}
            valueInit="Pending"
            id="selection-status"
            data={data}
          />
        );
      } else if (params.value === "Approved") {
        renderCell = (
          <ChangeStatusOverTime
            isCell={true}
            width={120}
            valueInit="Approved"
            id="selection-status"
            data={data}
          />
        );
      } else if (params.value === "Rejected") {
        renderCell = (
          <ChangeStatusOverTime
            isCell={true}
            width={120}
            valueInit="Rejected"
            id="selection-status"
            data={data}
          />
        );
      }
      return renderCell;
    },
  },
  {
    field: "staffId",
    headerName: "Staff Id",
    headerAlign: "center",
    headerClassName: "MuiDataGrid-header",
    type: "text",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 1,
    minWidth: 80,
  },
  {
    field: "fullName",
    headerName: "Name",
    headerAlign: "center",
    headerClassName: "MuiDataGrid-header",
    type: "text",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 3,
    minWidth: 150,
  },
  {
    field: "date",
    headerName: "Date",
    headerAlign: "center",
    headerClassName: "MuiDataGrid-header",
    type: "date",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 2,
    minWidth: 100,
  },
  {
    field: "month",
    headerName: "Month",
    headerAlign: "center",
    headerClassName: "MuiDataGrid-header",
    type: "text",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 2,
    minWidth: 50,
  },
  {
    field: "fromTime",
    headerName: "Start Time",
    type: "date",
    headerAlign: "center",
    headerClassName: "MuiDataGrid-header",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 2,
    renderCell: (params) => {
      let value = params.value.split("T")[1];
      return <div>{value.substring(0, 5)}</div>;
    },
    minWidth: 50,
  },
  {
    field: "toTime",
    headerName: "End Time",
    type: "date",
    headerClassName: "MuiDataGrid-header",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    headerAlign: "center",
    flex: 2,
    minWidth: 50,
    renderCell: (params) => {
      let value = params.value.split("T")[1];
      return <div>{value.substring(0, 5)}</div>;
    },
  },
  {
    field: "hours",
    headerName: "Hours",
    headerClassName: "MuiDataGrid-header",
    headerAlign: "center",
    type: "text",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 2,
    renderCell: (params) => {
      return params.value.toFixed(2);
    },
    minWidth: 60,
  },
  {
    field: "actualHours",
    headerName: "Actual Hours",
    headerClassName: "MuiDataGrid-header",
    headerAlign: "center",
    type: "text",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 2,
    renderCell: (params) => {
      return params.value?.toFixed(2);
    },
    minWidth: 60,
  },
  {
    field: "projectName",
    headerName: "Project Name",
    headerAlign: "center",
    headerClassName: "MuiDataGrid-header",
    type: "text",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 8,
    minWidth: 300,
  },
  {
    field: "reason",
    headerName: "Reason",
    headerClassName: "MuiDataGrid-header",
    headerAlign: "center",
    type: "text",
    cellClassName: "MuiDataGrid-tablecell MuiDataGrid-cell--textCenter ",
    flex: 7,
    minWidth: 250,
  },
  {
    field: "overtimeTypeName",
    headerName: "OT Type",
    headerClassName: "MuiDataGrid-header",
    headerAlign: "center",
    type: "text",
    cellClassName: "MuiDataGrid-tablecell MuiDataGrid-cell--textCenter ",
    minWidth: 120,
    flex: 2,
    renderCell: (params) => {
      var cellRender;
      if (params.value === "Weekday") {
        cellRender = <div className="weekday">Weekday</div>;
      } else if (params.value === "Weekend") {
        cellRender = <div className="weekend">Weekend</div>;
      } else if (params.value === "Night Overtime") {
        cellRender = <div className="night-overtime">Night Overtime</div>;
      } else if (params.value === "Public Holiday") {
        cellRender = <div className="public-holiday">Public Holiday</div>;
      }
      return cellRender;
    },
  },
];

function OTRequestTable(props) {
  return (
    <>
      <DataGrid
        getRowId={(row) => row.overtimeId}
        rows={props.listOT.map((request, index) => ({
          ...request,
          month: request.fromTime.substring(5, 7),
          date: request.fromTime.substring(0, 10),
          status: listStatus.filter(
            (st) => st.id === request.statusDefinitionId
          )[0]?.name,
        }))}
        rowHeight={40}
        columns={columns}
        pageSize={15}
        rowsPerPageOptions={[7]}
      />
      <CustomToastComponent />
    </>
  );
}

export default OTRequestTable;
