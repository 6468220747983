import axiosClient from "./axiosClient";

const DashBoardAPI = {
  //GET SUMMARY CALENDAR INIT
  async getSummaryCalendar(month, year) {
    const url = `/Reports/GetReportLeaveformOverviewAndCalendarByMonth/${year}/${month}`;
    return axiosClient.get(url);
  },
};
export default DashBoardAPI;
