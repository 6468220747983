import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

export default function showNotification(message) {
  const notification = new Notification("T&T Design Company.", {
    body: message,
    icon: "https://app.ttdesignco.com/static/media/TTDLogo2.a9d87b30.jpg",
  });
}
export const ToastShowSuccess = (message) =>
  toast.success(message, {
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    transition: Flip,
  });

export const ToastShowError = (message, time) =>
  toast.error(message, {
    position: "bottom-center",
    autoClose: time || 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    transition: Flip,
  });
