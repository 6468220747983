import axiosClient from "./axiosClient";

const UserOverTimeAPI = {
  async getListOverTime(params) {
    const dateFrom = params.dateFrom;
    const dateTo = params.dateTo;
    const userId = params.userId;
    // const userId = JSON.parse(localStorage.getItem("user")).userId;

    const url = `/Overtime/GetListOvertimesByDateRangeAndUserId?fromTime=${dateFrom}&toTime=${dateTo}&userId=${userId}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async getListOverTimeType(params) {
    const url = `/OvertimeType`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async postOverTimeRequest(params) {
    const url = "/Overtime";
    return axiosClient.request({
      method: "POST",
      url: url,

      data: params,
    });
  },

  async putOverTimeRequest(params) {
    const url = `/Overtime/UpdateOvertimeRequest/${params.overtimeId}`;
    return axiosClient.request({
      method: "PUT",
      url: url,

      data: params,
    });
  },
};
export default UserOverTimeAPI;
