import classes from "../CalendarService.module.scss";
import { Stack, Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import CalendarNote from "./CalendarNote";

function DateCalendar(props) {
  const [dayObj, setDayObj] = useState(
    props.date || format(new Date(), "yyyy/MM/dd")
  );
  const [status, setStatus] = useState(props.status || "");
  const [listNote, setListNote] = useState(props.listNote || []);
  const [user, setUser] = useState(props.user || null);
  const [isAuth, setIsAuth] = useState(props.isAuth || false);

  const onCreatNewNoteByDate = () => {
    if (!isAuth) {
      return;
    }
    props.createNewFormHander(format(dayObj, "yyyy/MM/dd"));
  };

  const onPropsChange = useCallback(() => {
    setDayObj(props.date || format(new Date(), "yyyy/MM/dd"));
    setStatus(props.status || "");
    setListNote(props.listNote || []);
    setUser(props.user || null);
    setIsAuth(props.isAuth || false);
  }, [props]);

  useEffect(() => {
    onPropsChange();
  }, [onPropsChange]);

  return (
    <div
      className={`${classes["date__container"]} 
                ${
                  status === "active"
                    ? classes["date__container--active"]
                    : status === "inactive"
                    ? classes["date__container--fade"]
                    : ""
                }  ${
        dayObj.getDay() === 0 || dayObj.getDay() === 6
          ? classes["date__container--weekend"]
          : ""
      }`}
    >
      <Box
        className={`${classes["date"]}`}
        onDoubleClick={onCreatNewNoteByDate}
      >
        <div className={classes["date__text"]}>{format(dayObj, "dd")}</div>
        {listNote.length > 0 ? (
          <Stack spacing={0.25} className={classes["scrollable"]}>
            {listNote.map((d, index) => (
              <CalendarNote
                key={`${d}_${index}`}
                calendarNote={d}
                user={user}
                onChangePrivacy={props.changePrivacyHandler}
                onOpenNoteForm={props.openNoteFormHandler}
                onDoubleClick={props.openNoteFormHandler}
              />
            ))}
          </Stack>
        ) : null}
      </Box>
    </div>
  );
}

export default DateCalendar;
