import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./style.scss";
import { Controller } from "react-hook-form";

const useStyle = makeStyles((theme) => ({
  input__all: {
    margin: "5px 0",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  input_label: {
    marginBottom: "3px",
    fontWeight: 600,
    fontSize: "16px",
  },
}));

function ListDataSectionField(props) {
  const classes = useStyle();

  const {
    name,
    form,
    label,
    disable,
    listSelection,
    fontWeight,
    color,
    initIndex,
  } = props;
  const [selected, setSelected] = React.useState(initIndex || 0);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelected(value);

    if (form)
      form.setValue(name, listSelection[value], { shouldValidate: true });
  };

  return (
    <div className={classes.input__all}>
      <div className={classes.input_label}>{label}</div>
      <Controller
        control={form.control}
        name={name}
        render={() => (
          <FormControl fullWidth>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selected}
              onChange={handleChange}
            >
              {listSelection.map((item, index) => (
                <MenuItem key={index} value={index}>
                  <p style={{ fontWeight: fontWeight, color: color[index] }}>
                    {item}
                  </p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </div>
  );
}

export default ListDataSectionField;
