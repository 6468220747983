import React from "react";
import logo from "./TT-Logo.png";
import "./ExportPdf.scss";

const columns = [
  {
    field: "id",
    headerName: "No.",
    headerClassName: "MuiDataGrid-header",
    headerAlign: "center",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 1,
  },
  {
    field: "leaveType",
    headerName: "Leave type",
    headerAlign: "center",
    headerClassName: "MuiDataGrid-header",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 3,
  },
  {
    field: "startTime",
    headerName: "Start time",
    headerAlign: "center",
    headerClassName: "MuiDataGrid-header",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 3,
  },
  {
    field: "endTime",
    headerName: "End time",
    headerClassName: "MuiDataGrid-header",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    headerAlign: "center",
    flex: 3,
  },
  {
    field: "hours",
    headerName: "Hours",
    headerClassName: "MuiDataGrid-header",
    headerAlign: "center",
    type: "number",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    headerClassName: "MuiDataGrid-header",
    headerAlign: "center",
    cellClassName: "MuiDataGrid-cell--textCenter MuiDataGrid-tablecell",
    flex: 2,
  },
  {
    field: "reason",
    headerName: "Reason",
    headerClassName: "MuiDataGrid-header",
    headerAlign: "center",
    cellClassName: "MuiDataGrid-tablecell",
    flex: 10,
  },
];

function sortObjbyProperties(item) {
  const columns = [
    "no",
    "leaveType",
    "fromDate",
    "toDate",
    "hours",
    "statusDefinitionId",
    "reason",
  ];
  return columns.reduce((target, key) => {
    if (key === "leaveType") {
      target[key] = item[key];
    } else if (key === "fromDate") {
      target[key] = item[key].replace("T", " ");
    } else if (key === "toDate") {
      target[key] = item[key].replace("T", " ");
    } else if (key === "hours") {
      if (!!item[key]) {
        target[key] = item[key].toFixed(2);
      } else {
        target[key] = item[key];
      }
    } else if (key === "statusDefinitionId") {
      target[key] = "Approved";
    } else {
      target[key] = item[key];
    }
    return target;
  }, {});
}

class ExportPdf extends React.Component {
  render() {
    const data = this.props.listRequestPrint;
    const dataSort = data
      .filter((item) => item.statusDefinitionId === 2)
      .map((item) => sortObjbyProperties(item));

    var userName = JSON.parse(localStorage.getItem("user")).fullName;
    var department;
    if (JSON.parse(localStorage.getItem("user")).teamId === 1) {
      department = "R&D";
    } else if (JSON.parse(localStorage.getItem("user")).teamId === 2) {
      department = "CIM Team";
    } else if (JSON.parse(localStorage.getItem("user")).teamId === 3) {
      department = "BIM Team";
    } else if (JSON.parse(localStorage.getItem("user")).teamId === 4) {
      department = "HR";
    }
    else if (JSON.parse(localStorage.getItem("user")).teamId === 6) {
      department = "SBD";
    }

    return (
      <div className="export printonly">
        <div className="export__info">
          <div className="export__info--logo">
            <img src={logo} alt="Company logo"></img>
          </div>
          <div className="export__info--address">
            <p className="line_normal">
              T&T DESIGN CO., LTD.
              <br />
              13 Floor, 167 Trung Kinh Str, Yen hoa Ward, Cau Giay Dist, Hanoi
              City City
              <br />
              Phone: (84) 24 6278 8699
            </p>
          </div>
          <div className="export__info--box"></div>
        </div>

        <div className="export__content">
          <div className="export__content--title">
            <b>LIST OF LEAVE REQUEST SUBMITTED</b>
          </div>
          <div className="export__content--year">
            <b>Year : {this.props.yearPrint} </b>
          </div>
          <div className="export__content--name">
            <b>Name of employee: {userName}</b>
          </div>
          <div className="export__content--dept">
            <b>Dept: {department}</b>
          </div>
          <div className="export__content--table"></div>
        </div>

        <div className="export__table">
          <table id="list-leave">
            <thead>
              <tr>
                {columns.map((item) => (
                  <th key={item.headerName}>{item.headerName}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataSort.map((item) => (
                <tr key={item.no}>
                  {Object.values(item).map((val) => (
                    <td key={Math.random()}>{val}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="export__signature">
          <div className="export__signature--employee">
            <b>Employee</b>
          </div>
          <div className="export__signature--hr">
            <b>Confirmed by HR</b>
          </div>
          <div className="export__signature--leader">
            <b>Confirmed by Leader</b>
          </div>
        </div>
      </div>
    );
  }
}

export default ExportPdf;
