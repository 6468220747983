import axiosClient from "./axiosClient";
import StorageKeys from "F_UTILS/constants/StorageKeys";

const CalendarAPI = {
  async getListVwCalendarAssignmentByUserId() {
    const userId = JSON.parse(localStorage.getItem("user")).userId;

    const accessToken = localStorage.getItem(StorageKeys.access);
    const url = `/Calendar/GetListVwCalendarAssignmentByUserId/${userId}`;
    return axiosClient.request({
      method: "GET",
      url: url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  async getListVwCalendarAssignmentByUserIdAndMonth(params) {
    const { userId, month } = params;

    const url = `/Calendar/GetListVwCalendarAssignmentByUserIdAndMonth/${userId}/${month}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async updateCalendarAndAssignment(params) {
    const url = `/Calendar/UpdateCalendarAndAssignment`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params,
    });
  },

  async deleteCalendarById(calendarId) {
    const url = `/Calendar/DeleteCalendarAndAssignmentByCalendarId/${calendarId}`;
    return axiosClient.request({
      method: "DELETE",
      url: url,
    });
  },
};

export default CalendarAPI;
