import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import UserTable from "./UserTable";
import UserInformationPopup from "A_SHARED_COMPONENT/UserInformationPopup";
import SelectionTeam from "./SelectionTeamAndGroup";
import DeletePopup from "A_SHARED_COMPONENT/DeletePopup";
import AdminTeamAPI from "B_API/AdminTeamAPI";
import RoleAPI from "B_API/RolesAPI";
import { ToastShowError, ToastShowSuccess } from "F_UTILS/Notify";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";

function UserTab(props) {
  const [userInfo, setUserInfo] = useState({});
  const { listAllUsers, setListAllUser, initTeamId, listTeamInCompany } = props;
  const [isNewUser, setIsNewUser] = useState(true);
  const [newDialog, setNewDialog] = useState({
    show: false,
  });
  const [teamSelected, setTeamSelected] = React.useState(initTeamId || 0);

  const [showDeletePopup, setShowDeletePopup] = useState({
    show: false,
  });

  const handleCloseDeletePopup = () => {
    setShowDeletePopup({ show: false });
  };

  const handleClose = () => {
    setNewDialog({ show: false });
  };

  const setShowSuccessPopup = () => {
    ToastShowSuccess("User info has been updated successfully.");
  };
  const setShowError = (message) => {
    ToastShowError(message || "User info updated failed.");
  };

  const handleCreateNewUser = () => {
    setUserInfo({
      name: "",
      birthday: "",
      empcode: "",
      team: "",
      role: "",
      phone: "",
      email: "",
      address: "",
      cmnd: "",
      ngaycap: "",
    });
    setIsNewUser(true);
    setNewDialog({ show: true });
  };

  const [listRole, setListRole] = useState([]);
  useEffect(() => {
    const fetchRoles = async () => {
      const response = await RoleAPI.getAllRoles();
      if (response.status === 200) setListRole(response.data);
    };

    fetchRoles();
  }, []);
  let popup = null;
  if (newDialog.show === true) {
    popup = (
      <UserInformationPopup
        listTeamInCompany={listTeamInCompany}
        open={newDialog.show}
        userInfo={userInfo}
        onClose={handleClose}
        isNew={isNewUser}
        setListAllUser={setListAllUser}
        setShowSuccessPopup={setShowSuccessPopup}
        setShowError={setShowError}
        listRole={listRole}
      />
    );
  }

  let deletePopup = null;
  if (showDeletePopup.show === true) {
    deletePopup = (
      <DeletePopup
        open={showDeletePopup.show}
        onClose={handleCloseDeletePopup}
        setShowSucessPopup={setShowSuccessPopup}
        setListAllUser={setListAllUser}
        teamSelected={teamSelected}
        data={userInfo}
      />
    );
  }

  const [listTeams, setListTeams] = React.useState([]);
  React.useEffect(() => {
    const fetchListTeams = async () => {
      try {
        const response = await AdminTeamAPI.getTeamOrGroup(1);
        if (response.status === 200) setListTeams(response?.data);
      } catch (error) {
        console.log("Failed to fetch list teams");
      }
    };
    fetchListTeams();
  }, []);

  return (
    <div className="user-content__user">
      <div className="user-content__user--selection">
        <div className="team-selection">
          <SelectionTeam
            width={200}
            setListAllUser={setListAllUser}
            teamSelected={teamSelected}
            setTeamSelected={setTeamSelected}
            listTeams={listTeams}
          />
        </div>
        <div className="user-summary">
          <h5>Total of user : {listAllUsers.length} user</h5>
        </div>
        <div className="user-create">
          <Button
            className="button--user--create"
            variant="contained"
            startIcon={<PersonAddAltIcon />}
            sx={{ width: "150px", marginLeft: "5px" }}
            size="small"
            onClick={handleCreateNewUser}
          >
            Create New
          </Button>
        </div>
      </div>

      <div className="user-content__user--table">
        <UserTable
          setUserInfo={setUserInfo}
          setShowDeletePopup={setShowDeletePopup}
          setIsNewUser={setIsNewUser}
          popup={setNewDialog}
          listAllUsers={listAllUsers}
          setListAllUser={setListAllUser}
        ></UserTable>
      </div>
      {popup}
      {deletePopup}
      <CustomToastComponent />
    </div>
  );
}

export default UserTab;
