// api/axiosClient.js
import axios from "axios";
import StorageKeys from "F_UTILS/constants/StorageKeys";
import queryString from "query-string";
import TokenServices from "./TokenServices";
import { refreshToken } from "E_REDUX_STORE/userSlice";
import store from "E_REDUX_STORE/store";
import UrlConstant from "F_UTILS/constants/UrlConstant";

// Set up default config for http requests here
// Please have a look at here `https://github.com/axios/axios#request config` for the full list of configs

const axiosClient = axios.create({
  baseURL: UrlConstant.REACT_APP_API_URL,
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, PATCH, DELETE, OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin,DNT,X-Mx-ReqToken,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type",
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
  const accessToken = await localStorage.getItem(StorageKeys.access);
  // const token = await TokenServices.getLocalAccessToken();

  try {
    if (!!accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
  } catch (error) {
    alert("Error In Axios config");
  }
  return config;
});

axiosClient.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const actions = refreshToken({
            token: localStorage.getItem(StorageKeys.refresh),
            userEmail: TokenServices.getUser()?.email,
          });
          const result = await store.dispatch(actions);
          return axiosClient(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default axiosClient;
