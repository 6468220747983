import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";

function SelectPerson({ listUsers, setUserId }) {
  const allValue = listUsers.find((user) => user.userId === 0);

  const [UserSelected, setUserSelected] = React.useState(allValue.userId);

  const handleSelectUser = (index) => {
    setUserSelected(index);

    let userIdSelected = listUsers.find(
      (person) => person.userId === index
    ).userId;

    setUserId(userIdSelected);
  };

  return (
    <FormControl fullWidth className="condition__dropdown--box__1">
      <Select
        value={UserSelected}
        onChange={(e) => {
          handleSelectUser(e.target.value);
        }}
      >
        {listUsers.map((item, index) => (
          <MenuItem key={index} value={item.userId}>
            <p>{item.fullName}</p>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectPerson;
