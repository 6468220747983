import React from "react";
import "A_SHARED_COMPONENT/shared_component.scss";
import { Button, Dialog } from "@mui/material";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

ConfirmPopup.propTypes = {};

function ConfirmPopup({
  onClose,
  open,
  onConfirmed,
  title,
  icon,
  description,
}) {
  const handleClose = () => {
    onClose();
  };
  const handlesConfirmed = () => {
    if (onConfirmed) onConfirmed();

    // if (fromPage === "Leave Form") {
    //   setShowSuccess({ show: true });
    // }
    // if (fromPage === "OverTime") {
    //   setShowSuccess({ show: true });
    // }
  };
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
      keepMounted
    >
      <div className="shared__confirm-popup">
        <div className="confirm-popup__tile">{title || "Are you sure?"}</div>
        <div className="confirm-popup__icon">
          {icon || <i className="far fa-question-circle"></i>}
        </div>
        <div className="confirm-popup__description">
          {description || "Do you want to change the data?"}
        </div>
        <div className="confirm-popup__footer">
          <Button
            className="button--cancel"
            variant="contained"
            onClick={handleClose}
            color="error"
            sx={{ width: "100px" }}
          >
            CANCEL
          </Button>
          <Button
            className="button--delete"
            variant="contained"
            onClick={handlesConfirmed}
            sx={{ width: "100px" }}
          >
            OK
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default ConfirmPopup;
