import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { unwrapResult } from "@reduxjs/toolkit";
import ConfirmPopup from "A_SHARED_COMPONENT/ConfirmPopup";
import ErrorPopup from "A_SHARED_COMPONENT/ErrorPopup";
import ProfileAvatar from "A_SHARED_COMPONENT/ProfileAvatar";
import SuccessPopup from "A_SHARED_COMPONENT/SuccessPopup";
import TextInputField from "A_SHARED_COMPONENT/TextInputField";
import {
  getListViewUserCombineTeam,
  getUserCredentialByEmail,
  updateUserCredentialByCommonUserAsync,
} from "E_REDUX_STORE/userSlice";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import "../../UserInformationService.scss";
import { ToastShowError, ToastShowSuccess } from "F_UTILS/Notify";
import { CustomToastComponent } from "A_SHARED_COMPONENT/CustomToastComponent";

UserProfileAccount.propTypes = {};

function UserProfileAccount(props) {
  const user = useSelector((state) => state.user.current);
  const userStatus = useSelector((state) => state.user.status);
  const error = useSelector((state) => state.user.error);
  const dispatch = useDispatch();

  let initPropsObj = {
    avatar: props.avatar || "",
    fullName: props.name || "",
    phoneNumber: props.phone || "",
    aboutMe: props.aboutMe || "",
  };
  const [userProfile, setUserProfile] = useState(initPropsObj);

  const [propsObj, setPropsObj] = useState(initPropsObj);

  const onPropsChange = useCallback(() => {
    setPropsObj(initPropsObj);
    if (initPropsObj != props) {
      setUserProfile({
        avatar: props.avatar || "",
        fullName: props.name || "",
        phoneNumber: props.phone || "",
        aboutMe: props.aboutMe || "",
      });
    }
  }, [props]);

  const schema = yup
    .object()
    .shape({
      fullName: yup.string().required("please enter the name."),
    })
    .required();

  const form = useForm({
    defaultValues: {
      ...userProfile,
    },
    resolver: yupResolver(schema),
  });

  const lastName = JSON.parse(localStorage.getItem("user"))
    .fullName.trim()
    .split(" ")
    .pop()
    .charAt(0);

  const handleSubmitForm = async (values) => {
    //CONFIRM POPUP

    const data = {
      ...user,
      fullName: values.fullName,
      phoneNumber: values.phoneNumber,
      aboutMe: values.aboutMe,
    };

    const callBackFunction = async () => {
      try {
        const action = updateUserCredentialByCommonUserAsync(data);
        const resultAction = await dispatch(action);
        const result = unwrapResult(resultAction);

        const action2 = getListViewUserCombineTeam();
        const result2 = await dispatch(action2);
        ToastShowSuccess("User info has been updated.");
      } catch (err) {
        ToastShowError("User info updated failed.");
      }
    };
    callBackFunction();
  };

  const avatarUploadHandle = async () => {
    const actions = getUserCredentialByEmail(user.email);
    const resultAction = await dispatch(actions);
  };

  useEffect(() => {
    onPropsChange();
  }, [onPropsChange]);

  return (
    <>
      <Box className="profile">
        <div className="profile__form">
          <ProfileAvatar
            avatar={userProfile.avatar}
            lastName={lastName}
            onUploadSubmit={avatarUploadHandle}
          />
          <div className="form__input">
            <TextInputField
              name="fullName"
              label="Name"
              form={form}
              width="50%"
              // disable={disable}
            />
          </div>
          <div className="form__input">
            <TextInputField
              name="phoneNumber"
              label="Phone"
              form={form}
              width="50%"
              // disable={disable}
            />
          </div>
          <div className="form__input">
            <TextInputField
              name="aboutMe"
              label="About me"
              form={form}
              width="50%"
              rows={3}
              // disable={disable}
            />
          </div>
        </div>

        <div className="form__button">
          <Button
            className="button"
            variant="contained"
            onClick={form.handleSubmit(handleSubmitForm)}
          >
            Save
          </Button>
        </div>
      </Box>
      <CustomToastComponent />
    </>
  );
}

export default UserProfileAccount;
