import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Button, Grid, Tooltip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box } from "@mui/system";
import TagContent from "A_SHARED_COMPONENT/TagContent";
import ExportAPI from "B_API/ExportAPI";
import { ToastShowError } from "F_UTILS/Notify";
import React, { useState } from "react";

OverViewLeaveFormTab.propTypes = {};

function OverViewLeaveFormTab(props) {
  let curYear = new Date().getFullYear();
  const listYear = [curYear, curYear - 1];

  const [loading, setLoading] = useState(false);

  const [yearSelected, setYearSelected] = React.useState(listYear[0]);

  const handleChange = async (event) => {
    setYearSelected(event.target.value);
  };

  const handleExport = async () => {
    await setLoading(true);
    const response = await ExportAPI.exportLeaveDaysManagament(yearSelected);
    await setLoading(false);
    if (response.status === 200) {
      const url = response.data.link;
      window.open(url);
    } else {
      ToastShowError("Error when export report");
    }
  };

  return (
    <>
      <div className="admin__timesheet--reporttab">
        <div className="reporttab__filter">
          <div className="reporttab__filter--condition">
            <TagContent
              width="60% "
              height="150px"
              title="EXPORT LEAVE DAYS MANAGEMENT"
              dataFlow="TopToDown"
            >
              <Grid container style={{ marginLeft: 1, marginTop: 25 }}>
                <Grid item xs={3}>
                  <Box sx={{ width: "150px" }}>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        sx={{
                          fontWeight: "600 !important",
                        }}
                        value={yearSelected}
                        onChange={handleChange}
                      >
                        {listYear.map((year) => {
                          return (
                            <MenuItem
                              sx={{ fontWeight: 600 }}
                              value={year}
                              key={year}
                            >
                              {year}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item xs={5}>
                  {loading ? (
                    <Tooltip title="Exporting data to Google sheet, please wait...">
                      <span>
                        <Button
                          className="reporttab__filter--button-save"
                          variant="contained"
                          size="small"
                          color="success"
                          startIcon={<ExitToAppIcon />}
                          onClick={handleExport}
                          disabled={true}
                          sx={{
                            height: "40px",
                            lineHeight: "1",
                            width: "150px",
                          }}
                        >
                          Export
                        </Button>
                      </span>
                    </Tooltip>
                  ) : (
                    <Button
                      className="reporttab__filter--button-save"
                      variant="contained"
                      size="small"
                      color="success"
                      startIcon={<ExitToAppIcon />}
                      onClick={handleExport}
                      sx={{
                        height: "40px",
                        lineHeight: "1",
                        width: "150px",
                      }}
                    >
                      Export
                    </Button>
                  )}
                </Grid>
              </Grid>
            </TagContent>
          </div>
        </div>
      </div>
    </>
  );
}

export default OverViewLeaveFormTab;
