import React from 'react'
import styled from 'styled-components';
import { ToastContainer } from "react-toastify";

const StyledContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {}
  .Toastify__toast--success {
    background: #5BB318;
  }  
  .Toastify__toast--error {
    background: #EC7272;
  }
`;

export const CustomToastComponent = () => {
  return (
    <StyledContainer style={{ width: "500px" }} />
  )
}
